import type { PluginListenerHandle } from "@capacitor/core";

export interface AudioEnginePlugin {
  /** Test function. Prints the given and returns it */
  echo(options: { value: string }): Promise<{ value: string }>;

  /** Configuration */
  setServerUrl(options: { serverUrl: string }): Promise<void>;

  initAudio(): Promise<void>;
  preloadAudio(options: PreloadOptions): Promise<void>;
  preloadCountOff(options: CountOffPreloadOptions): Promise<void>;
  setPlayCountoff(options: {active: boolean, duration: number}): Promise<void>;
  play(): Promise<void>;
  pause(): Promise<void>;
  stop(): Promise<void>;
  stopEngine(): Promise<void>;
  setTrackActive(options: {trackid: number, state: boolean}): Promise<void>;
  unloadAudio(): Promise<void>;
  setTempo(options: {tempo: number}): Promise<void>;
  
  getDuration(): Promise<{ value: number}>;
  jumpTo(value: {seconds: number}): Promise<void>;
  setVolume(options: {trackid: number, volume: number}): Promise<void>;
  getCurrentTime(): Promise<{ value: number}>;

  setLoop(value: {active: boolean}): Promise<void>;
  setLoopTimes(value: {from: number, to: number}): Promise<void>;

  setPlayingInfo(options: PlayingInfo): Promise<void>;

  //Download
  getIsTrackDownloaded(options: {trackID: number, path: string, fileMD5: string}): Promise<{ downloaded: boolean}>;
  downloadTrack(options: {trackID: number, accessToken: string, path: string, fileMD5: string, saveBase64Data: boolean}): Promise<void>;
  cancelDownload(options: {id: string}): Promise<void>;
  downloadCountOff(options: CountOffPreloadOptions): Promise<void>;
  removeFiles(options: {path: string}): Promise<void>;

  //Preview (Shop)
  playPreview(options: {tuneID: number, accessToken: string}): Promise<{ duration: number }>;
  stopPreview(): Promise<void>;
  getPreviewProgress(): Promise<{ tuneID: number, value: number }>;

  //Recording
  setupRecording(options: {tuneID: number}): Promise<void>;
  hasRecording(options: {tuneID: number}): Promise<{ exists: boolean, duration: number }>;
  playRecording(options: {tuneID: number}): Promise<void>;
  pauseRecordingPlayer(): Promise<void>;
  getCurrentRecPlayerTime(): Promise<{ value: number}>;
  setCurrentRecPlayerTime(options: {time: number}):  Promise<void>;
  createRecordingDownload(options: {tuneID: number}): Promise<{ url: string}>;

  //Analysis
  getSampleRate(options: {base64data: string}): Promise<{ sampleRate: number}>;
  getAudioDuration(options: {base64data: string}): Promise<{ duration: number}>;
  getBase64Data(options: {trackID: number}): Promise<{base64data: string}>;

  addListener(
    eventName: 'playPause',
    listenerFunc: (state: {state: boolean}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'jumpToNextPart',
    listenerFunc: () => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'jumpToPreviousPart',
    listenerFunc: () => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'setLoop',
    listenerFunc: (state: {state: boolean}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'audioLoaded',
    listenerFunc: (state: {trackid: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'countOffLoaded',
    listenerFunc: () => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'audioLoadProgress',
    listenerFunc: (state: {trackid: number, progress: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'trackInitialized',
    listenerFunc: (state: {trackid: number, duration: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'countOffInitialized',
    listenerFunc: () => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'previewStopped',
    listenerFunc: (state: {tuneID: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'recordingReady',
    listenerFunc: () => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'recordingPlayPause',
    listenerFunc: (state: {playing: boolean}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'currentTime',
    listenerFunc: (state: {time: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(
    eventName: 'currentRecTime',
    listenerFunc: (state: {time: number}) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  removeAllListeners(): Promise<void>;
}

export interface PreloadOptions {
  accessToken: string,
  assetPath: string,
  assetId: number,
  fileMD5: string,
  volume?: number,
  audioChannelNum?: number,
  isUrl?: boolean
}

export interface CountOffPreloadOptions {
  accessToken: string,
  tuneSpeedId: number,
  filePath: string,
  fileMD5: string
}

export interface PlayingInfo {
  songName: string,
  albumName: string,
  artistName: string,
  coverPath: string,
  token: string
}
