import { CachingService } from './services/caching/caching.service';
import { Platform, IonicModule } from '@ionic/angular';
import { LanguageService } from './services/languages/language.service';
import { Component, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { LibraryService } from './services/library.service';

import { TranslateService} from '@ngx-translate/core';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { EventBusService } from './services/events/event-bus.service';
import { StatusBar, Style } from '@capacitor/status-bar';

import { register } from 'swiper/element/bundle';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ConnectivityService } from './services/connectivity/connectivity.service';
import { UserMenuComponent } from './components/user/user-menu/user-menu.component';
import { NgIf } from '@angular/common';
import { FcmTokensService } from './services/notifications/fcm-tokens.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { AudioService } from './services/audio/audio.service';
import { UserMessagesService } from './services/social/user-messages.service';
import { UpdateService } from './services/notifications/update.service';
import { Keyboard } from '@capacitor/keyboard'
import { AuthenticationService } from 'bandon-shared';
import { Directory, Filesystem } from '@capacitor/filesystem';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        UserMenuComponent,
    ],
})
export class AppComponent implements OnInit, OnDestroy{
  zone = inject(NgZone)
  audioService = inject(AudioService)
  updateService = inject(UpdateService)
  userMessagesService = inject(UserMessagesService)

  eventBusSub?: Subscription;
  isAuth = true;

  public getScreenWidth: any;
  public getScreenHeight: any;

  private unsubscribe$ = new Subject<void>();

  private connectionCheckInterval: Subscription;

  constructor(
    private libraryService: LibraryService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private eventBusService: EventBusService,
    private authService: AuthenticationService,
    private cachingService: CachingService,
    private platform: Platform,
    private router: Router,
    private connServ: ConnectivityService,
    private fcmService: FcmTokensService
  ) {
    if(!this.isWeb) {
      StatusBar.setStyle({ style: Style.Dark });
    }

    this.languageService.setInitialAppLanguage();

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.toggleDarkTheme(prefersDark.matches);
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
  }

  get showMenu(): boolean {
    return this.getScreenWidth >= 768;
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  get language(): string {
    return this.translate.currentLang;
  }

  ngOnInit() {
    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.authService.logout();
    });

    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( auth => {
        this.isAuth=auth;
//        console.log('isAuth: ', auth, this.isAuth);
      });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    this.connectionCheckInterval = interval(10000).subscribe(() => {
      this.connServ.checkConnection();
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          console.log(`appUrlOpen ${event.url}`)
          if (event.url && this.isFileUrl(event.url)) {
            this.handleFileUrl(event.url);
          } else {
            const slug = event.url.split("app.band-on.com").pop();
            if (slug) {
              console.log(slug)
              this.router.navigateByUrl(slug);
            }

          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });

    // Listen for the custom native notification if `appUrlOpen` doesn’t work
    document.addEventListener('FileOpenNotification', (event: any) => {
      const fileUrl = event.detail as string;
      this.handleFileUrl(fileUrl);
    });

    App.addListener('appStateChange', ({ isActive }) => {
      if(!isActive && !this.audioService.isPlaying) {
        this.audioService.stopEngine()
      }

      if(isActive) {
        this.userMessagesService.startTimer()
      } else {
        this.userMessagesService.pauseTimer();
      }
    });

    this.platform.ready().then(() => {
      this.updateService.checkForUpdate();
    });

    this.platform.resume.subscribe(() => {
      this.updateService.checkForUpdate();
    })

    this.authService.checkAuthenticated(true);

//    this.printDeviceInfo();
  }

  ngOnDestroy(): void {
    if (this.eventBusSub) {
      this.eventBusSub.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.connectionCheckInterval.unsubscribe();
  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  async printDeviceInfo() {
    const id = await Device.getId();
    const info = await Device.getInfo();

    console.log('Device: ', id, info);
  };

  isFileUrl(url: string): boolean {
    // A file URL usually starts with "file://" on iOS when the file is shared to the app
    return url.startsWith('file://');
  }

  async handleFileUrl(fileUrl: string) {
    // Here, you can use the Filesystem plugin to read the file data
    try {
      const path = decodeURI(fileUrl.replace('file://', '')).trim();
      const filename = path.substring(path.lastIndexOf('/') + 1);
      console.log(path, filename)
      // Step 1: Copy the file to a persistent location
/*      const copiedFile = await Filesystem.copy({
        from: path,
        to: 'myAudioFile.mp3',
        directory: Directory.Cache // Store in app's Documents directory
      });*/

//      console.log(path)
      // Use the Capacitor Filesystem plugin to read the file's content
      const fileData = await Filesystem.readFile({
        path: filename,
        directory: Directory.Documents
      });

      console.log('File content:', fileData.data);
      // You can now do something with the file data, like navigating to a specific page,
      // loading the audio, etc.
    } catch (error) {
      console.error('Error reading file:', error);
    }
  }
}
