<!--<ion-list class="level-list" lines="none" detail="false" no-padding no-margin *ngIf="hasLevels" >
  <ion-list-header >
    <div class="level-container" role="button" ariaLabel="{{ 'MIXER.OVERALLLEVEL' | translate: { level: ariaOverallLevel } }}" aria-haspopup="true">
      <app-level-icon
          [level]="overallLevel"
          [hasLevels]="true"
          id="popover-trigger-tune-level"
      >
      </app-level-icon>
    </div>
    <div aria-hidden="true">
      {{ 'MIXER.ALLLEVELS' | translate }}
    </div>
  </ion-list-header>

  <ion-popover  *ngIf="hasLevels" trigger="popover-trigger-tune-level" [dismissOnSelect]="true" size="auto" backdropDismiss="true">
    <ng-template>
      <ion-content scrollY="false">
        <ion-list>
          <ion-list-header class="menu-list-header">
            Level
          </ion-list-header>
          <ion-item *ngFor="let level of overallLevels"
            [button]="true"
            [detail]="false"
            (click)="switchTuneLevel(level)"
          >
          {{ level.designation }}
        </ion-item>
        <ion-item style="height: 0;">
          {{ 'PLAYER.CLOSE' | translate}}
        </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

</ion-list>-->

<ion-grid class="ion-margin-horizontal">
  @if(hasLevels) {
    <ion-row class="ion-align-items-center">
      <ion-col size="2">
        <div role="button" ariaLabel="{{ 'MIXER.OVERALLLEVEL' | translate: { level: ariaOverallLevel } }}" aria-haspopup="true">
          <app-level-icon
              [level]="overallLevel"
              [hasLevels]="true"
              id="popover-trigger-tune-level-{{ tuneID }}"
          >
          </app-level-icon>
        </div>
      </ion-col>
      <ion-col size="2"></ion-col>
      <ion-col size="8" class="ion-text-left group-designation">
        <div aria-hidden="true">
          {{ 'MIXER.ALLLEVELS' | translate }}
        </div>
      </ion-col>
    </ion-row>
  }

  <ion-popover  *ngIf="hasLevels" trigger="popover-trigger-tune-level-{{ tuneID }}" [dismissOnSelect]="true" size="auto" backdropDismiss="true">
    <ng-template>
      <ion-content scrollY="false">
        <ion-list>
          <ion-list-header class="menu-list-header">
            Level
          </ion-list-header>
          <ion-item *ngFor="let level of overallLevels"
            [button]="true"
            [detail]="false"
            (click)="switchTuneLevel(level)"
          >
          {{ level.designation }}
        </ion-item>
        <ion-item style="height: 0;">
          {{ 'PLAYER.CLOSE' | translate}}
        </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  @for (group of instrumentGroups; track $index) {
    <ion-row class="ion-align-items-center group-header">
      <ion-col size="2">
        <div role="button" ariaLabel="{{ 'MIXER.GROUPLEVEL' | translate: { designation: group.designation } }}" aria-haspopup="true">
          @if(hasGroupLevels(group)) {
            <app-level-icon *ngIf="hasGroupLevels(group)"
              [level]="group.groupLevel"
              [hasLevels]="group.linked"
              [id]="getPopoverTriggerID(group)"
            >
            </app-level-icon>
          }
        </div>
      </ion-col>
      <ion-col size="2" class="ion-text-center">
        @if(hasGroupMute(group)) {
          <ion-button fill="clear" expand="block" size="small"
            (click)="muteGroup(group)"
            [disabled]="group.solo">
            @if(group.muted) {
              <ion-icon name="volume-mute" class="mute-icon" slot="icon-only" color="primary" ariaLabel="{{ 'MIXER.UNMUTEGROUP' | translate: { designation: group.designation} }}"></ion-icon>
            } @else {
              <ion-icon name="volume-mute" class="mute-icon" slot="icon-only" color="light" ariaLabel="{{ 'MIXER.MUTEGROUP' | translate: { designation: group.designation} }}"></ion-icon>
            }
          </ion-button>
        }
      </ion-col>
      <ion-col size="6" class="ion-text-start group-designation">{{ group.designation }}</ion-col>
      <ion-col size="2" class="ion-text-center">
        @if(hasGroupSolo(group)) {
          @if(group.solo) {
            <ion-button fill="clear" expand="block" size="small" class="solo-icon"
                color="primary" (click)="soloGroup(group)"
                ariaLabel="{{ 'MIXER.UNSOLOGROUP' | translate: { designation: group.designation} }}">
              S
            </ion-button>
          } @else {
            <ion-button fill="clear" expand="block" size="small" class="solo-icon"
                color="light" (click)="soloGroup(group)"
                ariaLabel="{{ 'MIXER.SOLOGROUP' | translate: { designation: group.designation} }}">
              S
            </ion-button>
          }
        }
      </ion-col>
    </ion-row>
    @for (instrument of getTracksOfGroup(group); track $index) {
      <app-instrument-item *ngIf="instrument.shown" [track]="instrument">
      </app-instrument-item>
    }
  }
  <ion-row>
    <ion-col size="12" class="ion-no-padding">
      <ion-button expand="full" color="secondary" (click)="resetMix()">{{ 'PLAYER.RESETMIX' | translate }}</ion-button>
    </ion-col>
  </ion-row>

  <ion-row class="group-header">
    <ion-col size="4"></ion-col>
    <ion-col size="8" class="ion-text-left group-designation">
      {{ 'MIXER.MYRECORDINGS' | translate }}
    </ion-col>
  </ion-row>
  @if(hasRecording) {
    <app-recording-item *ngIf="hasRecording"></app-recording-item>
  } @else {
    <ion-row>
      <ion-col size="12" class="ion-text-left">
        {{ 'MIXER.NORECORDINGS' | translate }}
      </ion-col>
    </ion-row>
  }
</ion-grid>

<!--<ion-list *ngFor="let group of instrumentGroups;" class="accordion-list" lines="none" detail="false" no-padding no-margin>

  <ion-list-header>
    <div class="level-container" role="button" ariaLabel="{{ 'MIXER.GROUPLEVEL' | translate: { designation: group.designation } }}" aria-haspopup="true">
      <app-level-icon *ngIf="hasGroupLevels(group)"
          [level]="group.groupLevel"
          [hasLevels]="group.linked"
          [id]="getPopoverTriggerID(group)"
      >
      </app-level-icon>
    </div>
    <ion-button fill="clear"  class="group-mute-button" expand="block" size="small"
        (click)="muteGroup(group)" *ngIf="hasGroupMute(group)"
        [disabled]="group.solo">
        @if(group.muted) {
          <ion-icon name="volume-mute" class="mute-icon" color="primary" ariaLabel="{{ 'MIXER.UNMUTEGROUP' | translate: { designation: group.designation} }}"></ion-icon>
        } @else {
          <ion-icon name="volume-mute" class="mute-icon" color="light" ariaLabel="{{ 'MIXER.MUTEGROUP' | translate: { designation: group.designation} }}"></ion-icon>
        }
    </ion-button>
    <div style="position: absolute; left: 140px; margin-bottom: 5px">
      {{ group.designation }}
    </div>
    @if(hasGroupSolo(group)) {
      @if(group.solo) {
        <ion-button fill="clear"  class="group-solo-button" expand="block" size="small"
            color="primary" (click)="soloGroup(group)"
            ariaLabel="{{ 'MIXER.UNSOLOGROUP' | translate: { designation: group.designation} }}">
          S
        </ion-button>
      } @else {
        <ion-button fill="clear"  class="group-solo-button" expand="block" size="small"
            color="light" (click)="soloGroup(group)"
            ariaLabel="{{ 'MIXER.SOLOGROUP' | translate: { designation: group.designation} }}">
          S
        </ion-button>
      }
    }

  </ion-list-header>

  <ion-popover  *ngIf="hasGroupLevels(group)" [trigger]="getPopoverTriggerID(group)" [dismissOnSelect]="true" size="auto" backdropDismiss="true">
    <ng-template>
      <ion-content scrollY="false">
        <ion-list>
          <ion-list-header class="menu-list-header">
            Level
          </ion-list-header>
          <ion-item *ngFor="let level of getPossibleLevels(group)"
            [button]="true"
            [detail]="false"
            (click)="switchGroupLevel(group, level)">
          {{ level.designation }}
        </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  <div class="divider"></div>

  <div *ngFor="let instrument of getTracksOfGroup(group)" no-padding no-margin>
    <app-instrument-item *ngIf="instrument.shown" [track]="instrument">
    </app-instrument-item>
  </div>

</ion-list>-->

<!--<ion-list class="accordion-list" lines="none" detail="false">
  <ion-button expand="full" color="secondary" (click)="resetMix()">{{ 'PLAYER.RESETMIX' | translate }}</ion-button>
</ion-list>-->

<!--<ion-list class="accordion-list" lines="none" detail="false" no-padding no-margin>
    <ion-list-header>
      <div class="level-container">
      </div>
      <div style="position: absolute; left: 140px">
        {{ 'MIXER.MYRECORDINGS' | translate }}
      </div>
  </ion-list-header>
  <div class="divider"></div>

  <app-recording-item *ngIf="hasRecording"></app-recording-item>
  <ion-item *ngIf="!hasRecording">
    {{ 'MIXER.NORECORDINGS' | translate }}
  </ion-item>
</ion-list>-->
