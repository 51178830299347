import { Injectable, inject } from '@angular/core';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from, switchMap, of, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CachingService } from '../caching/caching.service';
import { AuthenticationService, UserGroup, UserModel } from 'bandon-shared';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDataService } from '../user/user-data.service';

export const OWNER_ROLE_ID = 2

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  connectivityService = inject(ConnectivityService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  cachingService = inject(CachingService)
  router = inject(Router)
  translate = inject(TranslateService)
  alertController = inject(AlertController)
  sanitizer = inject(DomSanitizer)
  userDataService = inject(UserDataService)
  loadingController = inject(LoadingController)

  public groupsBehaviour = new BehaviorSubject<UserGroup[]>([]);
  public groups$ = this.groupsBehaviour.asObservable();

  public isInitialized = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitialized.asObservable();

  private isOnline = false;
  private isAuth = false;

  private savingMessage: HTMLIonLoadingElement | undefined;

  constructor() {
    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
    });

    this.userDataService.user$.subscribe(user => {
      if(user) {
        this.refreshGroups(true)
      }
    })
  }

  refreshGroups(forceRefresh = false) {
    if(this.userDataService.user && this.isAuth) {
      this.getData(`${environment.apiURL}/usergroups`, true)
        .subscribe({
          next: data => {
            let groups = data as UserGroup[];
            let myGroups = groups.filter(group => {
              if(group.users && this.userDataService.user) {
                return group.users.some(gu => gu.user.uid === this.userDataService.user.uid);
              }
              return false;
            });

            // Update or add groups
            myGroups.forEach(newGroup => {
              const existingGroupIndex = this.groupsBehaviour.value.findIndex(g => g.id === newGroup.id);
              if (existingGroupIndex !== -1) {
                // Update the existing group
                if(this.groupsBehaviour.value[existingGroupIndex].designation != newGroup.designation) {
                  this.groupsBehaviour.value[existingGroupIndex].designation = newGroup.designation;
                }
                this.groupsBehaviour.value[existingGroupIndex].description = newGroup.description;
                this.groupsBehaviour.value[existingGroupIndex].img = newGroup.img;
                this.groupsBehaviour.value[existingGroupIndex].userlimit = newGroup.userlimit;
                this.groupsBehaviour.value[existingGroupIndex].tunelimit = newGroup.tunelimit;
                this.groupsBehaviour.value[existingGroupIndex].identifier = newGroup.identifier;
                this.groupsBehaviour.value[existingGroupIndex].imgSrc = newGroup.imgSrc;
                this.groupsBehaviour.value[existingGroupIndex].users = newGroup.users;
                this.groupsBehaviour.value[existingGroupIndex].sharings = newGroup.sharings;
              } else {
                // Add new group
                this.groupsBehaviour.value.push(newGroup);
              }
            });
            //Remove groups
            this.groupsBehaviour.value.forEach(g => {
              if(!myGroups.find(gr => gr.id==g.id)) {
                this.groupsBehaviour.value.splice(this.groupsBehaviour.value.indexOf(g), 1);
              }
            })

            // Notify the updated groups
            this.groupsBehaviour.next([...this.groupsBehaviour.value]);

            // Check if group pictures exist
            this.groupsBehaviour.value.forEach(group => this.checkIfGroupPictureExists(group));

            /*let groups = data as UserGroup[]
            let myGroups = groups.filter(group => {
              if(group.users && this.userDataService.user) {
                return group.users.filter(gu => gu.user.uid===this.userDataService.user.uid).length > 0
              }
            });
            this.groupsBehaviour.next(myGroups);
            this.groupsBehaviour.value.forEach(group => this.checkIfGroupPictureExists(group))*/
            if(!this.isInitialized.value) {
              this.isInitialized.next(true);
            }
          }
        })
    } else {
      this.groupsBehaviour.next([]);
    }
  }

  getGroup(groupID: number) {
    return this.groupsBehaviour.value.find(g => g.id===groupID);
  }

  updateGroup(group: UserGroup) {
    let groups = this.groupsBehaviour.value;
    let existingGroup = groups.find(g => g.id === group.id);
    if(existingGroup) {
      groups.splice(groups.indexOf(existingGroup), 1)
    }
    groups.push(group)
    groups = groups.sort((g1, g2) => g1.designation.localeCompare(g2.designation));
    this.groupsBehaviour.next(groups);
  }

  public handleInvitation(group_id: string, addingUsername, loading: HTMLIonLoadingElement | undefined = undefined) {
    if(group_id && addingUsername && this.userDataService.user) {
      console.log(group_id, addingUsername)
      const token = this.authService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);

      const queryParams = new HttpParams().set('addingUsername', addingUsername);

      const body = { sendNotification: 1 };

      this.httpClient.put(`${environment.apiURL}/usergroups/invitation/${group_id}`, body, { headers, params: queryParams })
        .subscribe({
          next: async resp => {
            this.refreshGroups(true);
            this.router.navigateByUrl('collection/user/group-list')
            if(loading) {
              loading.dismiss();
            }
          },
          error: err => {
            if(loading) {
              loading.dismiss();
            }
            this.handleGroupError(err)
          }
        });
    } else {
      if(loading) {
        loading.dismiss();
      }
    }
  }

  handleGroupError(err) {
    let msg = this.translate.instant('GROUPS.ERROROTHER')
    if(err.error) {
      if(err.error.id === 1) {
        msg = this.translate.instant('GROUPS.ERROR1')
      } else if(err.error.id === 8) {
        msg = this.translate.instant('GROUPS.ERROR8')
      } else if(err.error.id === 7) {
        msg = this.translate.instant('GROUPS.ERROR7')
      } else if(err.error.id) {
        msg = this.translate.instant('GROUPS.ERROR2')
      }
    }
    this.presentAlert(msg)
  }

  isUserPartOfGroup(user: UserModel, group: UserGroup): boolean {
    if (group && user && group.users.find(u => u.user.uid==user.uid)) {
      return true;
    }
    return false;
  }

  isUserIDPartOfGroup(userID: string, group: UserGroup): boolean {
    if (group.users.find(u => u.user.uid==userID)) {
      return true;
    }
    return false;
  }

  ownsGroup(user: UserModel): UserGroup | undefined {
    if(user) {
      for(let group of this.groupsBehaviour.value) {
        let owner = group.users.find(gu => gu.role?.id==OWNER_ROLE_ID);
        if(owner && owner.user.uid==user.uid) {
          return group;
        }
      }
    }
    return undefined;
  }

  async deleteGroup(group: UserGroup) {
    if(this.userDataService.user && this.ownsGroup(this.userDataService.user)) {
      await this.showSaving();

      const token = this.authService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);

      this.httpClient.delete<any>(environment.apiURL+`/usergroups/${group.id}`, {headers})
      .subscribe({
        next: resp => {
          this.refreshGroups(true);
          this.hideSaving();
        },
        error: err => {
          this.hideSaving();
          this.presentAlert(this.translate.instant('GROUPS.ERRORDELETE'))
          console.error(err)
        }
      });
    }
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: this.translate.instant('GROUPS.ERROR'),
      message: message,
      buttons: ['Ok'],
      cssClass: 'band-on-alert'
    });

    await alert.present();

    this.router.navigateByUrl('collection/user/group-list')
  }


  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache(url): Observable<any> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

  refreshGroupPictures() {
    this.groupsBehaviour.value.forEach(group => this.checkIfGroupPictureExists(group, true))
  }

  checkIfGroupPictureExists(group: UserGroup, forceRefresh = false) {
    if(group.img && !group.imgSrc) {
      const filename = group.img;
      if(!forceRefresh) {
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${environment.groupImgCacheFolder}/${filename}`,
        }).then(result => {
          const fileType = filename.split('.').pop();
          group.imgSrc =  `data:image/${fileType};base64,${result.data}`
        }, async err => {
          this.downloadGroupPicture(group, filename);
        });
      } else {
        this.downloadGroupPicture(group, filename);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async downloadGroupPicture(group: UserGroup, filename: string) {
    console.log(`Download group image: ${group.img}`)
    try {
      const fileExists = await Filesystem.readFile({
        directory: Directory.Cache,
        path: `${environment.groupImgCacheFolder}/${filename}`
      });
      if (fileExists) {
        // If the file exists, delete it
        await Filesystem.deleteFile({
          path: `${environment.groupImgCacheFolder}/${filename}`,
          directory: Directory.Cache,
        });
      }
    } catch(error) {
      console.log('Error deleting group image');
    }

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);
    this.httpClient.get(`${environment.apiURL}/usergroups/${group.id}/img`, { headers, responseType: 'blob'})
    .subscribe({
      next: async resp => {
        if (resp) {
          const base64Data = await this.convertBlobToBase64(resp) as string;
          Filesystem.writeFile({
            directory: Directory.Cache,
            path: `${environment.groupImgCacheFolder}/${filename}`,
            data: base64Data
          }).then( e => console.log('Group image saved'));
          let objectURL = URL.createObjectURL(resp);
          group.imgSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          console.log(`Updated group image source: `, group.imgSrc)
        }
      },
      error: err => {
        console.log('download group image Error');
      }
    });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  async showSaving() {
    this.savingMessage = await this.loadingController.create({
      message: this.translate.instant('GROUPS.SAVING'),
      cssClass: 'band-on-loading'
    });

    this.savingMessage.present();
  }

  hideSaving() {
    this.savingMessage.dismiss();
  }
}
