import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { AuthenticationService, Collection, Musician, SimpleTuneModel } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { TuneSku } from 'src/app/shared/interfaces/tune-sku';
import { StoreService } from 'src/app/services/store/store.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { LevelIconComponent } from '../../mixer/level-icon/level-icon.component';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { SafeHtml } from '@angular/platform-browser';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { Package } from '@revenuecat/purchases-js';
import { Capacitor } from '@capacitor/core';
import { LibraryService } from 'src/app/services/library.service';
import { Router } from '@angular/router';
import { ContactsService } from 'src/app/services/social/contacts.service';

@Component({
    selector: 'app-tune-details',
    templateUrl: './tune-details.component.html',
    styleUrls: ['./tune-details.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        CachedImageComponent,
        NgIf,
        LevelIconComponent,
        DecimalPipe,
        CurrencyPipe,
        TranslateModule,
    ],
})
export class TuneDetailsComponent  implements OnInit {

  @Input() tuneSku: TuneSku;
  @Input() tune: SimpleTuneModel;

  private modalCtrl = inject(ModalController);
  private storeService = inject(StoreService);
  private translateService = inject(TranslateService);
  private authService = inject(AuthenticationService);
  private bandonTranslations = inject(BandONTranslationsService);
  private connService = inject(ConnectivityService);
  private userDataService = inject(UserDataService)
  private libraryService = inject(LibraryService)
  private router = inject(Router)
  private contactsService = inject(ContactsService)

  collection: Collection | undefined = undefined;

  tune_owner = '';

  constructor(
  ) {}

  get title() {
    if(this.tune) {
      return this.bandonTranslations.getTuneTitle(this.tune);
    }
    return '';
  }

  get coverText(): string{
    if(this.tune) {
      const translation = this.tune.translations.find(e => e.languageid===this.language);
      if(translation) {
        return translation.covertext;
      }
      return this.tune.covertext;
    }
    return '';
  }

  get language() {
    let language = this.translateService.currentLang;
    if(this.userDataService.user) {
      language = this.userDataService.user.language;
    }
    return language;
  }

  get sku(): PurchasesPackage | Package {
    if(this.tuneSku) {
      return this.tuneSku.sku;
    }
    return undefined;
  }

  get price(): number {
    if(this.sku) {
      if(this.isWeb) {
        let sku = this.sku as Package;
        return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
      } else {
        let sku = this.sku as PurchasesPackage;
        return sku.product.price;
      }
    }
    return 0;
  }

  get currencyCode(): string {
    if(this.sku) {
      if(this.isWeb) {
        let sku = this.sku as Package;
        return sku.rcBillingProduct.currentPrice.currency;
      } else {
        let sku = this.sku as PurchasesPackage;
        return sku.product.currencyCode;
      }
    }
    return '';
  }

/*  get tune() {
    if(this.tuneSku) {
      return this.tuneSku.tune;
    }
    return null;
  }*/

  get tonalitiesString() {
    if(this.tune) {
      return `${ this.translateService.instant('PLAYER.TONALITY')}: ${ this.tune.tonalities }`;
    }
    return '';
  }

  get ariaTonalitiesString() {
    if(this.tune) {
      return this.bandonTranslations.getTonalityString(this.tune.tonalities)
    }
    return '';
  }

  get tempiString() {
    if(this.tune) {
      if(this.tune.speeds.split(',').length>1) {
        return `${this.translateService.instant('PLAYER.SPEEDS')}: ${ this.tune.speeds }`;
      }
      return `${this.translateService.instant('PLAYER.SPEED')}: ${ this.tune.speeds }`;
    }
    return '';
  }

  get hasLevels() {
    if(this.tune) {
      return this.tune.levelids.split(',').length>1;
    }
    return false;
  }

  get levelsString() {
    if(this.tune) {
      if(this.tune.levelids.split(',').length>1) {
        return this.translateService.instant('STORE.HASLEVELS');
      }
      return ``;
    }
    return '';
  }

  get isOnline(): boolean {
    return this.connService.isConnected();
  }

  get musiciansString(): String {
    if(this.tune) {
      return this.tune.musicians;
    }
    return '';
  }

  get ariaCovertext(): SafeHtml {
    // Create a temporary DOM element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = this.coverText;

    // Retrieve text content from the temporary element
    const textContent = tempElement.textContent || tempElement.innerText || '';

    // Clean up temporary element
    tempElement.remove();

    return textContent.trim();
  }

  get showCollection(): boolean {
    if(this.tune && !this.tuneSku && this.collection) {
      return true;
    }
    return false;
  }

  get collectionDesignation(): string {
    if(this.collection) {
      return this.bandonTranslations.getCollectionTitle(this.collection);
    }
    return '';
  }

  get isShared() {
    if(this.userDataService.user) {
      return this.tune.ownerid==this.userDataService.user.uid
    }
    return false;
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }


  ngOnInit(): void {
    //Check if tune is part of a collection?
    if(this.tune && !this.tuneSku) {
      this.collection = this.libraryService.getCollectionToTune(this.tune)
      this.contactsService.getUserString(this.tune.ownerid).then(owner => this.tune_owner=owner)
    }
  }

  dismiss() {
    return this.modalCtrl.dismiss();
  }

  getTuneIconSrc(tune: SimpleTuneModel | undefined): CachedImgSource {
    if (tune && tune.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+tune.picture.id, path: tune.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

  purchase() {
    this.storeService.purchaseTune(this.tuneSku);
  }

  openCollection(collection: Collection) {
    if(collection) {
      this.modalCtrl.dismiss();
      this.router.navigateByUrl(`collection/store/collection/${collection.id}`);
    }
  }

}
