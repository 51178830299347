import { SubscriptionPlan } from './../../../../node_modules/bandon-shared/lib/models/pricing-model.d';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService } from 'bandon-shared';
import { CachingService } from '../caching/caching.service';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { BehaviorSubject, from, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlansService {
  private authService = inject(AuthenticationService);
  private http = inject(HttpClient);
  private cachingService = inject(CachingService)
  private connectivityService = inject(ConnectivityService)

  public subscriptionPlans = new BehaviorSubject<SubscriptionPlan[]>([]);
  public subscriptionPlans$ = this.subscriptionPlans.asObservable();

  private isOnline = false;
  private isAuth = false;

  constructor() { }

  async init() {
    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
      this.getData<SubscriptionPlan[]>(`${environment.apiURL}/subscription_plans`, false)
        .subscribe(plans => {
          this.subscriptionPlans.next(plans.sort((p1, p2) => p1.membercount-p2.membercount));
        })
    });
    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
    });
  }

  getSubscription(id: number): SubscriptionPlan | undefined {
    return this.subscriptionPlans.value.find(p => p.id == id);
  }

  private getData<T>(url, forceRefresh: boolean): Observable<T> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache<T>(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url)) as Observable<T>;
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache<T>(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache<T>(url): Observable<T> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.http.get<T>(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }
}
