import { Package } from '@revenuecat/purchases-js';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { SubscriptionPlan } from 'bandon-shared';
import { filter, take } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-subscribe-buttons',
  templateUrl: './subscribe-buttons.component.html',
  styleUrls: ['./subscribe-buttons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  providers: [
    CurrencyPipe, DecimalPipe
  ]
})
export class SubscribeButtonsComponent  implements OnInit, OnChanges {
  storeService = inject(StoreService)
  currencyPipe = inject(CurrencyPipe)
  decimalPipe = inject(DecimalPipe)
  translateService = inject(TranslateService)

  @Input() selectedPlan: SubscriptionPlan | undefined = undefined;
  @Output() subscriptionPurchased: EventEmitter<void> = new EventEmitter<void>();

  selectedAnnualPlan: PurchasesPackage | Package | undefined = undefined;
  selectedMonthlyPlan: PurchasesPackage | Package | undefined = undefined;

  constructor() { }

  get price_annual(): number {
    if(this.selectedAnnualPlan) {
      if(this.isWeb) {
        let sku = this.selectedAnnualPlan as Package;
        return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
      } else {
        let sku = this.selectedAnnualPlan as PurchasesPackage;
        return sku.product.price;
      }
    }
    return 0;
  }

  get price_monthly(): number {
    if(this.selectedMonthlyPlan) {
      if(this.isWeb) {
        let sku = this.selectedMonthlyPlan as Package;
        return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
      } else {
        let sku = this.selectedMonthlyPlan as PurchasesPackage;
        return sku.product.price;
      }
    }
    return 0;
  }

  get currencyCode_annual(): string {
    if(this.selectedAnnualPlan) {
      if(this.isWeb) {
        let sku = this.selectedAnnualPlan as Package;
        return sku.rcBillingProduct.currentPrice.currency;
      } else {
        let sku = this.selectedAnnualPlan as PurchasesPackage;
        return sku.product.currencyCode;
      }
    }
    return '';
  }

  get currencyCode_monthly(): string {
    if(this.selectedMonthlyPlan) {
      if(this.isWeb) {
        let sku = this.selectedMonthlyPlan as Package;
        return sku.rcBillingProduct.currentPrice.currency;
      } else {
        let sku = this.selectedMonthlyPlan as PurchasesPackage;
        return sku.product.currencyCode;
      }
    }
    return '';
  }

  get monthly_aria() {
    if(this.selectedMonthlyPlan) {
      const formattedPrice = this.currencyPipe.transform(
        this.price_annual,
        this.currencyCode_annual,
        'symbol',
        '1.0-2'
      );

      // Translate with formatted price
      return this.translateService.instant('SUBSCRIPTIONMODALS.ARIAANNUALPRICE', {
        price_annual: formattedPrice,
      });
    }
    return this.translateService.instant('SUBSCRIPTIONMODALS.CHOOSESIZE')
  }

  get annual_aria() {
    if(this.selectedAnnualPlan) {
      const formattedPrice = this.currencyPipe.transform(
        this.price_annual,
        this.currencyCode_annual,
        'symbol',
        '1.0-2'
      );

      // Translate with formatted price
      return this.translateService.instant('SUBSCRIPTIONMODALS.ARIAMONTHLYPRICE', {
        price_monthly: formattedPrice,
      });
    }
    return this.translateService.instant('SUBSCRIPTIONMODALS.CHOOSESIZE')
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.selectedPlan) {
      this.selectPlan(this.selectedPlan)
    }
  }

  selectPlan(plan: SubscriptionPlan) {
    if(plan) {
      if(this.isWeb) {
        this.storeService.subscriptions_web$
        .pipe(
          filter(subscriptions => {
            if(subscriptions)
              return true;
            return false;
          }),
          take(1)
        )
        .subscribe((s) => {
          this.selectedMonthlyPlan = this.storeService.getSubscriptionOffer(plan.sku_monthly);
          this.selectedAnnualPlan = this.storeService.getSubscriptionOffer(plan.sku_annual);
        })
      } else {
        this.storeService.subscriptions$
        .pipe(
          filter(subscriptions => {
            if(subscriptions)
              return true;
            return false;
          }),
          take(1)
        )
        .subscribe(() => {
          this.selectedMonthlyPlan = this.storeService.getSubscriptionOffer(plan.sku_monthly);
          this.selectedAnnualPlan = this.storeService.getSubscriptionOffer(plan.sku_annual);
        })
      }
    } else {
      this.selectedAnnualPlan = undefined;
      this.selectedMonthlyPlan = undefined;
    }
  }

  async subscribe(period: 'annual' | 'monthly') {
    // Here you'd handle the subscription logic (In-App Purchases or a web-based subscription system)
    // You could call a service or method to trigger the subscription process

    try {
      // Example placeholder code for a subscription action
      const result = await this.handleSubscription(period);
      if(result) {
        this.subscriptionPurchased.emit();
      }
    } catch (err) {
      console.error('Subscription error:', err);
    }
  }

  async handleSubscription(period: 'annual' | 'monthly') {
//    console.log('handle subscription '+period, this.selectedPlan, this.selectedAnnualPlan, this.selectedMonthlyPlan)
    // You would implement actual logic here for handling the subscription, such as calling your
    // server or using a plugin for in-app purchases (IAP)
    let purchased = false;
    if(period=='annual' && this.selectedPlan && this.selectedAnnualPlan) {
      purchased = await this.storeService.purchaseSubscription(this.selectedAnnualPlan, this.selectedPlan);
    } else if(this.selectedPlan && this.selectedMonthlyPlan) {
      purchased = await this.storeService.purchaseSubscription(this.selectedMonthlyPlan, this.selectedPlan);
//      console.log(`Purchased subscription: ${purchased}`)
    }
    return purchased;
  }

}
