import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Part } from 'bandon-shared';
import { Marker } from '../marker-view/marker-view.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-marker-bar',
  templateUrl: './time-marker-bar.component.html',
  styleUrls: ['./time-marker-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class TimeMarkerBarComponent  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('zoomContainer', { static: true }) zoomContainer: ElementRef;
  @ViewChild('markerBar', { static: true }) markerBar: ElementRef;

  translate = inject(TranslateService)

  // Example markers with time and position (in milliseconds)
  @Input() markers: Marker[] = [];
  @Input() selectedMarker = this.markers[0];
  @Input() duration = 1;
  @Input() ariaTitle = this.translate.instant('TUNES.ARRANGEMENTPART')
  @Input() loadingText = '';
  @Input() showLoading = false;

  @Output() markerSelected: EventEmitter<Marker> = new EventEmitter<Marker>();

  sizeObserver: ResizeObserver | undefined;

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.zoomContainer) {
      this.sizeObserver = new ResizeObserver(() => { this.calculateMarkerWidths() })

      this.sizeObserver.observe(this.zoomContainer.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if(this.sizeObserver) {
      this.sizeObserver.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateMarkerWidths();
  }

  calculateMarkerWidths(): void {
    // Iterate over markers and set the width to fill until the next marker
    this.markers.forEach((marker, index) => {
      if (index === this.markers.length - 1) {
        // Last marker: Fill the remaining space
        marker['width'] = this.duration - marker.time;
      } else {
        // Width between current marker and next marker
        marker['width'] = this.markers[index + 1].time - marker.time;
      }
    });
  }

  selectMarker(marker: Marker) {
    this.markerSelected.emit(marker)
  }
}
