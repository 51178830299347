import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { serviceLoader, createTranslateLoader } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { Capacitor } from '@capacitor/core';
import { provideAuth, initializeAuth, indexedDBLocalPersistence, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { TuneInfoService } from './app/services/audio/tune-info.service';
import { AudioService } from './app/services/audio/audio.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { AppLoaderService } from './app/services/app-loader.service';
import { StoreService } from './app/services/store/store.service';
import { LibraryService } from 'src/app/services/library.service';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { AudioDownloadService } from './app/services/audio/audio-download.service';
import { StorageService } from './app/services/storage/storage.service';
import { LoginService } from './app/services/auth/login.service';
import { CachingService } from './app/services/caching/caching.service';
import { ConnectivityService } from './app/services/connectivity/connectivity.service';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { DatePipe } from '@angular/common';
import { LibConfig } from 'bandon-shared/lib/config/lib-config';

import 'hammerjs';
import { AuthInterceptor, LibConfigService } from 'bandon-shared';
import { QuillModule, QuillModules } from 'ngx-quill';
import { IonRouterOutlet } from '@ionic/angular/common';

const libConfig: LibConfig = {
  apiServerUrl: environment.apiURL,
  apiKey: environment.apiKey,
  navigateToLogin: true,
  loginUrl: '/collection/user/login'
};


const quillModules: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote'],

//    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ header: [1, 2, 3, 4, 5, 6, false]}],

//    [{ color: [] }, { background: [] }],
  ]
}

bootstrapApplication(AppComponent, {
    providers: [
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => {
        if (Capacitor.isNativePlatform()) {
            return initializeAuth(getApp(), {
                persistence: indexedDBLocalPersistence
            });
        }
        else {
            return getAuth();
        }
      }),
      importProvidersFrom(
          BrowserModule,
          IonicModule.forRoot({
            backButtonText: ''
          }),
          QuillModule.forRoot({
            modules: quillModules
          }),
          AppRoutingModule,
          TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
          }),
          SharedComponentsModule, IonicStorageModule.forRoot({
            driverOrder: [Drivers.IndexedDB]
          }),
          YouTubePlayerModule
        ),
        NativeStorage,
        ConnectivityService,
        CachingService,
        LoginService,
        StorageService,
        AudioDownloadService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        LibraryService,
        StoreService,
        {
            provide: APP_INITIALIZER,
            useFactory: serviceLoader,
            deps: [AppLoaderService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AudioService,
        TuneInfoService,
        EmailComposer,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        DatePipe,
        { provide: 'LIB_CONFIG', useValue: libConfig },
        { provide: LibConfigService, useClass: LibConfigService, deps: ['LIB_CONFIG'] },
      ]
})
  .catch(err => console.log(err));

defineCustomElements(window);
if (environment.production) {
  enableProdMode();
}
