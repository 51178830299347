<div [formGroup]="fg" class="ion-text-center ion-margin-vertical" style="border-bottom: 1px solid gray;">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-align-items-center">
      <ion-col size="12" class="ion-text-center ion-margin-bottom">
        {{ filename }}
      </ion-col>
      <ion-col size="12" class="ion-text-center">
        <app-instrument-icon [track]="track" size="big"></app-instrument-icon>
      </ion-col>
      <ion-col size="12" size-sm="6" size-lg="3" class="col-margin">
        <app-searchable-select
          title="{{ 'TUNES.SECTION' | translate }}"
          [data]="instrumentGroups"
          [value]="track.instrumentgroup"
          (selectedChanged)="changeSection($event)"
        ></app-searchable-select>
      </ion-col>
      <ion-col size="12" size-sm="6" size-lg="3" class="col-margin-lg">
        <app-searchable-select
          title="{{ 'TUNES.INSTRUMENT' | translate }}"
          [data]="instruments"
          [value]="track.instrument"
          [displayFn]="instrumentDisplayFn"
          (selectedChanged)="changeInstrument($event)"
        ></app-searchable-select>
        @if ((instrumentInput.invalid && instrumentInput.touched) || instrumentInput.dirty) {
          <div class="ion-margin-bottom">
            @if (instrumentInput.errors?.['required']) {
              <ion-note color="primary">
                {{ 'TUNES.INSTRUMENTREQUIRED' | translate }}
              </ion-note>
            }
          </div>
        }
      </ion-col>
      <ion-col size="12" size-sm="6" size-lg="3" class="col-margin">
        <app-searchable-select
          title="{{ 'TUNES.VOICE' | translate }}"
          [data]="availableVoices"
          [value]="track.voice"
          itemTextField="designation"
          (selectedChanged)="changeVoice($event)"
          [disabled]="availableVoices.length==0"
        ></app-searchable-select>
      </ion-col>
      <ion-col size="12" size-sm="6" size-lg="3">
        <app-searchable-select
          title="{{ 'TUNES.MUSICIAN' | translate }}"
          [data]="musicians"
          [value]="track.trackmusician"
          itemTextField="name"
          idTextField="uid"
          [displayFn]="musicianDisplayFn"
          (selectedChanged)="changeMusician($event)"
        ></app-searchable-select>
        <!--<app-tags-input placeholder="Musiker*in"
          [suggestions]="musicians"
          [tags]="trackmusicians"
          [displayFn]="musicianDisplayFn"
          [tagFactoryFn]="musicianFactoryFn"
        ></app-tags-input>-->
      </ion-col>
      <ion-col size="12">
      </ion-col>
    </ion-row>
  </ion-grid><br />
  <div style="width: 100%;" class="ion-text-start">
    <ion-text>{{ 'TUNES.SHEETS' | translate }}</ion-text>
  </div>
  <app-file-upload
    [fileCheckFn]="pdfCheckFn"
    [selectedUploadFiles]="sheetFiles"
    [multiple]="false"
    uploadText="{{ 'TUNES.UPLOADSHEETS' | translate }}"
    fileExtension="application/pdf"
    (filesChanged)="changeSheet($event)"
  ></app-file-upload>
</div>
