<div class="tags-input-container" [class.ion-text-start]="tags.length>0" #tagContainer>
  @if(tags && tags.length>0) {
    <div class="tags-container">
      @for (tag of tags; track $index) {
        <ion-chip>
          <ion-label>{{ displayFn(tag).text }}</ion-label>
          <ion-icon name="close" color="light" (click)="removeTag(tag)"></ion-icon>
        </ion-chip>

      }
    </div>
  }
  <div class="input-wrapper">
    <ion-input [placeholder]="placeholder" (ionInput)="onInput($event)" #tagInput class="dark-ion-input"></ion-input>
  </div>
  <ion-popover #popover [isOpen]="showSuggestions" [backdropDismiss]="true" [keyboardClose]="false" side="bottom" alignment="start" [showBackdrop]="false"
      dismissOnSelect="true"
      (didDismiss)="showSuggestions = false">
    <ng-template>
      <ion-list lines="full">
        @for (suggestion of matchingSuggestions; track $index) {
          <ion-item (click)="selectSuggestion(suggestion)">
            @if (displayFn(suggestion).imageUrl) {
              <ion-avatar slot="start" aria-hidden="true">
                <img [src]="displayFn(suggestion).imageUrl">
              </ion-avatar>
            }
            {{ displayFn(suggestion).text }}
          </ion-item>
        }
        @if (!isExactMatch && searchTerm.length>=minFactoryLength && canGenerate) {
          <ion-item (click)="createTag(searchTerm)">
            {{ 'TUNES.CREATETAG' | translate: { designation: placeholder, name: searchTerm } }}
          </ion-item>
        }
      </ion-list>
    </ng-template>
  </ion-popover>
</div>

