<app-paywall-container title="{{'SUBSCRIPTIONMODALS.TITLE' | translate}}"
  [showCloseBtn]="false"
  [selectedPlan]="selectedPlan"
  (subscriptionPurchased)="dismissModal('purchased', null)"
>

<div class="ion-text-center ion-margin">
  <h2>{{'SUBSCRIPTIONMODALS.TITLEINACTIVE' | translate}}</h2>
  <p style="color: var(--ion-color-light);">{{'SUBSCRIPTIONMODALS.INACTIVETEXT1' | translate}}</p>

  <p style="color: var(--ion-color-light);"><b>{{'SUBSCRIPTIONMODALS.INACTIVETEXT2' | translate}}</b></p>
  <p style="color: var(--ion-color-primary);"><b>{{'SUBSCRIPTIONMODALS.INACTIVETEXT3' | translate}}</b></p>
</div>

<footer>
  <ion-button
    expand="block"
    color="light"
    fill="clear"
    (click)="showDeleteAlert()"
  >
    {{'SUBSCRIPTIONMODALS.DELETEGROUP' | translate}}
  </ion-button>
</footer>
