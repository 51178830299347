/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/member-ordering */
import { Capacitor } from '@capacitor/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from '../../../services/languages/language.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, OnDestroy, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertButton, IonSelect, ModalController, AlertController, IonicModule, IonInput, LoadingController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Instrument, profanityValidator, UserPrivilegesService } from 'bandon-shared';
import * as CryptoJS from 'crypto-js';
import { LibraryService } from 'src/app/services/library.service';
import { PurchasesPage } from 'src/app/pages/modal/purchases/purchases.page';
import { LocalFile } from 'src/app/shared/interfaces/local-file';
import { AbstractControl, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ContactsService } from 'src/app/services/social/contacts.service';
import { UserQrComponent } from '../user-qr/user-qr.component';
import { UserMessagesService } from 'src/app/services/social/user-messages.service';
import { UsernameValidator } from 'src/app/validators/username-validator';
import { CustomValidators } from 'src/app/shared/classes/custom-validators';
import { ShowHidePasswordComponent } from '../../general/show-hide-password/show-hide-password.component';
import { LowercaseDirective } from 'src/app/directives/lowercase.directive';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
        ShowHidePasswordComponent,
        LowercaseDirective
    ],
})
export class UserMenuComponent implements OnInit, OnDestroy {

  @Input() isAuth: boolean;

  @ViewChild('usernameInput') usernameInput: IonInput;
  @ViewChild('nameInput') nameInput: IonInput;
  @ViewChild('emailInput') emailInput: IonInput;
  @ViewChild('oldPasswordInput') oldPasswordInput: IonInput;
  @ViewChild('newPasswordInput') newPasswordInput: IonInput;
  @ViewChild('confirmPasswordInput') confirmPasswordInput: IonInput;

  @ViewChild('languageSelect') languageSelectRef: IonSelect;
  @ViewChild('instrumentSelect') instrumentSelectRef: IonSelect;

  alertController = inject(AlertController)
  authService = inject(AuthenticationService)
  contactService = inject(ContactsService);
  fb = inject(UntypedFormBuilder)
  formBuilder = inject(FormBuilder)
  httpClient = inject(HttpClient)
  languageService = inject(LanguageService)
  libraryService = inject(LibraryService)
  modalController = inject(ModalController)
  messagesService = inject(UserMessagesService)
  translate = inject(TranslateService)
  usernameValidator = inject(UsernameValidator)
  loadingController = inject(LoadingController)
  userDataService = inject(UserDataService)
  userPrivilegesService = inject(UserPrivilegesService)
  storeService = inject(StoreService)

//  userPicture: LocalFile;

  languages = [];
  instruments: Instrument[] = [];

  editName = false;
  editUsername = false;
  editEmail = false;
  editPassword = false;

  nameForm = this.fb.group({
    name: [this.name, [Validators.required, Validators.minLength(3), profanityValidator()]],
  });
  usernameForm = this.fb.group({
    username: [this.username, [Validators.required, Validators.minLength(3), profanityValidator(), Validators.pattern('^[a-zA-Z0-9]*$')],
      [this.usernameValidator.validate.bind(this.usernameValidator)]],
  });
  suggestedUsernames: string[] = []; // Property to store suggested usernames
  emailForm = this.fb.group({
    email: [this.email, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
  });
  pwdForm = this.fb.group({
    oldPassword: ['***', [Validators.required]],
    newPassword: new FormControl('', [
      Validators.required,
      CustomValidators.patternValidator(/\d/,{
        hasNumber: true
      }),
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)
    ]),
    confirmPassword: ['']
  }, { validators: this.confirmPasswordValidator() });

  public photoSourceButtons = [
    {
      text: this.translate.instant('USERMENU.CAMERA'),
      data: {
        source: 'CAMERA',
      },
    },
    {
      text: this.translate.instant('USERMENU.PHOTOS'),
      data: {
        source: 'PHOTOS',
      },
    },
    {
      text: this.translate.instant('USERMENU.CANCEL'),
      role: 'cancel',
      data: {
        source: 'cancel',
      },
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor() {
    this.isAuth = true;
  }

  get username() {
    if (this.userDataService.user) {
      return this.userDataService.user.username;
    }
    return '';
  }

  get usernameString() {
    return this.translate.instant('PROFILE.USERNAME')
  }

  get name() {
    if (this.userDataService.user) {
      return this.userDataService.user.name;
    }
    return '';
  }

  get nameString() {
    return this.translate.instant('PROFILE.NAME')
  }


  get email() {
    if (this.userDataService.user) {
      return this.userDataService.user.email;
    }
    return '';
  }

  get emailString() {
    return this.translate.instant('PROFILE.EMAIL')
  }

  get passwordString() {
    return this.translate.instant('PROFILE.PASSWORD')
  }

  get newPasswordString() {
    return this.translate.instant('PROFILE.NEWPWD')
  }

  get confirmPasswordString() {
    return this.translate.instant('PROFILE.PASSWORD')
  }

  get currentLanguage() {
    if (this.userDataService.user) {
      return this.userDataService.user.language;
    }
    return '';
  }

  get currentLanguageString() {
    if (this.currentLanguage) {
      return this.languageService.getLanguage(this.currentLanguage).text;
    }
    return '';
  }

  get userInstruments(): Instrument[] {
    if (this.userDataService.user) {
      return this.userDataService.user.instruments;
    }
    return [];
  }

  set userInstruments(instruments: Instrument[]) {
    if (this.userDataService.user) {
      this.userDataService.user.instruments = instruments;
    }
  }

  get userInstrumentsString(): string {
    let out = '';
    let i =0;
    if(this.userInstruments) {
      for (const instrument of this.userInstruments) {
        if (i>0) {
          out += ', ';
        }
        out += this.getInstrumentDesignation(instrument);
        i++;
      }
    }
    return out;
  }

  set username(username: string) {
    if (this.userDataService.user && this.userDataService.user.name!==username) {
      this.userDataService.user.username = username;
      this.userDataService.updateUserData();
    }
  }

  set name(name: string) {
    if (this.userDataService.user && this.userDataService.user.name!==name) {
      this.userDataService.user.name = name;
      this.userDataService.updateUserData();
    }
  }

  set email(email: string) {
    if (this.userDataService.user && this.userDataService.user.email!==email) {
      this.userDataService.updateUserMail(email);
    }
  }

  get newsletter() {
    if(this.userDataService.user) {
      return this.userDataService.user.newsletter;
    }
    return false;
  }

  set newsletter(hasNewsletter: boolean) {
    if (this.userDataService.user && this.userDataService.user.newsletter!==hasNewsletter) {
      this.userDataService.user.newsletter = hasNewsletter;
      this.userDataService.updateUserData();
    }
/*    if (hasNewsletter) {
      this.cleverReach.registerNewsletter(this.authService.user.email);
    } else {
      this.cleverReach.unregisterNewsletter(this.authService.user.email);
    }*/
  }

  get userPicture(): LocalFile | undefined {
    if(this.authService && this.userDataService.userPicture) {
      return this.userDataService.userPicture;
    }
    return undefined;
  }

  get contactsNotificationCount(): number {
    return this.contactService.notificationCount;
  }

  get messagesNotificationCount(): number {
    return this.messagesService.notificationCount;
  }

  get isEmailConfirmed() {
    if(this.userDataService.user) {
      return this.userDataService.user.emailconfirmed;
    }
    return false;
  }

  ngOnInit() {
    this.languages = this.languageService.getLanguages();

/*    this.authService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(auth => {
        if (auth) {
          this.checkIfUserPictureExists();
        } else if(this.userPicture) {
          this.userPicture.data = '';
        }
    });*/

    //Get all Instruments
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);

    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (instruments) => {
          this.instruments.length = 0;
          this.instruments.push(...instruments.filter(i => !i.softwareinstr));
        }
      );

    // Subscribe to username control value changes to update suggested usernames
    this.usernameForm.get('username').valueChanges.subscribe(value => {
      if (value && value.length > 0 && !this.usernameForm.get('username').errors) {
        this.usernameValidator.validate(this.usernameForm.get('username')).subscribe(
          (result) => {
            if (result && result.usernameTaken) {
              this.suggestedUsernames = result.suggestedUsernames || [];
            } else {
              this.suggestedUsernames = []; // Clear suggestions if username is available
            }
          }
        );
      } else {
        this.suggestedUsernames = []
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.authService.logout();
  }

  showLanguagesPopover() {
    this.languageSelectRef.open();
  }

  showInstrumentPopover() {
    this.instrumentSelectRef.open();
  }

  selectLanguage($event) {
    if($event.target.value) {
      this.languageService.setLanguage($event.target.value);
      if(this.userDataService.user && this.userDataService.user.language!==$event.target.value) {
        this.userDataService.user.language = $event.target.value;
        this.userDataService.updateUserData();
      }
    }
  }

  compareWith(o1: Instrument, o2: Instrument) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some(o => o.id === o1.id);
    }

    return o1.id === o2.id;
  }

  selectInstruments($event) {
    if($event.target.value && this.userInstruments!==$event.target.value) {
//      this.languageService.setLanguage($event.target.value);
      this.userInstruments = $event.target.value;
      this.userDataService.updateUserData();
    }
  }

  async openImgOptionSelection() {
    Camera.checkPermissions().then(status => {
      console.log(status)
      if (status.camera === 'denied' && status.photos=== 'denied') {
        Camera.requestPermissions().then(() => {
          this.takePicture('prompt')
        })
      } else {
        this.takePicture('prompt');
      }
    });
  }

  photoSourceAction(event) {
    if(event.detail.role !== 'cancel') {
      Camera.checkPermissions().then(status => {
        if (event.detail.data.source === 'CAMERA') {
          if(status.camera === 'granted'|| status.camera === 'limited') {
            this.takePicture('CAMERA')
          } else if(status.camera === 'denied') {
                this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.camera === 'granted' || status.camera === 'limited') {
                this.takePicture('CAMERA')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        } else {
          console.log(status)
          if(status.photos === 'granted' || status.photos === 'limited') {
            this.takePicture('PHOTOS')
          } else if(status.photos === 'denied') {
            this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.photos === 'granted' || status.photos === 'limited') {
                this.takePicture('PHOTOS')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        }
      });
    }
  }

  async presentPhotoPermissionAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('USERMENU.NOPHOTOPERMISSIONTITLE'),
      message: this.translate.instant('USERMENU.NOPHOTOPERMISSIONMSG'),
      buttons: [
        {
          text: this.translate.instant('USERMENU.TOSETTINGS'),
          handler: () => {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App
            })
          }
        },
        {
          text: this.translate.instant('USERMENU.CANCEL'),
          role: 'cancel'
        }
      ]
    });

    await alert.present()
  }

  checkPlattformForWebOrIos() {
    // eslint-disable-next-line eqeqeq
    if(Capacitor.getPlatform() == 'web' || Capacitor.getPlatform() == 'ios') {
      return true;
    }
    return false;
  }

  async takePicture(type) {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: this.checkPlattformForWebOrIos() ? CameraResultType.DataUrl : CameraResultType.Uri,
      source: type//CameraSource[type]
    });

    if (image) {
      if(this.checkPlattformForWebOrIos()) {image.webPath = image.dataUrl;}

      this.savePicture(image);
    }
  }

  async savePicture(photo: Photo) {
    const base64Data = await this.readAsBase64(photo);
//    const base64Data = photo.base64String;

    let filename = 'avatar.jpeg';
    const savedFile = await Filesystem.writeFile({
      directory: Directory.Cache,
      path: `${environment.avatarCacheFolder}/${filename}`,
      data: base64Data
    });
    this.userDataService.userPicture = {
      name: filename,
      path: `${environment.avatarCacheFolder}/${filename}`,
      data: `${base64Data}`
    };

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const decoded_token = this.authService.jwt_decode(this.authService.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const user_id = decoded_token.user_id;

    filename = `${user_id}.jpeg`;
    Filesystem.readFile({
      directory: Directory.Cache,
      path: `${environment.avatarCacheFolder}/${filename}`,
    }).then(result => {
      Filesystem.deleteFile({
        directory: Directory.Cache,
        path: `${environment.avatarCacheFolder}/${filename}`,
      });
    }, async err => {
    });


    this.uploadPicture();
  }

  async uploadPicture() {
    //Upload Img to Server
    if (this.userDataService.user) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const decoded_token = this.authService.jwt_decode(this.authService.getToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const user_id = decoded_token.user_id;

      const file = await fetch(this.userPicture.data);
      const blob = await file.blob();
      const formData = new FormData();
      formData.append('img', blob, `${user_id}_${this.userPicture.name}`);

      this.httpClient.post(environment.apiURL+`/users/${user_id}/img`, formData, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: resp => {
            console.log(resp);
          },
          error: err => {
            console.error(err);
          }
        });
    }
  }

  async readAsBase64(photo: Photo) {
    const response = await fetch(photo.webPath);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob) as string;
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  confirmPasswordValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: boolean } | null => {
      const newPassword = group.get('newPassword')?.value;
      const confirmPassword = group.get('confirmPassword')?.value;

      return newPassword === confirmPassword ? null : { passwordsNotMatch: true };
    };
  }

  async showError(msg: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('PROFILE.ERROR'),
      message: msg,
      buttons: ['OK'],
      cssClass: 'band-on-alert'
    });

    await alert.present();
  }

  triggerUsernameEdit() {
    this.editUsername = true;
    this.editPassword = false;

    this.usernameForm.get("username").setValue(this.username)

/*    if(this.usernameInput) {
      this.usernameInput.setFocus();
    }*/
  }

  changeUsername() {
    if(this.usernameForm.valid) {
      this.username = this.usernameForm.get("username").value;
    }
    this.editUsername = false;
  }

  cancelUsernameChange() {
    if(this.usernameForm.valid) {
      this.changeUsername()
    } else {
      this.usernameForm.get("username").setValue(this.username)
      this.usernameInput.value = this.username
    }
    this.editUsername = false;
    //TODO: save if valid
  }

  triggerNameEdit() {
    this.editName = true;
    this.editPassword = false;

    this.nameForm.get("name").setValue(this.name)

/*    if(this.nameInput) {
      this.nameInput.setFocus();
    }*/
  }

  changeName() {
    if(this.nameForm.valid) {
      this.name = this.nameForm.get("name").value;
    }
    this.editName = false;
  }

  cancelNameChange() {
    if(this.nameForm.valid) {
      this.changeName()
    } else {
      this.nameForm.get("name").setValue(this.name)
      this.nameInput.value = this.name
    }
    this.editName = false;
  }

  triggerEmailEdit() {
    this.editEmail = true;
    this.editPassword = false;

    this.emailForm.get("email").setValue(this.email)

/*    if(this.emailInput) {
      this.emailInput.setFocus();
    }*/
  }

  changeEmail() {
    if(this.emailForm.valid) {
      this.email = this.emailForm.get("email").value
    }
    this.emailForm.get("email").setValue(this.email)
    this.editEmail = false;
  }

  cancelEmailChange() {
    if(this.emailForm.valid) {
      this.changeEmail()
    } else {
      this.emailForm.get("email").setValue(this.email)
      this.emailInput.value = this.email
    }
    this.editEmail = false;
  }

  triggerEmailConfirm() {
    this.userDataService.sendConfirmationMail();
  }

  triggerPasswordEdit() {
    this.editPassword = true;

/*    if(this.oldPasswordInput) {
      this.oldPasswordInput.setFocus();
    }*/
  }

  changePassword() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const oldHashedPassword = CryptoJS.SHA256(this.pwdForm.get("oldPassword").value).toString();
    const newHashedPassword = CryptoJS.SHA256(this.pwdForm.get("newPassword").value).toString();
    this.editPassword = false;

    return this.httpClient.post(environment.apiURL+`/users/${this.authService.getUserID()}/password`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        {old_password: oldHashedPassword, new_password: newHashedPassword}, {headers})
        .subscribe({
          error: err => {
            this.showError(this.translate.instant('PROFILE.ERRORPWDCHANGE'));
            this.pwdForm.get("oldPassword").setValue('***')
          }
        });

  }

  cancelPasswordChange() {
    this.editPassword = false;
//    this.emailForm.get("email").setValue(this.email)
//    this.emailInput.value = this.email
    //TODO: save if valid
  }

  async deleteProfile() {
    const alert = await this.alertController.create({
      header: this.translate.instant('PROFILE.DELETE'),
      message: this.translate.instant('PROFILE.DELETEMSG'),
      buttons: [
        {
          text: this.translate.instant('PROFILE.CANCEL'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('PROFILE.DELETE'),
          role: 'confirm',
          handler: data => {
            console.log('delete Profile');
            this.userDataService.deleteUser()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                next: async () => {
                  let msg = this.translate.instant('PROFILE.DELETECONFIRM');
                  if(!this.userDataService.user.emailconfirmed) {
                    msg = this.translate.instant('PROFILE.DELETEDCONFIRM')
                  }
                //TODO: show alert
                  const confirmMsg = await this.alertController.create({
                    header: this.translate.instant('PLAYER.ALERT'),
                    message: this.translate.instant('PROFILE.DELETECONFIRM'),
                    buttons: ['OK'],
                    cssClass: 'band-on-alert'
                  });
                  await confirmMsg.present();

                  this.logout();
                },
                error: error => {

                }
              });
          }
        }
      ],
      cssClass: 'band-on-alert'
    });

    await alert.present();
  }

  async refreshPurchases() {
    let header = this.translate.instant('PROFILE.RELOAD')
    let message = this.translate.instant('PROFILE.REFRESHED')

    this.libraryService.loadUserLibraryFromServer(true);

    try {
      let customerInfo = await Purchases.restorePurchases();
      this.storeService.handleCustomerInfo(customerInfo.customerInfo);
    } catch(error) {
      if(error['code']=='7') {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PROFILE.ERRORREFRESHOTHERUSER')
      } else {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PROFILE.ERRORREFRESHUNKNOWN')
      }
    }

    const alert = await this.alertController.create({
      header, message,
      buttons: [
        {
          text: this.translate.instant('PROFILE.OK'),
          role: 'confirm',
        }
      ],
      cssClass: 'band-on-alert'
    });

    await alert.present();
  }

  async showPurchases() {
    const modal = await this.modalController.create({
      component: PurchasesPage,
      cssClass: 'transparent-modal-full-width',
    });

    modal.present();
  }

  getInstrumentDesignation(instrument: Instrument): string {
    if(this.translate && instrument) {
      const translation = instrument.translations.find(e => e.languageid===this.translate.currentLang);
      if(translation) {
        return translation.designation;
      }
      return instrument.designation;

    }
    return '';
  }

  async showProfileQRCode() {
    if(this.userDataService.user) {
      var data = {
        type: 'user',
        username: this.userDataService.user.username,
        url: this.getShareableLink()
  //         expirationTime: expirationTime.toISOString() // Convert expiration time to ISO string format
      };
      const qrString = JSON.stringify(data);

      const modal = await this.modalController.create({
        canDismiss: true,
        backdropDismiss: true,
        component: UserQrComponent,
        cssClass: 'user-qr-modal',
        componentProps: {
          qrString: this.getShareableLink(),
          helpString: this.translate.instant('USERMENU.QRINFO')
        }
      });
      await modal.present();
    }
  }

  getShareableLink() {
    // Construct the base URL
    const baseURL = `https://app.band-on.com/collection/user/user-contacts/invitation`;

    // Construct the query parameters
    const queryParams = new URLSearchParams({ type: 'user', username: this.userDataService.user.username });

    // Append the query parameters to the base URL
    return `${baseURL}?${queryParams}`;
  }

  // Method to get a dynamic aria-label for the send button
  getNameButtonAriaLabel(): string {
    let out = ': ';
    let addComma = false;
    //Name
    if(this.nameForm.get('name')?.hasError('required')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.REQUIREDFIELD', { field: this.nameString })
      addComma = true;
    }
    if(this.nameForm.get('name')?.hasError('maxlength')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAMAXLENGTH', { field: this.nameString, max: 30 })
      addComma = true;
    }
    if(this.nameForm.get('name')?.hasError('minlength')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAMINLENGTH', { field: this.nameString, min: 3 })
      addComma = true;
    }
    if(this.nameForm.get('name')?.hasError('profanity')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAFIELDPROFANITY', { field: this.nameString })
      addComma = true;
    }
    return out;
  }

  // Method to get a dynamic aria-label for the send button
  getUsernameButtonAriaLabel(): string {
    let out = ': ';
    let addComma = false;
    //Username
    if(this.usernameForm.get("username")?.hasError('required')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.REQUIREDFIELD', { field: this.usernameString })
      addComma = true;
    }
    if(this.usernameForm.get("username")?.hasError('minlength')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAMINLENGTH', { field: this.usernameString, min: 3 })
      addComma = true;
    }
    if(this.usernameForm.get("username")?.hasError('maxlength')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAMAXLENGTH', { field: this.usernameString, max: 30 })
      addComma = true;
    }
    if(this.usernameForm.get("username")?.hasError('usernameTaken')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.USERNAMETAKEN')
      addComma = true;
    }
    if(this.usernameForm.get("username")?.hasError('profanity')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAFIELDPROFANITY', { field: this.usernameString })
      addComma = true;
    }
    if(this.usernameForm.get("username")?.hasError('pattern')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.NOSPECIALCHARS')
      addComma = true;
    }
    return out;
  }

  // Method to get a dynamic aria-label for the send button
  getPasswordButtonAriaLabel(): string {
    let out = ': ';
    let addComma = false;

    //Password
    if(this.pwdForm.get("oldPassword")?.hasError('required')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.REQUIREDFIELD', { field: this.passwordString })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('required')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.REQUIREDFIELD', { field: this.passwordString })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('minlength')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIAMINLENGTH', { field: this.passwordString, min: 8 })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('hasNumber')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIANEEDSNUMBER', { field: this.passwordString, min: 1 })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('hasCapitalCase')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIANEEDSCAPITAL', { field: this.passwordString, min: 1 })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('hasSmallCase')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIANEEDSSMALL', { field: this.passwordString, min: 1 })
      addComma = true;
    }
    if(this.pwdForm.get("newPassword")?.hasError('hasSpecialCharacters')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.ARIANEEDSSPECIAL', { field: this.passwordString, min: 1 })
      addComma = true;
    }
    if(this.pwdForm.hasError('passwordsNotMatch')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('PROFILE.PWDNOTMATCH')
      addComma = true;
    }
    return out;
  }

   // Method to get a dynamic aria-label for the send button
   getEmailButtonAriaLabel(): string {
    let out = ': ';
    let addComma = false;
    if (this.emailForm.get("email")?.hasError('required')) {
      out += this.translate.instant('REGISTER.REQUIREDFIELD', { field: this.emailString })
      addComma = true;
    }
    if (this.emailForm.get("email")?.hasError('pattern')) {
      if(addComma) {
        out += ', ';
      }
      out += this.translate.instant('REGISTER.PLVALIDMAIL')
      addComma = true;
    }

    return out;
  }
}
