<ion-content scrollY="false">
  <ion-text style="text-align: center;">
    <p class="title"><b>{{ 'PLAYER.SPEED' | translate }}</b></p>
  </ion-text>
  <app-tempo-slider
    [minTempo]="minTempo"
    [maxTempo]="maxTempo"
    [ticks]="ticks"
    [startValue]="startValue"
    (onTempoChange)="tempoChange($event)"
    (changeSpeed)="changeSpeed()"
  ></app-tempo-slider>
  <!--<ion-button fill="clear" expand="full" color="light" (click)="dismiss()">{{ 'MODAL.CANCEL' | translate }}</ion-button>-->
  <ion-button fill="clear" expand="full" color="primary" (click)="confirm()">{{ 'MODAL.OK' | translate }}</ion-button>
</ion-content>
<!--<ion-footer>
  <ion-buttons slot="start">
    <ion-button color="primary" (click)="dismiss()">{{ 'MODAL.CANCEL' | translate }}</ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button color="primary" (click)="dismiss()">{{ 'MODAL.CONFIRM' | translate }}</ion-button>
  </ion-buttons>
</ion-footer>-->

