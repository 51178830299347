<ion-item (click)="openTune()" routerDirection="forward" detail="false" #tuneItem>
  <ion-icon slot="start" name="add-circle" size="large" class="add-icon" *ngIf="showRemove && !tune" (click)="triggerAdd()" aria-hidden="true"></ion-icon>
  <ion-icon slot="start" name="remove-circle" color="primary" size="large" *ngIf="showRemove && tune" (click)="triggerRemove()" aria-hidden="true"></ion-icon>
  <ion-thumbnail slot="start" class="tune-img" *ngIf="tune" aria-hidden="true">
    <app-cached-image [src]="tuneImgSrc"></app-cached-image>
  </ion-thumbnail>
  <ion-thumbnail slot="start" class="tune-img new-tune-icon" *ngIf="!tune && !showRemove" aria-hidden="true">
    <div class="new-tune-icon-container">
      <ion-icon name="add" color="primary" class="add-icon"></ion-icon>
    </div>
  </ion-thumbnail>
  <ion-label class="ion-text-wrap" *ngIf="tune">
    <h2 aria-level="3" [ariaLabel]="ariaText" role="button">
      {{ title }}
    </h2>
    <h3 *ngIf="tune.subtitle && showSubtitle" aria-hidden="true">{{ tune.subtitle }}</h3>
    <h3 aria-hidden="true">{{ description }}</h3>
    <p *ngIf="tune.searchNote && showSearchHints" aria-hidden="true">{{ tune.searchNote }}</p>
  </ion-label>
  <ion-label class="ion-text-wrap" *ngIf="!tune" color="primary" role="button">
    {{ addTuneText }}
  </ion-label>
  @if(showDownloadState && !isWeb && tune && !showRemove) {
    <app-load-tune-button class="ion-justify-content-center"
      slot="end"
      [fileState]="downloadState"
      [progress]="downloadProgress"
      [tuneID]="tune.id"
      (click)="downloadTune($event)"
      (deleteFiles)="deleteFiles()"
      (startDownload)="downloadFiles()"
    ></app-load-tune-button>
  }
  <ng-content select="[slot=end]" slot="end"></ng-content>
  <ion-reorder slot="end"></ion-reorder>
</ion-item>



