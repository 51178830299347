<div class="account-container" *ngIf="isAuth" >
  <div class="avatar-container">
    <ion-avatar>
      <ion-icon *ngIf="!userPicture"  name="help-circle" class="empty-avatar" ariaLabel="{{ 'USERMENU.PROFILEPICTURE' | translate }}"></ion-icon>
      <img *ngIf="userPicture" [src]="userPicture?.data" ariaLabel="{{ 'USERMENU.PROFILEPICTURE' | translate }}"/>
    </ion-avatar>

    <!-- edit icon -->
    <div class="edit-container" id="open-photo-source-sheet" role="button">
      <ion-icon name="camera-outline" ariaLabel="{{ 'USERMENU.EDITPICTURE' | translate }}"></ion-icon>
    </div>
    <ion-action-sheet
      trigger="open-photo-source-sheet"
      header="{{ 'USERMENU.NEWPHOTO' | translate }}"
      [buttons]="photoSourceButtons"
      (didDismiss)="photoSourceAction($event)"
      class="band-on-action-sheet"
    ></ion-action-sheet>
  </div>

  <div class="account-text">
    <ion-text class="name ion-text-no-wrap">{{ name }}</ion-text>
    <ion-text class="username ion-text-no-wrap">{{ username }}</ion-text>
    <ion-text class="email">
      {{ email }}
      @if( !isEmailConfirmed ) {
        ({{ 'PROFILE.UNCONFIRMED' | translate }})
      }
    </ion-text>
  </div>
</div>

@if(isAuth) {
  <div class="account-details-container">
    <ion-list inset="false" class="ion-margin-bottom ion-margin-top">
      <ion-item [button]="true" class="ion-no-padding" (click)="showProfileQRCode()" detail="true" role="link">
        <ion-icon aria-hidden="true" name="qr-code" slot="start" color="light"></ion-icon>
        <ion-label>{{ 'USERMENU.SHOWQR' | translate }}</ion-label>
      </ion-item>

      <ion-item [button]="true" class="ion-no-padding" routerLink="/collection/user/messages" detail="true">
        <ion-icon src="assets/icon/chat_bubble_black_24dp.svg" slot="start" aria-hidden="true" color="light"></ion-icon>
        <ion-label>
          <span aria-hidden="true">{{ 'USERMENU.MESSAGES' | translate }}</span>
          <span class="visually-hidden">
            @if(messagesNotificationCount>0) {
              {{ 'USERMENU.MESSAGES' | translate }}, {{ 'USERMENU.UNREADMESSAGES' | translate: { count: messagesNotificationCount } }}
            } @else {
              {{ 'USERMENU.MESSAGES' | translate }}
            }
          </span>
        </ion-label>
        @if(messagesNotificationCount>0) {
          <ion-badge slot="end" aria-hidden="true">{{ messagesNotificationCount }}</ion-badge>
        }
      </ion-item>
      <ion-item [button]="true" class="ion-no-padding" routerLink="/collection/user/user-contacts" detail="true" routerDirection="forward">
        <ion-icon aria-hidden="true" src="assets/icon/contact.svg" slot="start" color="light"></ion-icon>
        <ion-label>
          <span aria-hidden="true">{{ 'USERMENU.MYCONTACTS' | translate }}</span>
          <span class="visually-hidden">
            @if(contactsNotificationCount>0) {
              {{ 'USERMENU.MYCONTACTS' | translate }}, {{ 'USERMENU.UNANSWEREDREQ' | translate: { count: contactsNotificationCount } }}
            } @else {
              {{ 'USERMENU.MYCONTACTS' | translate }}
            }
          </span>
        </ion-label>
        @if(contactsNotificationCount>0) {
          <ion-badge slot="end" aria-hidden="true">{{ contactsNotificationCount }}</ion-badge>
        }
      </ion-item>
      <ion-item [button]="true" class="ion-no-padding" routerLink="/collection/user/group-list" detail="true">
        <ion-icon aria-hidden="true" name="people" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'USERMENU.MYGROUPS' | translate }}
        </ion-label>
      </ion-item>
      <ion-item [button]="true" class="ion-no-padding" detail="true" routerLink="/collection/user/devices">
        <ion-icon aria-hidden="true" name="phone-portrait" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'USERMENU.MYDEVICES' | translate }}
        </ion-label>
      </ion-item>
      <ion-item [button]="true" class="ion-no-padding" detail="true" routerLink="/collection/user/subscription">
        <ion-icon aria-hidden="true" name="card" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'USERMENU.MYSUBSCRIPTION' | translate }}
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div class="account-details-container">
    <ion-list-header style="margin-left: 0;">
      {{ 'USERMENU.MYDATA' | translate }}
    </ion-list-header>
    <ion-list inset="false">
      <!-- display name -->
      <form [formGroup]="nameForm">
        <ion-item class="ion-no-padding" [class.active-input]="editName">
          <div class="option-container">
            <div class="option-name">
              <ion-text class="label" aria-hidden="true">{{ 'PROFILE.NAME' | translate }}</ion-text>
              @if(!editName) {
                <div class="visually-hidden">
                  {{ 'PROFILE.NAME' | translate }}: {{ name }}
                </div>
                <ion-text class="value" (click)="triggerNameEdit()" aria-hidden="true">{{ name }}</ion-text>
              } @else {
                <ion-input #nameInput
                  ariaLabel = "{{ 'PROFILE.NAME' | translate }}"
                  formControlName="name"
                  [value]="name"
                  (ionBlur)="cancelNameChange()"
                >
                </ion-input>
                @if(editName && ((nameForm.get("name").invalid && nameForm.get("name").touched) || nameForm.get("name").dirty)) {
                  @if(nameForm.get("name").errors?.required) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: nameString } }}</ion-text>
                  }
                  @if(nameForm.get("name").errors?.minlength) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</ion-text>
                  }
                  @if(nameForm.get("name").errors?.profanity) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</ion-text>
                  }
                }
              }
            </div>
          </div>
          <div class="action-container">
            @if(!editName) {
              <ion-button color="primary" fill="clear" (click)="triggerNameEdit()" slot="end" ariaLabel="{{ 'PROFILE.NAME' | translate }} {{ 'PROFILE.EDIT' | translate }}">
                {{ 'PROFILE.EDIT' | translate }}
              </ion-button>
            } @else {
              <ion-button color="primary" fill="clear" (click)="cancelNameChange()" slot="end">
                {{ 'PROFILE.CANCEL' | translate }}
              </ion-button>
              <ion-button color="primary" fill="clear" (click)="changeName()" slot="end" [disabled]="!nameForm.valid">
                {{ 'PROFILE.CHANGE' | translate }}
                @if(!nameForm.valid) {
                  <div class="visually-hidden">
                    {{ getNameButtonAriaLabel() }}
                  </div>
                }
              </ion-button>
            }
          </div>
        </ion-item>
      </form>

      <!-- username -->
      <form [formGroup]="usernameForm">
        <ion-item class="ion-no-padding" [class.active-input]="editUsername">
          <div class="option-container">
            <div class="option-name">
              <ion-text class="label" aria-hidden="true">{{ 'PROFILE.USERNAME' | translate }}</ion-text>
              @if(!editUsername) {
                <div class="visually-hidden">
                  {{ 'PROFILE.USERNAME' | translate }}: {{ username }}
                </div>
                <ion-text class="value" (click)="triggerUsernameEdit()" aria-hidden="true">
                  {{ username }}
                </ion-text>
              } @else {
                  <ion-input #usernameInput
                  ariaLabel = "{{ 'PROFILE.USERNAME' | translate }}"
                  formControlName="username"
                  [value]="username"
                  (ionBlur)="cancelUsernameChange()"
                >
                </ion-input>
                @if(editUsername && ((usernameForm.get("username").invalid && usernameForm.get("username").touched) || usernameForm.get("username").dirty)) {
                  @if(usernameForm.get("username").errors?.required) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: usernameString } }}</ion-text>
                  }
                  @if(usernameForm.get("username").errors?.minlength) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</ion-text>
                  }
                  @if(usernameForm.get("username").errors?.profanity) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</ion-text>
                  }
                  @if(usernameForm.get("username").errors?.pattern) {
                    <ion-text class="text-danger ion-text-wrap">{{'REGISTER.NOSPECIALCHARS' | translate}}</ion-text>
                  }
                  @if(usernameForm.get("username").errors?.usernameTaken && usernameForm.get("username").value !== username) {
                    <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.USERNAMETAKEN' | translate }}</ion-text><br />
                    @if(suggestedUsernames.length > 0) {
                      <ion-text class="text-danger">{{ 'REGISTER.SUGGESTIONS' | translate }}:</ion-text>
                      <ion-text class="text-danger">
                        @for (suggestion of suggestedUsernames; track $index) {
                          @if($index < suggestedUsernames.length -1) {
                            {{ suggestion }},
                          } @else {
                            {{ suggestion }}
                          }
                        }
                      </ion-text>
                    }
                  }
                }
              }
            </div>
          </div>
          <div class="action-container">
            @if(!editUsername) {
              <ion-button color="primary" fill="clear" (click)="triggerUsernameEdit()" slot="end" ariaLabel="{{ 'PROFILE.USERNAME' | translate }} {{ 'PROFILE.EDIT' | translate }}">
                {{ 'PROFILE.EDIT' | translate }}
              </ion-button>
            } @else {
              <ion-button color="primary" fill="clear" (click)="cancelUsernameChange()" slot="end">
                {{ 'PROFILE.CANCEL' | translate }}
              </ion-button>
              <ion-button color="primary" fill="clear" (click)="changeUsername()" slot="end" [disabled]="!usernameForm.valid">
                {{ 'PROFILE.CHANGE' | translate }}
                @if(!usernameForm.valid) {
                  <div class="visually-hidden">
                    {{ getUsernameButtonAriaLabel() }}
                  </div>
                }
               </ion-button>
            }
          </div>
        </ion-item>
      </form>


      <!-- email -->
      <form [formGroup]="emailForm">
        <ion-item class="ion-no-padding" [class.active-input]="editEmail">
          <div class="option-container">
            <div class="option-name">
              <ion-text class="label" aria-hidden="true">
                {{ 'PROFILE.EMAIL' | translate }}
                @if( !isEmailConfirmed ) {
                  ({{ 'PROFILE.UNCONFIRMED' | translate }})
                }
              </ion-text>
              @if(!editEmail) {
                <div class="visually-hidden">
                  {{ 'PROFILE.EMAIL' | translate }}: {{ email }}
                  @if( !isEmailConfirmed ) {
                    ({{ 'PROFILE.UNCONFIRMED' | translate }})
                  }
                  </div>
                <ion-text class="value" (click)="triggerEmailEdit()" [color]="!isEmailConfirmed ? 'primary' : 'light'" aria-hidden="true">{{ email }}</ion-text>
              } @else {
                  <ion-input #emailInput
                    ariaLabel = "{{ 'PROFILE.EMAIL' | translate }}"
                    formControlName="email"
                    inputmode="email"
                    [value]="email"
                    (ionBlur)="cancelEmailChange()"
                    appLowercase
                  >
                  </ion-input>
                  @if(editEmail && ((emailForm.get("email").invalid && emailForm.get("email").touched) || emailForm.get("email").dirty)) {
                    @if(emailForm.get("email").errors?.required) {
                      <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: emailString } }}</ion-text>
                    }
                    @if(emailForm.get("email").errors?.pattern) {
                      <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.PLVALIDMAIL' | translate }}</ion-text>
                    }
                  }
              }
            </div>
          </div>
          <div class="action-container">
            @if(!editEmail) {
              @if(!isEmailConfirmed) {
                <ion-button color="primary" fill="clear" (click)="triggerEmailConfirm()" slot="end">
                  {{ 'PROFILE.CONFIRMMAIL' | translate }}
                </ion-button>
              }
              <ion-button color="primary" fill="clear" (click)="triggerEmailEdit()" slot="end" ariaLabel="{{ 'PROFILE.EMAIL' | translate }} {{ 'PROFILE.EDIT' | translate }}">
                {{ 'PROFILE.EDIT' | translate }}
              </ion-button>
            } @else {
                <ion-button color="primary" fill="clear" (click)="cancelEmailChange()" slot="end">
                  {{ 'PROFILE.CANCEL' | translate }}
                </ion-button>
                <ion-button color="primary" fill="clear" (click)="changeEmail()" slot="end" [disabled]="!emailForm.valid">
                  {{ 'PROFILE.CHANGE' | translate }}
                  @if(!emailForm.valid) {
                    <div class="visually-hidden">
                      {{ getEmailButtonAriaLabel() }}
                    </div>
                  }
                </ion-button>
            }
          </div>
        </ion-item>
      </form>

      <!-- password -->
      <form [formGroup]="pwdForm">
        <ion-item class="ion-no-padding" [lines]="editPassword ? 'none': 'full'"  [class.active-input]="editPassword">
          <div class="option-container">
            <div class="option-name">
              <ion-text class="label">{{ 'PROFILE.PASSWORD' | translate }}</ion-text>
              @if(!editPassword) {
                <ion-text class="value" (click)="triggerPasswordEdit()" aria-hidden="true">***</ion-text>
              } @else {
                  <ion-input #oldPasswordInput
                    ariaLabel = "{{ 'PROFILE.PASSWORD' | translate }}"
                    formControlName="oldPassword"
                    value="test"
                    type="password"
                    autocomplete="current-password"
                  >
                  </ion-input>
                  @if(editPassword && ((pwdForm.get("oldPassword").invalid && pwdForm.get("oldPassword").touched) || pwdForm.get("oldPassword").dirty)) {
                    @if(pwdForm.get("oldPassword").errors?.required) {
                      <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString } }}</ion-text>
                    }
                  }
              }
            </div>
          </div>
          <div class="action-container">
            @if(!editPassword) {
              <ion-button color="primary" fill="clear" (click)="triggerPasswordEdit()" slot="end" ariaLabel="{{ 'PROFILE.PASSWORD' | translate }} {{ 'PROFILE.EDIT' | translate }}">
                {{ 'PROFILE.EDIT' | translate }}
              </ion-button>
            } @else {
              <ion-button color="primary" fill="clear" (click)="cancelPasswordChange()" slot="end">
                {{ 'PROFILE.CANCEL' | translate }}
              </ion-button>
              <ion-button color="primary" fill="clear" (click)="changePassword()" slot="end" [disabled]="!pwdForm.valid">
                {{ 'PROFILE.CHANGE' | translate }}
                @if(!pwdForm.valid) {
                  <div class="visually-hidden">
                    {{ getPasswordButtonAriaLabel() }}
                  </div>
                }
               </ion-button>
            }
          </div>
        </ion-item>

        <ion-item class="ion-no-padding active-input" lines="none" [class.ion-hide]="!editPassword">
          <ion-label class="ion-no-margin">
            <app-show-hide-password color="light">
              <ion-input #newPasswordInput
                label = "{{ 'PROFILE.NEWPWD' | translate }}"
                labelPlacement="stacked"
                formControlName="newPassword"
                value="test"
                type="password"
                autocomplete="new-password"
                [class.active-input]="editPassword"
              >
              </ion-input>
            </app-show-hide-password>
            @if(editPassword && ((pwdForm.get("newPassword").invalid && pwdForm.get("newPassword").touched) || pwdForm.get("newPassword").dirty)) {
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: newPasswordString } }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.minlength) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 8} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasNumber) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSNUMBER' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasCapitalCase) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSCAPITAL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasSmallCase) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSSMALL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasSpecialCharacters) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSSPECIAL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString} }}</ion-text>
              }
            }
          </ion-label>
        </ion-item>

        <ion-item class="ion-no-padding active-input" [class.ion-hide]="!editPassword">
          <ion-label class="ion-no-margin">
            <app-show-hide-password color="light">
              <ion-input #confirmPasswordInput
                label = "{{ 'PROFILE.CONFIRMPWD' | translate }}"
                labelPlacement="stacked"
                formControlName="confirmPassword"
                value="test"
                type="password"
                autocomplete="new-password"
                [class.active-input]="editPassword"
              >
              </ion-input>
            </app-show-hide-password>
            @if(editPassword && ((pwdForm.get("newPassword").invalid && pwdForm.get("newPassword").touched) || pwdForm.get("newPassword").dirty)) {
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: newPasswordString } }}</ion-text>
              }
              @if(pwdForm.hasError('passwordsNotMatch')) {
                <ion-text class="text-danger ion-text-wrap">{{ 'PROFILE.PWDNOTMATCH' | translate }}</ion-text>
              }
            }
            </ion-label>
        </ion-item>
      </form>

      <!-- instruments -->
      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="label" aria-hidden="true">{{ 'PROFILE.INSTRUMENTS' | translate }}</ion-text>
            <ion-text class="value" aria-hidden="true">{{ userInstrumentsString }}</ion-text>
            <div class="visually-hidden">
              {{ 'PROFILE.INSTRUMENTS' | translate }}: {{ userInstrumentsString }}
            </div>
          </div>
        </div>
        <div class="action-container">
          <ion-button color="primary" fill="clear" (click)="showInstrumentPopover()" slot="end"  ariaLabel="{{ 'PROFILE.INSTRUMENTS' | translate }} {{ 'PROFILE.EDIT' | translate }}">
            {{ 'PROFILE.EDIT' | translate}}
          </ion-button>
        </div>
      </ion-item>
      <ion-item class="ion-hide">
        <ion-label>{{ 'USERMENU.INSTRUMENTS' | translate }}</ion-label>
        <ion-select #instrumentSelect
            (ionChange)="selectInstruments($event)"
            [compareWith]="compareWith"
            [value]="userInstruments"
            multiple="true"
            [interfaceOptions]="{ cssClass: 'band-on-alert'}"
            label="{{ 'USERMENU.INSTRUMENTS' | translate }}">
          <ion-select-option *ngFor="let i of instruments" [value]="i">{{ getInstrumentDesignation(i) }}</ion-select-option>
        </ion-select>
      </ion-item>

      <!-- language -->
      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="label" aria-hidden="true">{{ 'USERMENU.LANGUAGE' | translate }}</ion-text>
            <ion-text class="value" aria-hidden="true">{{ currentLanguageString }}</ion-text>
            <div class="visually-hidden">
              {{ 'PROFILE.LANGUAGE' | translate }}: {{ currentLanguageString }}
            </div>
          </div>
        </div>
        <div class="action-container">
          <ion-button color="primary" (click)="showLanguagesPopover()" fill="clear" slot="end" ariaLabel="{{ 'PROFILE.LANGUAGE' | translate }} {{ 'PROFILE.EDIT' | translate }}">
            {{ 'PROFILE.EDIT' | translate}}
          </ion-button>
        </div>
      </ion-item>
      <ion-item class="select-container ion-hide">
        <ion-label>{{ 'USERMENU.LANGUAGES' | translate }}</ion-label>
        <ion-select (ionChange)="selectLanguage($event)" #languageSelect [value]="currentLanguage" interface="alert" [interfaceOptions]="{ cssClass: 'band-on-alert'}" label="{{ 'USERMENU.LANGUAGES' | translate }}">
          <ion-select-option *ngFor="let l of languages" [value]="l.value">{{ l.text }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="value" style="margin-top: 21px" aria-hidden="true">{{ 'PROFILE.NEWSLETTER' | translate }}</ion-text>
          </div>
        </div>
        <ion-toggle [(ngModel)]="newsletter" ariaLabel="{{ 'PROFILE.NEWSLETTER' | translate }}"></ion-toggle>
      </ion-item>

      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="value" style="margin-top: 21px" aria-hidden="true">{{ 'PROFILE.MYPURCHASES' | translate }}</ion-text>
          </div>
        </div>
        <div class="action-container" style="top: 14px">
          <ion-button color="primary" (click)="refreshPurchases()" fill="clear" slot="end">
            <div class="visually-hidden">
              {{ 'PROFILE.MYPURCHASES' | translate }}
            </div>
            {{ 'PROFILE.RELOAD' | translate}}
          </ion-button>
          <ion-button color="primary" (click)="showPurchases()" fill="clear" slot="end">
            <div class="visually-hidden">
              {{ 'PROFILE.MYPURCHASES' | translate }}
            </div>
            {{ 'PROFILE.SHOWALL' | translate}}
          </ion-button>
        </div>
      </ion-item>


    </ion-list>
  </div>
}

<!-- sign out -->
<div class="delete-button-container" *ngIf="isAuth">
  <ion-button expand="full" fill="clear" class="signout-button" (click)="deleteProfile()">
    {{ 'PROFILE.DELETE' | translate }}
  </ion-button>
</div>
<div class="signout-button-container" *ngIf="isAuth">
  <ion-button expand="full" class="signout-button" (click)="logout()">
    {{ 'PROFILE.LOGOUT' | translate }}
  </ion-button>
</div>
