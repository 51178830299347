<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ modalTitle }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="musicianForm">
    <ion-item>
      <ion-label position="stacked">{{ 'TUNES.FIRSTNAME' | translate }}</ion-label>
      <ion-input [(ngModel)]="musician.firstname" formControlName="firstname"></ion-input>
      @if ((firstnameInput.invalid && firstnameInput.touched) || firstnameInput.dirty) {
        <div>
          @if (firstnameInput.errors?.['profanity']) {
            <ion-note color="primary">
              {{ 'TUNES.FIRSTNAMEPROFANITY' | translate }}
            </ion-note>
          }
          @if (firstnameInput.errors?.['required']) {
            <ion-note color="primary">
              {{ 'TUNES.FIRSTNAMEREQUIRED' | translate }}
            </ion-note>

          }
        </div>
      }
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ 'TUNES.SURNAME' | translate }}</ion-label>
      <ion-input [(ngModel)]="musician.surname" formControlName="surname"></ion-input>
      @if ((surnnameInput.invalid && surnnameInput.touched) || surnnameInput.dirty) {
        <div>
          @if (surnnameInput.errors?.['profanity']) {
            <ion-note color="primary">
              {{ 'TUNES.SURNAMEPROFANITY' | translate }}
            </ion-note>
          }
        </div>
      }
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ 'TUNES.WEBSITE' | translate }}</ion-label>
      <ion-input [(ngModel)]="musician.homepage" formControlName="homepage"></ion-input>
      @if ((homepageInput.invalid && homepageInput.touched) || homepageInput.dirty) {
        <div>
          @if (homepageInput.errors?.['invalidUrl']) {
            <ion-note color="primary">
              {{ 'TUNES.WEBSITEPATTERN' | translate }}
            </ion-note>
          }
        </div>
      }
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ 'TUNES.YEAROFBIRTH' | translate }}</ion-label>
      <ion-input [(ngModel)]="musician.year_of_birth" formControlName="birth" type="number"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ 'TUNES.YEAROFDEATH' | translate }}</ion-label>
      <ion-input [(ngModel)]="musician.year_of_death" formControlName="death" type="number"></ion-input>
    </ion-item>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="primary">{{ 'TUNES.CANCEL' | translate }}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="save()" color="primary" [disabled]="musicianForm.invalid">{{ 'TUNES.SAVE' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
