import { CommonModule, Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertController, IonAccordionGroup, IonicModule, IonModal, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService, Instrument, InstrumentGroup, MidiImportService, Musician, Part, profanityValidator, Speed, SpeedDesignation, Style, Tag, Tonality, Track, Tune, UserGroup, UserPrivilegesService, Voice } from 'bandon-shared';
import { OfflineHeaderComponent } from 'src/app/components/general/offline-header/offline-header.component';
import { SharedComponentsModule } from "../../../../modules/shared-components/shared-components.module";
import { CachedImageComponent, CachedImgSource } from "../../../../components/general/cached-image/cached-image.component";
import { environment } from 'src/environments/environment';
import { FileUploadComponent, UploadFile } from 'src/app/components/upload/file-upload/file-upload.component';
import { TagsInputComponent } from 'src/app/components/upload/tags-input/tags-input.component';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsService } from 'src/app/services/social/groups.service';
import { SharingAccordionComponent } from "../../../../components/upload/sharing-accordion/sharing-accordion.component";
import { ImageUploadComponent, UploadImg } from 'src/app/components/upload/image-upload/image-upload.component';
import { TrackItemComponent } from 'src/app/components/upload/track-item/track-item.component';
import { QuillModule } from 'ngx-quill';
import { SearchableSelectComponent } from "../../../../components/general/searchable-select/searchable-select.component";
import { MusicianModalComponent } from 'src/app/components/upload/musician-modal/musician-modal.component';
import { MarkerViewComponent } from 'src/app/components/upload/arrangement/marker-view/marker-view.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { AudioEngine } from '@musicdose/audioengine';
import { AudioService } from 'src/app/services/audio/audio.service';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { TempoSelectComponent } from 'src/app/components/general/tempo-select/tempo-select.component';
import { TrackAnalyzerService } from 'src/app/services/audio/track-analyzer.service';
import { transliterate } from 'transliteration';
import { LibraryService } from 'src/app/services/library.service';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { IonRouterOutlet } from '@ionic/angular';
import { CreatorPaywallComponent } from 'src/app/pages/modal/subscription/creator-paywall/creator-paywall.component';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { HorizontalDividerComponent } from "../../../../components/general/horizontal-divider/horizontal-divider.component";
import { UploadFooterComponent } from 'src/app/components/upload/upload-footer/upload-footer.component';

@Component({
  selector: 'app-user-tune-detail',
  templateUrl: './user-tune-detail.component.html',
  styleUrls: ['./user-tune-detail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    OfflineHeaderComponent,
    TranslateModule,
    SharedComponentsModule,
    CachedImageComponent,
    FileUploadComponent,
    ReactiveFormsModule,
    TagsInputComponent,
    ImageUploadComponent,
    SharingAccordionComponent,
    TrackItemComponent,
    QuillModule,
    SearchableSelectComponent,
    MarkerViewComponent,
    TempoSelectComponent,
    HorizontalDividerComponent,
    UploadFooterComponent
],
  animations: [
    trigger('markerDetails', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({ overflow: 'hidden' }),
        animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class UserTuneDetailComponent  implements OnInit, OnDestroy, AfterViewInit {
  activatedRoute = inject(ActivatedRoute)
  alertController = inject(AlertController)
  authService = inject(AuthenticationService)
  groupsService = inject(GroupsService)
  httpClient = inject(HttpClient)
  loadingController = inject(LoadingController)
  translateService = inject(TranslateService)
  fb = inject(FormBuilder)
  modalController = inject(ModalController)
  midiImporter = inject(MidiImportService)
  audioService = inject(AudioService)
  tuneInfoService = inject(TuneInfoService)
  trackAnalyzer = inject(TrackAnalyzerService)
  libraryService = inject(LibraryService)
  AlertController = inject(AlertController)
  router = inject(Router)
  connectivityService = inject(ConnectivityService)
  userDataService = inject(UserDataService)
  location = inject(Location)
  routerOutlet = inject(IonRouterOutlet)
  userPrivilegesService = inject(UserPrivilegesService)
  toastController = inject(ToastController)
  bandonTranslate = inject(BandONTranslationsService)

  @ViewChild('inputAccordion', { static: true }) accordionGroup: IonAccordionGroup;
  @ViewChild('modalContent', { static: false }) modalContent!: ElementRef;
  @ViewChild('playerModal', { static: false }) playerModal!: IonModal;

  accordions: string[] = ['audio', 'general', 'instrument', 'arrangement', 'sharing'];
  mode: 'display' | 'edit' = 'edit';

  private unsubscribe$ = new Subject<void>();

  tuneId: number;
  tune: Tune;
  tuneIcon: CachedImgSource | undefined;

  tuneForm: FormGroup;

  isConnected = false;

  unsafedData = false;
  isActionSheetOpen = false;

  //Breakpoint for footer
  dynamicBreakpoint: number = 0.0; // Default value
  breakpoints = [0.05, 0.1, this.dynamicBreakpoint];
  showFooter = true;
  htmlPlayerModal: HTMLIonModalElement;
  playerReady = false;
  isPlaying = false;

  public actionButtons = [
    {
      text: this.translateService.instant('PLAYLISTS.DISCARD'),
      role: 'destructive',
      handler: () => {
        this.location.back();
//          this.router.navigateByUrl('/collection/library/user-tunes', { state: { fromPreviousPage: true } })
      }
    },
    {
      text: this.translateService.instant('PLAYLISTS.CONTINUE'),
      role: 'cancel',
    }
  ];

  //Audio Files
  audioFiles: UploadFile[] = [];
  audioCheckFn: (file: UploadFile, existingFiles?: UploadFile[]) => Promise<UploadFile> = async (file, existingFiles): Promise<UploadFile> => {
    //Check if file with the same name exists
    if(existingFiles) {
      const existing = existingFiles.filter(f => f.name==file.name )
      if(existing && existing.length>1) {
        file.state = 'rejected'
        file.error = this.translateService.instant('TUNES.AUDIOFILEERROREXISTS')
        return file;
      }
    }
    else if(this.tune && this.tune.tracks) {
      const existing = this.tune.tracks.filter(t => this.getFileNameFromPath(t.path)==file.name )
  //    const existingFiles = this.audioFiles.filter(f => f.name===file.name);
      if(existing && existing.length>0) {
        file.state = 'rejected'
        file.error = this.translateService.instant('TUNES.AUDIOFILEERROREXISTS')
        return file;
      }
    }

    //Check File Extension
    const validTypes = ['audio/mpeg'];
    if (!validTypes.includes(file.mimeType)) {
      file.state = 'rejected';
      file.error = this.translateService.instant('TUNES.AUDIOFILEERRORTYPE')
      return file;
    }

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);

    const blob = await file.file;
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('filename', file.name)

    try {
      // Convert the HTTP request to a Promise-based approach using await
      const response = await firstValueFrom(
        this.httpClient.post(`${environment.apiURL}/check-audio-file`, formData, { headers })
      );
      // Handle successful response
      file.state = 'accepted';
      // Perform additional logic if needed
    } catch (err) {
      this.handleAudioUploadError(file, err)
    }

    return file;
  };


  //Tags
  tags: Tag[] = [];
  tagFactoryFn = (tagText: string): Tag => {
    return {
      id: -1, // Generate a random ID or handle as needed
      designation: tagText
    };
  };

  //Genres
  styles: Style[] = [];
  styleFactoryFn = (styleText: string): Style=> {
    return {
      id: -1, // Generate a random ID or handle as needed
      designation: styleText
    };
  };

  //Tonality
  tonalities: Tonality[] = []
  tuneTonality: Tonality | undefined;
  tonalityAriaFn: (item: Tonality) => string = (item) => {
    if(item) {
      return this.bandonTranslate.getTonalityString(item.designation)
    }
    return '';
  }
  tonalityAriaSelectFn: (item: Tonality) => string = (item) => {
    if(item) {
      return this.bandonTranslate.getTonalityString(item.designation, false)
    }
    return '';
  }

  //Tune Tempo
  speedDesignations: SpeedDesignation[] = [];
  tunetempo: Speed | undefined;

  instruments: Instrument[] = [];
  instrumentGroups: InstrumentGroup[] = [];
  voices: Voice[] = [];

  musicians: Musician[] = [];
  musicianDisplayFn: (item: Musician) => { text: string; imageUrl?: string } = (item) => {
    let out = {
      text: item.firstname+' '+item.surname,
      imageUrl: undefined
    }
    if(item.picture && item.picture.id) {
      out.imageUrl = `${environment.apiURL}/photoImg/${item.picture.id}`
    }
    return out;
  };
  musicianFactoryFn = async (musicianText: string): Promise<Musician | undefined> => {
    let musician: Musician = { id: -1, surname: '', firstname: ''}
    let textParts = musicianText.split(' ');
    if(textParts.length>1) {
      musician.firstname = textParts[0]
      musician.surname = textParts[1]
    } else {
      musician.firstname = musicianText
    }

    const modal = await this.modalController.create({
      component: MusicianModalComponent,
      componentProps: {
        musician
      }
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();

    return data;
  };

  //Arrangement view
  showMarkerview = false;

  //Category errors:
  generalErrorMessage = "no title"

  isPhotoSheetOpen = false;

  constructor() {
    this.tune = new Tune();
    this.tune.ownerid = this.userDataService.user?.uid;

    this.tune.title = this.translateService.instant('TUNES.NEWTUNE');

    this.tuneForm = this.fb.group({
      audioFiles: [null, [Validators.required]],
      title: ['', [Validators.required]],
      covertext: [''],
      composer: [''],
      arranger: [''],
      tonality: [''],
      tempo: ['', [Validators.required]],
      tracks: this.fb.array([])
    });

  }

  get audioFilesInput() {
    return this.tuneForm.get('audioFiles');
  }

  get titleInput() {
    return this.tuneForm.get('title');
  }

  get tempoInput() {
    return this.tuneForm.get('tempo');
  }

  get covertextInput() {
    return this.tuneForm.get('covertext');
  }

  get tracksInput() {
    return this.tuneForm.get('tracks')
  }

  get isGotoInstrumentButtonDisabled(): boolean {
    let out = false;
    if(this.covertextInput.invalid) {
      out = true;
    }
    if(this.titleInput.invalid) {
      out = true;
    }
/*    if(this.tempoInput.invalid) {
      out = true;
    }*/
    return out;
  }

  get isGotoArrangementButtonDisabled(): boolean {
    return this.tracks.invalid;
  }

  get tracks() {
    return this.tuneForm.controls["tracks"] as FormArray<FormGroup>;
  }

  get hasGeneralError() {
    let out = (this.titleInput.invalid && this.titleInput.touched) || this.titleInput.dirty;
//    out = (this.tempoInput.invalid && this.tempoInput.touched) || this.tempoInput.dirty;
    return out;
  }

  get hasInstrumentError() {
    let out = (this.tracksInput.invalid && this.tracksInput.touched) || this.tracksInput.dirty;
    return out;
  }

  get tuneImgPath() {
    if(this.tune && this.tune.picture) {
      return '/photoImg/'+this.tune.picture.id;
    }
    return undefined;
  }

  get composers(): Musician[] {
    if(this.tune && this.tune.composers) {
      return this.tune.composers;
    }
    return [];
  }

  get arrangers(): Musician[] {
    if(this.tune && this.tune.arrangers) {
      return this.tune.arrangers;
    }
    return [];
  }

  get shownAudioFiles(): UploadFile[] {
    let out: UploadFile[] = [];
    if(this.tuneForm.controls['audioFiles']) {
      out = this.tuneForm.controls['audioFiles'].value;
    }
    return out;
  }

  get duration(): number {
    return this.audioService.duration;
  }

  get showBackgroundDownloadProgress(): boolean {
    return this.audioService.showBackgroundProgressBar;
  }

  get backgroundProgress(): number {
    return this.audioService.backgroundDownloadProgress / 100;
  }

  get disableMarkerview(): boolean {
    if(this.tune && this.tune.tracks) {
      return this.tune.tracks.length===0;
    }
    return true;
  }

  get canUploadMusic() {
    if(this.userDataService.user) {
      return this.userPrivilegesService.canEditContent(this.userDataService.user);
    }
    return false;
  }


  async ngOnInit() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //TODO: show only for new tunes?
    /*if(!this.canUploadMusic) {
      console.log(this.userDataService.user)
      this.showPaywall();
    }*/

    this.tuneId = Number(this.activatedRoute.snapshot.paramMap.get('tuneid'));

    this.getTuneIconSrc(this.tune);

    this.tunetempo = { id: -1, speed: 120, notevalue: 4, start: 0, main: 1,
      countofffile: '', countoffchecksum: '', countoffbeats: 0,
      lyrics: []}
    this.tempoInput.patchValue(this.tunetempo)

    if(this.tuneId>-1) {
      const loading = await this.showLoading();

      (await this.libraryService.loadTuneFromServer(this.tuneId, false))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: async data => {
          if(!this.checkEditPermission(data)) {
            this.showNoPermissionAlert();
          }

          this.audioService.saveBase64Data = true;
          this.tune = data;

          this.tuneForm.patchValue({ covertext: this.tune.covertext })

          let audioFiles = [];
          //Generate FormArray
          if(this.tune.tracks) {
            this.tune.tracks.forEach(t => {
              const trackForm = this.fb.group({
                instrumentgroup: [t.instrumentgroup],
                instrument: [t.instrument, Validators.required],
                voice: [t.voice],
              });

              this.tracks.push(trackForm);

              if(t.speed && t.speed.main) {
                this.tuneForm.patchValue({ tempo: t.speed.speed});
                this.tunetempo = t.speed;
              }
              if(t.tonality) {
                this.tuneForm.patchValue({ tonality: t.tonality })
                this.tuneTonality = t.tonality;
              }

              const audioFile: UploadFile = { name: this.getFileNameFromPath(t.path), file: undefined, state: 'accepted', mimeType: ''}
              audioFiles.push(audioFile)
            });
            this.audioFiles.length = 0
            this.audioFiles = [...audioFiles]; // or use this.audioFiles = this.audioFiles.slice();
            this.tuneForm.patchValue({ 'audioFiles': this.audioFiles})
          }

          this.showMarkerview = true;

          //Observe track download
          this.audioService.initCompleted$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(async completed => {
            if(completed && this.tune.tracks) {
              let audioFiles = [];
              for(let track of this.tune.tracks) {
                let base64Data = (await AudioEngine.getBase64Data({ trackID: track.id})).base64data;
                let blob = this.base64ToBlob(base64Data, 'audio/mpeg');
                const audioFile: UploadFile = { name: this.getFileNameFromPath(track.path), file: blob, state: 'accepted', mimeType: ''}
                audioFiles.push(audioFile)
              }
              this.audioFiles.length = 0;
              this.audioFiles = [...audioFiles]; // or use this.audioFiles = this.audioFiles.slice();
              this.tuneForm.patchValue({ 'audioFiles': this.audioFiles})
              this.playerReady = true;
              //TODO:
//              this.dismissLoading();
//              this.libraryService.updateTuneDownloadedState(this.tune.id, true);
            }
          });

          await this.audioService.loadTune(this.tune);
          this.audioService.selectTonality(this.audioService.tonalities[0]);

          this.unsafedData = false;
          loading.dismiss();
        },
        error: async err => {
          loading.dismiss();
          this.showNoPermissionAlert();
        }
      });
    } else if(!this.canUploadMusic) {
      console.log(this.userDataService.user)
      this.showPaywall();
    } else {
      await this.audioService.loadTune(this.tune, false)
      this.unsafedData = false;
    }

    //Connectivity
    this.connectivityService.appIsOnline$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(c => this.isConnected = c);

    //TODO: Caching?
    //Get all tags
    this.httpClient.get<Tag[]>(environment.apiURL+"/tags", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tags) => {
          this.tags.length = 0;
          this.tags.push(...tags);
        }
      );

    //Get all styles
    this.httpClient.get<Style[]>(environment.apiURL+"/styles", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (styles) => {
          this.styles.length = 0;
          this.styles.push(...styles);
        }
      );

    //Get all tonalities
    this.httpClient.get<Tonality[]>(environment.apiURL+'/tonalities', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.tonalities = data;
      });

    //Get all speed designations
    this.httpClient.get<SpeedDesignation[]>(environment.apiURL+'/speeds', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.speedDesignations = data;
      });


    //Load all possible Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.instruments = data.sort((i1, i2) => i1.designation.localeCompare(i2.designation));
      });

    //Load all possible IntrumentGroups
    this.httpClient.get<InstrumentGroup[]>(environment.apiURL+'/instrumentgroups', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.instrumentGroups = data.sort((g1, g2) => g1.designation.localeCompare(g2.designation));
      });

    //Load all possible Voices
    this.httpClient.get<Voice[]>(environment.apiURL+'/voices', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.voices = data;
    });

    //Load all musicians (public profiles)
    this.httpClient.get<Musician[]>(environment.apiURL+'/musicians', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.musicians = data;
    });

    this.authService.isAuthenticated
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(auth => {
      if(!auth) {
        this.router.navigateByUrl('/collection/library')
      }
    });

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if(this.htmlPlayerModal) {
      this.htmlPlayerModal.canDismiss = true;
      this.htmlPlayerModal.dismiss()
    }
    this.showFooter = false;

    this.tuneInfoService.unloadTune();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ionViewWillEnter() {
    // Disable the swipe back gesture when the view enters
    this.routerOutlet.swipeGesture = false;
  }

  ionViewWillLeave() {
    // Enable it again when leaving the view
    this.routerOutlet.swipeGesture = true;
  }

  checkEditPermission(tune: Tune) {
    if(tune.ownerid==this.userDataService.user.uid) {
      return true;
    }
    for(let sharing of tune.sharings) {
      if(sharing.userid==this.userDataService.user.uid && sharing.privileges) {
        const edit_privilege = sharing.privileges.find(p => p.id==1)
        if(edit_privilege) {
          return true;
        }
      }
    }
    return false;
  }

  async showNoPermissionAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('TUNES.PERMISSIONALERT'),
      message: this.translateService.instant('TUNES.PERMISSIONMSG'),
      buttons: ['OK'],
      cssClass: 'band-on-alert'
    });
    await alert.present();
    this.router.navigateByUrl('/collection/library/', { replaceUrl: true });
  }

  goToAccordion(value: string) {
    const nativeEl = this.accordionGroup;
    if (nativeEl) {
      nativeEl.value = value;
      this.updateTouchedStates(value);
    }
  }

  cancel() {
    if(this.unsafedData) {
      this.setActionSheetOpen(true);
    } else {
//      this.router.navigateByUrl('/collection/library/user-tunes', { state: { fromPreviousPage: true } })
      this.location.back();
    }
  }

  async saveTune() {
    if (this.tuneForm.valid) {
      const loading = await this.showLoading();

      const formData = new FormData();

      if(this.tuneId<0) {
        this.tune.ownerid = this.userDataService.user?.uid;
      }

      this.tune.tracks?.forEach(t => {
        if(t.newAudio && t.newAudioFilename) {
          let filename = transliterate(t.newAudioFilename)
          t.newAudioFilename = filename;
          formData.append(t.newAudioFilename, t.newAudio, t.newAudioFilename);
        }

        if(t.sheets && t.sheets.length>=0 && t.sheets[0]) {
          let sheet = t.sheets[0]
          if(sheet.newFile && sheet.imgSaveFilename) {
            formData.append(`track_${this.tune.tracks.indexOf(t)}_sheet`, sheet.newFile, sheet.imgSaveFilename)
          }
        }
      })

      if(this.tune.newImage && this.tune.newImageExt) {
        formData.append('NewTuneImg', this.tune.newImage, this.tune.newImageExt)
      }


      if(this.tune.recordingdate && this.tune.recordingdate instanceof Date) {
        this.tune.recordingdate = this.adjustDate(this.tune.recordingdate);
      }
      if(this.tune.publishdate && this.tune.publishdate instanceof Date) {
        console.log(this.tune.publishdate);
        this.tune.publishdate = this.adjustDate(this.tune.publishdate);
      }


      formData.append('tune', JSON.stringify(this.tune));
      console.log(this.tune)

      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      if(this.tuneId>0) {
        this.httpClient.post<Tune>(environment.apiURL+`/tunes/${this.tune.id}`, formData, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: resp => {
            this.tune = resp;
            this.libraryService.refreshCaches();
            this.unsafedData = false;
            loading.dismiss();
            this.presentSavedToast();
          },
          error: err => {
            loading.dismiss();
            this.showAlert( this.translateService.instant('TUNES.ERROR'), undefined, this.translateService.instant('TUNES.ERROROCURRED', { title: this.tune.title }));
          }
        });
      } else {
        this.httpClient.put<Tune>(environment.apiURL+`/tunes`, formData, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: resp => {
            this.tune = resp;
            this.libraryService.refreshCaches();
            this.unsafedData = false;
            loading.dismiss();
            this.presentSavedToast();
          },
          error: err => {
            loading.dismiss();
            this.showAlert( this.translateService.instant('TUNES.ERROR'), undefined, this.translateService.instant('TUNES.ERROROCURRED', { title: this.tune.title }));
          }
        });
      }
    }
  }

  async presentSavedToast() {
    const toast = await this.toastController.create({
      message: this.translateService.instant('TUNES.SAVED'),
      duration: 1500,
      position: 'top'
    });

    await toast.present();
  }

  setPhotoSheetOpen(isOpen: boolean) {
    this.isPhotoSheetOpen = isOpen;
  }

  getTuneIconSrc(tune: Tune): CachedImgSource {
    if (tune && tune.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+tune.picture.id, path: tune.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

  tagsChanged(tags: Tag[]) {
    this.tune.tags = tags;
    this.unsafedData = true;
  }

  styleChanged(styles: Style[]) {
   this.tune.styles = styles;
   this.unsafedData = true;
  }

  composersChanged(composers: Musician[]) {
    this.tune.composers = composers;
    this.unsafedData = true;
  }

  arrangersChanged(arrangers: Musician[]) {
    this.tune.arrangers = arrangers;
    this.unsafedData = true;
  }

  changeTuneTonality(event: Tonality[]) {
    this.tuneTonality = event[0];

    this.tune.tracks.forEach(t => t.tonality = this.tuneTonality);
    this.unsafedData = true;
  }

  changeTempo(event: Speed) {
    const tempoparts = this.tune.parts.filter(p => p.tempo);
    this.tunetempo = event;
    if(tempoparts.length>0) {
      tempoparts[0].tempo = event.speed;
    }
    for(let track of this.tune.tracks) {
      if(track.speed && track.speed.id==event.id) {
        track.speed = event;
      }
    }
    this.tuneInfoService.setupArrangementInfo(this.tunetempo);
    this.unsafedData = true;
  }

  audioFilesChanged(event: UploadFile[]) {
    // Step 1: Create a Set of file names from the event array
    const eventFileNames = new Set(event.map(file => file.name));

    // Step 2: Remove tracks that do not have a corresponding file in the event
    if (this.tune.tracks) {
      this.tune.tracks = this.tune.tracks.filter(track =>
        eventFileNames.has(this.getFileNameFromPath(track.path))
      );
    } else {
      this.tune.tracks = [];
    }

    if(event.length===0) {
      this.showMarkerview = false;
      this.tune.parts = [];
    }

    // Step 3: Add new files from the event array
    event.forEach(async file => {
      // Check if a file with the same name already exists
      let fileExists = false;
      if (this.tune.tracks) {
        fileExists = this.tune.tracks.some(track => this.getFileNameFromPath(track.path) === file.name);
      }

      if (!fileExists) {
        // If not, add the file to the array
        const track = await this.addTrack(file.name, file.file);
      }
    });

    // Step 4: Update the form value
    this.tuneForm.patchValue({ 'audioFiles': event });

    this.setHasUnsafedData();
  }

  tuneImgChanged(image: UploadImg) {
    this.tune.newImage = image.file;
    this.tune.newImageExt = image.filename;

    this.setHasUnsafedData();
  }

  accordionChanged(event) {
    this.updateTouchedStates(event.detail.value);
  }

  updateTouchedStates(value: string) {
    const collapsedItems = this.accordions.filter((a) => a !== value);
    const selectedValue = value;

    if(collapsedItems.includes(this.accordions[0])) {
      //Audio Dateien
      this.audioFilesInput.markAsTouched();
    }
    if(collapsedItems.includes(this.accordions[1]) && this.accordions.indexOf(value)>1) {
      //Allgemeine Informationen
      this.titleInput.markAllAsTouched();
    }
    if(collapsedItems.includes(this.accordions[2]) && this.accordions.indexOf(value)>2) {
      //Instrument Informationen
      this.tracks.controls.forEach((group: FormGroup) => {
        Object.keys(group.controls).forEach(controlName => {
          const control = group.get(controlName);
          control?.markAsTouched(); // Mark the control as touched
        });
      });
    }
    if(collapsedItems.includes(this.accordions[3]) && this.accordions.indexOf(value)>3) {
      //Arrangement Informationen
      this.tempoInput.markAllAsTouched();
    }

/*    console.log(
      `Expanded: ${selectedValue === undefined ? 'None' : value} | Collapsed: ${collapsedItems.join(', ')}`
    );*/
  }

  async importMidi() {
    const result = await FilePicker.pickFiles({
      types: [ 'audio/midi'],
      limit: 1
    });
    if(result && result.files && result.files.length>0) {
      this.midiImporter.importMidi(result.files[0].blob, this.tune, this.tunetempo)

      this.midiImporter.update$
        .pipe(take(1))
        .subscribe(() => {
          this.showMarkerview = true;
          this.tuneInfoService.setupArrangementInfo(this.tunetempo);
        })
//      console.log(this.tune.parts)
//      this.processFiles(result.files)
    }

  }

  async showLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });

    loading.present();

    return loading;
  }

  setActionSheetOpen(isOpen: boolean) {
    this.isActionSheetOpen = isOpen;
  }

  private async addTrack(filename: string, file: Blob) {
    const newTrack: Track = { id:-1, path: "", mainvoice: 1, variation: 0 };
    newTrack.newAudio = file;
    newTrack.newAudioFilename = filename;
    newTrack.path = filename;

    //Set tonality
    newTrack.tonality = { id: -1, designation: "Tonart wählen", accidentals: 0, minor: 0 };
    if (this.tuneTonality) {
      newTrack.tonality = this.tuneTonality
    }

    //Set speed
    newTrack.speed = { id: -1, speed: 0, main: 0, start: 0,
      countoffbeats: 0, countofffile: '', countoffchecksum: '',
      notevalue: 4, lyrics: [] }
    if (this.tunetempo) {
      newTrack.speed = this.tunetempo;
    }
    newTrack.level = { id: 0, designation: 'All', short: '', description: ''}

    //Give it a default instrument (vocal)
    newTrack.instrument = this.trackAnalyzer.guessTrackInstrument(filename);

    //Give it a default group
    newTrack.instrumentgroup = this.instrumentGroups.find(g => g.id == 0);

    //Give it a default voice
    newTrack.voice = this.voices.find(g => g.id == 0);

    //Add to tune
    if (!this.tune.tracks) {
      this.tune.tracks = []
    }
    this.tune?.tracks?.push(newTrack);

    const trackForm = this.fb.group({
      instrumentgroup: [newTrack.instrumentgroup],
      instrument: [newTrack.instrument, Validators.required],
      voice: [newTrack.voice],
    });

    this.tracks.push(trackForm);

    if(!this.tune.parts || this.tune.parts.length===0) {
      if(!this.tune.parts) {
        this.tune.parts = [];
      }
      const part: Part = { id: '',  designation: 'Melodie', barstart: 0, barend: 0, timestart: 0, pickup: 0, timeend: 0}
      this.tune.parts.push(part);

      let duration = (await AudioEngine.getAudioDuration({ base64data: await this.blobToBase64(newTrack.newAudio) })).duration * 1000;
      part.timeend = duration;

      this.tuneInfoService.setupArrangementInfo(this.tunetempo);
    }

    return newTrack;
  }

  setHasUnsafedData() {
    this.unsafedData = true;
  }

  async showPaywall(goBack = true) {
    const modal = await this.modalController.create({
      component: CreatorPaywallComponent,
      backdropDismiss: false  // Disable dismissing the modal by tapping outside
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'cancel' && goBack) {
      this.location.back();
    }
  }


  private async showAlert(title: string, subtitle: string | undefined, msg: string) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: subtitle,
      message: msg,
      buttons: [ this.translateService.instant('TUNES.OK') ],
    });

    await alert.present();
  }

  private deleteTrack(track: Track) {
    if (this.tune && this.tune.tracks) {
      const index : number = this.tune.tracks.indexOf(track);
      this.tune.tracks.splice(index, 1);
      this.tracks.removeAt(index);
    }
  }

  private getFileNameFromPath(path: string): string {
    return path.split('/').pop() || '';  // Works for Unix-style paths
    // For Windows paths, you may need to use `path.split('\\').pop()`
  }

  private handleAudioUploadError(file: UploadFile, error: HttpErrorResponse) {
    let errorMessage = this.translateService.instant('TUNES.UPLOOADERRUNKNOWN');
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred
      errorMessage = this.translateService.instant('TUNES.UPLOADERRCLIENT');
    } else {
      // The backend returned an unsuccessful response code
      if (error.error && error.error.error) {
        errorMessage = this.translateService.instant('TUNES.UPLOADERRSERVER', { message: error.error.error });
        const errorCode = error.error.error_code; // Extract error code
        if (errorCode===1) { // No File provided
          errorMessage = this.translateService.instant('TUNES.UPLOADERRCLIENT');
        } else if(errorCode===2 || errorCode===3) { // Wrong file format
          errorMessage = this.translateService.instant('TUNES.AUDIOFILEERRORTYPE');
        } else if(errorCode===4) { // Sample rate
          errorMessage = this.translateService.instant('TUNES.AUDIOFILEERRORSAMPLE');
        }
      } else {
        errorMessage = this.translateService.instant('TUNES.UPLOADERRSERVER', { message: error.status });
      }
    }
    file.state = 'rejected';
    file.error = errorMessage;

    console.error(errorMessage);
  }

  // Function to convert a base64 string to a Blob
  private base64ToBlob(base64: string, mimeType: string): Blob {
    // Decode base64 string to binary string
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    // Convert binary string to Uint8Array
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create and return a Blob
    return new Blob([bytes], { type: mimeType });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = (reader.result as string).split(',')[1]; // Remove "data:*/*;base64," prefix
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private adjustDate(date: Date) {
    const timeZoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() - timeZoneOffset * 60000);
  }

  onSeekTo(time: number) {
    this.audioService.setCurrentTime(time)
  }

  setIsPlaying(playing: boolean) {
    this.isPlaying = playing;
  }

}
