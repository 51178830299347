import { CachedImgSource } from './../../general/cached-image/cached-image.component';
import { Component, inject, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService, Collection } from 'bandon-shared';
import { CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
    selector: 'app-collection-cover',
    templateUrl: './collection-cover.component.html',
    styleUrls: ['./collection-cover.component.scss'],
    standalone: true,
    imports: [CachedImageComponent],
})
export class CollectionCoverComponent implements OnInit {
  private authService = inject(AuthenticationService)
  private userService = inject(UserDataService)

  @Input() collection: Collection;

  constructor() { }

  get designation(): string{
    if(this.userService.user && this.collection) {
      const translation = this.collection.translations.find(e => e.languageid===this.userService.user.language);
      if(translation) {
        return translation.designation;
      }
      return this.collection.designation;
    } else if(this.collection) {
      return this.collection.designation;
    }
    return '';
  }

  get collectionImgSrc(): CachedImgSource {
    if(this.collection && this.collection.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+this.collection.picture.id, path: this.collection.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

  get collectionImg(): string {
    if(this.collection && this.collection.picture) {
      return environment.apiURL+'/photoImg/'+this.collection.picture.id;
    }
    return '';
  }

  ngOnInit() {}

}
