<ion-item [button]="detail" [detail]="detail" [lines]="lines">
  <ion-avatar aria-hidden="true" slot="start">
    @if(group.imgSrc) {
      <img [src]="group.imgSrc">
    }
    @else {
      <img src="assets/Band-On_Logo_loading.png">
    }
  </ion-avatar>
  <ion-label ariaLabel="{{ group.designation}}: {{ group.description }}">
    <ion-text>{{ group.designation }}</ion-text>
    @if(showGroupDescription) {
      <br />
      <ion-note aria-hidden="true">{{ group.description }}</ion-note>
    }
    @if(showUserLimit && group.userlimit) {
      <br />
      <ion-note>{{ 'GROUPS.USERLIMIT' | translate: { limit: group.userlimit } }}</ion-note>
    }
  </ion-label>

  <ng-content select="[slot=end]" slot="end"></ng-content>
</ion-item>
