import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, Instrument } from 'bandon-shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackAnalyzerService {
  authService = inject(AuthenticationService)
  httpClient = inject(HttpClient)

  instruments: Instrument[] = [];

  constructor() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Load all possible Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', { headers })
      .subscribe(async data => {
        this.instruments = data.sort((i1, i2) => i1.designation.localeCompare(i2.designation));
      });
  }

  guessTrackInstrument(filename: string) {
    const fname = filename.toLowerCase();

    const matchesInstrument = (name: string | undefined): boolean =>
      !!name && (fname.includes(`${name.toLowerCase()} `) || fname.includes(`${name.toLowerCase()}_`) || fname.includes(`${name.toLowerCase()}-`));

    let sortedInstruments = this.instruments.sort((a, b) => {
      const lengthA = a.abbreviation ? a.abbreviation.length : 0;
      const lengthB = b.abbreviation ? b.abbreviation.length : 0;

      return lengthB - lengthA;
    });

    for (const instrument of sortedInstruments) {
      if (matchesInstrument(instrument.abbreviation) || matchesInstrument(instrument.designation)) {
        return instrument;
      }
      for (let translation of instrument.translations) {
        if(matchesInstrument(translation.designation)) {
          return instrument
        }
      }
    }

    return this.instruments.find(i => i.id === 9); // Optional default
  }
}
