<div class="tune-img">
  @if(mode==='edit') {
    <ion-icon name="camera" class="overlay-icon" (click)="setPhotoSheetOpen(true)" [ariaLabel]="buttonAriaLabel" role="button"></ion-icon>
  }
  <!--TODO:-->
  @if(mode==='edit' && newImgSrc) {
    <img [src]="newImgSrc" [ariaLabel]="imgAriaLabel">
  }
  @else {
    <app-cached-image [src]="imgSrc"></app-cached-image>
  }
  <!--<div *ngIf="isEditMode && newPlaylistImgSrc" class="ion-margin new-playlist-img">
    <img [src]="newPlaylistImgSrc">
  </div>-->
</div>

<ion-action-sheet
  [isOpen]="isPhotoSheetOpen"
  header="{{ 'USERMENU.NEWPHOTO' | translate }}"
  [buttons]="photoSourceButtons"
  (didDismiss)="photoSourceAction($event)"
  class="band-on-action-sheet"
></ion-action-sheet>
