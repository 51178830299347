<!-- Display selected files -->
<div *ngIf="selectedUploadFiles.length > 0" >
  <ion-list lines="full">
    <ion-item *ngFor="let file of selectedUploadFiles" class="track-item">
      @if(file.state==='in progress') {
        <ion-spinner slot="start" ariaLabel="{{'TUNES.ARIAFILECHECK' | translate}}"></ion-spinner>
      }
      @else if (file.state==='rejected') {
        <ion-icon name="close-circle-outline" color="primary" slot="start" ariaLabel="{{'TUNES.ARIAFILEREJECTED' | translate}}"></ion-icon>
      }
      @else if (file.state==='accepted') {
        <ion-icon name="checkmark-circle-outline" color="success" slot="start" ariaLabel="{{'TUNES.ARIAFILEACCEPTED' | translate}}"></ion-icon>
      }
      <ion-label>
        {{ file.name }}<br />
        @if(file.error) {
          <ion-note color="primary">
            {{ file.error }}
          </ion-note>
        }
        @else if(file.state==='in progress') {
          <ion-note>
            {{ 'TUNES.AUDIOFILECHECK' | translate }}
          </ion-note>
        }
      </ion-label>
      <ion-button (click)="triggerRemoveFile(file)" color="primary" fill="solid" size="small" slot="end" class="ion-margin-vertical" role="button" ariaLabel="{{'TUNES.ARIADELETETRACK' | translate}}">
        <ion-icon name="trash-outline" slot="icon-only" aria-hidden="true"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</div>

@if (multiple || selectedUploadFiles.length==0) {
  <ion-button (click)="triggerAudioFileInput()" expand="full" class="ion-margin-vertical">{{ uploadText }}</ion-button>
}

@if(formatRemarks) {
  <div class="ion-padding-bottom ion-text-start ion-padding-start">
    <ion-text [innerHTML]="formatRemarks" style="color: gray">
    </ion-text>
  </div>
}
