import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioMergeService {

  private _audioContext: AudioContext;

  constructor() {
    this._audioContext = new AudioContext();
  }

  mergeAudio(buffers: AudioBuffer[]): AudioBuffer {
    const output = this._audioContext.createBuffer(
      this._maxNumberOfChannels(buffers),
      this._audioContext.sampleRate * this._maxDuration(buffers),
      this._audioContext.sampleRate
    );

    buffers.forEach((buffer) => {
      for (let channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
        const outputData = output.getChannelData(channelNumber);
        const bufferData = buffer.getChannelData(channelNumber);

        for (let i = bufferData.length - 1; i >= 0; i--) {
          outputData[i] += bufferData[i];
        }
      }
    });

    return output;
  }

  private _maxNumberOfChannels(buffers: AudioBuffer[]): number {
    return Math.max(...buffers.map(buffer => buffer.numberOfChannels));
  }

  private _maxDuration(buffers: AudioBuffer[]): number {
    return Math.max(...buffers.map(buffer => buffer.duration));
  }

  audioBufferToWaveUrl(buffer: AudioBuffer): string {
    const wav = this.audioBufferToWav(buffer);
    const blob = new Blob([new DataView(wav)], { type: 'audio/wav' });
    return URL.createObjectURL(blob);
  }

  audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
    const numOfChan = buffer.numberOfChannels,
      length = buffer.length * numOfChan * 2 + 44,
      bufferResult = new ArrayBuffer(length),
      view = new DataView(bufferResult),
      channels = [],
      sampleRate = buffer.sampleRate;

    let offset = 0;

    function setUint16(data: number) {
      view.setUint16(offset, data, true);
      offset += 2;
    }

    function setUint32(data: number) {
      view.setUint32(offset, data, true);
      offset += 4;
    }

    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(sampleRate);
    setUint32(sampleRate * 2 * numOfChan);
    setUint16(numOfChan * 2);
    setUint16(16); // bits per sample

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - offset - 4);

    for (let i = 0; i < buffer.numberOfChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    const interleaved = this._interleave(channels);

    interleaved.forEach((sample) => {
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
      offset += 2;
    });

    return bufferResult;
  }

  private _interleave(channels: Float32Array[]): Float32Array {
    const length = channels[0].length;
    const result = new Float32Array(length * channels.length);
    let inputIndex = 0;

    for (let i = 0; i < length; i++) {
      for (let channel = 0; channel < channels.length; channel++) {
        result[inputIndex++] = channels[channel][i];
      }
    }
    return result;
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = (reader.result as string).split(',')[1]; // Remove "data:*/*;base64," prefix
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
