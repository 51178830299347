import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { AlertController, IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService, GroupUser, profanityValidator, UserGroup } from 'bandon-shared';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { Subject, takeUntil } from 'rxjs';
import { ImgCropperComponent } from 'src/app/components/general/img-cropper/img-cropper.component';
import { GroupsService } from 'src/app/services/social/groups.service';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creator-welcome',
  templateUrl: './creator-welcome.component.html',
  styleUrls: ['./creator-welcome.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class CreatorWelcomeComponent  implements OnInit, OnDestroy {
  private fb = inject(FormBuilder)
  private translateService = inject(TranslateService)
  private modalController = inject(ModalController)
  private alertController = inject(AlertController)
  private loadingController = inject(LoadingController)
  private groupsService = inject(GroupsService)
  private httpClient = inject(HttpClient)
  private authService = inject(AuthenticationService)
  private router = inject(Router)

  @Input() group: UserGroup;

  groupForm: FormGroup = this.fb.group({
    nameInput: ['', [Validators.required, profanityValidator()]],
    descriptionInput: ['', [profanityValidator()]]
  });
  groupImage: string | ArrayBuffer | null = null;

  unsafedData = false;
  savingMessage: HTMLIonLoadingElement | undefined;

  newGroupImgSrc: any | undefined;

  public isPhotoSheetOpen = false;
  public photoSourceButtons = [
    {
      text: this.translateService.instant('USERMENU.CAMERA'),
      data: {
        source: 'CAMERA',
      },
    },
    {
      text: this.translateService.instant('USERMENU.PHOTOS'),
      data: {
        source: 'PHOTOS',
      },
    },
    {
      text: this.translateService.instant('USERMENU.CANCEL'),
      role: 'cancel',
      data: {
        source: 'cancel',
      },
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor() {}

  get nameInput() {
    return this.groupForm.get('nameInput');
  }

  get descriptionInput() {
    return this.groupForm.get('descriptionInput');
  }

  get designation() {
    if(this.group) {
      return this.group.designation;
    }
    return '';
  }

  set designation(designation: string) {
    if(this.group) {
      this.group.designation = designation;
    }
  }

  get description() {
    if(this.group) {
      return this.group.description;
    }
    return '';
  }

  set description(description: string) {
    if(this.group) {
      this.group.description = description;
    }
  }


  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onSubmit() {
    this.saveChanges();
  }

  async saveChanges() {
    await this.showSaving();
    const formData = new FormData();

    formData.append('group', JSON.stringify(this.group));

    //Add new Picture
    if(this.newGroupImgSrc) {
      const data = await this.readAsBase64(this.newGroupImgSrc);
      const file = await fetch(data);
      const blob = await file.blob();
      const filename = `group_${this.group.id}.png`;
      formData.append('img', blob, filename);
    }

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);

    this.httpClient.post<UserGroup>(environment.apiURL+`/usergroups/${this.group.id}`, formData, {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: resp => {
        this.group = resp;
        this.groupsService.updateGroup(this.group);
        this.router.navigateByUrl(`collection/user/group-list/${this.group.id}`)
        this.hideSaving();
        this.modalController.dismiss();
      },
      error: err => {
        this.hideSaving();
        console.error(err)
      }
    });
  }

  changedDesignation(event) {
    this.designation = event.detail.value;
    this.changedData();
  }

  changedDescription(event) {
    this.description = event.detail.value;
    this.changedData();
  }

  changedData() {
    this.unsafedData = true;
  }

  setPhotoSheetOpen(isOpen: boolean) {
    this.isPhotoSheetOpen = isOpen;
  }

  async openImgOptionSelection() {
    this.takePicture('prompt');
  }

  photoSourceAction(event) {
    this.isPhotoSheetOpen = false;
    if(event.detail.role !== 'cancel') {
      Camera.checkPermissions().then(status => {
        if (event.detail.data.source === 'CAMERA') {
          if(status.camera === 'granted'|| status.camera === 'limited') {
            this.takePicture('CAMERA')
          } else if(status.camera === 'denied') {
                this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.camera === 'granted' || status.camera === 'limited') {
                this.takePicture('CAMERA')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        } else {
          console.log(status)
          if(status.photos === 'granted' || status.photos === 'limited') {
            this.takePicture('PHOTOS')
          } else if(status.photos === 'denied') {
            this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.photos === 'granted' || status.photos === 'limited') {
                this.takePicture('PHOTOS')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        }
      });
    }
  }

  async presentPhotoPermissionAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('USERMENU.NOPHOTOPERMISSIONTITLE'),
      message: this.translateService.instant('USERMENU.NOPHOTOPERMISSIONMSG'),
      buttons: [
        {
          text: this.translateService.instant('USERMENU.TOSETTINGS'),
          handler: () => {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App
            })
          }
        },
        {
          text: this.translateService.instant('USERMENU.CANCEL'),
          role: 'cancel'
        }
      ]
    });

    await alert.present()
  }

  async takePicture(type) {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: this.checkPlattformForWebOrIos() ? CameraResultType.DataUrl : CameraResultType.Uri,
      source: type
    });

    if (image) {
      if(this.checkPlattformForWebOrIos()) {image.webPath = image.dataUrl;}

      const modal = await this.modalController.create({
        component: ImgCropperComponent,
        componentProps: {
          image: image.webPath
        }
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if(role==='confirm') {
        console.log(data, role);
        this.newGroupImgSrc = data;
      }

    }
  }

  checkPlattformForWebOrIos() {
    // eslint-disable-next-line eqeqeq
    if(Capacitor.getPlatform() == 'web' || Capacitor.getPlatform() == 'ios') {
      return true;
    }
    return false;
  }

  async showSaving() {
    this.savingMessage = await this.loadingController.create({
      message: this.translateService.instant('GROUPS.SAVING'),
      cssClass: 'band-on-loading'
    });

    this.savingMessage.present();
  }

  hideSaving() {
    this.savingMessage.dismiss();
  }

  async readAsBase64(webPath: string) {
    const response = await fetch(webPath);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob) as string;
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

}
