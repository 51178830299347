<div class="select-container" [class.disabled]="disabled" aria-haspopup="listbox" aria-expanded="false" role="button">
  <div class="visually-hidden">
    {{ ariaFn(value) }}
  </div>
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-start" aria-hidden="true">
        {{ title }}
      </ion-col>
      <ion-col (click)="open()" class="ion-text-end ion-content-col">
        <div class="text-icon-container" aria-hidden="true">
          @if (value) {
            @if (!showBpmInput) {
              {{ displayFn(value).text }}
            } @else {
              <div class="ellipsis-overflow" [innerHTML]="getNoteChar(value.notevalue)">
              </div>
            }
          }
          @else {
            <span class="placeholder ellipsis-overflow" [class.disabled-darker]="disabled">
              {{ placeholder }}
            </span>
          }

          @if (isIos) {
            <ion-icon name="chevron-expand-outline" [class.disabled-darker]="disabled" color="primary" class="centered-icon"></ion-icon>
          }
          @else {
            <ion-icon name="caret-down-outline" [ngClass]="{ 'rotate-icon': isOpen, 'reset-icon': !isOpen }"  class="centered-icon"></ion-icon>
          }
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ion-modal [isOpen]="isOpen" (didDismiss)="isOpen = false">
  <ng-template>
    <ion-header [class.android-select-header]="!isIos">
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar (ionInput)="filter($event)" placeholder="suchen"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content class="modal-background ion-text-center">
      <ion-radio-group [value]="radioGroupValue" [compareWith]="compareWith" (ionChange)="selectItem($event)" #radioGroup>
        @for (item of filtered; track $index) {
          <ion-item class="modal-background" lines="none">
            @if(displayDesignationFn(item).text) {
              <ion-radio labelPlacement="end" justify="start" [value]="item">{{ displayDesignationFn(item).text }}</ion-radio>
            }
            @else if(displayDesignationFn(item).innerHTML) {
              <ion-radio labelPlacement="end" justify="start" [value]="item" [ariaLabel]="ariaBpmSelectFn(item)">
                <div [innerHTML]="displayDesignationFn(item).innerHTML">
                </div>
              </ion-radio>
            }
          </ion-item>
        }
        @empty {
          <div class="ion-text-center ion-margin">
            {{ 'TUNES.NORESULTSELECT' | translate }}
          </div>
        }
      </ion-radio-group>
    </ion-content>

    <ion-footer [class.android-select-footer]="!isIos">

        <ion-toolbar
          [@markerDetails]="showBpmInput
          ? {value: 'visible',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
          :  {value: 'hidden',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
        >
          <ion-input class="modal-ion-input ion-padding-horizontal" type="number" [(ngModel)]="speed" [min]="1"></ion-input>
        </ion-toolbar>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="cancel()" color="primary">{{ 'TUNES.CANCEL' | translate | uppercase }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="confirm()" color="primary">{{ 'TUNES.OK' | translate | uppercase }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
