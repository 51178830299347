import { inject, Injectable } from '@angular/core';
import { AuthenticationService, Collection, Instrument, InstrumentGroup, SimpleTuneModel, SubscriptionPlan, Tune } from 'bandon-shared';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from '../user/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class BandONTranslationsService {
  private translate = inject(TranslateService);
  private userDataService = inject(UserDataService);

  constructor() { }

  getTuneTitle(tune: SimpleTuneModel | Tune) {
    if(this.userDataService.user && tune && tune.translations) {
      const translation = tune.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.title) {
        return translation.title;
      }
      return tune.title;
    } else if(tune) {
      return tune.title;
    }
    return '';
  }

  getTuneSubtitle(tune: SimpleTuneModel | Tune) {
    if(this.userDataService.user && tune && tune.subtitle && tune.translations) {
      const translation = tune.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.subtitle) {
        return translation.subtitle;
      }
      return tune.subtitle;
    } else if(tune && tune.subtitle) {
      return tune.subtitle;
    }
    return '';
  }

  getCollectionTitle(collection: Collection) {
    if(this.userDataService.user && collection && collection.translations) {
      const translation = collection.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.designation) {
        return translation.designation;
      }
      return collection.designation;
    } else if(collection) {
      return collection.designation;
    }
    return '';
  }

  getCollectionDesc(collection: Collection) {
    if(this.userDataService.user && collection && collection.translations) {
      const translation = collection.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.description) {
        return translation.description;
      }
      return collection.description;
    } else if(collection) {
      return collection.description;
    }
    return '';
  }

  getTonalityString(tonalities: string, withTitle=true) {
    let tonalityString = this.translate.instant('PLAYER.TONALITY');
    if(tonalities.split(',').length>1) {
      tonalityString = this.translate.instant('PLAYER.TONALITIES')
    }
    if(!withTitle) {
      tonalityString = '';
    }
    for(let tonality of tonalities.split(',')) {
      //TODO: verbessern, evtl. in Service auslagern
      if(this.translate.currentLang==='de') {
        tonality = tonality.replace('B', 'H')
        tonality = tonality.replace('b', 'es')
        tonality = tonality.replace('#', 'is')
        tonality = tonality.replace('Aes', 'As')
        tonality = tonality.replace('Ees', 'Es')
        tonality = tonality.replace('Hes', 'B')
        tonality = tonality.replace('-', '-'+this.translate.instant('PLAYER.MINOR'))
      } else if(this.translate.currentLang==='en') {
        tonality = tonality.replace('b', ' flat')
        tonality = tonality.replace('#', ' sharp')
        tonality = tonality.replace('-', '-'+this.translate.instant('PLAYER.MINOR'))
      }
      tonalityString += ' '+tonality
    }
    return tonalityString;
  }

  getNoteValueString(notevalue: number): string {
    switch(notevalue) {
      case 1:
        return this.translate.instant('TUNES.ARIAWHOLENOTE')
      case 2:
        return this.translate.instant('TUNES.ARIAHALFNOTE')
      case 4:
        return this.translate.instant('TUNES.ARIAQUARTERNOTE')
      case 8:
        return this.translate.instant('TUNES.ARIAEIGHTNOTE')
      case 16:
        return this.translate.instant('TUNES.ARIASIXTEENTHNOTE')
    }
    return ''
  }

  getTempiString(speeds: string) {
    let tempoString = this.translate.instant('PLAYER.SPEED');
    if(speeds.split(',').length>1) {
      tempoString = this.translate.instant('PLAYER.SPEEDS');
    }
    return tempoString + speeds;
  }

  getTuneDescription(tune: SimpleTuneModel) {
    let tonalityString = this.getTonalityString(tune.tonalities);
    let tempoString = this.getTempiString(tune.speeds);
    const title = this.getTuneTitle(tune);
    const subtitle = this.getTuneSubtitle(tune);
    let out = `${this.translate.instant('PLAYER.TUNE')} ${title}, ${subtitle}, ${tonalityString}, ${tempoString}`;
    return out;
  }

  getCollectionDescription(collection: Collection) {
    return `${this.translate.instant('COLLECTION.COLLECTION')} ${this.getCollectionTitle(collection)}: ${this.getCollectionDesc(collection)}`;
  }

  getInstrumentDesignation(instrument: Instrument) {
    if(this.userDataService.user && instrument) {
      const translation = instrument.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.designation) {
        return translation.designation;
      }
      return instrument.designation;
    } else if(instrument) {
      return instrument.designation;
    }
    return '';
  }

  getSubscriptionPlanDesignation(plan: SubscriptionPlan) {
    if(this.userDataService.user && plan) {
      const translation = plan.translations.find(e => e.language===this.userDataService.user.language);
      if(translation && translation.designation) {
        return translation.designation;
      }
      return plan.designation;
    } else if(plan) {
      return plan.designation;
    }
    return '';
  }

  //TODO: Instrumentengruppen Übersetzungen
/*  getInstrumentGroupDesignation(instrument: InstrumentGroup) {
    if(this.userDataService.user && instrument) {
      const translation = instrument.translations.find(e => e.languageid===this.userDataService.user.language);
      if(translation && translation.designation) {
        return translation.designation;
      }
      return instrument.designation;
    } else if(instrument) {
      return instrument.designation;
    }
    return '';
  }*/
}
