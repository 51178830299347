import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AlertController, IonicModule, IonInput, IonSelect, RangeCustomEvent } from '@ionic/angular';
import { TimeMarkerBarComponent } from '../time-marker-bar/time-marker-bar.component';
import { Region, WaveformComponent } from '../waveform/waveform.component';
import { AuthenticationService, Lyric, Meter, Part, Speed, TimeInputDirective, Track, Tune } from 'bandon-shared';
import { UploadFile } from '../../file-upload/file-upload.component';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { AudioEngine } from '@musicdose/audioengine';
import { AudioService } from 'src/app/services/audio/audio.service';
import { TimelineComponent } from '../timeline/timeline.component';
import { Subject, takeUntil } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { AudioMergeService } from 'src/app/services/audio/audio-merge.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchableSelectComponent } from 'src/app/components/general/searchable-select/searchable-select.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HorizontalDividerComponent } from 'src/app/components/general/horizontal-divider/horizontal-divider.component';
import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { Filesystem } from '@capacitor/filesystem';
import { TranscribeService } from 'src/app/services/audio/transcribe.service';

export interface Marker {
  time: number;
  pickupTime?: number;
  label: string;
  width: number;
  part?: Part;
  type?: 'arrangement' | 'tempo' | 'meter' | 'lyric';
  meter?: Meter;
}

@Component({
  selector: 'app-marker-view',
  templateUrl: './marker-view.component.html',
  styleUrls: ['./marker-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TimeMarkerBarComponent,
    WaveformComponent,
    TimelineComponent,
    TimeInputDirective,
    FormsModule,
    SearchableSelectComponent,
    TranslateModule,
    HorizontalDividerComponent
  ],
  animations: [
    trigger('markerDetails', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({ overflow: 'hidden' }),
        animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class MarkerViewComponent  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('zoomContainer', { static: true }) zoomContainer: ElementRef;
  @ViewChild('waveformElement') waveformElement: ElementRef;
  @ViewChild('partNameInput', { static: false }) partNameInput: IonInput;
  @ViewChild('transcribeSelect', { static: false}) transcribeSelector: IonSelect;

  alertController = inject(AlertController)
  translate = inject(TranslateService)
  tuneInfoService = inject(TuneInfoService);
  audioService = inject(AudioService)
  audioMergeService = inject(AudioMergeService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  transcribeService = inject(TranscribeService)

  @Input() tune: Tune;
  @Input() audioFiles: UploadFile[] = [];
  @Input() tunetempo: Speed | undefined = undefined;
  @Input() isPlaying = false;

  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() seekTo: EventEmitter<number> = new EventEmitter<number>();

  private _audioContext: AudioContext;

  showAccordeon = true;

  sizeInit = false;
  zoomLevel = 1;
  contentWidth = 1000; // Initial width, will be dynamically calculated

  sizeObserver: ResizeObserver | undefined;

  shownFile: any;

  selectedMarker: Marker | undefined;

  seekToTime = 0;

  scrollLeft: number = 0;

  showWaveformUpdate = false;

  meters: Meter[] = [];
  meterDisplayFn: (item: Meter) => { text: string; imageUrl?: string } = (item) => ({
    text: `${item.nn}/${item.dd}`
  });

  //Tune informations
  _duration = 0; // duration in s

  partMarkers: Marker[] = [];

  meterMarkers: Marker[] = [];

  tempoMarkers: Marker[] = [];

  lyricMarkers: Marker[] = [];

  //Transcribing
  isTranscribing = false;

  showTimeMark = 0;
  showRegion: Region | undefined = undefined;
  showRegionTime: Region | undefined = undefined;

  private scrollDisabled: boolean = false;

  public alertButtons = [
    {
      text: this.translate.instant('TUNES.CANCEL'),
      role: 'cancel',
    },
    {
      text: this.translate.instant('TUNES.OK'),
      role: 'confirm',
      handler: () => {
        this.removeMarker()
      },
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor() {
    this._audioContext = new AudioContext();
  }

  get duration() {
    if(this._duration) {
      return this._duration;
    }
    return this.audioService.duration * 1000;
  }

  get durationS() {
    return this.duration/1000;
  }

  get selectedTime() {
    if(this.selectedMarker) {
      return Math.round(this.selectedMarker.time);
    }
    return 0;
  }

  set selectedTime(time: number) {
    this.onSeekTo(time/1000)
    if(this.selectedMarker && this.selectedMarker.time!==time) {
      const delta = time-this.selectedMarker.time;

      this.selectedMarker.time = time;
      if(this.selectedMarker.pickupTime) {
        this.selectedMarker.pickupTime += delta;
      }

      this.showTimeMark = this.selectedMarker.time/1000;

      this.hasChanged();
      this.generateTuneParts();
    }
  }

  get selectedPickupTime() {
    if(this.selectedMarker && this.selectedMarker.pickupTime) {
      return Math.round(this.selectedMarker.pickupTime);
//      return undefined;
    }
    return undefined;
  }

  set selectedPickupTime(time: number) {
    if(this.selectedMarker) {
      this.selectedMarker.pickupTime = time;
    }
  }

  get selectedMarkerType(): 'arrangement' | 'tempo' | 'meter' | 'lyric' | 'none' {
    if(this.selectedMarker) {
      return this.selectedMarker.type;
    }
    return 'none'
  }

  get selectedMeter(): Meter | undefined {
    if(this.selectedMarker && this.selectedMarker.part) {
      return this.selectedMarker.part.rMeter;
    }
    return undefined;
  }

  get selectedArrangementLabel(): string | undefined {
    if(this.selectedMarker) {
      return this.selectedMarker.label
    }
    return undefined;
  }

  set selectedArrangementLabel(label: string) {
    if(this.selectedMarker) {
      this.selectedMarker.label = label;
      if(this.selectedMarker.part) {
        this.selectedMarker.part.designation = label;
      }
    }
  }

  ngOnInit() {
    this.tuneInfoService.filteredParts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(parts => {
        let partDesignation = '';
        let meter: Meter | undefined = undefined;
        let tempo = 0;

        let partMarkers: Marker[] = [];
        let metrumMarkers: Marker[] = [];
        let tempoMarkers: Marker[] = [];

        for(let part of parts) {
          if(part.designation!=partDesignation) {
            partDesignation = part.designation;

            let marker: Marker = { time: part.timestart, label: partDesignation, width: 0, part, type: 'arrangement' };
            if(part.timepickup) {
              marker.pickupTime = part.timepickup;
            }
            partMarkers.push(marker)
          }

          if((part.rMeter && part.rMeter!=meter) || part.meter) {
            meter = part.rMeter;
            metrumMarkers.push({ time: part.timestart, label: `${meter.nn}/${meter.dd}`, width: 0, part, type: 'meter', meter: meter})
          }

          if((part.rTempo && part.rTempo!=tempo) || part.tempo) {
            tempo = part.rTempo;
            tempoMarkers.push({ time: part.timestart, label: `${tempo}`, width: 0, part, type: 'tempo'});
          }

        }

        this.partMarkers.length = 0;
        this.partMarkers = [...partMarkers];
        this.meterMarkers.length = 0;
        this.meterMarkers = [...metrumMarkers];
        this.tempoMarkers.length = 0;
        this.tempoMarkers = [...tempoMarkers];
        this.fitToContainer();

        //Update selectedMarker
        if(this.selectedMarker) {
          let newMarker = undefined;
          if(this.selectedMarker.type==='arrangement') {
            newMarker = this.partMarkers.find(m => m.label===this.selectedMarker.label && m.time===this.selectedMarker.time);
          } else if(this.selectedMarker.type==='tempo') {
            newMarker = this.tempoMarkers.find(m => m.label===this.selectedMarker.label && m.time===this.selectedMarker.time);
          } else if(this.selectedMarker.type==='meter') {
            newMarker = this.meterMarkers.find(m => m.meter.id===this.selectedMarker.meter.id && m.time===this.selectedMarker.time);
          } else if(this.selectedMarker.type==='lyric') {
            newMarker = this.partMarkers.find(m => m.label===this.selectedMarker.label && m.time===this.selectedMarker.time);
          }
          if(newMarker) {
            this.onMarkerSelect(newMarker)
          }
        }
      })

    //Load all possible Meters
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.get<Meter[]>(environment.apiURL+'/meters', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.meters = data;
    });

  }

  ngAfterViewInit(): void {
    if(this.zoomContainer) {
      this.sizeObserver = new ResizeObserver(() => { this.fitToContainer() })

      this.sizeObserver.observe(this.zoomContainer.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if(this.sizeObserver) {
      this.sizeObserver.disconnect();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if(changes['tune'] && this.tune) {
      this.updateTuneData();
    }
    if(changes['audioFiles'] && this.audioFiles && this.audioFiles.length>0) {
      this.showWaveformUpdate = true;
      let files = this.audioFiles.map(f => f.file);
      // Once all files are read, merge the audio

      const audioBuffers: AudioBuffer[] = [];

      for(let f of files) {
        if(f) {
          audioBuffers.push(await this.readAndDecodeFile(f))
        }
      }

      if(audioBuffers.length>0) {
        const mergedAudioBuffer = this.audioMergeService.mergeAudio(audioBuffers);
        this.shownFile = this.audioMergeService.audioBufferToWaveUrl(mergedAudioBuffer);
      }
      this.updateTuneData();
      /*this.combineAudioFiles(files)
        .then(f => {
          this.shownFile = URL.createObjectURL(f);
          console.log('Audio files combined')
        });*/
//      console.log(this.audioFiles)
//      this.shownFile = URL.createObjectURL(this.audioFiles[0].file)
    } else if(changes['audioFiles']) {
      this.shownFile = undefined;
    }
  }

  onWaveformRedraw() {
    this.showWaveformUpdate = false;
  }

  async updateTuneData() {
    if(!this.tune) {
      return;
    }

    if(this.audioFiles && this.audioFiles.length>0 && this.audioFiles[0].file) {
      this._duration = (await AudioEngine.getAudioDuration({ base64data: await this.blobToBase64(this.audioFiles[0].file) })).duration * 1000;
    }

    if(this.tune.tracks && this.tune.tracks.length>0) {
      let speed = this.tune.tracks[0].speed
      if(speed) {
        this.lyricMarkers.length = 0;
        for(let phrase of speed.lyrics) {
          this.lyricMarkers.push({ time: phrase.timestamp, label: phrase.phrase, width: 0, type: 'lyric'});
        }
      }
    }

    this.fitToContainer();
  }

  changeZoom(ev: any) {
    this.zoomLevel = ev.detail.value;
  }

  // Adjust scaling to fit the container width
  fitToContainer() {
    const containerWidth = this.zoomContainer.nativeElement.offsetWidth;

    this.contentWidth = containerWidth;
/*    // Calculate the total width required for all markers
    const totalMarkerDistance = this.markers[this.markers.length - 1].position;

    // Calculate the scale factor to fill the container width
    this.zoomLevel = containerWidth / totalMarkerDistance;

    // Adjust the marker bar width to fit the container
    this.markerBarWidth = totalMarkerDistance * this.zoomLevel;

    // Recalculate marker widths after scaling
    this.calculateMarkerWidths();

    this.sizeInit = true;*/
  }

  // Handler for pinch gestures to control zoom
  onPinch(event: any) {
    const pinchScale = event.scale;
    this.zoomLevel *= pinchScale;

    if (this.zoomLevel < 0.5) this.zoomLevel = 0.5; // Minimum zoom level
    if (this.zoomLevel > 3) this.zoomLevel = 3; // Maximum zoom level
  }

  onScroll(event: any) {
    const target = event.target as HTMLElement;
    this.scrollLeft = target.scrollLeft;
  }

  onMarkerSelect(marker: Marker) {
    if(this.selectedMarker===marker) {
      this.selectedMarker = undefined;
//      this.showTimeMark = 0;
      this.showRegion = undefined;
      this.showRegionTime = undefined;
    } else {
//      this.showTimeMark = marker.time/1000;
      this.selectedMarker = marker;

      const nextMarker = this.getNextMarker(marker);
      let upper = this.durationS;
      if(nextMarker) {
        upper = nextMarker.time/1000;
      }

      if(!this.showRegion || (this.showRegion && (this.showRegion.lower!=marker.time/1000 || this.showRegion.upper!=upper || this.showRegion.label!=marker.label))) {
        this.showRegion = {
          lower: marker.time/1000,
          upper: upper,
          label: marker.label
        }
        this.showRegionTime = this.showRegion;
      }
    }
  }

  addMarker(type: 'arrangement' | 'tempo' | 'meter' | 'lyric') {
    if(type==='arrangement') {
      this.selectedMarker = { time: this.getUniqueMarkerTime( this.seekToTime, this.partMarkers ),
        label: this.getNextMarkerName(), width: 0, type }
      this.partMarkers.push(this.selectedMarker)
    } else if(type==='tempo') {
      this.selectedMarker = { time: this.getUniqueMarkerTime( this.seekToTime, this.tempoMarkers ),
        label: this.tunetempo.speed.toString(), width: 0, type }
      this.tempoMarkers.push(this.selectedMarker)
    } else if(type==='meter') {
      this.selectedMarker = { time: this.getUniqueMarkerTime( this.seekToTime, this.meterMarkers ),
        label: this.getNextMarkerName(),
        meter: this.meters.find(m => m.dd==4 && m.nn==4), width: 0, type }
      this.meterMarkers.push(this.selectedMarker)
    } else if(type==='lyric') {
      this.selectedMarker = { time: this.getUniqueMarkerTime( this.seekToTime, this.partMarkers ),
        label: this.getNextMarkerName(), width: 0, type }
      this.lyricMarkers.push(this.selectedMarker)
    }
    this.generateTuneParts();
    if(this.partNameInput) {
      this.partNameInput.setFocus()
    }
    this.hasChanged();
  }

  removeMarker() {
    if(this.selectedMarker) {
      if(this.partMarkers.includes(this.selectedMarker)) {
        this.partMarkers.splice(this.partMarkers.indexOf(this.selectedMarker), 1)
      } else if(this.tempoMarkers.includes(this.selectedMarker)) {
        this.tempoMarkers.splice(this.tempoMarkers.indexOf(this.selectedMarker), 1)
      } else if(this.meterMarkers.includes(this.selectedMarker)) {
        this.meterMarkers.splice(this.meterMarkers.indexOf(this.selectedMarker), 1)
      }
      this.showTimeMark = 0;
      this.generateTuneParts();
      this.hasChanged();
      this.onMarkerSelect(this.selectedMarker)
      this.selectedMarker = undefined
    }
  }

  getNextMarkerName(): string {
    if (this.tune.parts && this.tune.parts.length > 0) {
      // Sammle alle existierenden designations in einem Set
      const existingDesignations = new Set(this.tune.parts.map(part => part.designation));

      // Iteriere über die Buchstaben des Alphabets, beginnend bei 'A'
      for (let i = 0; i < 26; i++) {
        const designation = String.fromCharCode(65 + i); // 65 ist der ASCII-Wert von 'A'
        if (!existingDesignations.has(designation)) {
          return designation; // Gib den ersten freien Buchstaben zurück
        }
      }
    }

    // Fallback: Gib 'A' zurück, wenn keine parts vorhanden sind
    return 'A';
  }

  getUniqueMarkerTime(initialTime: number, marker_array: Marker[]): number {
    let time = initialTime;
    const timeExists = (time: number) =>
      marker_array.some(marker => marker.time === time);

    // Erhöhe die Zeit in kleinen Schritten, bis sie einzigartig ist
    while (timeExists(time)) {
      time += 5000; // Schrittweite von 5 Sekunden
    }

    return time;
  }

  updateMarkerDesignation() {
    this.generateTuneParts();
  }

  changeMarkerTime(marker, $event) {
    console.log($event);
  }

  changeMarkerMeter(marker: Marker, meter: Meter) {
    marker.meter = meter;
  }

  onSeekTo(time: number) {
    this.seekToTime = time*1000;
    this.seekTo.emit(time);
/*    if(this.selectedMarker) {
      const delta = this.seekToTime-this.selectedMarker.time;
      this.selectedMarker.time = this.seekToTime;
      if(this.selectedMarker.pickupTime) {
        this.selectedMarker.pickupTime += delta;
      }
      if(delta!==0) {
        this.generateTuneParts();
      }
    }*/
  }

  updateRegion(region: Region) {
    if(this.selectedMarker) {
      let nextMarker = this.getNextMarker(this.selectedMarker);
      const deltaLower = region.lower*1000-this.selectedMarker.time;
      this.selectedMarker.time = region.lower*1000;
      if(this.selectedMarker.pickupTime) {
        this.selectedMarker.pickupTime += deltaLower;
      }

      if(nextMarker) {
        const deltaUpper = region.upper*1000-nextMarker.time;
        nextMarker.time = region.upper*1000;
        if(nextMarker.pickupTime) {
          nextMarker.pickupTime += deltaUpper;
        }
      }

      this.showRegion = region;
      this.showRegionTime = region;
      this.generateTuneParts();
      this.hasChanged();
    }
  }

  updateRegionTime(region: Region) {
    this.showRegionTime = region;
  }

  generateTuneParts() {
    let index = this.getMarkerIndex(this.selectedMarker);
    if(index==0 && (this.selectedMarker.type!=='lyric' || this.selectedMarker.time<this.partMarkers[0].time)) {
      //First Arrangement Marker
      if(this.partMarkers[0]) {
        this.partMarkers[0].time = this.selectedMarker.time;
      }
      if(this.meterMarkers[0]) {
        this.meterMarkers[0].time = this.selectedMarker.time;
      }
      if(this.tempoMarkers[0]) {
        this.tempoMarkers[0].time = this.selectedMarker.time;
      }
    }

    let markers: Marker[] = [...this.partMarkers];
    markers.push(...this.tempoMarkers)
    markers.push(...this.meterMarkers)

    markers = markers.sort((m1, m2) => m1.time-m2.time);

    let parts: Part[] = [];
    let last_part: Part = { id: "", designation: "", barstart: 0, barend: 0, pickup: 0, timestart: 0, timeend: 0, timepickup: 0 };
    let last_t = -1;
    for(let marker of markers) {
      if(marker.time!=last_t) {
        last_t = marker.time;
        last_part.timeend = marker.time
        last_part = { id: "", designation: last_part.designation, barstart: 0, barend: 0, pickup: 0, timestart: marker.time, timeend: 0, timepickup: 0 };
        parts.push(last_part)
      }
      if(marker.type==='arrangement') {
        last_part.designation = marker.label;
        last_part.timepickup = marker.pickupTime;
//        console.log(`Arrangement part ${marker.label}, ${marker.pickupTime}`)
      } else if(marker.type==='tempo') {
        last_part.tempo = Number.parseInt(marker.label)
//        console.log(`Tempo marker ${marker.label}`)
      } else if(marker.type==='meter') {
        last_part.meter = marker.meter;
//        console.log(`Meter marker ${marker.meter?.nn}/${marker.meter?.dd}`)
      } else if(marker.type==='lyric') {
        last_part.designation = marker.label;
      }
    }

    //Generate Lyrics
    if (this.tune.tracks?.length) {
      this.tune.tracks.forEach(track => {
        const speed = track.speed;

        // Initialisiere `speed.lyrics` direkt als leeres Array
        speed.lyrics = this.lyricMarkers.map(phrase => ({
          id: -1,
          timestamp: phrase.time,
          phrase: phrase.label
        }));
      });
    }

    last_part.timeend = this.audioService.duration*1000
    this.partMarkers = [...this.partMarkers]
    this.tune.parts = [...parts]
    this.tuneInfoService.setupArrangementInfo(this.tunetempo);
  }

  hasChanged() {
    this.onChange.emit();
  }

  onTouchStart(event: TouchEvent) {
    const touchedElement = event.target as HTMLElement;

    // Check if the touched element is the waveform or a child of it
    if (this.hasParentWithClass(touchedElement, "band-on-waveform")) {
      this.scrollDisabled = true;
    } else {
      this.scrollDisabled = false;
    }

    if (this.scrollDisabled) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  onTouchEnd(event: TouchEvent) {
    if (this.scrollDisabled) {
      this.enableScroll();
    }
  }

  disableScroll() {
    this.zoomContainer.nativeElement.style.overflowX = 'hidden';
  }

  enableScroll() {
    this.zoomContainer.nativeElement.style.overflowX = 'scroll';
  }

  async triggerTranscribeFileInput() {
    if(this.transcribeSelector) {
      this.transcribeSelector.open();
    }
  }

  transcribeFile(track: Track) {
    this.isTranscribing = true;
    const fileName = track.path.substring(track.path.lastIndexOf('/') + 1);
    let file = this.audioFiles.find(f => f.name===fileName);
    if(file) {
      this.transcribeService.transcribeFromFile(track.speed, file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          console.log(res);
          this.addLyrics(track.speed, res);
          this.isTranscribing = false;
        },
        error: err => {
          console.log(err)
          this.isTranscribing = false;
          this.showAlert(this.translate.instant('TUNES.ERROR'),this.translate.instant('TUNES.ERRORTRANSCRIBE'))
        }
      })
    } else {
      this.showAlert(this.translate.instant('TUNES.ERROR'),this.translate.instant('TUNES.ERRORTRANSCRIBE'))
    }
  }

  addLyrics(speed: Speed, lyrics: Lyric[]) {
    if(speed) {
      for(let phrase of lyrics) {
        this.selectedMarker = { time: phrase.timestamp,
          label: phrase.phrase, width: 0, type: 'lyric' }
        this.lyricMarkers.push(this.selectedMarker)
      }
    }
    this.generateTuneParts();
  }

  getTrackDesignation(track: Track) {
    if(track.voice && track.voice.id>0) {
      return track.voice.designation;
    }
    else if(this.translate && track.instrument && track.instrument.translations) {
      const translation = track.instrument.translations.find(e => e.languageid===this.translate.currentLang);
      if(translation) {
        return translation.designation;
      }
      return track.instrument.designation;
    } else if(track.instrument) {
      return track.instrument.designation;
    }
    return '';
  }

  async showAlert(header: string, message: string, subHeader: string | undefined = undefined) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['Ok'],
    });

    await alert.present();
  }


  private hasParentWithClass(element: HTMLElement, className: string): boolean {
    while (element) {
      if (element.classList && element.classList.contains(className)) {
        return true;
      }
      element = element.parentElement; // Move up to the parent element
    }
    return false;
  }

  private getNextMarker(marker: Marker) {
    //Calculate the end time of the region
    let upper = this.durationS;
    if(marker.type=='arrangement') {
      let index = this.partMarkers.indexOf(marker);
      if (index >= 0 && index<this.partMarkers.length-1) {
        return this.partMarkers[index+1];
      }
    } else if(marker.type==='meter') {
      let index = this.meterMarkers.indexOf(marker);
      if (index >= 0 && index<this.meterMarkers.length-1) {
        return this.meterMarkers[index+1];
      }
    } else if(marker.type==='tempo') {
      let index = this.tempoMarkers.indexOf(marker);
      if (index >= 0 && index<this.tempoMarkers.length-1) {
        return this.tempoMarkers[index+1];
      }
    } else if(marker.type==='lyric') {
      let index = this.lyricMarkers.indexOf(marker);
      if (index >= 0 && index<this.lyricMarkers.length-1) {
        return this.lyricMarkers[index+1];
      }

    }
    return undefined;
  }

  private getMarkerIndex(marker: Marker) {
    //Calculate the end time of the region
    if(marker.type=='arrangement') {
      return this.partMarkers.indexOf(marker);
    } else if(marker.type==='meter') {
      return this.meterMarkers.indexOf(marker);
    } else if(marker.type==='tempo') {
      return this.tempoMarkers.indexOf(marker);
    } else if(marker.type==='lyric') {
      return this.lyricMarkers.indexOf(marker);
    }
    return -1;
  }

  //TODO: put in audioMergeService
  async readAndDecodeFile(file: Blob): Promise<AudioBuffer> {
    const arrayBuffer = await file.arrayBuffer();
    return this._audioContext.decodeAudioData(arrayBuffer);
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = (reader.result as string).split(',')[1]; // Remove "data:*/*;base64," prefix
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

}
