<!-- Wrapper for the horizontal scrolling and zoomable container -->
<div class="zoom-container"  #zoomContainer>

  @if (showLoading) {
    <!-- Overlay to display when loading is true -->
    <div class="overlay">
      <div class="overlay-content">
        <ion-spinner name="bubbles" class="ion-margin-end" aria-hidden="true"></ion-spinner>
        {{ loadingText }}...
      </div>
    </div>
  }

  <div class="marker-bar" #markerBar>
    <!-- Loop through the markers array to display markers -->
    @for (marker of markers; track $index) {
      <div
        class="marker"
        [class.selected-marker]="marker==selectedMarker"
        [style.left.%]="(marker.time / duration) * 100"
        [style.width.%]="(marker.width / duration) * 100"
        [title]="marker.label"
        (click)="selectMarker(marker)"
        role="button"
        [ariaLabel]="ariaTitle+' '+marker.label"
      >
        @if (marker.label) {
          <div style="padding-left: 4px;">
            {{ marker.label }}
          </div>
        }
      </div>
    }
  </div>
</div>
