<ion-header >
  <ion-toolbar>
    <ion-title>{{ 'PURCHASES.MYPURCHASES' | translate }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" color="primary" class="close-icon" ariaLabel="{{ 'STORE.CLOSE' | translate }}"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-list>
    @for (purchaseItem of userPurchases; track $index) {
      <ion-item>
        <ion-text slot="start">
          {{ purchaseItem.date | date: 'dd.MM.yy' }}
        </ion-text>
        <ion-text>
          <p *ngIf="purchaseItem.collectionid>0" >
            {{ 'PURCHASES.COLLECTION' | translate }}:
            {{ getCollectionTitle(purchaseItem.collectionid) }}</p>
          <p *ngIf="purchaseItem.tuneid>0" >{{ getTuneDesc(purchaseItem.tuneid) }}</p>
          <p *ngIf="purchaseItem.subscription_plan" >{{ getSubscriptionDesc(purchaseItem.subscription_plan) }}</p>
        </ion-text>
      </ion-item>
    }
    @empty {
      <div
        style="margin: auto; margin-top: 1em" class="ion-text-center"
      >
        {{ 'STORE.NOPURCHASES' | translate }}
      </div>
    }
  </ion-list>
</ion-content>
