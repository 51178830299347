import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController, IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { INTRO_ELIGIBILITY_STATUS, Purchases, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { AuthenticationService, SubscriptionPlan } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';
import { PaywallContainerComponent } from '../paywall-container/paywall-container.component';
import { SizeSelectorComponent } from './size-selector/size-selector.component';
import { PaywallContentComponent } from './paywall-content/paywall-content.component';

@Component({
  selector: 'app-creator-paywall',
  templateUrl: './creator-paywall.component.html',
  styleUrls: ['./creator-paywall.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    PaywallContainerComponent,
    SizeSelectorComponent,
    PaywallContentComponent
  ]
})
export class CreatorPaywallComponent  implements OnInit, OnDestroy {
  modalController = inject(ModalController)
  storeService = inject(StoreService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  alertController = inject(AlertController)
  translate = inject(TranslateService)
  subscriptionService = inject(SubscriptionPlansService)
  loadingController = inject(LoadingController)

  selectedPeriod: 'monthly' | 'annual' | null = null;  // To store the selected plan

  selectedPlan: SubscriptionPlan | undefined = undefined;

  subcription_plans: SubscriptionPlan[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor() { }

  get isWeb() {
    //    return true;
    return Capacitor.getPlatform() === 'web';
  }


  ngOnInit(): void {

  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  dismissModal(role: string, data: any) {
    this.modalController.dismiss(data, role);
  }

  selectSize(plan: SubscriptionPlan) {
    this.selectedPlan = plan;
  }

  async showAlert(title: string, msg: string) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [ this.translate.instant('PAYWALL.OK') ],
    });

    await alert.present();
  }

  async refreshPurchases() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();
    let header = this.translate.instant('PROFILE.RELOAD')
    let message = this.translate.instant('PROFILE.REFRESHED')

    try {
      let customerInfo = await Purchases.restorePurchases();
      this.storeService.handleCustomerInfo(customerInfo.customerInfo);
    } catch(error) {
      if(error['code']=='7') {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PROFILE.ERRORREFRESHOTHERUSER')
      } else {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PROFILE.ERRORREFRESHUNKNOWN')
      }
    }

    const alert = await this.alertController.create({
      header, message,
      buttons: [
        {
          text: this.translate.instant('PROFILE.OK'),
          role: 'confirm',
        }
      ],
      cssClass: 'band-on-alert'
    });

    loading.dismiss
    await alert.present();
  }

}
