@if(showHeader) {
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start"(click)="dismiss()">
        <ion-icon name="close" class="close-icon" color="primary" ariaLabel="{{ 'PLAYER.CLOSE' | translate }}"></ion-icon>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
      @if(showSharing) {
        <ion-buttons slot="end" (click)="share()">
          <ion-icon name="share-outline" size="large" color="primary" ariaLabel="{{ 'PLAYER.SHARE' | translate }}"></ion-icon>
        </ion-buttons>
      }
    </ion-toolbar>
  </ion-header>
}
<!--<ion-content>-->
  <ion-grid class="ion-no-padding sticky-grid" aria-hidden="true">
    <ion-row class="ion-no-padding ion-align-items-center" style="height: 45px">
      <ion-col size="4" class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button fill="clear" color="primary" (click)="zoomOut()" class="zoom-button" expand="full">
          <ion-icon name="remove" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="4" class="ion-text-center ion-no-padding ion-no-margin" (click)="resetZoom()">
        <ion-button fill="clear" color="primary" class="zoom-button" expand="full">
          1:1
        </ion-button>
      </ion-col>
      <ion-col size="4" class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button fill="clear" color="primary" (click)="zoomIn()" class="zoom-button" expand="full">
          <ion-icon name="add" slot="icon-only"></ion-icon>
          </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div style="overflow-y: auto;">
    <ngx-extended-pdf-viewer style="width: 100vw;"
      [base64Src]="base64src"
      [pageViewMode]="'infinite-scroll'"
      [showDownloadButton]="false"
      [showToolbar]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="false"
      [showDrawEditor]="false"
      [showTextEditor]="false"
      [showZoomButtons]="true"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
      [showBorders]="false"
      [backgroundColor]="backgroundColor"
      [(zoom)]="zoomSetting"
      [minZoom]="minZoom"
      [rotation]="0"
      (currentZoomFactor)="updateZoomFactor($event)"
      (pagesLoaded)="onPagesLoaded()"
      aria-hidden="true"
    >
    </ngx-extended-pdf-viewer>
    <div class="visually-hidden">
      Ansicht Noten
    </div>
  </div>
<!--</ion-content>-->
