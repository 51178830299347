import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-divider',
  templateUrl: './horizontal-divider.component.html',
  styleUrls: ['./horizontal-divider.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class HorizontalDividerComponent  implements OnInit {
  @Input() color: string = '#ccc';
  @Input() thickness: string = '1px';
  @Input() margin: string = '5px 0';

  constructor() { }

  ngOnInit() {}

}
