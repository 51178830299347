import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

export interface Region {
  lower: number;
  upper: number;
  label: string;
}

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class WaveformComponent  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() src: any;
  @Input() time: number = 0;
  @Input() shownRegion: Region | undefined = undefined;
  @Input() isPlaying = false;

  @Output() seekTo: EventEmitter<number> = new EventEmitter<number>();
  @Output() updateRegion: EventEmitter<Region> = new EventEmitter<Region>();
  @Output() updateRegionTime: EventEmitter<Region> = new EventEmitter<Region>();
  @Output() waveformRedraw: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('waveformContainer', { static: true }) waveformContainer!: ElementRef;
  private waveSurfer!: WaveSurfer;
  resizeObserver: ResizeObserver;

  regions: any;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // Initialize WaveSurfer with dynamic timeline
    this.initWaveSurfer();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check if the audioFile input has changed
    if (changes['src'] && this.src) {
      this.onAudioUpdate();
    }
    if (changes['time'] && this.waveSurfer) {
      this.waveSurfer.setTime(this.time)
    }

    if (changes['shownRegion'] && this.waveSurfer) {
      this.regions.clearRegions();
      if(this.shownRegion) {
        this.regions.addRegion({
          start: this.shownRegion.lower,
          end: this.shownRegion.upper,
          content: this.shownRegion.label,
          color: '#840645AA',
          drag: false,
          resize: true,
          loop: true
        })
      }

    }

    if (changes['isPlaying'] && this.waveSurfer) {
      if(this.isPlaying) {
        if(this.shownRegion && this.regions.getRegions()[0]) {
          this.regions.getRegions()[0].play()
        } else {
          this.waveSurfer.play();
        }
      } else {
        this.waveSurfer.pause();
      }
    }
  }

  initWaveSurfer(): void {
    this.regions = RegionsPlugin.create();

    this.regions.on('region-updated', (region) => {
      if(this.shownRegion) {
        this.updateRegion.emit({
          lower: region.start,
          upper: region.end,
          label: this.shownRegion.label
        })
      }
    })

    this.regions.on('region-update', (region) => {
      if(this.shownRegion) {
        this.updateRegionTime.emit({
          lower: region.start,
          upper: region.end,
          label: this.shownRegion.label
        })
      }
    })

    this.regions.on('region-out', (region) => {
      if(this.shownRegion) {
        region.play(); // Starte den Loop erneut
      }
    });

    // Initialize WaveSurfer
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformContainer.nativeElement,
      waveColor: '#D40D53',
      progressColor: '#D40D53',
      barWidth: 2,
      height: 100,
      interact: true,
      dragToSeek: true,
      fillParent: true,
      normalize: true,
      autoCenter: true,
      mediaControls: false,
      plugins: [this.regions]
    });

    this.waveSurfer.on('seeking', (currentTime) => {
      this.seekTo.emit(currentTime);
    });

    this.waveSurfer.on('dragend', (relativeX) => {
//      this.seekTo.emit(relativeX*this.waveSurfer.getDuration());
//      console.log('Waveform dragged', this.waveSurfer.getCurrentTime(), relativeX*this.waveSurfer.getDuration())
    })

    /** When audio starts loading */
    /*this.waveSurfer.on('load', (url) => {
      console.log('Load', url)
    })*/

    /** During audio loading */
    /*this.waveSurfer.on('loading', (percent) => {
      console.log('Loading', percent + '%')
    })*/

    /** When all audio channel chunks of the waveform have drawn */
    this.waveSurfer.on('redrawcomplete', () => {
      this.waveformRedraw.emit();
    })
  }

  ngOnDestroy(): void {
    // Destroy WaveSurfer instance when the component is destroyed
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
    }
  }

  onAudioUpdate() {
    // Load the audio file into WaveSurfer
    this.waveSurfer.load(this.src);

  }
}
