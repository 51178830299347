import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonSearchbar } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Playlist, Relationship, Sharing, Tune, UserGroup, UserModel } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { GroupsService } from 'src/app/services/social/groups.service';
import { GroupListItemComponent } from "../../group/group-list-item/group-list-item.component";
import { ContactsService } from 'src/app/services/social/contacts.service';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { UserItemComponent } from '../../user/user-item/user-item.component';

@Component({
  selector: 'app-sharing-accordion',
  templateUrl: './sharing-accordion.component.html',
  styleUrls: ['./sharing-accordion.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    GroupListItemComponent,
    UserItemComponent
],
  animations: [
    trigger('searchSegment', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({ overflow: 'hidden' }),
        animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class SharingAccordionComponent  implements OnInit, OnDestroy {
  contactsService = inject(ContactsService)
  groupsService = inject(GroupsService)
  userDataService = inject(UserDataService)

  @Input() tune: Tune;
  @Input() playlist: Playlist;

  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() onShowPaywall: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('groupSearchBar', { static: false }) groupSearchBar: IonSearchbar;
  @ViewChild('contactsSearchBar', { static: false }) contactsSearchBar: IonSearchbar;

  //TODO: take from Subscription
  maxSharingSlots = 50;
  hasSubscription = false;

  contacts: Relationship[] = []
  groups: UserGroup[] = []

  groupsearchHidden = true;
  groupSearchTerm: string;
  searchedGroups: UserGroup[] = []

  contactsearchHidden = true;
  contactSearchTerm: string;
  searchedContacts: Relationship[] = []

//  contactsSharing: UserModel[] = [];
//  groupsSharing: UserGroup[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor() { }

  get groupsSharing(): UserGroup[] {
    if(this.tune && this.tune.sharings) {
      let out = this.tune.sharings
        .filter(s => s.groupid)
        .map(s => this.groups.find(g => g.id===s.groupid))
      return out;
    } else if(this.playlist && this.playlist.sharings) {
      let out = this.playlist.sharings
        .filter(s => s.groupid)
        .map(s => this.groups.find(g => g.id===s.groupid))
      return out;
    }
    return [];
  }

  get contactsSharing(): UserModel[] {
    if(this.tune && this.tune.sharings) {
      let out = this.tune.sharings
        .filter(s => s.userid)
        .map(s => this.contacts.map(c => this.getUser(c)).find(u => u.uid===s.userid))
      return out;
    } else if(this.playlist && this.playlist.sharings) {
      let out = this.playlist.sharings
        .filter(s => s.userid)
        .map(s => this.contacts.map(c => this.getUser(c)).find(u => u.uid===s.userid))
      return out;
    }
    return [];
  }
  get shownContacts(): Relationship[] {
    if(this.contactSearchTerm && this.searchedContacts) {
      return this.searchedContacts;
    } else if(!this.contactSearchTerm) {
      return this.contacts
    }
    return [];
  }

  get shownGroups(): UserGroup[] {
    if(this.groupSearchTerm && this.searchedGroups) {
      return this.searchedGroups;
    } else if(!this.groupSearchTerm) {
      return this.groups
    }
    return [];
  }

  get availableSharingSlots(): number {
    if(this.playlist) {
      return 1e6;
    }
    let out = this.maxSharingSlots;
//    out -= this.contactsSharing.length;
    for(const contact of this.contactsSharing) {
      if(!this.isPartOfGroupSharing(contact)) {
        out--;
      }
    }
    for(const group of this.groupsSharing) {
      if(group && group.userlimit) {
        out -= group.userlimit;
      }
    }
    return out;
  }

  ngOnInit() {
    this.contactsService.contacts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(contacts => this.contacts=contacts)

    this.groupsService.groups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(groups => {
        this.groups = groups;
      })

    this.userDataService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.getMaxSharingSlots(user)
      })
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  showPaywall() {
    this.onShowPaywall.emit();
  }

  getMaxSharingSlots(user: UserModel) {
    if(user) {
      if(user.subscriptiontype=='band-on') {
        this.maxSharingSlots = user.customtunelimit;
        this.hasSubscription = true;
      } else if(user.subscription) {
        this.maxSharingSlots = user.subscription.membercount;
        this.hasSubscription = true;
      } else {
        this.maxSharingSlots = 0;
        this.hasSubscription = false;
      }
    } else {
      this.maxSharingSlots = 0;
      this.hasSubscription = false;
    }
  }

  searchContact(event) {
    if(this.contactSearchTerm) {
      this.searchedContacts = this.contacts.filter(c => {
        const user = this.getUser(c);
        if(user) {
          const idValue = user.email+user.name;
          return idValue.toLowerCase().includes(this.contactSearchTerm.toLowerCase());
        }
        return false;
      });
    }
  }

  getUser(relationship: Relationship): UserModel {
    if(relationship.username1 === this.userDataService.user.username) {
      return relationship.user2;
    }
    return relationship.user1;
  }

  getRelationStatus(relationship: Relationship): "request" | "established" | "asked" | "none" {
    if(relationship.state.designation === "established") {
      return "established"
    } else if(relationship.state.designation === 'request' && relationship.username1===this.userDataService.user.username) {
      return "asked"
    }
    return "request"
  }

  showContactSearch() {
    this.contactsearchHidden = false;
    if(this.contactsSearchBar) {
      this.contactsSearchBar.setFocus();
    }
  }

  hideContactSearch() {
    this.contactsearchHidden = true;
    this.contactSearchTerm = undefined;
  }

  searchGroup(event) {
    if(this.groupSearchTerm) {
      this.searchedGroups = this.groups.filter(g => {
        const s = this.groupSearchTerm.toLowerCase();
        let out = g.designation.toLowerCase().includes(s)
//        out &&= u.user.name.toLowerCase().includes(s)
        return out;
      });
    }
  }

  showGroupSearch() {
    this.groupsearchHidden = false;
    if(this.groupSearchBar) {
      this.groupSearchBar.setFocus();
    }
  }

  hideGroupSearch() {
    this.groupsearchHidden = true;
    this.groupSearchTerm = undefined;
  }

  changeGroupSharing(event: any, group: UserGroup) {
    if(!this.tune && !this.playlist) {
      return;
    }

    const active = event.detail.checked;

    if(active && this.tune && !this.tune.sharings) {
      this.tune.sharings = [];
    } else if(active && this.playlist && !this.playlist.sharings) {
      this.playlist.sharings = [];
    }

    if(this.tune) {
      const existingSharing = this.tune.sharings?.find(s => s.groupid===group.id);
      if(active && !existingSharing) {
        this.tune.sharings?.push({
          id: -1,
          groupid: group.id,
          tuneid: this.tune.id
        });
        this.deleteAllContactForGroups(group)
      } else if(!active && existingSharing) {
        this.tune.sharings?.splice(this.tune.sharings.indexOf(existingSharing), 1);
      }
      console.log(this.tune.sharings)
    } else if(this.playlist) {
      const existingSharing = this.playlist.sharings?.find(s => s.groupid===group.id);
      if(active && !existingSharing) {
        this.playlist.sharings?.push({
          id: -1,
          groupid: group.id,
          playlistid: this.playlist.id
        });
        this.deleteAllContactForGroups(group)
      } else if(!active && existingSharing) {
        this.playlist.sharings?.splice(this.playlist.sharings.indexOf(existingSharing), 1);
      }
      console.log(this.playlist.sharings)
    }

    //TODO: delete all contact sharings where user can only listen

    this.hasChanged();
  }

  hasGroupSharing(group: UserGroup): boolean {
    if(this.tune && this.tune.sharings) {
      for(let s of this.tune.sharings) {
        if(s.groupid===group.id) {
          return true;
        }
      }
    } else if(this.playlist && this.playlist.sharings) {
      for(let s of this.playlist.sharings) {
        if(s.groupid===group.id) {
          return true;
        }
      }
    }
    return false;
  }

  deleteAllContactForGroups(group: UserGroup) {
    if(this.tune && this.tune.sharings) {
      for(let s of this.tune.sharings) {
        let edit_privilege = s.privileges?.find(p => p.id==1);
        if(s.userid && this.groupsService.isUserIDPartOfGroup(s.userid, group) && !edit_privilege) {
          this.tune.sharings?.splice(this.tune.sharings.indexOf(s), 1);
        }
      }
    } else if(this.playlist && this.playlist.sharings) {
      for(let s of this.playlist.sharings) {
        let edit_privilege = s.privileges?.find(p => p.id==1);
        if(s.userid && this.groupsService.isUserIDPartOfGroup(s.userid, group) && !edit_privilege) {
          this.playlist.sharings?.splice(this.playlist.sharings.indexOf(s), 1);
        }
      }
    }
  }

  changeContactSharing(event: any, contact: UserModel) {
    if(!this.tune && !this.playlist) {
      return;
    }

//    const active = event.detail.checked;
    const value = event.detail.value;

    if(value!='none' && this.tune && !this.tune.sharings) {
      this.tune.sharings = [];
    }
    if(value!='none' && this.playlist && !this.playlist.sharings) {
      this.tune.sharings = [];
    }

    if(this.tune) {
      let sharing: Sharing = this.tune.sharings?.find(s => s.userid===contact.uid);
      if(value!='none') {
        if(!sharing) {
          sharing = {
            id: -1,
            userid: contact.uid,
            tuneid: this.tune.id
          };
          this.tune.sharings?.push(sharing);
        }
        sharing.privileges = [];
        if(value=='edit') {
          //TODO: dynamically from DB
          sharing.privileges.push({ id: 1, designation: 'can_edit', description: '' });
        }
        if(value=='listen' && this.isPartOfGroupSharing(contact)) {
          this.tune.sharings?.splice(this.tune.sharings.indexOf(sharing), 1);
        }
      }  if(value=='none' && sharing) {
        this.tune.sharings?.splice(this.tune.sharings.indexOf(sharing), 1);
      }
      console.log(this.tune.sharings)
    } else if(this.playlist) {
      let sharing: Sharing = this.playlist.sharings?.find(s => s.userid===contact.uid);
      if(value!='none') {
        if(!sharing) {
          sharing = {
            id: -1,
            userid: contact.uid,
            playlistid: this.playlist.id
          };
          this.playlist.sharings?.push(sharing);
        }
        sharing.privileges = [];
        if(value=='edit') {
          //TODO: dynamically from DB
          sharing.privileges.push({ id: 1, designation: 'can_edit', description: '' });
        }
        if(value=='listen' && this.isPartOfGroupSharing(contact)) {
          this.playlist.sharings?.splice(this.playlist.sharings.indexOf(sharing), 1);
        }
      }  if(value=='none' && sharing) {
        this.playlist.sharings?.splice(this.playlist.sharings.indexOf(sharing), 1);
      }
      console.log(this.playlist.sharings)
    }

    this.hasChanged();
  }

  hasContactSharing(contact: UserModel): 'none' | 'listen' | 'edit' {
    if(this.tune && this.tune.sharings) {
      for(let s of this.tune.sharings) {
        if(s.userid===contact.uid) {
          const edit_privilege = s.privileges?.find(p => p.id==1);
          if(edit_privilege) {
            return 'edit'
          }
          return 'listen';
        }
        if(s.groupid && this.groupsService.isUserPartOfGroup(contact, this.groupsService.getGroup(s.groupid))) {
          return 'listen';
        }
      }
    } else if(this.playlist && this.playlist.sharings) {
      for(let s of this.playlist.sharings) {
        if(s.userid===contact.uid) {
          const edit_privilege = s.privileges?.find(p => p.id==1);
          if(edit_privilege) {
            return 'edit'
          }
          return 'listen';
        }
        if(s.groupid && this.groupsService.isUserPartOfGroup(contact, this.groupsService.getGroup(s.groupid))) {
          return 'listen';
        }
      }
    }
    return 'none';
  }

  isPartOfGroupSharing(contact: UserModel) {
    if(this.tune && this.tune.sharings) {
      for(let s of this.tune.sharings) {
        if(s.groupid && this.groupsService.isUserPartOfGroup(contact, this.groupsService.getGroup(s.groupid))) {
          return true;
        }
      }
    } else if(this.playlist && this.playlist.sharings) {
      for(let s of this.playlist.sharings) {
        if(s.groupid && this.groupsService.isUserPartOfGroup(contact, this.groupsService.getGroup(s.groupid))) {
          return true;
        }
      }
    }

    return false;
  }

  hasChanged() {
    this.onChange.emit();
  }

  isMe(contact: UserModel) {
    if(this.userDataService.user && contact.uid === this.userDataService.user.uid) {
      return true;
    }
    return false;
  }

  isOwner(contact: UserModel) {
    if(this.tune) {
      if(contact.uid === this.tune.ownerid) {
        return true;
      }
    } else if(this.playlist) {
      if(contact.uid === this.playlist.user_id) {
        return true;
      }
    }
    return false;
  }
}
