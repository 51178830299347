import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService, SimpleTuneModel, SubscriptionPlan, UserGroup } from 'bandon-shared';
import { filter, lastValueFrom, Subject, take, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';
import { PaywallContainerComponent } from '../paywall-container/paywall-container.component';
import { GroupsService } from 'src/app/services/social/groups.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LibraryService } from 'src/app/services/library.service';

@Component({
  selector: 'app-group-delete',
  templateUrl: './group-delete.component.html',
  styleUrls: ['./group-delete.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PaywallContainerComponent
  ]
})
export class GroupDeleteComponent  implements OnInit, OnChanges, OnDestroy {
  subscriptionPlanService = inject(SubscriptionPlansService)
  storeService = inject(StoreService)
  modalController = inject(ModalController)
  groupsService = inject(GroupsService)
  authService = inject(AuthenticationService)
  httpClient = inject(HttpClient)
  alertController = inject(AlertController)
  translateService = inject(TranslateService)
  libraryService = inject(LibraryService)

  @Input() group: UserGroup;

  selectedPlan: SubscriptionPlan | undefined = undefined;

  private unsubscribe$ = new Subject<void>();

  public alertButtons = [
    {
      text: this.translateService.instant('GROUPS.CANCEL'),
      role: 'cancel',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: this.translateService.instant('GROUPS.OK'),
      role: 'confirm',
      handler: () => {
        this.deleteSubscription();
      },
    },
  ];

  constructor() { }

  get isWeb() {
    //    return true;
    return Capacitor.getPlatform() === 'web';
  }

  ngOnInit() {
    this.getProlongingOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProlongingOptions();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  getProlongingOptions() {
    if(this.subscriptionPlanService.subscriptionPlans.value.length>0) {
      this.updateProlongingOptions(this.subscriptionPlanService.subscriptionPlans.value)
    } else {
      this.subscriptionPlanService.subscriptionPlans$
      .pipe(
        filter(plans => plans.length>0),
        take(1)
      )
      .subscribe(plans => this.updateProlongingOptions(plans))
    }
  }

  updateProlongingOptions(plans: SubscriptionPlan[]) {
    if(this.group && plans.length>0 && this.group.userlimit) {
      let selectedPlan = undefined;
      for(let plan of plans) {
        if(plan.membercount<=this.group.userlimit) {
          selectedPlan = plan;
        }
      };
      this.selectedPlan = selectedPlan;
    }
  }

  dismissModal(role: string, data: any) {
    this.modalController.dismiss(data, role);
  }

  async showDeleteAlert() {

    const alert = await this.alertController.create({
      header: this.translateService.instant('GROUPS.ATTENTION'),
      message: this.translateService.instant('GROUPS.ALERTDELETE'),
      buttons: this.alertButtons,
      cssClass: 'band-on-alert'
    });
    alert.style.zIndex = '1000000';

    await alert.present();
  }

  async deleteSubscription() {
    //TODO: show alert, delete tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    try {
      // First, retrieve the tunes
      const tunes = await lastValueFrom(
        this.httpClient.get<SimpleTuneModel[]>(`${environment.apiURL}/usertunes`, { headers })
        .pipe(takeUntil(this.unsubscribe$))
      );

      // If there are tunes, delete them
      if (tunes && tunes.length > 0) {
        await Promise.all(
          tunes.map(tune =>
            lastValueFrom(
              this.httpClient.delete(`${environment.apiURL}/tunes/${tune.id}`, { headers })
              .pipe(takeUntil(this.unsubscribe$))
            )
            .then(() => {
              console.log(`Tune ${tune.title} deleted`);
            })
            .catch(err => {
              console.error(err);
              // Uncomment if you want to show alert on error
              // this.showAlert(this.translate.instant('TUNES.ERROR'), undefined, this.translate.instant('TUNES.ERRORDELETE', { title: tune.title }));
            })
          )
        );
      }

      // If a group exists, delete it
      if (this.group) {
        await this.groupsService.deleteGroup(this.group);
      }

      this.libraryService.refreshCaches();

      // Call the final function after all deletions are completed
      this.dismissModal('deleted', null);
    } catch (error) {
      console.error("Error deleting subscription:", error);
      // Handle additional error cases if needed
    }
  }
}
