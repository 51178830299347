import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonicModule, IonInput, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class TagsInputComponent  implements OnInit {
  @Input() tags: any[] = [];
  @Input() placeholder = '';
  @Input() suggestions: any[] = [];
  @Input() canGenerate = false;
//  @Input() displayFn: (item: any) => string = (item) => item?.designation || item?.name || item?.label || item?.toString(); // Default display function
  @Input() displayFn: (item: any) => { text: string; imageUrl?: string } = (item) => ({
    text: item?.designation || item?.name || item?.label || item?.toString(),
    imageUrl: item?.image || item?.icon || '', // Adjust keys according to your data structure
  });
  @Input() tagFactoryFn: (tagText: string) => any = (tagText) => ({ custom: tagText }); // Default factory function for new tags

  @Output() tagsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild('popover') popover;
  @ViewChild('tagContainer') container;
  @ViewChild('tagInput', { static: false }) tagInput: IonInput;

  popoverController = inject(PopoverController)

  showSuggestions = false;
  matchingSuggestions: any[] = [];
  searchTerm = '';

  minFactoryLength = 3;

  constructor() { }

  get isExactMatch(): boolean {
    if(this.matchingSuggestions.length>0 && this.displayFn(this.matchingSuggestions[0]).text===this.searchTerm) {
      return true;
    }
    return false;
  }

  ngOnInit() {}

  //Delete last Tag if Backspace is pressed
  @HostListener('document:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    const inputElement = await this.tagInput.getInputElement()
    if (event.key === 'Backspace' && inputElement===document.activeElement && !this.tagInput.value) {
      this.tags.splice(this.tags.length-1, 1);
    }
  }

  async onInput(event: any) {
    const inputValue = event.detail.value;
    this.searchTerm = inputValue;
    const lastChar = inputValue[inputValue.length - 1];

    // Check if the last character is a comma
    if (lastChar === ',' && inputValue.length>=this.minFactoryLength+1) {
      const newTag = inputValue.slice(0, -1).trim();
      await this.createTag(newTag)
    }

    //Look for suggestions
    this.matchingSuggestions = this.suggestions.filter(s => {
      return this.displayFn(s).text.toLowerCase().includes(inputValue.toLowerCase());
    });

    if (this.matchingSuggestions.length > 0 && inputValue) {
      this.popover.event = event;
      this.showSuggestions = true;
    } else if(!this.isExactMatch && this.searchTerm.length>=this.minFactoryLength && this.canGenerate) {
      this.popover.event = event;
      this.showSuggestions = true;
    } else if (this.showSuggestions) {
      this.showSuggestions = false;
    }
  }

  async createTag(newTag: string) {
    // Remove the comma and trim whitespace
    if (newTag && !this.tags.some(tag => this.displayFn(tag).text === newTag)) {
      // Find the corresponding object from suggestions based on the display function
      const matchingSuggestion = this.suggestions.find(s => this.displayFn(s).text.toLowerCase() === newTag.toLowerCase());
      if (matchingSuggestion) {
        this.tags.push(matchingSuggestion);
        this.tagsChanged.emit(this.tags);
        // Clear the input value
        this.tagInput.value = '';
        this.searchTerm = '';
      } else if(this.canGenerate) {
        // Or add a new object directly if no match found
        let tag = await this.tagFactoryFn(newTag);
        if(tag) {
          this.tags.push(tag);
          this.tagsChanged.emit(this.tags);
        // Clear the input value
        this.tagInput.value = '';
        this.searchTerm = '';
        }
      }
    }
  }

  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if(index || index==0) {
      this.tags.splice(index, 1)
      this.tagsChanged.emit(this.tags);
    }
  }

  selectSuggestion(suggestion: string) {
    this.showSuggestions = false;
    this.tags.push(suggestion);
    this.tagsChanged.emit(this.tags);
    if(this.tagInput) {
      this.tagInput.value = '';
    }
  }

}
