import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthenticationService, ShopWindow } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router, RouterLink } from '@angular/router';
import { NgIf, NgFor, CommonModule } from '@angular/common';
import { LibraryService } from 'src/app/services/library.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CreatorPaywallComponent } from 'src/app/pages/modal/subscription/creator-paywall/creator-paywall.component';

@Component({
    selector: 'app-shop-window',
    templateUrl: './shop-window.component.html',
    styleUrls: ['./shop-window.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        IonicModule,
        CachedImageComponent,
        TranslateModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShopWindowComponent  implements OnInit, OnDestroy {
  router = inject(Router)
  modalController = inject(ModalController)

  slidesPerView: string = '1';
  centeredSlides = true

  private unsubscribe$ = new Subject<void>();
  private isAuth = false;

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    private storeService: StoreService,
    private libaryService: LibraryService,
    private translateService: TranslateService
  ) { }

  get shopWindows(): ShopWindow[] {
    return this.storeService.shopWindows;
  }

  ngOnInit() {
    this.onWindowResize();
    //Get all Shop Windows
    const headers = new HttpHeaders();
    if (this.isAuth) {
      headers.set('Authorization', this.authService.getIDToken());
    } else {
      headers.set('Authorization', 'Bearer '+environment.apiKey);
    }

    this.httpClient.get<ShopWindow[]>(environment.apiURL+'/shop/windows', {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (windows) => {
          this.shopWindows.length = 0;
          this.shopWindows.push(...windows);

          this.shopWindows.forEach(w => {
            const segments = w.link.split('/');
            const lastSegment = segments[segments.length - 1];
            const collectionid = parseInt(lastSegment, 10);

            if(collectionid) {
              this.libaryService.getCollection(collectionid)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(c => {
                w.collection = c;
              })
            }
          })
        }
      );

    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(auth => this.isAuth = auth);
  }

  ngOnDestroy(): void {

  }

  getWindowImgSrc(window: ShopWindow): CachedImgSource {
    if(window.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+window.picture.id, path: window.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

  getWindowAriaLabel(window: ShopWindow, index: number): string {
    if(window.collection) {
      return this.translateService.instant('STORE.SHOPWINDOW', { collection: window.collection.designation, index: index+1, count: this.shopWindows.length })
    }
    return '';
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    if(window) {
      const screenWidth = window.innerWidth;
      // Adjust the screen size breakpoints and corresponding slidesPerView values as needed
      this.centeredSlides = true;
//      this.showPagination = true;

      if (screenWidth < 450) {
        this.slidesPerView = '1';
      } else {
        this.slidesPerView = 'auto';
        this.centeredSlides = false;
      }
    }
  }

  openLink(window: ShopWindow) {
    if(window.link=='subscription') {
      this.showPaywall()
    } else {
      this.router.navigateByUrl(`/collection/library/${window.link}`);
    }
  }

  async showPaywall(goBack = true) {
    const modal = await this.modalController.create({
      component: CreatorPaywallComponent,
      backdropDismiss: false  // Disable dismissing the modal by tapping outside
    });
    modal.present();
  }
}
