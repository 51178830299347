import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { AuthenticationService, Instrument, InstrumentGroup, Musician, Sheet, Track, Tune, Voice } from 'bandon-shared';
import { CachedImageComponent, CachedImgSource } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { InstrumentIconComponent } from "../../general/instrument-icon/instrument-icon.component";
import { SearchableSelectComponent } from '../../general/searchable-select/searchable-select.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagsInputComponent } from '../tags-input/tags-input.component';
import { MusicianModalComponent } from '../musician-modal/musician-modal.component';
import { FileUploadComponent, UploadFile } from '../file-upload/file-upload.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';

@Component({
  selector: 'app-track-item',
  templateUrl: './track-item.component.html',
  styleUrls: ['./track-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CachedImageComponent,
    InstrumentIconComponent,
    SearchableSelectComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    TagsInputComponent,
    FileUploadComponent
  ]
})
export class TrackItemComponent  implements OnInit, OnChanges {
  @Input() tune: Tune;
  @Input() track: Track;
  @Input() instruments: Instrument[] = [];
  @Input() instrumentGroups: InstrumentGroup[] = [];
  @Input() voices: Voice[] = [];
  @Input() fg: FormGroup;
  @Input() musicians: Musician[] = [];

  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  platform = inject(Platform)
  modalController = inject(ModalController)
  authService = inject(AuthenticationService)
  translateService = inject(TranslateService)
  httpClient = inject(HttpClient)
  bandonTranslations = inject(BandONTranslationsService)

  availableVoices: Voice[] = [];

  sheetFiles: UploadFile[] = [];

  musicianDisplayFn: (item: Musician) => { text: string; imageUrl?: string } = (item) => {
    let out = {
      text: item.firstname+' '+item.surname,
      imageUrl: undefined
    }
    if(item.picture && item.picture.id) {
      out.imageUrl = `${environment.apiURL}/photoImg/${item.picture.id}`
    }
    return out;
  };
  musicianFactoryFn = async (musicianText: string): Promise<Musician | undefined> => {
    let musician: Musician = { id: -1, surname: '', firstname: ''}
    let textParts = musicianText.split(' ');
    if(textParts.length>1) {
      musician.firstname = textParts[0]
      musician.surname = textParts[1]
    } else {
      musician.firstname = musicianText
    }

    const modal = await this.modalController.create({
      component: MusicianModalComponent,
      componentProps: {
        musician
      }
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();

    return data;
  };

  instrumentDisplayFn: (item: Instrument) => { text: string; imageUrl?: string } = (item) => {
    let out = {
      text: this.bandonTranslations.getInstrumentDesignation(item),
      imageUrl: undefined
    }
    if(item.img) {
      out.imageUrl = `${environment.apiURL}/instrImg/${item.img }`
    }
    return out;
  };

  pdfCheckFn: (file: UploadFile) => Promise<UploadFile> = async (file): Promise<UploadFile> => {
    //Check File Extension
    const validTypes = ['application/pdf'];
    if (!validTypes.includes(file.mimeType)) {
      file.state = 'rejected';
      file.error = this.translateService.instant('TUNES.AUDIOFILEERRORTYPE') //TODO: other message
      return file;
    }

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);

    const blob = await file.file;
    const formData = new FormData();
    formData.append('file', blob);

    try {
      // Convert the HTTP request to a Promise-based approach using await, TODO: make server endpoint for pdf check
      const response = await firstValueFrom(
        this.httpClient.post(`${environment.apiURL}/check-pdf-file`, formData, { headers })
      );
      // Handle successful response
      file.state = 'accepted';
      // Perform additional logic if needed
    } catch (err) {
      // Handle error response, TODO: translate error message
      file.state = 'rejected';
      file.error = 'The file got rejected by the server check.';
      console.log(err);
    }

    return file;
  };

  constructor() { }

  get serverUrl(): string {
    return environment.apiURL;
  }

  get instrument(): Instrument {
    if(this.track) {
      return this.track.instrument;
    }
    return null;
  }

  get filename(): string {
    if (this.track && this.track.path) {
      return this.track.path.split('/').pop();
    }
    return '';
  }

  get instrumentInput() {
    return this.fg.get('instrument');
  }

  get trackmusicians(): Musician[] {
    if(this.track && this.track.trackmusician) {
      return [this.track.trackmusician]
    }
    return [];
  }


  ngOnInit() {
    this.updateVoices();
    this.updateSheets();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateVoices();
    this.updateSheets();
  }

  changeSection(group: InstrumentGroup[]) {
    if(group[0]) {
      this.track.instrumentgroup = group[0];
      this.updateVoices()
      this.hasChanged();
    }
  }

  changeInstrument(instrument: Instrument[]) {
    if(instrument[0]) {
      this.track.instrument = instrument[0];
      this.instrumentInput.patchValue('instrument', instrument[0])
      this.hasChanged();
    }
  }

  changeVoice(voice: Voice[]) {
    if(voice[0]) {
      this.track.voice = voice[0];
      this.hasChanged();
    }
  }

  changeMusician(musician: Musician[]) {
    if(musician && musician.length>0 && this.track) {
      this.track.trackmusician = musician[0]
      this.hasChanged();
    }
  }

  updateVoices() {
    if(this.track.instrumentgroup) {
      this.availableVoices = this.voices.filter(e => {
        if (!this.track.instrumentgroup || this.track.instrumentgroup.id===0 || !e.instrumentgroup ||
            e.instrumentgroup.id===0 || e.instrumentgroup.id===this.track.instrumentgroup?.id) {
          return true;
        }
        return false;
      });
      this.availableVoices = this.availableVoices.sort((v1, v2) => v1.designation.localeCompare(v2.designation))
    } else {
      this.availableVoices = [];
    }
  }

  updateSheets() {
    if(this.track.sheets) {
      this.sheetFiles.length = 0;
      this.track.sheets.forEach(s => {
        const sheetFile: UploadFile = { name: this.getFileNameFromPath(s.path), file: undefined, state: 'accepted', mimeType: ''}
        this.sheetFiles.push(sheetFile)
      })
      this.sheetFiles = [...this.sheetFiles]
    }
  }

  changeSheet(sheet: UploadFile[]) {
    if(sheet && sheet.length>0 && this.track) {
      this.track.sheets = [
        { id: -1, path: '', date: new Date(), authorid: 0, newFile: sheet[0].file, imgSaveFilename: sheet[0].name }
      ]
    } else {
      this.track.sheets = [];
    }
  }

  hasChanged() {
    this.onChange.emit();
  }

  private getFileNameFromPath(path: string): string {
    return path.split('/').pop() || '';  // Works for Unix-style paths
    // For Windows paths, you may need to use `path.split('\\').pop()`
  }

}
