import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, Role, UserModel } from 'bandon-shared';
import { BehaviorSubject , Observable, of, tap, from, lastValueFrom, firstValueFrom } from 'rxjs';
import { LocalFile } from 'src/app/shared/interfaces/local-file';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../languages/language.service';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { CachingService } from '../caching/caching.service';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Purchases as PurchasesJS } from '@revenuecat/purchases-js'

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  alertController = inject(AlertController)
  authService = inject(AuthenticationService)
  cachingService = inject(CachingService)
  connectivityService = inject(ConnectivityService)
  httpClient = inject(HttpClient)
  languageService = inject(LanguageService)
  loadingController = inject(LoadingController)
  translate = inject(TranslateService)

  user: UserModel;
  private userSubject = new BehaviorSubject<UserModel>(null);
  user$ = this.userSubject.asObservable();

  userPicture: LocalFile;

  isAuth = false;
  isOnline = false;

  constructor() {
    this.authService.isAuthenticated.subscribe(auth => {
      this.isAuth = auth;
      if(auth) {
        this.getUserData(true);
      } else {
        this.userPicture = undefined;
        this.user = undefined;
        if(this.userSubject.getValue()) {
          this.userSubject.next(null);
        }
        if(!this.isWeb) {
          Purchases.logOut()
            .then(r => console.log(r))
            .catch(error => console.error(error));
        }
      }
    });

    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }

  async init() {
    const auth = await firstValueFrom(
      this.authService.isAuthenticated
    )
    this.isAuth = auth;
    if(auth) {
      await this.getUserData(true)
    } else {
      this.userPicture = undefined;
      this.user = undefined;
      if(this.userSubject.getValue()) {
        this.userSubject.next(null);
      }
    }
  }

  deleteUser() {
    if(this.user) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const user_id = this.authService.getUserID();

      return this.httpClient.put(`${environment.apiURL}/users/${user_id}/deletion`, null, {headers});
    }
  }

  updateUserData() {
    if (!this.user) {
      return;
    }


    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    const data = {
      name: this.user.name,
      username: this.user.username,
      language: this.user.language,
      newsletter: this.user.newsletter,
      instruments: this.user.instruments,
      lastreviewrequest: this.user.lastreviewrequest,
      subscriptionid: this.user.subscriptionid,
      showedexpiryalert: this.user.showedexpiryalert,
      subscriptiontype: this.user.subscriptiontype,
      customtunelimit: this.user.customtunelimit
    };

    const user_id = this.authService.getUserID();

    return this.httpClient.post<UserModel>(environment.apiURL+`/users/${user_id}`, data, {headers})
      .subscribe(resp => {
        if(this.user !== resp) {
          this.user = resp;
          this.userSubject.next(resp);
          }
      });
  }

  updateUserMail(email) {
    if (!this.user) {
      return;
    }

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    const data = {
      email: email,
    };

    const user_id = this.authService.getUserID();

    return this.httpClient.post<UserModel>(environment.apiURL+`/users/${user_id}`, data, {headers})
      .subscribe({
        next: () => {
          this.alertController.create({
            header: this.translate.instant('PROFILE.CONFIRM'),
            message: this.translate.instant('PROFILE.EMAILCONFIRM'),
            buttons: ['Ok'],
          }).then( alert => alert.present())
          this.user.email = email;
          this.user.emailconfirmed = false;
        },
        error: err => {
          this.alertController.create({
            header: this.translate.instant('PROFILE.ERROR'),
            message: this.translate.instant('PROFILE.ERROREMAIL'),
            buttons: ['Ok'],
          }).then( alert => alert.present())
        }
      });
  }

  async sendConfirmationMail() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    if (!this.user) {
      await loading.present();
      return;
    }

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.put(`${environment.apiURL}/users/${this.user.uid}/emailconfirm`, {headers})
    .subscribe({
      next: async data => {
        const alert = await this.alertController.create({
          header: this.translate.instant('PROFILE.EMAIL'),
          message: this.translate.instant('PROFILE.EMAILCONFIRM2'),
          buttons: ['OK'],
          cssClass: 'band-on-alert'
        });
        await loading.dismiss();
        await alert.present();
      },
      error: error => {
        loading.dismiss();
        this.alertController.create({
          header: this.translate.instant('PROFILE.ERROR'),
          message: this.translate.instant('PROFILE.ERROREMAILCONFIRM'),
          buttons: ['Ok'],
        }).then( alert => alert.present())
      }
    });
  }

  public async getUserData(forceRefresh = false) {
    // Get user Data
    const user_id = this.authService.getUserID();

    let resp = await lastValueFrom(this.getData(environment.apiURL+`/users/${user_id}`, forceRefresh))
    .catch(error => console.log(error))
    if(resp && (this.user!==resp || forceRefresh)) {
      if(!this.areUsersEqual(this.user, resp)) {
        this.user = resp;
        this.userSubject.next(resp);
        this.languageService.setLanguage(this.user.language);
        this.checkIfUserPictureExists(this.user.img, forceRefresh);
        if(!this.isWeb) {
          Purchases.logIn({ appUserID: `${user_id}`})
            .then(r => console.log(r))
            .catch(error => console.error(error));
        } else {
          PurchasesJS.getSharedInstance().changeUser(user_id)
        }
      }
    }
/*    return this.getData(environment.apiURL+`/users/${user_id}`, forceRefresh)
      .subscribe({
        next: resp => {
          if(resp && (this.user!==resp || forceRefresh)) {
            this.user = resp;
            this.userSubject.next(resp);
            this.languageService.setLanguage(this.user.language);
            this.checkIfUserPictureExists(this.user.img, forceRefresh);
          }
        },
        error: () => {}
      });*/
  }

  async setRole(role_id: number, active: boolean) {
    if(active) {
      let result = this.user.roles?.filter(e => e.id==role_id)
      if(!result || (result && result.length==0)) {
        if(!this.user.roles) {
          this.user.roles = []
        }
        this.user.roles.push({ id: role_id, designation: ''});
        await this.saveRoles();
      }
    } else if(this.user.roles) {
      const p = this.user.roles.find(e => e.id===role_id);
      if(p) {
        const index = this.user.roles.indexOf(p);
        this.user.roles.splice(index, 1);
        await this.saveRoles();
      }
    }
  }

  private async saveRoles() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('user', JSON.stringify(this.user));
    if(this.user) {
      await lastValueFrom(
        this.httpClient.post<UserModel>(environment.apiURL+`/users/${this.user.uid}/roles`, formData, {headers})
      )
      .catch( err =>
        console.log(err)
      );
    }
  }

  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isAuth) {
        return of(null);
    }
    if(!this.isOnline || !this.isAuth) {
      return from(this.cachingService.getCachedRequest(url));
    }

    return this.callAndCache(url);
  }

  private callAndCache(url): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

  checkIfUserPictureExists(img: string | undefined, forceRefresh = false) {
    if(this.authService.getToken()) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const user_id = this.authService.getUserID();

      //Check if directory exists and if not create it
  /*    Filesystem.readdir({
        directory: Directory.Cache,
        path: IMAGE_DIR
      }).then(result => {
      }, async error => {
        await Filesystem.mkdir({
          directory: Directory.Data,
          path: IMAGE_DIR
        });
      });*/

      const filename = `${user_id}.jpeg`;
      if(!forceRefresh) {
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${environment.avatarCacheFolder}/${filename}`,
        }).then(result => {
          const fileType = filename.split('.').pop();
          this.userPicture = {
            name: filename,
            path: `${environment.avatarCacheFolder}/${filename}`,
            data: `data:image/${fileType};base64,${result.data}`
          };
        }, async err => {
          if(img) {
            this.downloadUserPicture(user_id, headers, filename);
          }
        });
      } else {
        if(img) {
          this.downloadUserPicture(user_id, headers, filename);
        }
      }
    }

  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async downloadUserPicture(user_id: string, headers: HttpHeaders, filename: string) {
    try {
      const fileExists = await Filesystem.readFile({
        directory: Directory.Cache,
        path: `${environment.avatarCacheFolder}/${filename}`
      });
      if (fileExists) {
        // If the file exists, delete it
        await Filesystem.deleteFile({
          path: `${environment.avatarCacheFolder}/${filename}`,
          directory: Directory.Cache,
        });
      }
    } catch(error) {
      console.log('Error deleting User-File');
    }
    this.httpClient.get(environment.apiURL+`/users/${user_id}/img`, { headers, responseType: 'blob'})
      .subscribe({
        next: async resp => {
          if (resp) {
            const base64Data = await this.convertBlobToBase64(resp) as string;
            this.userPicture = {
              name: filename,
              path: `${environment.avatarCacheFolder}/${filename}`,
              data: `${base64Data}`
            };
            Filesystem.writeFile({
              directory: Directory.Cache,
              path: `${environment.avatarCacheFolder}/${filename}`,
              data: base64Data
            }).then( e => console.log('User Picture saved'));

//            this.userImg.webPath = `${IMAGE_DIR}/${filename}`;
          }
        },
        error: err => {
          console.log('download User Picture Error');
        }
      });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  areUsersEqual(user1: UserModel, user2: UserModel): boolean {
    // Convert both user objects to JSON strings
    return JSON.stringify(user1) === JSON.stringify(user2);
  }

}
