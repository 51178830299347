import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { Share } from '@capacitor/share';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-sheet-viewer',
    templateUrl: './sheet-viewer.component.html',
    styleUrls: ['./sheet-viewer.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgxExtendedPdfViewerModule,
        NgIf,
        TranslateModule
    ],
})
export class SheetViewerComponent  implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() data: any;
  @Input() uri: string;
  @Input() showSharing = false;
  @Input() blob: Blob;
  @Input() base64src = '';
  @Input() showHeader = true;

  backgroundColor = "#1C1B1C";

  minZoom = undefined;

  private _zoomSetting: number | string | undefined = 'page-width';
  private currentZoomFactor: number;

  constructor(
    private modalController: ModalController,
  ) { }

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  public get zoomSetting(): string | number {
    return String(this._zoomSetting)
  }

  public set zoomSetting(zoom: string | number) {
    if (isNaN(Number(zoom))) {
      this._zoomSetting = zoom;
    } else {
      this._zoomSetting = zoom + '%';
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {}

  dismiss() {
    return this.modalController.dismiss();
  }

  resetZoom() {
    this.zoomSetting = 'page-width'
  }

  zoomIn() {
    this.zoomSetting = this.currentZoomFactor*100 + 5;
    console.log(this.zoomSetting)
  }

  zoomOut() {
    this.zoomSetting = Math.max(this.currentZoomFactor*100 - 5, this.minZoom*100);
  }

  updateZoomFactor(event: any) {
    this.currentZoomFactor = event;
    if(this.minZoom==undefined) {
      this.minZoom = this.currentZoomFactor;
    }
  }

  onPagesLoaded() {
/*    new PdfDocumentPropertiesExtractor().getDocumentProperties().then(result => {
      console.log(result);
    });*/
  }

  async share() {
    //TODO: not working
    if(this.blob) {
      const base64String = await this.blobToBase64(this.blob);

      await Share.share({
        title: 'Share Blob',
        text: 'Check out this blob!',
        url: 'data:image/jpeg;base64,' + base64String,
        dialogTitle: 'Share via'
      });
    }

  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(new Error('Failed to convert blob to base64'));
            }
        };
        reader.onerror = () => {
            reject(new Error('Failed to read blob'));
        };
        reader.readAsDataURL(blob);
    });
}
}
