import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'bandon-shared';
import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { Filesystem, Directory } from '@capacitor/filesystem';

export interface UploadFile {
  name: string;
  file: Blob;
  state: 'in progress' | 'accepted' | 'rejected';
  mimeType: string;
  error?: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class FileUploadComponent  implements OnInit, OnChanges {
  alertController = inject(AlertController)
  authService = inject(AuthenticationService)
  httpClient = inject(HttpClient)
  translateService = inject(TranslateService)

  @Input() fileExtension = 'audio/mpeg';
  @Input() multiple = true;
  @Input() uploadText = this.translateService.instant('TUNES.UPLOADFILES')
  @Input() selectedUploadFiles: UploadFile[];
  @Input() fileCheckFn: (file: UploadFile, existingFiles?: UploadFile[]) => Promise<UploadFile> = async (file): Promise<UploadFile> => {
    return file;
  };
  @Input() formatRemarks = '';
  @Input() deleteMsg = '';

  @Output() filesChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor() { }

  ngOnInit() {
    this.selectedUploadFiles = [];  // Reset selected files
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  async triggerAudioFileInput() {
    const result = await FilePicker.pickFiles({
      types: [this.fileExtension],
      limit: this.multiple ? 0 : 1
    });

    if(result && result.files && result.files.length>0) {
      this.processFiles(result.files)
    }
  }

  private async processFiles(files: PickedFile[]) {
    for (let i = 0; i < files.length; i++) {
      let blob = files[i].blob;
      const audioFile: UploadFile = { name: files[i].name, file: blob, state: 'in progress', mimeType: files[i].mimeType }
      this.selectedUploadFiles.push(audioFile)
      if(!files[i].blob) {
        // Get the file path
        const filePath = files[i].path;

        if (filePath) {
          // Read the file as a base64 string
          const readFileResult = await Filesystem.readFile({
            path: filePath,
          });

          // Ensure that readFileResult.data is a string
          const base64Data = readFileResult.data;

          blob = this.base64ToBlob(base64Data as string, files[i].mimeType);
          audioFile.file = blob;
        } else {
          console.error('File path is not defined.');
        }
      }
      //TODO: error if no blob
      this.fileCheckFn(audioFile, this.selectedUploadFiles).then(file => {
        this.emitFileList();
      });
    }
  }

  async triggerRemoveFile(file: UploadFile) {
    const alert = await this.alertController.create({
      header: this.translateService.instant('TUNES.REMOVEFILE'),
      subHeader: file.name,
      message: this.deleteMsg,
      buttons: [
        {
          text: this.translateService.instant('TUNES.CANCEL'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('TUNES.OK'),
          role: 'confirm',
          handler: () => {
            this.removeFile(file)
          }
        }
      ],
    });

    await alert.present();
  }

  removeFile(file: UploadFile) {
    if(this.selectedUploadFiles.includes(file)) {
      this.selectedUploadFiles.splice(this.selectedUploadFiles.indexOf(file), 1);
      this.emitFileList();
      // Update the FormControl to reflect the removal
/*      const files = Array.from(this.tuneForm.get('files')?.value || []);
      this.tuneForm.patchValue({
        files: files.filter((f: File) => f !== file.file)
      });*/
    }
  }

  emitFileList() {
    let inProgress = this.selectedUploadFiles
      .filter(f => f.state=='in progress');

    if(inProgress.length>0) {
      return;
    }

    const files = this.selectedUploadFiles
      .filter(f => f.state=='accepted')
//      .map(f => f.file)
    this.filesChanged.emit(files)
  }

  async showAlert(header: string, message: string, subHeader: string | undefined = undefined) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['Ok'],
    });

    await alert.present();
  }

  // Helper function to convert base64 to Blob
  private base64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

}
