import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { Package } from '@revenuecat/purchases-js';
import { SubscriptionPlan } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';

@Component({
  selector: 'app-size-selector',
  templateUrl: './size-selector.component.html',
  styleUrls: ['./size-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class SizeSelectorComponent  implements OnInit, OnDestroy, OnChanges {
  subscriptionService = inject(SubscriptionPlansService)
  translate = inject(TranslateService)
  storeService = inject(StoreService)
  platform = inject(Platform)

  @Input() selectedPlan: SubscriptionPlan | undefined = undefined;
  @Input() excludedPlans: SubscriptionPlan[] = [];
  @Output() onSizeSelect: EventEmitter<SubscriptionPlan> = new EventEmitter<SubscriptionPlan>();

  selectedPlanID = 2;
  old_plan: SubscriptionPlan | undefined;

  subcription_plans: SubscriptionPlan[] = [];

  options: SubscriptionPlan[] = [];

  isIos: boolean;
  isAndroid: boolean;

  isOpen = false;

  @Input() displayFn: (item: any) => { text: string; imageUrl?: string } = (item) => ({
    text: item?.designation || item?.name || item?.label || item?.toString(),
    imageUrl: item?.image || item?.icon || '', // Adjust keys according to your data structure
  });
  @Input() ariaFn: (item: any) => string = (item) => {
    if(item) {
      return this.translate.instant('PAYWALL.SIZE')+' '+this.displayFn(item).text
    }
    return this.translate.instant('PAYWALL.SIZE');
  };
  @Input() ariaSelectFn: (item: any) => string = (item) => {
    if(item) {
      return this.displayFn(item).text
    }
    return this.translate.instant('PAYWALL.SIZE');
  };


  private unsubscribe$ = new Subject<void>();

  constructor() { }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }


  ngOnInit() {
    this.isIos = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
    this.old_plan = this.selectedPlan;

    if(this.selectedPlan==undefined) {
      this.selectSize(2);
    }
    if(this.selectedPlan) {
      this.selectSize(this.selectedPlan.id)
      this.onSizeSelect.emit(this.selectedPlan);
      this.selectedPlanID = this.selectedPlan.id;
    }


    this.subscriptionService.subscriptionPlans$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(plans => {
      this.subcription_plans = plans.sort((p1, p2) => p1.membercount - p2.membercount);
      this.checkExlusions();
      this.selectSize(this.selectedPlanID)
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.old_plan = this.selectedPlan;
    this.checkExlusions();
    if(this.selectedPlan) {
      this.selectSize(this.selectedPlan.id)
      this.onSizeSelect.emit(this.selectedPlan);
    }

  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  compareWith(o1, o2) {
    if(!o1 && !o1) {
      return false;
    } else if (o1.id !== undefined && o2.id !== undefined) {
      return o1.id === o2.id;
    } else if (o1.uid !== undefined && o2.uid !== undefined) {
      return o1.uid === o2.uid;
    } else {
      return false; // If neither id nor uid exist or if they are not matching
    }
  }

  selectSize(id: number) {
    this.selectedPlanID = id;
    const plan = this.subcription_plans.find(p => p.id==id);
    if(plan) {
      this.selectedPlan = plan;
    }
  }

  getNextSmallerPlan(plan: SubscriptionPlan): SubscriptionPlan | undefined {
    const planIndex = this.subcription_plans.indexOf(plan)
    if(planIndex>0) {
      return this.subcription_plans[planIndex-1];
    }
    return undefined;
  }

  checkExlusions() {
    this.options = this.subcription_plans.filter(plan =>
      !this.excludedPlans.some(excludedPlan => excludedPlan.id === plan.id)
    );
  }

  isExcluded(plan: SubscriptionPlan) {
    if(this.excludedPlans && this.excludedPlans.find(p => p.id == plan.id)) {
      return true;
    }
    return false;
  }

  getSizeAriaLable(plan: SubscriptionPlan) {
    const smaller = this.getNextSmallerPlan(plan);
    if(smaller) {
      return this.translate.instant('PAYWALL.ARIASIZELABEL', { min: smaller.membercount+1, max: plan.membercount })
    }
    return this.translate.instant('PAYWALL.ARIASIZELABEL', { min: 1, max: plan.membercount })
  }

  getMonthlyPrice(plan: SubscriptionPlan) {
    let p = this.storeService.getSubscriptionOffer(plan.sku_monthly);
    if(this.isWeb) {
      let sku = p as Package;
      return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
    }
    let sku = p as PurchasesPackage;
    return sku.product.price;
  }

  getMonthlyCurrency(plan: SubscriptionPlan): string {
    let p = this.storeService.getSubscriptionOffer(plan.sku_monthly);
    if(this.isWeb) {
      let sku = p as Package;
      return sku.rcBillingProduct.currentPrice.currency;
    }
    let sku = p as PurchasesPackage;
    return sku.product.currencyCode;
  }

  open() {
    this.isOpen = true;
  }

  cancel() {
    this.selectedPlan = this.old_plan;
    this.isOpen = false;
  }

  confirm() {
    this.isOpen = false;

    if(this.selectedPlan) {
      this.onSizeSelect.emit(this.selectedPlan);
    }
/*    if(this.radioGroup && this.radioGroup.value) {
      this.value = this.radioGroup.value;
      this.selectedChanged.emit([this.value]);
    }*/
  }

}
