<ion-range ariaLabel="{{'TUNES.ARIAZOOMLEVEL' | translate}}" min="1" max="20" [value]="zoomLevel" step="0.1" (ionInput)="changeZoom($event)">
  <ion-icon slot="start" src="/assets/icon/zoom-out.svg" aria-hidden="true"></ion-icon>
  <ion-icon slot="end" src="/assets/icon/zoom-in.svg" aria-hidden="true"></ion-icon>
</ion-range>

<div #zoomContainer class="zoom-container">
  <div #zoomContent class="zoom-content" [style.width.px]="contentWidth * zoomLevel" (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">

    <div class="overlay-parent">
    @if(showWaveformUpdate) {
      <div class="overlay">
        <div class="overlay-content">
          {{ 'TUNES.WAVEFORMLOADING' | translate }}
        </div>
      </div>

    }
    <app-waveform #waveformElement
      [src]="shownFile"
      [time]="showTimeMark"
      [shownRegion]="showRegion"
      [isPlaying]="isPlaying"
      (seekTo)="onSeekTo($event)"
      (updateRegion)="updateRegion($event)"
      (updateRegionTime)="updateRegionTime($event)"
      (waveformRedraw)="onWaveformRedraw()"
    ></app-waveform>
  </div>


    <app-timeline
      [duration]="durationS"
      [shownRegion]="showRegionTime"
      aria-hidden="true"
    ></app-timeline>

    <app-time-marker-bar class="marker-bar"
      [markers]="partMarkers"
      [selectedMarker]="selectedMarker"
      [duration]="duration"
      (markerSelected)="onMarkerSelect($event)"
    ></app-time-marker-bar>

    @if(showAccordeon) {
      <ion-accordion-group [multiple]="true">
        <ion-accordion value="first" toggleIconSlot="start">
          <ion-item slot="header" class="arrangement-accordion">
            <ion-label class="ion-no-margin">{{ 'TUNES.TIMESIGNATURE' | translate }}</ion-label>
          </ion-item>
          <div class="ion-no-padding" slot="content">
            <app-time-marker-bar class="marker-bar"
              [markers]="meterMarkers"
              [selectedMarker]="selectedMarker"
              [duration]="duration"
              (markerSelected)="onMarkerSelect($event)"
              ariaTitle="{{ 'TUNES.TIMESIGNATURE' | translate }}"
            ></app-time-marker-bar>
          </div>
        </ion-accordion>
        <ion-accordion value="second" toggleIconSlot="start">
          <ion-item slot="header"  class="arrangement-accordion">
            <ion-label class="ion-no-margin">{{ 'TUNES.TEMPO' | translate }}</ion-label>
          </ion-item>
          <div class="ion-no-padding" slot="content">
            <app-time-marker-bar class="marker-bar"
              [markers]="tempoMarkers"
              [selectedMarker]="selectedMarker"
              [duration]="duration"
              (markerSelected)="onMarkerSelect($event)"
              ariaTitle="{{ 'TUNES.TEMPO' | translate }}"
            ></app-time-marker-bar>
          </div>
        </ion-accordion>
        <ion-accordion value="third" toggleIconSlot="start">
          <ion-item slot="header"  class="arrangement-accordion">
            <ion-label class="ion-no-margin">{{ 'TUNES.LYRICS' | translate }}</ion-label>
          </ion-item>
          <div class="ion-no-padding" slot="content">
            <app-time-marker-bar class="marker-bar"
              [markers]="lyricMarkers"
              [selectedMarker]="selectedMarker"
              [duration]="duration"
              [showLoading]="isTranscribing"
              loadingText="{{'TUNES.TRANSCRIBING' | translate}}"
              (markerSelected)="onMarkerSelect($event)"
              ariaTitle="{{ 'TUNES.LYRICS' | translate }}"
            ></app-time-marker-bar><br>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    } @else {
      <app-time-marker-bar class="marker-bar"
        [markers]="meterMarkers"
        [selectedMarker]="selectedMarker"
        [duration]="duration"
        (markerSelected)="onMarkerSelect($event)"
      ></app-time-marker-bar>

      <app-time-marker-bar class="marker-bar"
        [markers]="tempoMarkers"
        [selectedMarker]="selectedMarker"
        [duration]="duration"
        (markerSelected)="onMarkerSelect($event)"
      ></app-time-marker-bar>
    }


  </div>
</div>

<ion-button expand="full" id="marker-trigger" class="ion-no-margin ion-margin-vertical">{{ 'TUNES.ADDSECTION' | translate }}</ion-button>
<ion-popover trigger="marker-trigger" triggerAction="click" dismissOnSelect="true">
  <ng-template>
    <ion-content scrollY="false">
      <ion-list>
        <ion-item lines="full" (click)="addMarker('arrangement')">
          {{ 'TUNES.ARRANGEMENTPART' | translate }}
        </ion-item>
        <ion-item lines="full" (click)="addMarker('meter')">
          {{ 'TUNES.TIMESIGNATURE' | translate }}
        </ion-item>
        <ion-item lines="full" (click)="addMarker('tempo')">
          {{ 'TUNES.TEMPO' | translate }}
        </ion-item>
        <ion-item lines="full" (click)="addMarker('lyric')" [disabled]="isTranscribing">
          {{ 'TUNES.LYRICS' | translate }}
        </ion-item>
        <ion-item lines="full" (click)="triggerTranscribeFileInput()">
          <ion-icon name="sparkles-outline" slot="start" aria-hidden="true" color="primary"></ion-icon>
          {{ 'TUNES.TRANSCRIBE' | translate }}
        </ion-item>
        <ion-item lines="full" class="visually-hidden">
          {{ 'TUNES.CLOSE' | translate }}
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-select label="{{'TUNES.CHOOSETRACK' | translate}}" cancelText="{{'TUNES.CANCEL' | translate}}"
  okText="{{'TUNES.OK' | translate}}" class="ion-hide"
  #transcribeSelect (ionChange)="transcribeFile($event.detail.value)"
>
  @for (track of tune?.tracks; track $index) {
    <ion-select-option [value]="track">{{ getTrackDesignation(track) }}</ion-select-option>
  }
</ion-select>


<div
[@markerDetails]="selectedMarker
? {value: 'visible',
  params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
:  {value: 'hidden',
  params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
>
  <ion-grid class="ion-text-center ion-no-padding">
    <ion-row class="ion-align-items-center">
      <ion-col size="12" size-sm="6">
        <ion-input
          label="{{ 'TUNES.NAME' | translate }}"
          placeholder="{{ 'TUNES.NAME' | translate }}"
          ariaLabel="Bezeichnung" [(ngModel)]="selectedArrangementLabel"
          class="dark-ion-input ion-text-end"
          #partNameInput
          [class.ion-hide]="selectedMarkerType!=='arrangement'"
          (ionChange)="updateMarkerDesignation()"
        ></ion-input>
        @if(selectedMarkerType=='tempo') {
          <ion-input label="{{ 'TUNES.TEMPO' | translate }}" placeholder="{{ 'TUNES.TEMPO' | translate }}" ariaLabel="Tempo" type="number" [min]="1" [value]="selectedMarker?.label" class="dark-ion-input ion-text-end"></ion-input>
        } @else if(selectedMarkerType=='meter') {
          <app-searchable-select
            title="{{ 'TUNES.METER' | translate }}"
            [data]="meters"
            [value]="selectedMeter"
            [displayFn]="meterDisplayFn"
            (selectedChanged)="changeMarkerMeter(selectedMarker, $event)"
          ></app-searchable-select>
        } @else if(selectedMarkerType=='lyric') {
          <ion-textarea [(ngModel)]="selectedArrangementLabel" ariaLabel="{{ 'TUNES.LYRICS' | translate }}" class="ion-dark-input" placeholder="Type something here"></ion-textarea>
        }

        <!--TODO: select for meters, input for tempo-->
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-input label="{{ 'TUNES.TIMESTAMP' | translate }}" placeholder="{{ 'TUNES.TIMESTAMP' | translate }}" ariaLabel="Start" class="dark-ion-input ion-text-end" appTimeInput [(ngModel)]="selectedTime" debounce="1000" [minTime]="0"></ion-input>
      </ion-col>
      @if(selectedMarkerType=='arrangement') {
        <ion-col size="12" size-sm="6">
          <ion-input
            label="{{ 'TUNES.TIMESTAMPPICKUP' | translate }}"
            placeholder="{{ 'TUNES.TIMESTAMPPICKUP' | translate }}"
            ariaLabel="Auftakt"
            class="dark-ion-input ion-text-end"
            appTimeInput
            [minTime]="0"
            [maxTime]="selectedTime"
            [showZero]="true"
            [(ngModel)]="selectedPickupTime"
            debounce="1000"
          ></ion-input>
        </ion-col>
      }
    </ion-row>
  </ion-grid>

  <ion-button expand="full" class="ion-no-margin ion-margin-vertical" id="present-part-delete-alert">{{ 'TUNES.DELETESECTION' | translate }}</ion-button>
  <ion-alert
    trigger="present-part-delete-alert"
    header="{{ 'TUNES.ALERTDELETESECTION' | translate }}"
    [buttons]="alertButtons"
  ></ion-alert>
</div>

