<div class="background-div">
  <ion-toolbar class="ion-no-margin ion-no-padding control-bar footer-element">
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row class="ion-align-items-center" id="media-controls">
        <ion-col class="ion-align-self-center ion-no-padding" size="2.4">
          @if(hasCountOff) {
            <ion-button fill="clear" auto-hide="false" (click)="toggleCountOff()" class="player-button" [disabled]="disabled">
              <ion-icon src="/assets/icon/CountOff.svg" [color]="countOffActive ? 'primary' : 'light'" [ariaLabel]="ariaCountOffButton"  slot="icon-only" class="player-icon"></ion-icon>
            </ion-button>
          }
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
          <ion-button fill="clear" auto-hide="false" (click)="playPause()"  class="player-button" [disabled]="disabled">
            <ion-icon *ngIf="!isPlaying" name="play" class="player-icon" ariaLabel="{{ 'PLAYER.PLAY' | translate }}" role="button"  slot="icon-only"></ion-icon>
            <ion-icon *ngIf="isPlaying" name="pause" class="player-icon" ariaLabel="{{ 'PLAYER.PAUSE' | translate }}" role="button"  slot="icon-only"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</div>
