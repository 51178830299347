import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, Sheet } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { SheetViewerComponent } from 'src/app/components/general/sheet-viewer/sheet-viewer.component';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SheetsService {
  private httpClient = inject(HttpClient);
  private authService = inject(AuthenticationService);
  private modalController = inject(ModalController);

  constructor() { }

  get isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  get isMacOS() {

    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('macintosh');
  }

  loadSheet(title: string, sheet: Sheet, previewSheet = false) {
    this.download(title, sheet, previewSheet);
  }

  async download(title: string, sheet: Sheet, previewSheet): Promise<string> {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    try {
      const response$ = this.httpClient.get(`${environment.apiURL}/sheetFile/${sheet.id}`, { headers, responseType: 'blob' });
      const resp: Blob = await lastValueFrom(response$);

      if (resp) {
        try {
          const base64Data = await this.convertBlobToBase64(resp) as string;

          // Extract the parent folder from the sheet path
          const parentFolderPath = `${environment.sheetsCacheFolder}/${sheet.path}`.split('/').slice(0, -1).join('/');

          try {
            await Filesystem.readdir({
              directory: Directory.Library,
              path: parentFolderPath
            });

            // Folder exists, save the file
            return base64Data.split(',')[1]; // Return the URI

          } catch {
            // Folder does not exist, create it and then save the file
            await Filesystem.mkdir({
              directory: Directory.Library,
              path: parentFolderPath,
              recursive: true
            });

            return base64Data.split(',')[1]; // Return the URI
          }

        } catch (error) {
          throw new Error('Error converting blob to base64 or saving file: ' + error);
        }
      } else {
        throw new Error('No response received for the sheet download');
      }
    } catch (error) {
      throw new Error('Error downloading the sheet file: ' + error);
    }
  }

  async saveFile(title: string, base64Data, sheet: Sheet, previewSheet) {
    const result = await Filesystem.writeFile({
      path: `${environment.sheetsCacheFolder}/${sheet.path}`,
      data: base64Data,
      directory: Directory.Library
    });

    /*Filesystem.readFile({
      directory: Directory.Library,
      path: `${environment.sheetsCacheFolder}/${sheet.path}`
    }).then(async file => {
      const uri = (await Filesystem.getUri({path: `${environment.sheetsCacheFolder}/${sheet.path}`, directory: Directory.Library})).uri;
      if(previewSheet) {
        console.log(uri, file.data);
        this.previewFile(title, file.data, uri);
      } else {
        this.openFile(file.data, uri);
      }
    });*/
    return result.uri
  }

  async openFile(data, path: string) {
    const mimeType = 'application/pdf';

    if(!this.isWeb/* && !this.isMacOS*/) {
      FileOpener.open({ filePath: path, contentType: mimeType, openWithDefault: true})
      .catch(error => console.log('Error opening file', error));
/*    } else if(this.isMacOS) {
      console.log('Platform is Mac OS');
      //TODO: find a solution*/
    } else {
      const blob = this.dataURItoBlob(data);
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    }
  }

  async previewFile(title: string, data, path: string) {
    const blob = this.dataURItoBlob(data);
    const fileURL = window.URL.createObjectURL(blob);
    console.log(fileURL);
    const modal = await this.modalController.create({
      component: SheetViewerComponent,
      componentProps: {
        title, data, uri: fileURL, blob: blob
      },
      cssClass: "fullscreen"
    });
    modal.present();
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf',});
    return blob;
  }

  private getMimetype(name) {
    if (name.indexof('pdf') >= 0) {
      return 'application/pdf';
    }
  }
}
