import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { CachedImageComponent, CachedImgSource } from '../../general/cached-image/cached-image.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { ImgCropperComponent } from '../../general/img-cropper/img-cropper.component';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

export interface UploadImg {
  file: Blob;
  filename: string;
}

const mimeTypeToExtension: { [key: string]: string } = {
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif',
  'application/pdf': '.pdf',
  'text/html': '.html',
  'application/json': '.json',
  // Add more MIME types as needed
};


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CachedImageComponent,
    TranslateModule
  ]
})
export class ImageUploadComponent  implements OnInit, OnChanges {
  alertController = inject(AlertController)
  modalController = inject(ModalController)
  translateService = inject(TranslateService)

  @Input() mode: 'display' | 'edit' = 'display';
  @Input() image: string | undefined;
  @Input() buttonAriaLabel = this.translateService.instant('PLAYLISTS.CHANGEPICTURE')
  @Input() imgAriaLabel = this.translateService.instant('GROUPS.PICTURE')

  @Output() imageChanged: EventEmitter<UploadImg> = new EventEmitter<UploadImg>();

  newImgSrc: Blob | undefined;
  imgSrc: CachedImgSource | undefined;

  public isPhotoSheetOpen = false;
  public photoSourceButtons = [
    {
      text: this.translateService.instant('USERMENU.CAMERA'),
      data: {
        source: 'CAMERA',
      },
    },
    {
      text: this.translateService.instant('USERMENU.PHOTOS'),
      data: {
        source: 'PHOTOS',
      },
    },
    {
      text: this.translateService.instant('USERMENU.CANCEL'),
      role: 'cancel',
      data: {
        source: 'cancel',
      },
    },
  ];

  constructor() { }

  ngOnInit() {
    this.updateCachedImgSrc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateCachedImgSrc();
  }

  updateCachedImgSrc() {
    if(this.image) {
      this.imgSrc = {
        url: `${environment.apiURL}${this.image}`,
        path: this.image,
        token: environment.apiKey
      }
    } else {
      this.imgSrc = undefined;
    }
  }

  setPhotoSheetOpen(isOpen: boolean) {
    this.isPhotoSheetOpen = isOpen;
  }

  photoSourceAction(event) {
    this.isPhotoSheetOpen = false;
    if(event.detail.role !== 'cancel') {
      Camera.checkPermissions().then(status => {
        if (event.detail.data.source === 'CAMERA') {
          if(status.camera === 'granted'|| status.camera === 'limited') {
            this.takePicture('CAMERA')
          } else if(status.camera === 'denied') {
                this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.camera === 'granted' || status.camera === 'limited') {
                this.takePicture('CAMERA')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        } else {
          console.log(status)
          if(status.photos === 'granted' || status.photos === 'limited') {
            this.takePicture('PHOTOS')
          } else if(status.photos === 'denied') {
            this.presentPhotoPermissionAlert();
          } else {
            Camera.requestPermissions().then(status => {
              if(status.photos === 'granted' || status.photos === 'limited') {
                this.takePicture('PHOTOS')
              } else {
                this.presentPhotoPermissionAlert();
              }
            })
          }
        }
      });
    }
  }

  async presentPhotoPermissionAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('USERMENU.NOPHOTOPERMISSIONTITLE'),
      message: this.translateService.instant('USERMENU.NOPHOTOPERMISSIONMSG'),
      buttons: [
        {
          text: this.translateService.instant('USERMENU.TOSETTINGS'),
          handler: () => {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App
            })
          }
        },
        {
          text: this.translateService.instant('USERMENU.CANCEL'),
          role: 'cancel'
        }
      ]
    });

    await alert.present()
  }

  async takePicture(type) {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: this.checkPlattformForWebOrIos() ? CameraResultType.DataUrl : CameraResultType.Uri,
      source: type
    });

    if (image) {
      if(this.checkPlattformForWebOrIos()) {image.webPath = image.dataUrl;}

      const modal = await this.modalController.create({
        component: ImgCropperComponent,
        componentProps: {
          image: image.webPath
        }
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if(role==='confirm') {
        let blob = await this.getBlobFromUrl(data)
        let fileExt = this.getExtensionFromMimeType(blob.type)
//        console.log(blob, fileExt);
        this.newImgSrc = data;
        this.imageChanged.emit({ file: blob, filename: fileExt })
      }

    }
  }

  checkPlattformForWebOrIos() {
    // eslint-disable-next-line eqeqeq
    if(Capacitor.getPlatform() == 'web' || Capacitor.getPlatform() == 'ios') {
      return true;
    }
    return false;
  }

  // Function to get the Blob from a Blob URL
  getBlobFromUrl(blobUrl: string): Promise<Blob> {
    return fetch(blobUrl)
      .then(response => response.blob())
      .catch(error => {
        console.error("Error fetching the blob:", error);
        return null;
      });
  }


  async readAsBase64(webPath: string) {
    const response = await fetch(webPath);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob) as string;
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  private getExtensionFromMimeType(mimeType: string): string | null {
    return mimeTypeToExtension[mimeType] || null;
  }
}
