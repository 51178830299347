import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';
import { LibraryService } from 'src/app/services/library.service';
import { AuthenticationService, Collection, PricingCategory, UserModel } from 'bandon-shared';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';

export interface UserPurchaseItem {
  date: Date;
  collectionid?: number;
  tuneid?: number;
  subscription_plan?: number;
  price?: PricingCategory;
}

@Component({
    selector: 'app-purchases',
    templateUrl: './purchases.page.html',
    styleUrls: ['./purchases.page.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgFor,
        NgIf,
        DatePipe,
        TranslateModule,
    ],
})
export class PurchasesPage implements OnInit, OnDestroy {
  private modalController = inject(ModalController);
  private authService = inject(AuthenticationService);
  private libraryService = inject(LibraryService)
  private bandonTranslations = inject(BandONTranslationsService)
  private userDataService = inject(UserDataService)
  private subscriptionService = inject(SubscriptionPlansService)

  public userPurchases: UserPurchaseItem[] = [];

  private unsubscribe$ = new Subject<void>();
  private user: UserModel | undefined = undefined;
  private collections: Collection[] = [];

  constructor() { }

  ngOnInit() {
    this.userDataService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;

        this.user.purchases?.forEach(purchase => {
          purchase.items.forEach(item=> {
            const purchaseItem: UserPurchaseItem = {
              date: purchase.purchasedate,
              collectionid: item.collectionid,
              tuneid: item.tuneid,
              subscription_plan: item.subscription_plan
            };
            this.userPurchases.push(purchaseItem);
          });
        });
    });

    this.libraryService.collections$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(collections => {
        console.log(collections);
        this.collections.length = 0;
        this.collections.push(...collections);
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  getCollectionTitle(collectionid: number) {
    return this.collections.find(c => c.id===collectionid)?.designation;
  }

  getTuneDesc(tuneid: number) {
    const tune = this.libraryService.getSimpleTune(tuneid);
    if(tune) {
      return this.bandonTranslations.getTuneTitle(tune);
    }
    return 'Unbekanntes Stück';
  }

  getSubscriptionDesc(planid: number) {
    let plan = this.subscriptionService.getSubscription(planid);
    if(plan) {
      return plan.designation;
    }
    return 'Unbekanntes Abo'
  }

}

