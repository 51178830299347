<app-paywall-container title="{{'SUBSCRIPTIONMODALS.PAYWALLTITLE' | translate}}"
  [showCloseBtn]="true"
  [selectedPlan]="selectedPlan"
  (subscriptionPurchased)="dismissModal('purchased', null)"
>
  <app-paywall-content (onSizeSelect)="selectSize($event)"></app-paywall-content>

  <footer>
    <ion-button
      expand="block"
      color="light"
      fill="clear"
      class="restore-button ion-margin-bottom"
      (click)="refreshPurchases()"
    >
      {{'SUBSCRIPTIONMODALS.RESTORE' | translate}}
    </ion-button>
  </footer>
</app-paywall-container>
