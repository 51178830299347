<div class="instrument-img ion-text-center"
  [class.small-container]="size==='small'"
  [class.big-container]="size==='big'"
>
  @if (size==='small') {
    <ion-thumbnail>
      <app-cached-image [src]="instrImg"></app-cached-image>
    </ion-thumbnail>
  }
  @else {
    <div class="big-icon">
      <app-cached-image [src]="instrImg"></app-cached-image>
    </div>
  }
  <ion-text class="instrument-title ion-no-padding ion-no-margin">{{ designation }}</ion-text><br>
  <app-level-icon [level]="track.level.id" [hasLevels]="hasLevels"></app-level-icon>
</div>
