import { LoadingController, ModalController, Platform } from '@ionic/angular';
/* eslint-disable max-len */
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable, OnInit, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BandonPurchase, CollectionSku, TuneSku } from 'src/app/shared/interfaces/tune-sku';
import { LibraryService } from '../library.service';
import { AuthenticationService, Collection, PricingCategory, ShopWindow, SimpleTuneModel, SubscriptionPlan } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CachingService } from '../caching/caching.service';
import { BandONTranslationsService } from '../languages/band-ontranslations.service';
import { UserDataService } from '../user/user-data.service';
import { Purchases, LOG_LEVEL, PurchasesOffering, PurchasesOfferings, CustomerInfo, PurchasesPackage, PurchasesEntitlementInfo, GoogleProductChangeInfo, PRORATION_MODE } from '@revenuecat/purchases-capacitor';
import { SubscriptionPlansService } from './subscription-plans.service';
import { LogLevel, Offering, Offerings, Package, Purchases as PurchasesJS } from '@revenuecat/purchases-js'

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private alertController = inject(AlertController);
  private modalController = inject(ModalController);
  private libraryService = inject(LibraryService);
  private authService = inject(AuthenticationService);
  private httpClient = inject(HttpClient);
  private loadingController = inject(LoadingController)
  private translate = inject(TranslateService);
  private router = inject(Router);
  private cachingService = inject(CachingService);
  private bandonTranslations = inject(BandONTranslationsService);
  private userDataService = inject(UserDataService)
  private subscriptionService = inject(SubscriptionPlansService)
  private platform = inject(Platform)

  tuneSkus: TuneSku[] = [];
  offeredTunes: SimpleTuneModel[] = [];

  collectionSkus: CollectionSku[] = [];
  offeredCollections: Collection[] = [];

  shopWindows: ShopWindow[] = [];

  selectedPlan: BehaviorSubject<SubscriptionPlan> = new BehaviorSubject(undefined);
  selectedPlan$ = this.selectedPlan.asObservable();
  selectedPlanInitialized = false;
  activeEntitlement: PurchasesEntitlementInfo | undefined = undefined;

  private offerings: BehaviorSubject<PurchasesOfferings> = new BehaviorSubject(undefined);
  private offerings_web: BehaviorSubject<Offerings> = new BehaviorSubject(undefined);

  private permissions: BehaviorSubject<CustomerInfo> = new BehaviorSubject(undefined);
//  private purchases: BehaviorSubject<GlassfyAccountableSku[]> = new BehaviorSubject([]);

  private consumables: PurchasesOffering;
  private consumables_web: Offering;
  private customerInfoUpdate: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  customerInfoUpdate$ = this.customerInfoUpdate.asObservable();
  private subscriptions: BehaviorSubject<PurchasesOffering> = new BehaviorSubject(undefined);
  subscriptions$ = this.subscriptions.asObservable();
  private subscriptions_web: BehaviorSubject<Offering> = new BehaviorSubject(undefined);
  subscriptions_web$ = this.subscriptions_web.asObservable();

  private pricingCategories: PricingCategory[] = [];

  private isAuth = false;
  private isInit = false;

  private unregisteresPurchases: BandonPurchase[] = [];
  private sentRegistrationRequests: BandonPurchase[] = [];

  constructor() {
    this.platform.ready().then(() => {
      if(!this.isWeb) {
        this.initRevenueCat();
      }
    })

    this.authService.isAuthenticated
    .subscribe(auth => this.isAuth = auth);

    this.userDataService.user$.subscribe(user => {
      if(!this.isWeb) {
        this.refreshOfferings();
      }

      if(user && this.isWeb) {
        this.initRevenueCat();
      }
      if(!user) {
        this.selectedPlanInitialized = false;
      }
    });

    this.libraryService.userCollections$
    .subscribe(() => {
      if(this.consumables || this.consumables_web) {
        this.updateTuneOfferings();
        this.updateCollectionOfferings();
//        this.registerAllPurchases();
      }
    });

    this.libraryService.userTunes$
    .subscribe(() => {
      if(this.consumables || this.consumables_web) {
        this.updateTuneOfferings();
        this.updateCollectionOfferings();
//        this.registerAllPurchases();
      }
    });
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }

  get isAndroid(): boolean {
    return Capacitor.getPlatform()==='android';
  }


  async initRevenueCat() {
    if(this.isWeb) {
      if(!this.isInit) {
        PurchasesJS.setLogLevel(LogLevel.Info)
        PurchasesJS.configure(environment.revenueCatKeyWeb, this.userDataService.user.uid)

        this.isInit = true;
        const offerings = await PurchasesJS.getSharedInstance().getOfferings();
        this.offerings_web.next(offerings)

        this.refreshOfferings();
      }
    } else {
      await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
        this.handleCustomerInfo(customerInfo);
      });

      try {
        //      console.log('Init RevenueCat');
        await Purchases.setLogLevel({ level: LOG_LEVEL.ERROR });
        if (Capacitor.getPlatform() === 'ios') {
            await Purchases.configure({ apiKey: environment.revenueCatKeyIOS });
        } else if (Capacitor.getPlatform() === 'android') {
            await Purchases.configure({ apiKey: environment.revenueCatKeyAndroid });
        }

        const offerings = await Purchases.getOfferings();

        this.offerings.next(offerings);

        this.refreshOfferings();
        Purchases.getCustomerInfo().then(customerInfo => this.handleCustomerInfo(customerInfo.customerInfo))
      } catch (e) {
        // initialization error
      }
    }

    this.libraryService.collections$.subscribe(collections => {
      this.updateCollectionOfferings();
    });

  }

  async refreshOfferings() {
    console.log('refreshOfferings')
    if(this.isWeb) {
      const offerings = await PurchasesJS.getSharedInstance().getOfferings();
      this.offerings_web.next(offerings);
      this.consumables_web = offerings.all['Consumables'];
      this.subscriptions_web.next(offerings.all['content_creator']);
    } else {
      const offerings = await Purchases.getOfferings();
      this.offerings.next(offerings);
      this.consumables = offerings.all['Consumables'];
      this.subscriptions.next(offerings.all['content_creator']);
    }

    //Load all Pricing Categories
    let headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    if (this.isAuth) {
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    this.httpClient.get<PricingCategory[]>(environment.apiURL+'/pricing/categories', {headers})
    .subscribe({
      next: categories => {
        this.pricingCategories.length = 0;
        this.pricingCategories = categories;

        this.updateCollectionOfferings();
      },
      error: err => {
        console.log('refreshOfferings, Pricing Categories error: ', err);
      }
    });

    this.updateTuneOfferings();

    this.registerAllPurchases();
  }

  refreshShopWindows() {
    //Get all Shop Windows
    const headers = new HttpHeaders();
    if (this.isAuth) {
      headers.set('Authorization', this.authService.getIDToken());
    } else {
      headers.set('Authorization', 'Bearer '+environment.apiKey);
    }

    this.httpClient.get<ShopWindow[]>(environment.apiURL+'/shop/windows', {headers})
      .subscribe(
        (windows) => {
          this.shopWindows.length = 0;
          this.shopWindows.push(...windows);
        }
      );
  }

  updateTuneOfferings() {
    this.libraryService.getTunes()
    .subscribe(tunes => {
      for(const tune of tunes) {
        if(tune.glassfysku) {
          let sku: PurchasesPackage | Package = undefined;
          if(this.isWeb) {
            sku = this.consumables_web.availablePackages.find(p => p.identifier===tune.glassfysku);
          } else {
            sku = this.consumables.availablePackages.find(p => p.identifier===tune.glassfysku);
          }
          let tuneSku = this.tuneSkus.find(s => s.tune.id===tune.id);
          if(!tuneSku && sku) {
            tuneSku = {
              sku,
              tune,
              isPurchased: this.isTunePurchased(tune)
            };
            this.tuneSkus.push(tuneSku);
            this.offeredTunes.push(tune);
          } else if(tuneSku) {
            tuneSku.isPurchased = this.isTunePurchased(tune);
          }
        }
      }
      this.tuneSkus.sort((s1, s2) => {
        if(s1.isPurchased!==s2.isPurchased) {
          if(s1.isPurchased) {
            return 1;
          }
          return -1;
        }
        const title1 = this.bandonTranslations.getTuneTitle(s1.tune);
        const title2 = this.bandonTranslations.getTuneTitle(s2.tune);
        return title1.localeCompare(title2);
      });
    });
  }

  updateCollectionOfferings() {
    const collections = this.libraryService.collectionsBehaviour.getValue();
    this.collectionSkus.length = 0;
    this.offeredCollections.length = 0;

    for(const collection of collections) {
//      console.log(`updateCollectionOfferings, check collection ${collection.designation}`);
      if(collection.pricinghistory) {
        const pricing = collection.pricinghistory.find(p => !p.end);
        if(pricing) {
          const cat = this.pricingCategories.find(c => c.id===pricing.pricingcategoryid);
          if(cat && ((this.consumables && !this.isWeb) || (this.consumables_web && this.isWeb)) ) {
            let sku: PurchasesPackage | Package = undefined;
            if(this.isWeb) {
              sku = this.consumables_web.availablePackages.find(p => p.identifier===cat.glassfysku);
            } else {
              sku = this.consumables.availablePackages.find(p => p.identifier===cat.glassfysku);
            }
            let collectionSku = this.collectionSkus.find(s => s.collection.id===collection.id);
            if(!collectionSku && sku) {
              collectionSku = {
                sku, collection, isPurchased: this.isCollectionPurchased(collection), reductions: []
              };
              for(const reduction of cat.reductions) {
                //TODO: web
//                const skuReduced = this.consumables.availablePackages.find(p => p.identifier===reduction.glassfysku);
                let skuReduced: PurchasesPackage | Package = undefined;
                if(this.isWeb) {
                  skuReduced = this.consumables_web.availablePackages.find(p => p.identifier===reduction.glassfysku);
                } else {
                  skuReduced = this.consumables.availablePackages.find(p => p.identifier===reduction.glassfysku);
                }

                if(skuReduced) {
                  collectionSku.reductions.push({tunecount: reduction.tunecount, skuReduced});
                } else {
                  console.log(`ERROR: updateCollectionOfferings: couldn't find sku: ${reduction.glassfysku}`);
                }
              }
//              console.log('updateCollectionOfferings: add collection ', collectionSku.collection.designation);
              this.collectionSkus.push(collectionSku);
              this.offeredCollections.push(collection);
//              console.log(collection)
            } else if(collectionSku) {
              collectionSku.isPurchased = this.isCollectionPurchased(collection);
            } else {
              console.log(`ERROR: updateCollectionOfferings no collectionSku ${collection.designation}`);
            }
          }
        }
      }
    }
    this.collectionSkus.sort((s1, s2) => {
      if(s1.isPurchased!==s2.isPurchased) {
        if(s1.isPurchased) {
          return 1;
        }
        return -1;
      }
      return s1.collection.designation.localeCompare(s2.collection.designation);
    });

  }

  getOfferings() {
    if(this.isWeb) {
      return this.offerings_web.asObservable();
    }
    return this.offerings.asObservable();
  }

  async showNotLoggedInAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('STORE.ALERT'),
      message: this.translate.instant('STORE.NEEDSACCOUNT'),
      buttons: [
        {
          text: this.translate.instant('STORE.REGISTER'),
          handler: () => {
            this.router.navigateByUrl('/collection/user/register');
            this.modalController.dismiss();
          }
        },
        {
          text: this.translate.instant('STORE.SIGNIN'),
          handler: () => {
            this.router.navigateByUrl('/collection/user/login');
            this.modalController.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  async showNotConfirmedAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('STORE.ALERT'),
      message: this.translate.instant('STORE.ERRORUNCONFIRMED'),
      buttons: [
        {
          text: this.translate.instant('STORE.CONFIRM'),
          handler: () => {
            this.userDataService.sendConfirmationMail();
          }
        }
      ]
    });

    await alert.present();
  }

  async purchaseTune(tuneSku: TuneSku) {
    if(tuneSku.isPurchased) {
      return;
    }

    if(!this.isAuth) {
      this.showNotLoggedInAlert();
      return;
    }
    if(this.userDataService.user && !this.userDataService.user.emailconfirmed) {
      this.showNotConfirmedAlert();
      return;
    }

    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    try {
      if(this.isWeb) {
        let sku = tuneSku.sku as Package
        let purchase = await PurchasesJS.getSharedInstance().purchase({ customerEmail: this.userDataService.user.email, rcPackage: sku })
        this.putPurchaseToDB(loading, tuneSku.tune.id, 0, 0, tuneSku.sku.identifier, sku.rcBillingProduct.currentPrice.amountMicros/1000000, sku.rcBillingProduct.currentPrice.currency, '');
      } else {
        let sku = tuneSku.sku as PurchasesPackage
        let purchase = await Purchases.purchasePackage({ aPackage: sku })
        this.putPurchaseToDB(loading, tuneSku.tune.id, 0, 0, tuneSku.sku.identifier, sku.product.price, sku.product.currencyCode, purchase.transaction.transactionIdentifier);
      }
    } catch (e) {
      const alert = await this.alertController.create({
        header: this.translate.instant('STORE.PURCHASEFAILED'),
        message: this.translate.instant('STORE.PURCHASEFAILEDMSG'),
        buttons: ['OK'],
        cssClass: 'band-on-alert'
      });
      await loading.dismiss();
      await alert.present();
    }
  }

  registerAllPurchases() {
    this.cachingService.getUnregisteredPurchases()
    .then(async purchases => {
      if(purchases) {
        this.unregisteresPurchases = [...purchases];
        const loading = await this.loadingController.create({
          cssClass: 'band-on-loading'
        });
//        await loading.present();

        const unregPurchases = [...purchases];
        for (const p of unregPurchases) {
          const sentRequest = this.sentRegistrationRequests.find(r => (r.tuneid===p.tuneid || r.collectionid===p.collectionid) && r.sku===p.sku);

          if(!sentRequest) {
            this.sentRegistrationRequests.push(p);
            this.putPurchaseToDB( loading, p.tuneid, p.collectionid, p.subscriptionid, p.sku, p.price, p.currency, p.transaction);
          }
        }
      }
    });
  }

  putPurchaseToDB(loading: HTMLIonLoadingElement, tuneid: number, collectionid: number, subscriptionid: number, sku: string, price: number, currency: string, transaction: string) {
    //Register it on band-on-server
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let data: BandonPurchase = {
      tuneid,
      sku,
      price,
      currency,
      transaction
    };
    if (collectionid>0) {
      data = {
        collectionid,
        sku,
        price,
        currency,
        transaction
      };
    } else if(subscriptionid>0) {
      data = {
        subscriptionid,
        sku,
        price,
        currency,
        transaction
      };
    }

    this.httpClient.put(`${environment.apiURL}/users/${this.authService.getUserID()}/purchase`, data, {headers})
    .subscribe({
      next: () => {
        const purchase = this.unregisteresPurchases.find(p => p.tuneid===data.tuneid || p.collectionid===data.collectionid);
        if(purchase) {
          this.unregisteresPurchases.splice(this.unregisteresPurchases.indexOf(purchase), 1);
          this.cachingService.cacheUnregisteredPurchases(this.unregisteresPurchases);
        }

        this.libraryService.loadUserLibraryFromServer(true);
        this.userDataService.getUserData();

        const sentRequests = this.unregisteresPurchases.filter(p => (p.tuneid===tuneid || p.collectionid===collectionid) && p.sku===sku);
        sentRequests.forEach(r => this.sentRegistrationRequests.splice(this.sentRegistrationRequests.indexOf(r), 1));
        loading.dismiss();
      },
      error: async () => {
        const alert = await this.alertController.create({
          header: this.translate.instant('STORE.ALERT'),
          message: this.translate.instant('STORE.PURCHASEERRSERVER'),
          buttons: [
            {
              text: this.translate.instant('STORE.CANCEL'),
              role: 'cancel',
              handler: () => {
                const purchase = this.unregisteresPurchases.find(p => p.tuneid===data.tuneid || p.collectionid===data.collectionid);
                if(!purchase) {
                  this.unregisteresPurchases.push(data);
                  this.cachingService.cacheUnregisteredPurchases(this.unregisteresPurchases);
                }
              }
            },
            {
              text: this.translate.instant('STORE.AGAIN'),
              role: 'confirm',
              handler: () => {
                this.putPurchaseToDB(loading, tuneid, collectionid, subscriptionid, data.sku, data.price, data.currency, data.transaction);
              }
            }
          ],
        });

        await alert.present();
        loading.dismiss();
      }
    });
  }

  async purchaseCollection(collectionSku: CollectionSku, sku: PurchasesPackage | Package) {
    if(collectionSku.isPurchased) {
      return;
    }

    if(!this.isAuth) {
      this.showNotLoggedInAlert();
      return;
    }
    if(this.userDataService.user && !this.userDataService.user.emailconfirmed) {
      this.showNotConfirmedAlert();
      return;
    }

    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    try {
      if(this.isWeb) {
        let sku = collectionSku.sku as Package
        let purchase = await PurchasesJS.getSharedInstance().purchase({ customerEmail: this.userDataService.user.email, rcPackage: sku })
        this.putPurchaseToDB(loading, 0, collectionSku.collection.id, 0, sku.identifier, sku.rcBillingProduct.currentPrice.amountMicros/1000000, sku.rcBillingProduct.currentPrice.currency, '');
      } else {
        let sku = collectionSku.sku as PurchasesPackage
        let purchase = await Purchases.purchasePackage({ aPackage: sku})
        this.putPurchaseToDB(loading, 0, collectionSku.collection.id, 0, sku.identifier, sku.product.price, sku.product.currencyCode, purchase.transaction.transactionIdentifier);
      }

    } catch (e) {
      const alert = await this.alertController.create({
        header: this.translate.instant('STORE.PURCHASEFAILED'),
        message: this.translate.instant('STORE.PURCHASEFAILEDMSG'),
        buttons: ['OK'],
        cssClass: 'band-on-alert'
      });
      await loading.dismiss();
      await alert.present();
    }
  }

  async purchaseSubscription(offer: PurchasesPackage | Package, plan: SubscriptionPlan) {
    if(!this.isAuth) {
      this.showNotLoggedInAlert();
      return false;
    }
    if(this.userDataService.user && !this.userDataService.user.emailconfirmed) {
      this.showNotConfirmedAlert();
      return false;
    }

    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    if(!this.isWeb) {
      try {
        await this.refreshPurchases();
      } catch(e) {
        await loading.dismiss();
        return
      }
    }

    try {
      let purchase = undefined;
      let price = undefined;
      let currency = undefined;
      let transaction = '';
      if(this.isAndroid && this.activeEntitlement) {
        purchase = await Purchases.purchasePackage({ aPackage: offer as PurchasesPackage, googleProductChangeInfo: this.getGoogleProductChangeInfo(this.userDataService.user.subscription, plan) })
      } else {
        if(this.isWeb) {
          let sku = offer as Package
          let purchase = await PurchasesJS.getSharedInstance().purchase({ rcPackage: sku, customerEmail: this.userDataService.user.email })
//          this.putPurchaseToDB(loading, 0, collectionSku.collection.id, 0, sku.identifier, sku.rcBillingProduct.currentPrice.amountMicros/1000000, sku.rcBillingProduct.currentPrice.currency, '');
          price = sku.rcBillingProduct.currentPrice.amountMicros/1000000;
          currency = sku.rcBillingProduct.currentPrice.currency;
        } else {
          let sku = offer as PurchasesPackage
          let purchase = await Purchases.purchasePackage({ aPackage: sku})
//          this.putPurchaseToDB(loading, 0, collectionSku.collection.id, 0, sku.identifier, sku.product.price, sku.product.currencyCode, purchase.transaction.transactionIdentifier);
          price = sku.product.price;
          currency = sku.product.currencyCode;
          transaction = purchase.transaction.transactionIdentifier;
        }
//        purchase = await Purchases.purchasePackage({ aPackage: offer })
      }

      this.putPurchaseToDB(loading, 0, 0, plan.id, offer.identifier, price, currency, transaction);

      this.refreshCustomerInfo();
    } catch (e) {
      const alert = await this.alertController.create({
        header: this.translate.instant('STORE.PURCHASEFAILED'),
        message: this.translate.instant('STORE.PURCHASEFAILEDMSG'),
        buttons: ['OK'],
        cssClass: 'band-on-alert'
      });
      await loading.dismiss();
      await alert.present();
      return false;
    }
    return true;
  }

  refreshCustomerInfo() {
    Purchases.getCustomerInfo().then(customerInfo => this.handleCustomerInfo(customerInfo.customerInfo));
  }

  handleCustomerInfo(customerInfo: CustomerInfo) {
    console.log('Customer info changed: ',
      customerInfo.activeSubscriptions,
      customerInfo.entitlements,
    )

    this.subscriptionService.subscriptionPlans$
    .pipe(
      filter(plans => plans.length>0),
      take(1)
    )
    .subscribe(plans => {
      let selectedPlan: SubscriptionPlan | undefined = null;
      let activeEntitlement: PurchasesEntitlementInfo | undefined = undefined;
      for (let plan of plans) {
        if (plan.entitlement in customerInfo.entitlements.active) {
          activeEntitlement = customerInfo.entitlements.active[plan.entitlement]
            if(!selectedPlan) {
              selectedPlan = plan;
            } else if(plan.membercount>selectedPlan.membercount) {
              selectedPlan = plan;
            }
        }
      }

      this.selectedPlanInitialized = true;
      if(selectedPlan!=this.selectedPlan.value) {
        this.selectedPlan.next(selectedPlan);
      }
      this.activeEntitlement = activeEntitlement;
      console.log('active entitlement: ', activeEntitlement);
      //TODO:
      this.customerInfoUpdate.next();
//      this.userSubscriptionService.updateSubscriptionInfo();
    })
  }

  async restore() {
    this.permissions.next((await Purchases.restorePurchases()).customerInfo);
  }

  isTunePurchased(tune: SimpleTuneModel): boolean {
    const userTunes = this.libraryService.userTunesBehaviour.getValue();
    if(userTunes && userTunes.length>0) {
      return userTunes.includes(tune.id);
    }
    return tune.isdemo;
  }

  isCollectionPurchased(collection: Collection): boolean {
    const userCollections = this.libraryService.userCollectionsBehaviour.getValue();
    if(userCollections && userCollections.length>0) {
      return userCollections.includes(collection.id);
    }
    return false;
  }

  getTuneSku(tune: SimpleTuneModel): TuneSku {
    return this.tuneSkus.find(ts => ts.tune.id===tune.id);
  }

  getCollectionSku(collection: Collection): CollectionSku {
    return this.collectionSkus.find(cs => cs.collection.id===collection.id);
  }

  getSubscriptionOffer(identifier: string): PurchasesPackage | Package {
    if(this.isWeb) {
      return this.subscriptions_web.value.availablePackages.find(p => p.identifier===identifier);
    }
    return this.subscriptions.value.availablePackages.find(p => p.identifier===identifier);
  }

  checkEligibilty(): boolean {
    if(this.userDataService.user) {
      if(this.userDataService.user.purchases && this.userDataService.user.purchases.length>0) {
        for(let p of this.userDataService.user.purchases) {
          for(let item of p.items) {
            if(item.subscription_plan) {
              return false;
            }
          }
        }
        return true;
      }
    }
    return true;
  }

  getGoogleProductChangeInfo(old_plan: SubscriptionPlan, new_plan: SubscriptionPlan): GoogleProductChangeInfo {
    let oldProductIdentifier = this.activeEntitlement.productIdentifier;

    //Change from annual/monthly
    let prorationMode = PRORATION_MODE.IMMEDIATE_AND_CHARGE_FULL_PRICE;
    if(old_plan.membercount<new_plan.membercount) {
      //Upgrade
      prorationMode = PRORATION_MODE.IMMEDIATE_WITH_TIME_PRORATION;
    } else if(old_plan.membercount>new_plan.membercount) {
      //Downgrade
      prorationMode = PRORATION_MODE.DEFERRED
    }
    return { oldProductIdentifier, prorationMode }
  }

  async refreshPurchases() {
    let header = this.translate.instant('PROFILE.RELOAD')
    let message = this.translate.instant('PROFILE.REFRESHED')

    try {
      let customerInfo = await Purchases.restorePurchases();
    } catch(error) {
      console.error(error)
      if(error['code']=='7') {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PAYWALL.ERRORMULTIPLESUB')
      } else {
        header = this.translate.instant('PROFILE.ERROR')
        message = this.translate.instant('PROFILE.ERRORREFRESHUNKNOWN')
      }

      const alert = await this.alertController.create({
        header, message,
        buttons: [
          {
            text: this.translate.instant('PROFILE.OK'),
            role: 'confirm',
          }
        ],
        cssClass: 'band-on-alert'
      });

      await alert.present();

      throw error;
    }

  }
}
