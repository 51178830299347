import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomValidators, Musician, profanityValidator } from 'bandon-shared';

@Component({
  selector: 'app-musician-modal',
  templateUrl: './musician-modal.component.html',
  styleUrls: ['./musician-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class MusicianModalComponent  implements OnInit {
  modalController = inject(ModalController)
  translate = inject(TranslateService)
  fb = inject(FormBuilder)

  @Input() musician: Musician = { id: -1, surname: '', firstname: ''};
  @Input() modalTitle: string = this.translate.instant('TUNES.CREATEMUSICIAN');

  musicianForm: FormGroup = this.fb.group({
    firstname: [this.musician.firstname, [Validators.required, profanityValidator()]],
    surname: [this.musician.surname, profanityValidator()],
    homepage: [this.musician.homepage, CustomValidators.validUrlValidator],
    birth: [this.musician.year_of_birth],
    death: [this.musician.year_of_death]
  });

  constructor() { }

  get firstnameInput() {
    return this.musicianForm.get('firstname');
  }

  get surnnameInput() {
    return this.musicianForm.get('surname');
  }

  get homepageInput() {
    return this.musicianForm.get('homepage');
  }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }

  save() {
    this.modalController.dismiss(this.musician);
  }
}
