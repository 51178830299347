import { NotificationService } from './notifications/notification.service';
import { StoreService } from './store/store.service';
import { AudioService } from 'src/app/services/audio/audio.service';
import { inject, Injectable } from '@angular/core';
import { AudioDownloadService } from './audio/audio-download.service';
import { CachingService } from './caching/caching.service';
import { LibraryService } from './library.service';
import { TuneInfoService } from './audio/tune-info.service';
import { DeviceInfoService } from './device-info.service';
import { BadgeService } from './notifications/badge.service';
import { AuthenticationService } from 'bandon-shared';
import { AudioEngine } from '@musicdose/audioengine';
import { environment } from 'src/environments/environment';
import { SubscriptionPlansService } from './store/subscription-plans.service';
import { UserDataService } from './user/user-data.service';
import { UserSubscriptionService } from './user/user-subscription.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  private audioService = inject(AudioService);
  private audioDownloadService = inject(AudioDownloadService);
  private cachingService = inject(CachingService);
  private authService = inject(AuthenticationService);
  private libraryService = inject(LibraryService);
  private notificationService = inject(NotificationService);
  private tuneInfoService = inject(TuneInfoService);
  private deviceInfoService = inject(DeviceInfoService);
  private badgeService = inject(BadgeService);
  private subscriptionService = inject(SubscriptionPlansService)
  private userDataService = inject(UserDataService)
  private userSubscriptionService = inject(UserSubscriptionService)
  private storeServie =inject(StoreService);

  constructor() { }

  async init() {
    await AudioEngine.setServerUrl({ serverUrl: `${environment.apiURL}/` });
    await this.deviceInfoService.init();
    await this.cachingService.init();
    await this.authService.lookForBiometricToken();
    await this.authService.checkAuthenticated();
    await this.userDataService.init();
    await this.audioDownloadService.init();
    await this.audioService.init();
    await this.tuneInfoService.init();
    await this.libraryService.init();
    await this.subscriptionService.init();
    this.notificationService.init();
//    await this.storeServie.initGlassfy();
//    this.authService.checkAuthenticated();
    return Promise.resolve().then(() => {});
  }
}
