<div class="select-container" [class.disabled]="disabled" (click)="open()" aria-haspopup="listbox" aria-expanded="false" role="button">
  <ion-label color="light" aria-hidden="true">{{ title }}</ion-label>
  <div class="visually-hidden">{{ ariaFn(value) }}</div>
  @if (multiple && selected.length) {
    @for (item of selected; track $index; let last = $last) {
      <span class="ellipsis-overflow ion-no-wrap" aria-hidden="true">
        {{ displayFn(item).text }}{{ last ? "": ", "}}
      </span>
    }
  }
  @else if (!multiple && value) {
    <span class="ellipsis-overflow" aria-hidden="true">
      {{ displayFn(value).text }}
    </span>
  }
  @else {
    <span class="placeholder ellipsis-overflow" [class.disabled-darker]="disabled" aria-hidden="true">
      {{ placeholder }}
    </span>
  }

  @if (isIos) {
    <ion-icon name="chevron-expand-outline" [class.disabled-darker]="disabled" color="primary" aria-hidden="true"></ion-icon>
  }
  @else {
    <ion-icon name="caret-down-outline" [ngClass]="{ 'rotate-icon': isOpen, 'reset-icon': !isOpen }" aria-hidden="true"></ion-icon>
  }
</div>

<ion-modal [isOpen]="isOpen" (didDismiss)="isOpen = false">
  <ng-template>
    <ion-header [class.android-select-header]="!isIos">
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar (ionInput)="filter($event)" placeholder="suchen"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content class="modal-background ion-text-center">
      @if(multiple) {
        @for (item of filtered; track $index) {
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="item.selected"
              (ionChange)="itemSelected()" [ariaLabel]="ariaSelectFn(item)"
            ></ion-checkbox>
            <ion-label>{{ displayFn(item).text }}</ion-label>
          </ion-item>
        }
        @empty {
          <div class="ion-text-center ion-margin">
            {{ 'TUNES.NORESULTSELECT' | translate }}
          </div>
      }
      }
      @else {
        <ion-radio-group [value]="value" [compareWith]="compareWith" (ionChange)="selectItem($event)" #radioGroup>
          @for (item of filtered; track $index) {
            <ion-item class="modal-background" lines="none">
              <ion-radio labelPlacement="end" justify="start" [value]="item" [ariaLabel]="ariaSelectFn(item)">{{ displayFn(item).text }}</ion-radio>
              @if(displayFn(item).imageUrl) {
                <ion-avatar slot="end" aria-hidden="true">
                  <img [src]="displayFn(item).imageUrl">
                </ion-avatar>
              }
            </ion-item>
          }
          @empty {
            <div class="ion-text-center ion-margin">
              {{ 'TUNES.NORESULTSELECT' | translate }}
            </div>
          }
        </ion-radio-group>
    }
    </ion-content>

    <ion-footer [class.android-select-footer]="!isIos">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="cancel()" color="primary">{{ 'TUNES.CANCEL' | translate | uppercase }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="confirm()" color="primary">{{ 'TUNES.OK' | translate | uppercase }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
