import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserGroup } from 'bandon-shared';

@Component({
  selector: 'app-group-list-item',
  templateUrl: './group-list-item.component.html',
  styleUrls: ['./group-list-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class GroupListItemComponent  implements OnInit {
  @Input() group: UserGroup;
  @Input() detail = false;
  @Input() showGroupDescription = true;
  @Input() showUserLimit = false;
  @Input() lines: 'full' | 'inset' | 'none' | undefined = undefined;

  constructor() { }

  ngOnInit() {}

}
