/* eslint-disable @typescript-eslint/member-ordering */
import { AudioService } from 'src/app/services/audio/audio.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RangeCustomEvent } from '@ionic/core';
import { FormControl, FormsModule } from '@angular/forms';
import { Part } from 'bandon-shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-time-slider',
    templateUrl: './time-slider.component.html',
    styleUrls: ['./time-slider.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        FormsModule,
        NgIf,
        TranslateModule
    ],
})
export class TimeSliderComponent implements OnInit, OnDestroy {

  private changeTimeMode = false;
  currentTimeToSend = 0.0;

  seekbar: FormControl = new FormControl('seekbar');

  isSeeking = false;

  parts: Part[] = [];

  private unsubscribe$ = new Subject<void>();

  get currentTime(): number {
    if(this.changeTimeMode) {
      return this.currentTimeToSend;
    }
    return this.audioService.currentTime;
  }

  set currentTime(time: number) {
    this.currentTimeToSend = time;
  }

  get currentTimeString(): string {
    const time = this.correctTime(this.currentTime);
    const minute = Math.max(Math.floor(time / 60), 0);
    const seconds = Math.max(Math.floor(time - (minute * 60)), 0);
    return ('00'+minute).slice(-2)+':' + ('00'+seconds).slice(-2);
  }

  get currentBarString(): string {
    let barInfoString = '';
    if (this.hasBarInformation) {
      barInfoString = ` ${this.translateService.instant('ARRANGEMENT.BAR')} ${this.tuneInfoService.getBarOfTime(this.currentTime)}`;
    }
    return barInfoString;
  }

  get duration(): number {
    return this.audioService.duration;
  }

  get showLoop(): boolean {
    if (this.tuneInfoService.loopStartPart) {
      return true;
    }
    return false;
  }

  get loopStart(): number {
    if (this.audioService.loopStart) {
      return this.audioService.loopStart;
    } else if(this.audioService.currentSpeed && this.audioService.currentSpeed.start) {
      return this.audioService.currentSpeed.start/1000;
    }
    return 0.0;
  }

  get loopStartString(): string  {
    const time = this.correctTime(this.audioService.loopStart);
    const minute = Math.max(Math.floor(time / 60), 0);
    const seconds = Math.max(Math.floor(time - (minute * 60)), 0);
    return ('00'+minute).slice(-2)+':' + ('00'+seconds).slice(-2);
  }

  get loopStartBarString(): string  {
    const time = this.correctTime(this.audioService.loopStart);
    return ` ${this.translateService.instant('ARRANGEMENT.BAR')} ${this.tuneInfoService.getBarOfTime(time)}`;
//    return ` ${this.translateService.instant('ARRANGEMENT.BAR')} ${this.tuneInfoService.getBarOfTime(this.audioService.loopStart)}`;
  }

  get loopEnd(): number {
    if (this.audioService.loopEnd) {
      return this.audioService.loopEnd;
    }
    return this.duration;
  }

  get loopEndString(): string {
    const time = this.correctTime(this.audioService.loopEnd);
    const minute = Math.max(Math.floor(time / 60), 0);
    const seconds = Math.max(Math.floor(time - (minute * 60)), 0);
    return ('00'+minute).slice(-2)+':' + ('00'+seconds).slice(-2);
  }

  get loopEndBarString(): string  {
    const time = this.correctTime(this.audioService.loopEnd);
    return ` ${this.translateService.instant('ARRANGEMENT.BAR')} ${this.tuneInfoService.getBarOfTime(time)}`;
//    return ` ${this.translateService.instant('ARRANGEMENT.BAR')} ${this.tuneInfoService.getBarOfTime(this.audioService.loopEnd)}`;
  }

/*  get parts(): Part[] {
    if(this.tuneInfoService.filteredPartsBehaviour.value) {
      return this.tuneInfoService.filteredParts;
    }
    return [];
  }*/

  get hasBarInformation(): boolean {
    if(this.tuneInfoService.currentPart && this.tuneInfoService.currentPart.showbarslider) {
      return true;
    }
    return false;
  }

  get rangeAriaLabel(): string {
    let out = `${this.translateService.instant('PLAYER.TIME')}, ${this.currentTimeString}`;
    if(this.hasBarInformation) {
      out += `, ${this.currentBarString}`;
    }
    if(this.showLoop) {
      out += `, ${this.translateService.instant('PLAYER.LOOPSTART')} ${this.loopStartString}`;
      if(this.hasBarInformation) {
        out += ` ${this.loopStartBarString}`;
      }
      out += `, ${this.translateService.instant('PLAYER.LOOPEND')} ${this.loopEndString}`;
      if(this.hasBarInformation) {
        out += ` ${this.loopEndBarString}`;
      }
    }
    return out;
  }


  constructor(
    private audioService: AudioService,
    private translateService: TranslateService,
    private tuneInfoService: TuneInfoService) { }

  ngOnInit() {
    this.tuneInfoService.filteredParts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(p => this.parts = p)
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  startJump(ev: Event) {
    this.changeTimeMode = true;
    this.setCurrentTime(ev);
  }

  setCurrentTime(ev: Event) {
    this.currentTime = Number((ev as RangeCustomEvent).detail.value);
  }

  async jumpTo(ev: Event) {
    if(this.isSeeking) {
      return;
    }
    this.isSeeking = true;
    const value = Number((ev as RangeCustomEvent).detail.value);
    await this.audioService.setCurrentTime(value /*this.currentTimeToSend*/);
    this.changeTimeMode = false;
    this.isSeeking = false;
  }

  correctTime(t: number) {
    let time = t;
    if(this.audioService.tune && this.audioService.currentSpeed && this.audioService.currentSpeed.start &&
        this.parts[0] && this.parts[0].timepickup) {
      time -= this.parts[0].timepickup/1000;
    } else if(this.audioService.tune && this.audioService.currentSpeed && this.audioService.currentSpeed.start &&
        this.parts[0] && this.parts[0].pickup) {
      time -= (this.audioService.currentSpeed.start/1000-
        this.parts[0].pickup*(60/this.audioService.currentSpeed.speed));
    } else if(this.audioService.currentSpeed && this.audioService.currentSpeed.start) {
      time -= this.audioService.currentSpeed.start/1000;
    }
    return time;
  }

}
