import { LanguageService } from '../languages/language.service';
/* eslint-disable @typescript-eslint/member-ordering */
import { AuthenticationService, LoginCredentials } from 'bandon-shared';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Auth, OAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { DeviceInfoService } from '../device-info.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private auth =  inject(Auth);
  private authService = inject(AuthenticationService);
  private httpClient = inject(HttpClient);
  private router = inject(Router);
  private deviceInfoService = inject(DeviceInfoService);
  private loadingController = inject(LoadingController);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  apple_options: SignInWithAppleOptions = {
    clientId: 'com.scherer.musican.auth',
    redirectURI: 'https://ch.musicdose.starter://auth/apple',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce'
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  apple_web_options: SignInWithAppleOptions = {
    clientId: 'com.scherer.musican.auth',
    redirectURI: 'https://app.band-on.com',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce'
  };

  private isAuth = false;

  constructor() {
/*    GoogleAuth.initialize({
      clientId: '589593319665-f39ikp8bjs9nm54s8s2tb3g9vhf9ppmo.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true
    });*/
    GoogleAuth.initialize({scopes: ['profile', 'email'], grantOfflineAccess: true});

    this.authService.isAuthenticated.subscribe(auth => {
      if(!auth && auth!==this.isAuth) {
        this.logout();
      }
      this.isAuth = auth;
    })
  }

  async loginWithApple() {
    try {
      if (Capacitor.getPlatform() === 'web') {
        return this.signInWithAppleWeb();
      } else {
        return this.signInWithAppleNative();
      }
    } catch (error) {
      throw Error('Login with Apple: There was an error loggin in: '+error.message);
    }
  }

  async signInWithAppleWeb() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email name');

    const user = await signInWithPopup(this.auth, provider);
    const params: LoginCredentials = {
      uid: user.user.uid,
      name: user.user.displayName ?? user.user.email.substring(0, user.user.email.indexOf('@')),
      email: user.user.email,
      device_id: this.deviceInfoService.id.identifier,
      device_name: JSON.stringify(this.deviceInfoService.device)
    }

    return this.authService.loginWithCredentials(params);
  }

  async signInWithAppleNative() {
/*    const options: SignInWithAppleOptions = {
      clientId: 'ch.musicdose.starter',
      redirectURI: 'https://musican-345011.firebaseapp.com/__/auth/handler',
      scopes: 'email',
      state: '12345',
//      nonce: 'nonce',
    };*/
    const options: SignInWithAppleOptions = {
      clientId: 'com.scherer.musican.auth',
      redirectURI: 'https://ch.musicdose.starter://auth/apple',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce'
    };

    let result: SignInWithAppleResponse | undefined;
    try {
      result = await SignInWithApple.authorize(options);

      const cred = this.authService.jwt_decode(result.response.identityToken);

      const email = cred.email;
      let emailName = '';
      if (email && email.indexOf('@')) {
        emailName = email.substring(0, email.indexOf('@'));
      }

      const params: LoginCredentials = {
        uid: result.response.user,
        name: result.response.givenName ?? emailName,
        email: email,
        device_id: this.deviceInfoService.id.identifier,
        device_name: JSON.stringify(this.deviceInfoService.device)
      }

      return this.authService.loginWithCredentials(params);
    } catch (error) {
      throw Error('Login with Apple Native: There was an error loggin in: '+error.message);
    }

  }

  async loginWithGoogle() {
    const user = await GoogleAuth.signIn();
    const provider = new OAuthProvider('google.com');
    const credential = provider.credential({
      idToken: user.authentication.idToken,
    });
    const params: LoginCredentials = {
      uid: user.id,
      name: user.givenName ?? user.email.substring(0, user.email.indexOf('@')),
      email: user.email,
      device_id: this.deviceInfoService.id.identifier,
      device_name: JSON.stringify(this.deviceInfoService.device)
    }
    return this.authService.loginWithCredentials(params);
  }

  async signInWithGoogleWeb() {
    try {
      const provider = new OAuthProvider('google.com');

      const user = await signInWithPopup(this.auth, provider);

      return user;
    } catch(e) {
      return null;
    }

  }

  async logout() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

//    await this.router.navigateByUrl('/collection/user/login');
//    await this.router.navigateByUrl('/collection/library');

    loading.dismiss();
  }
}
