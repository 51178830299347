import { CommonModule } from '@angular/common';
import { Component, inject, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Instrument, Track } from 'bandon-shared';
import { CachedImageComponent, CachedImgSource } from '../cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LevelIconComponent } from '../../mixer/level-icon/level-icon.component';
import { AudioService } from 'src/app/services/audio/audio.service';

@Component({
  selector: 'app-instrument-icon',
  templateUrl: './instrument-icon.component.html',
  styleUrls: ['./instrument-icon.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CachedImageComponent,
    LevelIconComponent
  ]
})
export class InstrumentIconComponent  implements OnInit {
  @Input() track: Track;
  @Input() size: 'small' | 'big' = 'small';

  translateService = inject(TranslateService)
  audioService = inject(AudioService)
  zone = inject(NgZone)

//  instrImg: CachedImgSource

  constructor() { }

  get instrument(): Instrument {
    if(this.track) {
      return this.track.instrument;
    }
    return null;
  }

  get serverUrl(): string {
    return environment.apiURL;
  }

  get instrImg(): CachedImgSource {
    const token = 'Bearer '+environment.apiKey;
    return { url: this.instrImgPath, path: this.instrImgPath, token };
  }

  get instrImgPath(): string {
    if(this.instrument && this.instrument.img) {
      return this.serverUrl+'/instrImg/'+this.instrument.img;
    }
    return '';
  }

  get designation(): string {
    if(this.track.voice && this.track.voice.id>0) {
      return this.track.voice.designation;
    }
    else if(this.translateService && this.track.instrument && this.track.instrument.translations) {
      const translation = this.track.instrument.translations.find(e => e.languageid===this.translateService.currentLang);
      if(translation) {
        return translation.designation;
      }
      return this.track.instrument.designation;
    } else if(this.track.instrument) {
      return this.track.instrument.designation;
    }
    return '';
  }

  get possibleLevels(): Track[] {
    const out: Track[] = [];
    this.audioService.getTracksToInstrument(this.track.instrument, this.track.voice).forEach((track) =>{
      if (!out.find(e => e.level.id===track.level.id)) {
          out.push(track);
        }
    });
    out.sort((a, b) => a.level.short > b.level.short ? 1 : -1);

    return out;
  }

  get hasLevels(): boolean {
    return this.possibleLevels.length>1 && !this.track.instrumentgroup.linked;
  }


  ngOnInit() {}


}
