<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      @if(unsafedData) {
        <ion-button color="primary" (click)="cancel()">
          {{ 'TUNES.CANCEL' | translate }}
        </ion-button>
      } @else {
        <ion-back-button defaultHref="/collection/library/user-tunes" text="" attr.aria-Label="{{ 'MESSAGES.BACK' | translate }}"></ion-back-button>
      }
    </ion-buttons>
    <ion-title>{{ tune.title }}</ion-title>
    <ion-buttons slot="end" >
      <ion-button color="primary" (click)="saveTune()" [disabled]="!tuneForm.valid || !isConnected">
        {{ 'TUNES.SAVE' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-offline-header></app-offline-header>
</ion-header>

<ion-content>
  <!--Action Sheet: Usafed Content-->
  <ion-action-sheet
    class="band-on-action-sheet"
    header="{{ tune.title }}"
    subHeader="{{ 'PLAYLISTS.REALLYCANCEL' | translate }}"
    [isOpen]="isActionSheetOpen"
    [buttons]="actionButtons"
    (didDismiss)="setActionSheetOpen(false)"
  ></ion-action-sheet>


  <ion-accordion-group #inputAccordion [value]="accordions[0]" (ionChange)="accordionChanged($event)">
    <form [formGroup]="tuneForm">

    <!--Audio Dateien-->
    <ion-accordion [value]="accordions[0]" class="ion-no-margin">
      <ion-item slot="header">
        <div class="accordion-header-content">
          <ion-label>{{ 'TUNES.AUDIOFILES' | translate }}</ion-label>
          @if ((audioFilesInput.invalid && audioFilesInput.touched) || titleInput.dirty) {
            <div class="accordion-error-message">
              @if (audioFilesInput.errors?.['required']) {
                <ion-note color="primary">
                  {{ 'TUNES.AUDIOFILESREQUIRED' | translate }}
                </ion-note>
              }
            </div>
          }
        </div>
      </ion-item>
      <div class="ion-padding" slot="content">
        <div class="ion-text-center">
          <app-file-upload
            uploadText="{{ 'TUNES.UPLOADFILES' | translate }}"
            [selectedUploadFiles]="audioFiles"
            [fileCheckFn]="audioCheckFn"
            formatRemarks="{{ 'TUNES.AUDIOFILESREQUIREMENTS' | translate }}"
            (filesChanged)="audioFilesChanged($event)"
            deleteMsg="{{ 'TUNES.REMOVEAUDIOFILE' | translate }}"
          ></app-file-upload>

          <ion-button (click)="goToAccordion(accordions[1])" [disabled]="!tune.tracks || tune.tracks.length===0" expand="full">{{ 'TUNES.NEXT' | translate}}</ion-button>
        </div>
      </div>
    </ion-accordion>
    <!--Allgemeine Informationen-->
    <ion-accordion [value]="accordions[1]">
      <ion-item slot="header">
        <div class="accordion-header-content">
          <ion-label>{{ 'TUNES.GENERALINFORMATION' | translate }}</ion-label>
          @if (hasGeneralError) {
            <div class="accordion-error-message">
              @if (audioFilesInput.errors?.['required']) {
                <ion-note color="primary">
                  {{ 'TUNES.ERRORSEXIST' | translate }}
                </ion-note>
              }
            </div>
          }
        </div>
      </ion-item>
      <div class="ion-padding" slot="content">
        <ion-grid class="ion-text-center ion-no-margin ion-no-padding">
          <ion-row class="ion-align-items-center">
            <ion-col size="12">
              <ion-input placeholder="{{'TUNES.TITLE' | translate}}" ariaLabel="{{'TUNES.TITLE' | translate}}" [(ngModel)]="tune.title" formControlName="title" class="dark-ion-input" (ionChange)="setHasUnsafedData()"></ion-input>
              @if ((titleInput.invalid && titleInput.touched) || titleInput.dirty) {
                <div class="ion-margin-bottom">
                  @if (titleInput.errors?.['profanity']) {
                    <ion-note color="primary">
                      {{ 'TUNES.TITLEPROFANITY' | translate }}
                    </ion-note>
                  }
                  @if (titleInput.errors?.['required']) {
                    <ion-note color="primary">
                      {{ 'TUNES.TITLEREQUIRED' | translate }}
                    </ion-note>

                  }
                </div>
              }
            </ion-col>

            <ion-col size="12" class="ion-padding">
              <app-image-upload
                buttonAriaLabel="{{'TUNES.ARIACHANGEIMG' | translate}}"
                imgAriaLabel="{{'TUNES.ARIATUNEIMG' | translate}}"
                [mode]="mode"
                [image]="tuneImgPath"
                (imageChanged)="tuneImgChanged($event)"
              ></app-image-upload>
            </ion-col>

            <ion-col size="12" size-sm="6" class="col-margin">
              <app-tags-input placeholder="{{ 'TUNES.COMPOSERS' | translate }}"
                [suggestions]="musicians"
                [displayFn]="musicianDisplayFn"
                [tags]="composers"
                [tagFactoryFn]="musicianFactoryFn"
                [canGenerate]="true"
                (tagsChanged)="composersChanged($event)"
              ></app-tags-input>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <app-tags-input placeholder="{{ 'TUNES.ARRANGERS' | translate }}"
                [suggestions]="musicians"
                [displayFn]="musicianDisplayFn"
                [tags]="arrangers"
                [tagFactoryFn]="musicianFactoryFn"
                [canGenerate]="true"
                (tagsChanged)="arrangersChanged($event)"
              ></app-tags-input>
            </ion-col>
            <ion-col size="12" size-sm="6" class="col-margin">
              <app-tags-input placeholder="{{ 'TUNES.STYLES' | translate }}"
                [suggestions]="styles"
                [tagFactoryFn]="styleFactoryFn"
                [tags]="tune.styles"
                (tagsChanged)="styleChanged($event)"></app-tags-input>
            </ion-col>
            <!--<ion-col size="12" size-sm="6">
              <app-tags-input placeholder="{{ 'TUNES.TAGS' | translate }}"
                [suggestions]="tags"
                [tagFactoryFn]="tagFactoryFn"
                [tags]="tune.tags"
                [canGenerate]="true"
                (tagsChanged)="tagsChanged($event)"></app-tags-input>
            </ion-col>-->

            <ion-col size="12">
              <quill-editor placeholder="{{ 'TUNES.COVERTEXT' | translate }}" formControlName="covertext" class="covertext-editor" [(ngModel)]="tune.covertext" (onContentChanged)="setHasUnsafedData()"></quill-editor>
            </ion-col>

            <ion-col size="12" size-sm="6" class="col-margin">
              <app-searchable-select
                title="{{ 'TUNES.TONALITY' | translate }}"
                [data]="tonalities"
                [value]="tuneTonality"
                itemTextField="designation"
                [ariaFn]="tonalityAriaFn"
                [ariaSelectFn]="tonalityAriaSelectFn"
                (selectedChanged)="changeTuneTonality($event)"
              ></app-searchable-select>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <app-tempo-select
                title="{{ 'TUNES.TEMPO' | translate }}"
                placeholder="{{ 'TUNES.SELECT' | translate }}"
                [data]="speedDesignations"
                [value]="tunetempo"
                (selectedChanged)="changeTempo($event)"
              ></app-tempo-select>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button (click)="goToAccordion(accordions[2])" [disabled]="isGotoInstrumentButtonDisabled" expand="full" class="ion-no-margin ion-margin-top">{{ 'TUNES.NEXT' | translate}}</ion-button>
      </div>
    </ion-accordion>

    <!--Instrumenten Informationen-->
    <ion-accordion [value]="accordions[2]">
      <ion-item slot="header">
        <ion-label>{{ 'TUNES.INSTRUMENTINFO' | translate }}</ion-label>
        @if (hasInstrumentError) {
          <div class="accordion-error-message">
            <ion-note color="primary">
              {{ 'TUNES.ERRORSEXIST' | translate }}
            </ion-note>
          </div>
        }
      </ion-item>
      <div class="ion-padding" slot="content">
        <div class="ion-text-center">
          <ion-list>
            @for (track of tune.tracks; track $index) {
              <app-track-item
                [tune]="tune"
                [track]="track"
                [instrumentGroups]="instrumentGroups"
                [instruments]="instruments"
                [voices]="voices"
                [fg]="tracks.controls[$index]"
                [musicians]="musicians"
                (onChange)="setHasUnsafedData()"
              ></app-track-item>
            }
          </ion-list>
          <ion-button (click)="goToAccordion(accordions[3])" [disabled]="isGotoArrangementButtonDisabled" expand="full">{{ 'TUNES.NEXT' | translate}}</ion-button>
        </div>
      </div>
    </ion-accordion>

    <!--Arrangement Informationen-->
    <ion-accordion [value]="accordions[3]">
      <ion-item slot="header">
        <ion-label>{{ 'TUNES.ARRANGEMENTINFO' | translate }}</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="6">
              <ion-button expand="block" (click)="showMarkerview=true" [disabled]="disableMarkerview">{{ 'TUNES.MANUALLY' | translate }}</ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button expand="block" (click)="importMidi()" [disabled]="disableMarkerview">{{ 'TUNES.MIDI' | translate }}</ion-button>
            </ion-col>
            <!--<ion-col size="4">
              <ion-button expand="block">MusicXML</ion-button>
            </ion-col>-->
          </ion-row>
          @if(disableMarkerview) {
            <ion-row>
              <ion-col>
                <p>{{ 'TUNES.ERRORARRNOFILES' | translate }}</p>
              </ion-col>
            </ion-row>
          }
          @if(!disableMarkerview) {
            <ion-row>
              <ion-col size="12">
                <p>{{ 'TUNES.HELPMIDI' | translate }} <a href="https://band-on.com/de/midi-import" target="_blank">band-on.com/midi-import</a></p>
              </ion-col>
            </ion-row>
          }
        </ion-grid>
        <app-horizontal-divider></app-horizontal-divider>

        <div
          [@markerDetails]="showMarkerview
          ? {value: 'visible',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
          :  {value: 'hidden',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
        >
          @if(showBackgroundDownloadProgress) {
            <p class="ion-text-center">{{ 'TUNES.LOADINGARRANGEMENT' | translate }}</p>
            <ion-progress-bar [value]="backgroundProgress"></ion-progress-bar>
          }
          @else {
            <!--<ion-input ariaLabel="Tempo" placeholder="Tempo" type="number" formControlName="tempo" class="ion-padding-start ion-padding-end dark-ion-input ion-text-center"></ion-input>
            @if ((tempoInput.invalid && tempoInput.touched) || tempoInput.dirty) {
              <div class="ion-margin-bottom">
                @if (tempoInput.errors?.['min']) {
                  <ion-note color="primary">
                    {{ 'TUNES.TEMPOMIN' | translate }}
                  </ion-note>
                }
                @if (tempoInput.errors?.['required']) {
                  <ion-note color="primary">
                    {{ 'TUNES.TEMPOREQUIRED' | translate }}
                  </ion-note>
                }
              </div>
            }-->
            <app-marker-view
              [tune]="tune"
              [audioFiles]="shownAudioFiles"
              [tunetempo]="tunetempo"
              [isPlaying]="isPlaying"
              (onChange)="setHasUnsafedData()"
              (seekTo)="onSeekTo($event)"
            ></app-marker-view>
          }
        </div>
        <ion-button (click)="goToAccordion(accordions[4])" expand="full" class="ion-no-margin ion-margin-top">{{ 'TUNES.NEXT' | translate}}</ion-button>
      </div>
    </ion-accordion>
    </form>

    <!--Stück freigeben-->
    <ion-accordion [value]="accordions[4]">
      <ion-item slot="header">
        <ion-label>{{ 'TUNES.SHARETUNE' | translate }}</ion-label>
      </ion-item>
      <div class="ion-padding ion-text-center" slot="content">
        <app-sharing-accordion [tune]="tune" class="ion-margin-bottom" (onChange)="setHasUnsafedData()" (onShowPaywall)="showPaywall(false)"></app-sharing-accordion>

        <ion-button (click)="saveTune()" [disabled]="!tuneForm.valid" expand="full" class="ion-margin-top">{{ 'TUNES.SAVE' | translate}}</ion-button>
      </div>
    </ion-accordion>
  </ion-accordion-group>

</ion-content>
<ion-footer>
  <app-upload-footer
    [disabled]="showBackgroundDownloadProgress"
    [isPlaying]="isPlaying"
    (pause)="setIsPlaying(false)"
    (play)="setIsPlaying(true)"
  >
  </app-upload-footer>

</ion-footer>
