/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IonicModule } from '@ionic/angular';
import { CommonModule, NgIf } from '@angular/common';
import { AuthenticationService } from 'bandon-shared';

const CACHE_KEY = '_bandoncached_';
const DOWNLOADSTATE_KEY = 'imgDownloadState_';
const TTL = 3600; //seconds

export interface CachedImgSource {
  url: string;
  path: string;
  token: string;
}

@Component({
    selector: 'app-cached-image',
    templateUrl: './cached-image.component.html',
    styleUrls: ['./cached-image.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule],
})
export class CachedImageComponent  implements OnInit, OnDestroy, OnChanges {

  @Input() spinner = false;
  @Input() imageFit: 'contain' | 'cover' = 'contain';
  @Input() ariaInfo = "";
  @Input() src: CachedImgSource | undefined = undefined;

  _src = '';

  private unsubscribe$ = new Subject<void>();
  private imgSource: CachedImgSource;

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient
  ) { }

  get isAriaHidden(): boolean {
    return this.ariaInfo ? false : true;
  }

/*  @Input()
  set src(imagesrc: CachedImgSource) {
    if(!imagesrc || !imagesrc.path || !imagesrc.url) {
      this._src = '';
      return;
    }
    if(this.imgSource && this.imgSource.url === imagesrc.url && this.imgSource.path === imagesrc.path) {
      return;
    }
    this.imgSource = imagesrc;

    const imageName = imagesrc.path.split('/').pop();
    const fileType = imageName.split('.').pop();

    const isSVG = fileType.toLowerCase() === 'svg';

    Filesystem.readFile({
      directory: Directory.Library,
      path: `${environment.imgCacheFolder}/${imageName}`
    }).then(file => {
      if (isSVG) {
        this._src = `data:image/svg+xml;base64,${file.data}`;
      } else {
        this._src = `data:image/${fileType};base64,${file.data}`;
      }
    }).catch(async e => {
      //Write the file
//      console.log('Download img: ', imagesrc.url);
      this.downloadImage(imagesrc.url, imageName, fileType, imagesrc.token);
    });
  }*/

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.src || !this.src.path || !this.src.url) {
      this._src = '';
      this.imgSource = undefined;
      return;
    }
    if(this.imgSource && this.imgSource.url === this.src.url && this.imgSource.path === this.src.path) {
      return;
    }
    this.imgSource = this.src;

    const imageName = this.src.path.split('/').pop();
    const fileType = imageName.split('.').pop();

    const isSVG = fileType.toLowerCase() === 'svg';

    Filesystem.readFile({
      directory: Directory.Library,
      path: `${environment.imgCacheFolder}/${imageName}`
    }).then(file => {
      if (isSVG) {
        this._src = `data:image/svg+xml;base64,${file.data}`;
      } else {
        this._src = `data:image/${fileType};base64,${file.data}`;
      }
    }).catch(async e => {
      //Write the file
//      console.log('Download img: ', imagesrc.url);
      this.downloadImage(this.src.url, imageName, fileType, this.src.token);
    });
  }

  downloadImage(url, path, type, token) {
    const headers = new HttpHeaders().set('Authorization', token);
    // eslint-disable-next-line @typescript-eslint/naming-convention
//    const decoded_token = this.authService.jwt_decode(this.authService.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
//    const user_id = decoded_token.user_id;

    this.httpClient.get(url, { headers, responseType: 'blob'})
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: async resp => {
          if (resp) {
            const base64Data = await this.convertBlobToBase64(resp) as string;

            await Filesystem.writeFile({
              path: `${environment.imgCacheFolder}/${path}`,
              data: base64Data,
              directory: Directory.Library
            });

            const isSVG = type.toLowerCase() === 'svg';

            Filesystem.readFile({
              directory: Directory.Library,
              path: `${environment.imgCacheFolder}/${path}`
            }).then(file => {
              if (isSVG) {
                this._src = `data:image/svg+xml;base64,${file.data}`;
              } else {
                this._src = `data:image/${type};base64,${file.data}`;
              }
            });
          }
        },
        error: err => {
          console.log(`Error downloading image: ${path}`);
        }
      });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
