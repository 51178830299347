import { Component, inject, Input, OnInit } from '@angular/core';
import { Playlist, SimpleTuneModel, Tune } from 'bandon-shared';
import { CachedImgSource } from '../../general/cached-image/cached-image.component';
import { LibraryService } from 'src/app/services/library.service';
import { environment } from 'src/environments/environment';
import { LocalFile } from 'src/app/shared/interfaces/local-file';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { PlaylistIconComponent } from '../playlist-icon/playlist-icon.component';
import { IonicModule } from '@ionic/angular';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
    selector: 'app-playlist-item',
    templateUrl: './playlist-item.component.html',
    styleUrls: ['./playlist-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        PlaylistIconComponent,
        NgIf,
        TranslateModule,
    ],
})
export class PlaylistItemComponent  implements OnInit {

  @Input() playlist: Playlist;
  @Input() showNewPlaylistIcon = false;

  private userDataService = inject(UserDataService)
  private translateService = inject(TranslateService)

  constructor(
    private libraryService: LibraryService,
  ) { }

  get playlistImgSrc(): CachedImgSource {
    if(this.playlist && this.playlist.tunes && this.playlist.tunes.length>0) {
      const tune = this.getTune(this.playlist.tunes[0].tune_id);
      if(tune) {
        const token = 'Bearer '+environment.apiKey;

        return { url: environment.apiURL+'/photoImg/'+tune.picture.id, path: this.getTunePath(tune), token };
      }
    }
    return { url: '', path: '', token: '' };
  }

  ngOnInit() {}

  getTune(tuneid: number): SimpleTuneModel {
    return this.libraryService.getSimpleTune(tuneid);
  }

  getTunePath(tune: SimpleTuneModel): string {
    if(tune && tune.picture) {
      return tune.picture.path;
    }
    return '';
  }

  isShared(playlist: Playlist) {
    if(playlist && playlist.id>0 && this.userDataService.user && this.userDataService.user.uid!==playlist.user_id) {
      return true;
    }
    return false;
  }
}
