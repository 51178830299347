import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AlertController, IonicModule, IonSearchbar, LoadingController, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService, GroupUser, SimpleTuneModel, SubscriptionPlan, UserGroup, UserModel } from 'bandon-shared';
import { PaywallContainerComponent } from '../paywall-container/paywall-container.component';
import { filter, lastValueFrom, Subject, take, takeUntil } from 'rxjs';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { UserItemComponent } from 'src/app/components/user/user-item/user-item.component';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { ContactsService } from 'src/app/services/social/contacts.service';
import { GroupsService, OWNER_ROLE_ID } from 'src/app/services/social/groups.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LibraryService } from 'src/app/services/library.service';
import { TuneItemComponent } from 'src/app/components/general/tune-item/tune-item.component';

@Component({
  selector: 'app-group-decrease',
  templateUrl: './group-decrease.component.html',
  styleUrls: ['./group-decrease.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    PaywallContainerComponent,
    FormsModule,
    UserItemComponent,
    TuneItemComponent,
  ],
  animations: [
    trigger('searchSegment', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({ overflow: 'hidden' }),
        animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class GroupDecreaseComponent  implements OnInit, OnChanges, OnDestroy {
  private modalController = inject(ModalController);
  private subscriptionPlanService = inject(SubscriptionPlansService)
  private userDataService = inject(UserDataService)
  private contactsService = inject(ContactsService)
  private httpClient = inject(HttpClient)
  private authService = inject(AuthenticationService)
  private libraryService = inject(LibraryService)
  private alertController = inject(AlertController)
  private translateService = inject(TranslateService)
  private groupsService = inject(GroupsService)
  private loadingController = inject(LoadingController)

  @Input() group: UserGroup;
  @Input() newMemberLimit: number;
  @Input() newTuneLimit: number;

  @ViewChild('memberSearchBar', { static: false }) memberSearchBar: IonSearchbar;
  @ViewChild('tuneSearchBar', { static: false }) tuneSearchBar: IonSearchbar;


  selectedPlan: SubscriptionPlan | undefined = undefined;

  membersearchHidden = true;
  memberSearchTerm: string;
  searchedMembers: GroupUser[] = []

  removeMembers: GroupUser[] = []

  editedMembers = false;

  userTunes: SimpleTuneModel[] = []

  tunesearchHidden = true;
  tuneSearchTerm: string;
  searchedTunes: SimpleTuneModel[] = []

  removeTunes: SimpleTuneModel[] = []

  editedTunes = false;

  savingMessage: HTMLIonLoadingElement | undefined;

  private unsubscribe$ = new Subject<void>();

  public alertButtons = [
    {
      text: this.translateService.instant('GROUPS.CANCEL'),
      role: 'cancel',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: this.translateService.instant('GROUPS.OK'),
      role: 'confirm',
      handler: () => {
        this.removeMembersAndTunes();
      },
    },
  ];

  constructor() { }

  get showMemberRemove() {
    if(this.group && this.group.users.length>this.newMemberLimit) {
      return true;
    }
    return false;
  }

  get shownMembers(): GroupUser[] {
    if(this.memberSearchTerm && this.searchedMembers) {
      return this.searchedMembers;
    } else if(!this.memberSearchTerm) {
      return this.group.users
    }
    return [];
  }

  get memberToRemove() {
    return this.group.users.length-this.newMemberLimit;
  }

  get memberToChoose() {
    return this.memberToRemove - this.removeMembers.length;
  }

  get showTuneRemove() {
    if(this.group && this.userTunes.length>this.newTuneLimit) {
      return true;
    }
    return false;
  }

  get shownTunes(): SimpleTuneModel[] {
    if(this.tuneSearchTerm && this.searchedTunes) {
      return this.searchedTunes;
    } else if(!this.memberSearchTerm) {
      return this.userTunes
    }
    return [];
  }

  get tuneToRemove() {
    return this.userTunes.length-this.newTuneLimit;
  }

  get tuneToChoose() {
    return this.tuneToRemove - this.removeTunes.length;
  }

  get enableRemoveButton() {
    return this.memberToRemove<=this.removeMembers.length && this.tuneToRemove<=this.removeTunes.length;
  }


/*  get showTuneRemove() {
    if(this.group && this.group.users.length>this.newTuneLimit) {
      return true;
    }
    return false;
  }*/

  ngOnInit() {
    this.getProlongingOptions();

    this.libraryService.tunes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(tunes => this.loadUserTunes(tunes))

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProlongingOptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async loadUserTunes(tunes: SimpleTuneModel[]) {
    if(this.userDataService.user) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/usertunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (tunes) => {
          this.userTunes.length = 0;
          this.userTunes.push(...tunes);
        },
        error: (error) => {
        }
      });
    } else {
      this.userTunes.length = 0;
    }
  }

  getProlongingOptions() {
    if(this.subscriptionPlanService.subscriptionPlans.value.length>0) {
      this.updateProlongingOptions(this.subscriptionPlanService.subscriptionPlans.value)
    } else {
      this.subscriptionPlanService.subscriptionPlans$
      .pipe(
        filter(plans => plans.length>0),
        take(1)
      )
      .subscribe(plans => this.updateProlongingOptions(plans))
    }
  }

  updateProlongingOptions(plans: SubscriptionPlan[]) {
    if(this.group && plans.length>0 && this.group.userlimit) {
      let selectedPlan = undefined;
      for(let plan of plans) {
        if(plan.membercount>=this.group.users.length) {
          selectedPlan = plan;
          break;
        }
      };
      this.selectedPlan = selectedPlan;
      console.log(selectedPlan);
    }
  }

  showMemberSearch() {
    this.membersearchHidden = false;
    if(this.memberSearchBar) {
      this.memberSearchBar.setFocus();
    }
  }

  hideMemberSearch() {
    this.membersearchHidden = true;
    this.memberSearchTerm = undefined;
  }

  searchMember(event) {
    if(this.memberSearchTerm && this.group) {
      this.searchedMembers = this.group.users.filter(gu => {
        const user = gu.user;
        if(user) {
          const idValue = user.email+user.name;
          return idValue.toLowerCase().includes(this.memberSearchTerm.toLowerCase());
        }
        return false;
      });
    }
  }

  showTuneSearch() {
    this.tunesearchHidden = false;
    if(this.tuneSearchBar) {
      this.tuneSearchBar.setFocus();
    }
  }

  hideTuneSearch() {
    this.tunesearchHidden = true;
    this.tuneSearchTerm = undefined;
  }

  searchTune(event) {
    if(this.tuneSearchTerm && this.group) {
      this.searchedTunes = this.userTunes.filter(tune => {
        const idValue = tune.title;
        return idValue.toLowerCase().includes(this.tuneSearchTerm.toLowerCase());
      });
    }
  }

  getUserStatus(user: UserModel): "established" | "hide" | "asked" | "request" | "myself" {
    if(user.username === this.userDataService.user.username) {
      return "myself";
    }
    const relationship = this.contactsService.getRelationship(this.userDataService.user.username, user.username);
    if(relationship) {
      if(relationship.state.designation === "established") {
        return "established"
      } else if(relationship.state.designation === 'request' && relationship.username1===this.userDataService.user.username) {
        return "asked"
      }
      return "asked"
    }
    return "hide"
  }

  isGroupOwner(groupuser: GroupUser) {
    if(groupuser.role && groupuser.role.id==OWNER_ROLE_ID) {
      return true;
    }
    return false;
  }

  changeMemberRemoveStatus(member: GroupUser, remove: boolean) {
    let existingRemoveMember = this.removeMembers.find(m => m.user.uid==member.user.uid)
    if(remove && existingRemoveMember) {
      this.removeMembers.splice(this.removeMembers.indexOf(existingRemoveMember), 1);
    } else if(!existingRemoveMember) {
      this.removeMembers.push(member);
    }
    this.editedMembers = true;
  }

  changeTuneRemoveStatus(tune: SimpleTuneModel, remove: boolean) {
    let existingRemoveTune = this.removeTunes.find(t => t.id==tune.id)
    if(remove && existingRemoveTune) {
      this.removeTunes.splice(this.removeTunes.indexOf(existingRemoveTune), 1);
    } else if(!existingRemoveTune) {
      this.removeTunes.push(tune);
    }
    this.editedTunes = true;
  }

  dismissModal(role: string, data: any) {
    this.modalController.dismiss(data, role);
  }

  async showDeleteAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('SUBSCRIPTIONMODALS.ATTENTION'),
      message: this.translateService.instant('SUBSCRIPTIONMODALS.ALERTREDUCTION'),
      buttons: this.alertButtons,
      cssClass: 'band-on-alert'
    });
    alert.style.zIndex = '1000000';

    await alert.present();
  }

  async removeMembersAndTunes() {
    if(this.group) {
      for(let member of this.removeMembers) {
        const index = this.group.users.indexOf(member);
        this.group.users.splice(index, 1)
      }

      await this.saveGroup();
    }

    for(let tune of this.removeTunes) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      await lastValueFrom(
        this.httpClient.delete(`${environment.apiURL}/tunes/${tune.id}`, { headers })
          .pipe(takeUntil(this.unsubscribe$))
        )
        .then(() => {
          console.log(`Tune ${tune.title} deleted`);
        })
        .catch(err => {
          console.error(err);
          // Uncomment if you want to show alert on error
          // this.showAlert(this.translate.instant('TUNES.ERROR'), undefined, this.translate.instant('TUNES.ERRORDELETE', { title: tune.title }));
        }
      );
    }

    this.dismissModal('reduced', null);
  }

  async saveGroup() {
    await this.showSaving();
    const formData = new FormData();

    formData.append('group', JSON.stringify(this.group));

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);

    this.httpClient.post<UserGroup>(environment.apiURL+`/usergroups/${this.group.id}`, formData, {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: resp => {
        this.group = resp;
        this.groupsService.updateGroup(this.group);
        this.hideSaving();
      },
      error: err => {
        this.hideSaving();
        console.error(err)
      }
    });
  }

  async showSaving() {
    this.savingMessage = await this.loadingController.create({
      message: this.translateService.instant('GROUPS.SAVING'),
      cssClass: 'band-on-loading'
    });

    this.savingMessage.present();
  }

  hideSaving() {
    this.savingMessage.dismiss();
  }
}
