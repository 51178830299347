import { CommonModule } from '@angular/common';
import { Component, inject, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserTuneDetailComponent } from 'src/app/pages/user/tunes/user-tune-detail/user-tune-detail.component';
import { AudioService } from 'src/app/services/audio/audio.service';

@Component({
  selector: 'app-upload-footer',
  templateUrl: './upload-footer.component.html',
  styleUrls: ['./upload-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
})
export class UploadFooterComponent  implements OnInit {
  audioService = inject(AudioService)
  translateService = inject(TranslateService)

  @Input() disabled = false;
  @Input() isPlaying = false;

  @Output() play: EventEmitter<void> = new EventEmitter<void>();
  @Output() pause: EventEmitter<void> = new EventEmitter<void>();


  get hasCountOff(): boolean {
    return false;
  }

  get countOffActive(): boolean {
    return this.audioService.countOffActive;
  }

  set countOffActive(active: boolean) {
    this.audioService.countOffActive = active;
  }

  get ariaCountOffButton() {
    if(this.countOffActive) {
      return this.translateService.instant('PLAYER.COUNTOFFOFF');
    }
    return this.translateService.instant('PLAYER.COUNTOFFON');
  }

  constructor() { }

  ngOnInit() {}

  playPause() {
    if(!this.isPlaying) {
      this.play.emit()
    } else {
      this.pause.emit()
    }
  }

  toggleCountOff() {
    this.countOffActive = !this.countOffActive;
    this.audioService.countOffUpdated();
  }

}
