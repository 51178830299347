import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonModal, IonRadioGroup, Platform, SearchbarCustomEvent } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Speed, SpeedDesignation } from 'bandon-shared';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { NoteFontService } from 'src/app/services/sheets/note-font.service';

@Component({
  selector: 'app-tempo-select',
  templateUrl: './tempo-select.component.html',
  styleUrls: ['./tempo-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule
  ],
  animations: [
    trigger('markerDetails', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({ overflow: 'hidden' }),
        animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class TempoSelectComponent  implements OnInit, OnChanges {
  translate = inject(TranslateService)
  platform = inject(Platform)
  notefont = inject(NoteFontService)
  bandonTranslate = inject(BandONTranslationsService)

  @ViewChild(IonModal) modal: IonModal;

  @Input() title = '';
  @Input() data: SpeedDesignation[];
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() value: Speed | undefined = undefined;
  @Input() displayFn: (item: Speed) => { text: string; imageUrl?: string } = (item) => ({
    text: item.speeddesignation?.designation || item.notevalue.toString() || item.speed.toString()
  });
  @Input() ariaFn: (item: Speed) => string = (item) => {
    let tempo = this.displayFn(item).text
    if(this.showBpmInput) {
      tempo = this.bandonTranslate.getNoteValueString(item.notevalue)+'='+item.speed?.toString();
    }
    if(item.speeddesignation) {
      tempo = item.speeddesignation.designation
    }
    return this.title+' '+tempo;
  }


  @Output() selectedChanged: EventEmitter<Speed> = new EventEmitter();

  @ViewChild('radioGroup') radioGroup: IonRadioGroup;

  fixedDesignations: SpeedDesignation[] = [
    { id: -1, designation: "", notevalue: 1, tempo: 120 },
    { id: -2, designation: "", notevalue: 2, tempo: 120 },
    { id: -3, designation: "", notevalue: 4, tempo: 120 },
    { id: -4, designation: "", notevalue: 8, tempo: 120 },
    { id: -5, designation: "", notevalue: 16, tempo: 120 },
  ]

  isIos: boolean;
  isAndroid: boolean;

  isOpen = false;
  selected = [];
  filtered = [];

  showBpmInput = false;
  speed = 0;

  radioGroupValue: SpeedDesignation | undefined = undefined;

  displayDesignationFn: (item: SpeedDesignation) => { text: string; innerHTML: string } = (item) => {
    let out = { text: '', innerHTML: ''};
    if(item.designation) {
      out.text = item.designation
    } else {
      out.innerHTML = this.getNoteChar(item.notevalue)
    }
    return out;
  };
  ariaBpmSelectFn: (item: Speed) => string = (item) => {
    if(item) {
      let speedText = ''
      if(this.speed) {
        speedText = this.speed.toFixed()
      }
      return this.title+' '+this.bandonTranslate.getNoteValueString(item.notevalue)+'='+speedText;
    }
    return this.title;
  }

  constructor() {
    this.title = this.translate.instant('TUNES.TEMPO');
    this.placeholder = this.translate.instant('TUNES.SELECT');
  }

/*  get speed(): number | undefined {
    if(this.value) {
      return this.value.speed;
    }
    return undefined;
  }

  set speed(value: number) {
    if(this.value) {
      this.value.speed = value;
    }
  }*/

  ngOnInit() {
    this.isIos = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');

    this.readValueData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let allDesignations = [...this.data]
    allDesignations.push(...this.fixedDesignations)
    this.filtered = allDesignations;

    this.readValueData();
  }

  readValueData() {
    if(this.value?.speeddesignation) {
      this.showBpmInput = false;
    } else {
      this.showBpmInput = true;
    }
    if(this.value) {
      this.speed = this.value.speed;

      if(this.value.speeddesignation) {
        this.radioGroupValue = this.value.speeddesignation;
      } else {
        this.radioGroupValue = this.fixedDesignations.find(d => d.notevalue==this.value.notevalue)
      }
    }
  }


  open() {
    if(!this.disabled) {
      this.isOpen = true;
    }
  }

  cancel() {
    this.readValueData();
    this.modal.dismiss(null, 'cancel');
  }

  filter(event: SearchbarCustomEvent) {
    const filter = event.detail.value.toLowerCase();
    let allDesignations = [...this.data]
    allDesignations.push(...this.fixedDesignations)
    this.filtered = allDesignations.filter(item => {
      return item.designation.toLowerCase().includes(filter)
//      return true
    });
  }

  compareWith(o1: SpeedDesignation, o2: SpeedDesignation) {
    if(!o1 || !o1 || o1==null || o2==null || typeof o1 != 'object' || typeof o2 != 'object') {
      return false;
    }
    return o1.id === o2.id;
  }

  selectItem(event: any) {
    if(event.detail.value.id<0) {
      this.showBpmInput = true;
    } else {
      this.showBpmInput = false;
    }
  }

  confirm() {
    if(this.radioGroup && this.radioGroup.value) {
      if(this.radioGroup.value.id>0) {
        this.value.speeddesignation = this.radioGroup.value;
        this.value.notevalue = this.radioGroup.value.notevalue;
        this.value.speed = 0;
      } else {
        this.value.notevalue = this.radioGroup.value.notevalue;
        this.value.speed = this.speed;
        this.value.speeddesignation = undefined;
      }
      this.selectedChanged.emit(this.value);
    }
    this.modal.dismiss(this.value, 'confirm');
  }

  getNoteChar(notevalue: number): string {
    const noteChar = this.notefont.getCharToNoteValue(notevalue);
    return `<span class="note-font">${noteChar}</span> = ${this.speed}`;
  }

}
