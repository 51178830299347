<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ title }}</ion-title>
    @if (showCloseBtn) {
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal('cancel', undefined)">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="false">
  <ng-content />
</ion-content>

<ion-footer>
  <div class="ion-margin-horizontal">
    <app-horizontal-divider></app-horizontal-divider>
  </div>
  <div class="ion-margin-horizontal">
    <app-subscribe-buttons [selectedPlan]="selectedPlan" (subscriptionPurchased)="onPurchase()"></app-subscribe-buttons>
  </div>

  <ng-content select="footer"></ng-content>
</ion-footer>
