<div class="zoom-container" #zoomContainer>
  <div class="timeline">
    @for (label of timeLabels; track $index) {
      <div class="timeline-label"
        [style.left.%]="(label.time / duration) * 100"
        [style.width.%]="(label.width / duration) * 100"
      >
        {{ label.label }}
      </div>

    }

    <!-- Loop to display time ticks at regular intervals -->
    @for (tick of timeTicks; track $index) {
      <div class="timeline-tick"
        [style.left.%]="(tick / duration) * 100"
      >
        <!-- Optional: You can display the time on the tick as well -->
        <!-- {{ tick }} -->
      </div>
    }

    @if(shownRegion) {
      <div class="region-overlay"
        [style.left.%] = "(shownRegion.lower / duration) * 100"
        [style.width.%] = "(shownRegion.upper - shownRegion.lower) / duration * 100"
      ></div>
    }
  </div>
</div>
