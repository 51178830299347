import { inject } from '@angular/core';
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { AudioService } from 'src/app/services/audio/audio.service';
import { RangeCustomEvent, IonicModule } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'bandon-shared';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
    selector: 'app-recording-item',
    templateUrl: './recording-item.component.html',
    styleUrls: ['./recording-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        CachedImageComponent,
        NgIf,
        FormsModule,
        TranslateModule
    ],
})
export class RecordingItemComponent  implements OnInit {
  private audioService = inject(AudioService);
  private authService = inject(AuthenticationService);
  private userDataService = inject(UserDataService);

  isSeeking = false;
  currentTimeToSend = 0.0;
  canShare = false;

  private jumpToTime: number;
  private changeTimeMode = false;

  constructor() { }

  get recordPosition(): number {
/*    if (this.instrMuted) {
      return 0;
    }*/
    return 0;
  }

  set recordPosition(value) {
//    this.track.instrVolume = value;
//    this.audioService.setTrackVolume(this.track);
  }

  get serverUrl(): string {
    return environment.apiURL;
  }

  get instrImgPath(): string {
    return this.serverUrl+'/instrImg/Instrument_Gesang.svg';
  }

  get instImgSrc(): CachedImgSource {
    const token = 'Bearer '+environment.apiKey;
    return { url: this.instrImgPath, path: this.instrImgPath, token };
  }

  get playState(): boolean {
    return this.audioService.recPlayState;
  }

  get currentTime(): number {
    if(this.changeTimeMode) {
      return this.currentTimeToSend;
    }
    return this.audioService.currentRecPlayTime;
  }

  set currentTime(time: number) {
    this.currentTimeToSend = time;
  }

  get duration(): number {
    return this.audioService.recPlayDuration;
  }

  get designation(): string {
    if (this.userDataService.user) {
      return this.userDataService.user.name;
    }
    return '';
  }

  get isWeb() {
  //    return true;
      return Capacitor.getPlatform() === 'web';
    }

  ngOnInit() {
    Share.canShare().then(result => {
      this.canShare = result.value;
    });
  }

  togglePlay() {
    if(!this.playState) {
      this.audioService.playRecording();
    } else {
      this.audioService.pauseRecordingPlayer();
    }
  }

  startJump(ev: Event) {
    this.changeTimeMode = true;
    this.setCurrentTime(ev);
  }

  setCurrentTime(ev: Event) {
    this.currentTime = Number((ev as RangeCustomEvent).detail.value);
  }

  async jumpTo(ev: Event) {
    if(this.isSeeking) {
      return;
    }
    this.isSeeking = true;
    await this.audioService.recPlayerJumpTo(this.currentTimeToSend);
    this.changeTimeMode = false;
    this.isSeeking = false;
  }

  share() {
    this.audioService.generateRecordingDownload().then( async url => {
      Filesystem.getUri({path: url, directory: Directory.Data}).then( uri => {
        const shareData = {
          url: uri.uri,
        };

        try {
          Share.share(shareData);
        } catch (error) {
          console.error('Sharing failed', error);
        }
      });
    });
  }

  download() {
    this.audioService.generateRecordingDownload().then( url => {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'band-on_Recording.wav';
      downloadLink.target = '_blank';

      downloadLink.click();
    });
  }
}
