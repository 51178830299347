import { Injectable } from '@angular/core';

export interface NoteCharacter {
  notevalue: number;
  char: string;
}

const noteChars: NoteCharacter[] = [
  { notevalue: 1, char: '𝅗'},
  { notevalue: 2, char: '𝅗𝅥'},
  { notevalue: 4, char: '𝅘𝅥'},
  { notevalue: 8, char: '𝅘𝅥𝅮'},
  { notevalue: 16, char: '𝅘𝅥𝅯'},
]

@Injectable({
  providedIn: 'root'
})
export class NoteFontService {

  constructor() { }

  getCharToNoteValue(notevalue: number): string | undefined {
    return noteChars.find(c => c.notevalue===notevalue)?.char;
  }
}
