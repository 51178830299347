import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AudioService } from 'src/app/services/audio/audio.service';
import { ArrangementShowPart, Part, Tune } from 'bandon-shared';
import { ArrangementPartComponent, LoopBeatDef, LoopTimesDef } from './arrangement-part/arrangement-part.component';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { NgFor } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-arrangement-view',
    templateUrl: './arrangement-view.component.html',
    styleUrls: ['./arrangement-view.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgFor,
        ArrangementPartComponent,
    ],
})
export class ArrangementViewComponent  implements OnInit, OnChanges, OnDestroy {

  @Input() tune: Tune = null;
  @Input() arrangementDiv: HTMLDivElement = null;
  @Input() currentPart: ArrangementShowPart;

  parts: ArrangementShowPart[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private audioService: AudioService,
    private tuneInfoService: TuneInfoService
  ) { }

/*  get parts(): ArrangementShowPart[] {
    if(this.tuneInfoService.shownParts) {
      return this.tuneInfoService.shownParts;
    }
    return [];
  }*/

/*  get currentPart(): Part {
    return this.audioService.currentPart;
  }*/

  ngOnInit() {
    this.tuneInfoService.shownParts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(p => {
        console.log('Arr: shown parts updated', p.length)
        this.parts = p
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.arrangementDiv && this.currentPart) {
      const elementToScrollTo = this.arrangementDiv.querySelector(`#${this.getPartID(this.currentPart)}`) as HTMLElement;

      if (elementToScrollTo && elementToScrollTo.offsetTop) {
        const offsetTop = elementToScrollTo.offsetTop;
        const elementHeight = elementToScrollTo.clientHeight;
        const parentScrollTop = this.arrangementDiv.scrollTop;
        const parentHeight = this.arrangementDiv.clientHeight;

        if (offsetTop < parentScrollTop || offsetTop + elementHeight > parentScrollTop + parentHeight) {
          // The element is outside the current view, so scroll to it
          this.arrangementDiv.scrollTo({
            top: elementToScrollTo.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  setLoopStartPart(event: any, part: ArrangementShowPart) {
    if(event) {

      //Find Loop End
      let endLoopPart = this.tuneInfoService.endLoopPart;
      if(!endLoopPart) {
        endLoopPart = this.parts[this.parts.length-1];
      } else if(this.parts.indexOf(endLoopPart)<this.parts.indexOf(part)) {
        endLoopPart = this.parts[this.parts.length-1];
      }

      this.tuneInfoService.setLoopParts(part, endLoopPart);
    } else {
      this.tuneInfoService.resetLoop();
    }
  }

  setLoopEndPart(event: any, part: ArrangementShowPart) {
    if(event) {
      //Find Loop Start
      let startLoopPart = this.tuneInfoService.startLoopPart;
      if(!startLoopPart) {
        startLoopPart = this.parts[0];
      } else if(this.parts.indexOf(startLoopPart)>this.parts.indexOf(part)) {
        startLoopPart = this.parts[0];
      }

      this.tuneInfoService.setLoopParts(startLoopPart, part);
    } else {
      this.tuneInfoService.resetLoop();
    }
  }

  setLoopTime(event: LoopTimesDef) {
    if(event) {
      let startTime = event.startTime;
      let endTime = event.endTime;
      if(event.endTime===-1) {
        //Start Loop Definition
        let endLoopPart = this.tuneInfoService.endLoopPart;
        if(!endLoopPart) {
          endLoopPart = this.parts[this.parts.length-1];
        }
        endTime = this.tuneInfoService.getLoopEndTime(endLoopPart);
      } if(event.startTime===-1) {
        //End Loop Definition
        let startLoopPart = this.tuneInfoService.startLoopPart;
        if(!startLoopPart) {
          startLoopPart = this.parts[0];
        }
        startTime = this.tuneInfoService.getLoopStartTime(startLoopPart);
      }
      if((startTime || startTime==0) && (endTime || endTime==0)) {
        this.tuneInfoService.setLoopTimes(startTime, endTime);
      }
    }
  }

  isStartLoopPart(part: ArrangementShowPart): boolean {
    return part===this.tuneInfoService.startLoopPart;
  }

  isEndLoopPart(part: ArrangementShowPart): boolean {
    return part===this.tuneInfoService.endLoopPart;
  }

  jumpToPart(part: ArrangementShowPart) {
    this.tuneInfoService.jumpToPart(part, true, false, true);
  }

  getPartID(part: ArrangementShowPart) {
    if(part) {
      return `ArrangementPartCard${part.id}`;
    }
    return '';
  }
}
