/* eslint-disable no-underscore-dangle */
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, InfiniteScrollCustomEvent, ModalController, IonicModule } from '@ionic/angular';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService, Playlist, SimpleTuneModel } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { LibraryService } from 'src/app/services/library.service';
import { TuneItemComponent } from '../../general/tune-item/tune-item.component';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
    selector: 'app-add-to-playlist',
    templateUrl: './add-to-playlist.component.html',
    styleUrls: ['./add-to-playlist.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        FormsModule,
        NgFor,
        TuneItemComponent,
        TranslateModule,
    ],
})
export class AddToPlaylistComponent  implements OnInit, OnDestroy {

  @Input() playlist: Playlist;

  private modalController = inject(ModalController)
  private libraryService = inject(LibraryService)
  private alertController = inject(AlertController)
  private translateService = inject(TranslateService)
  private authService = inject(AuthenticationService)
  private userDataService = inject(UserDataService)

  isAuth = false;

  lastPlayed: SimpleTuneModel[] = [];

  tunes: SimpleTuneModel[] = [];
  _searchedTunes: SimpleTuneModel[] = [];
  searchTerm: string;
  _shownTunes: SimpleTuneModel[] = [];

  userTunes: SimpleTuneModel[] = [];

  addedTunes: SimpleTuneModel[] = [];

  private page = 1;
  private loadTunes = 10;
  private scrollTarget: any;

  private unsubscribe$ = new Subject<void>();

  constructor() { }

  get plDesignation(): string {
    if(this.playlist) {
      return this.playlist.designation;
    }
    return '';
  }

  get searchedTunes(): Array<SimpleTuneModel> {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedTunes;
    }
    return this.lastPlayed;
  }

  get shownTunes(): SimpleTuneModel[] {
    if(this._shownTunes && this.searchedTunes) {
      if(this._shownTunes.length===0 && this.searchedTunes.length>0) {
        this.loadMoreTunes(null);
      }
    }
    return this._shownTunes;
  }


  ngOnInit() {
    this.libraryService.getTunes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((tunes) => this.tunes = tunes);

    this.authService.isAuthenticated
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( auth => {
      this.isAuth=auth;
//        console.log('isAuth: ', auth, this.isAuth);
    });
    this.authService.checkAuthenticated(true);

    this.libraryService.userTunesBehaviour
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      this.filterUserTunes();
    });

    this.userDataService.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( user => {
      this.updateLastPlayed();
    });

    this.libraryService.collections$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( () => {
      this.updateLastPlayed();
    });

    this.libraryService.tunes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( () => {
      this.updateLastPlayed();
    });

    this.libraryService.userTunes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( () => {
      this.updateLastPlayed();
    });

    this.libraryService.userCollections$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( () => {
      this.updateLastPlayed();
    });

  }

  ngOnDestroy(): void {

  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  done() {
    this.modalController.dismiss(this.addedTunes, 'done');
  }

  async addToPlaylist(tune: SimpleTuneModel) {
    if(this.checkIfTuneIsInPlaylist(tune)) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('PLAYLISTS.ALREADYADDED', { designation: this.plDesignation}),
        cssClass: 'band-on-alert',
        buttons: [
          {
            text: this.translateService.instant('PLAYLISTS.ADDAGAIN'),
            role: 'confirm'
          },
          {
            text: this.translateService.instant('PLAYLISTS.CANCEL'),
            role: 'cancel'
          }
        ],
      });

      await alert.present();

      const { role } = await alert.onWillDismiss();

      if (role === 'confirm') {
        this.addedTunes.push(tune);
      }
    } else {
      this.addedTunes.push(tune);
    }
  }

  checkIfTuneIsInPlaylist(tune: SimpleTuneModel): boolean {
    if(this.playlist && this.playlist.tunes) {
      return this.playlist.tunes.filter(t => t.tune_id===tune.id).length > 0 ||
        this.addedTunes.includes(tune);
    }
    return false;
  }

  filterUserTunes() {
    const userTunes = this.libraryService.userTunesBehaviour.getValue();
    this.libraryService.demoTunes.forEach(t => userTunes.push(t.id));
    this.userTunes = this.tunes.filter(t => userTunes.includes(t.id));
  }

  searchTune(event) {
    const text = this.searchTerm; //event.target.value;

    this._searchedTunes.length = 0;

    this._searchedTunes.push(...this.userTunes);

    this._searchedTunes = this.libraryService.filterTunes(this._searchedTunes, text);

    this.page = 1;
    this._shownTunes = [];
    if(this.scrollTarget) {
      this.scrollTarget.disabled = false;
    }

  }

  updateLastPlayed() {
    this.lastPlayed = [];
    if(this.isAuth && this.userDataService.user) {
      const userTunes = this.libraryService.userTunesBehaviour.getValue();
      this.libraryService.demoTunes.forEach(t => userTunes.push(t.id));

      for ( const t of this.userDataService.user.playerhistory ) {
        if (this.lastPlayed.filter(e => e.id===t.tuneid).length===0 && userTunes.includes(t.tuneid)) {
          const tune = this.libraryService.getSimpleTune(t.tuneid);
          if (tune) {
            this.lastPlayed.push(tune);
          }
        }
      }
    } else {
      this.lastPlayed.push(...this.libraryService.demoTunes);
    }
  }

  getTunes(page?: number, size?: number): SimpleTuneModel[] {
    let tunes = [];


    if (page!=null && size!=null) {
      tunes = this.searchedTunes.slice((page - 1) * size, ((page - 1) * size) + size);
    } else {
      tunes = this.searchedTunes;
    }

    return tunes;
  }

  loadMoreTunes(event) {
    const maxPage = Math.ceil(this.searchedTunes.length/this.loadTunes);

    this._shownTunes = this._shownTunes.concat(this.getTunes(this.page, this.loadTunes));

    if(event) {
      event.target.complete();

      if (this.page===maxPage) {
        event.target.disabled = true;
        this.scrollTarget = event.target;
      }
    }

    this.page++;
  }

}
