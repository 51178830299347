<ion-img
  src="assets/keyvisuals/chor_2_Metronom.jpg"
  alt="{{'PAYWALL.ARIAIMG' | translate}}"
></ion-img>
<ion-grid class="ion-margin-horizontal">
  <ion-row>
    <ion-col size="12">
    </ion-col>
  </ion-row>

  <ion-row class="ion-text-center">
    <ion-col size="12">
      <!--<p>Mit unserem Ensemble Abo kannst du selbst Aufnahmen für deine Band/Ensemble/Chor in band-on laden und mit den Mitgliedern teilen.</p>-->
      <p class="ion-no-margin">{{ 'PAYWALL.HOWBIG' | translate }}</p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12">
      <app-size-selector (onSizeSelect)="selectSize($event)"></app-size-selector>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12">
      <ul>
        <li>{{ 'PAYWALL.MANAGEGROUP' | translate: { memberlimit: selectedPlan?.membercount } }}</li>
        <li>{{'PAYWALL.UPLOADTUNES' | translate: { tunelimit: selectedPlan?.tunecount} }}</li>
        <li>{{'PAYWALL.SHAREMUSIC' | translate}}</li>
        <li>{{'PAYWALL.USEALLFUNCS' | translate}}</li>
      </ul>
    </ion-col>
    @if(isEligible) {
      <ion-col size="12" class="ion-text-center">
        {{'PAYWALL.FREETEST' | translate}}
      </ion-col>
    }
    @if(showIsBigger) {
      <ion-col size="12" class="ion-text-center">
        <p>
          {{'PAYWALL.ISBIGGER' | translate}}<br>
          {{'PAYWALL.CONTACTUS' | translate}}
        </p>
      </ion-col>
    }
  </ion-row>
</ion-grid>
