import { inject, Injectable } from '@angular/core';
import { AuthenticationService, CustomerSubscriptionInformation, GroupUser, RenewalState, SubscriptionItem, SubscriptionPlan, SubscriptionStatus, UserGroup, UserModel } from 'bandon-shared';
import { UserDataService } from './user-data.service';
import { GroupsService } from '../social/groups.service';
import { SubscriptionPlansService } from '../store/subscription-plans.service';
import { CreatorWelcomeComponent } from 'src/app/pages/modal/subscription/creator-welcome/creator-welcome.component';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject, filter, lastValueFrom, take } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GroupDecreaseComponent } from 'src/app/pages/modal/subscription/group-decrease/group-decrease.component';
import { GroupDeleteComponent } from 'src/app/pages/modal/subscription/group-delete/group-delete.component';
import { DatePipe } from '@angular/common';
import { LanguageService } from '../languages/language.service';
import { StoreService } from '../store/store.service';
import { Capacitor } from '@capacitor/core';

const CONTENT_CREATOR_ROLE_ID = 7

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService {
  private authService = inject(AuthenticationService)
  private userDataService = inject(UserDataService)
  private groupsService = inject(GroupsService)
  private subscriptionPlanService = inject(SubscriptionPlansService)
  private modalController = inject(ModalController)
  private httpClient = inject(HttpClient)
  private loadingController = inject(LoadingController)
  private translateService = inject(TranslateService)
  private alertController = inject(AlertController)
  private languageService = inject(LanguageService)
  private storeService = inject(StoreService)

  savingMessage: HTMLIonLoadingElement | undefined;

  showDelete = false;
  deleteMessage: HTMLIonModalElement | undefined;

  showDecrease = false;

  isCreatingGroup = false;

  current_subscription: BehaviorSubject<SubscriptionItem> = new BehaviorSubject(undefined);
  current_subscripition$ = this.current_subscription.asObservable();

  current_plan: SubscriptionPlan | undefined = null;
  tunelimit = 0;

  constructor() {
/*    this.groupsService.groups$
      .pipe(
        filter(groups => groups.length>0),
        take(1)
      )
      .subscribe(groups => this.decreaseGroupSize(1, 0))*/
    this.userDataService.user$
      .subscribe(user => {
        console.log('CheckSubscriptionStatus', user?.subscriptionid)
        this.checkSubscriptionStatus(user?.subscription, this.storeService.selectedPlan.value);
      })

    this.storeService.selectedPlan$
      .subscribe(plan => {
        console.log('CheckSubscriptionStatus')
        this.checkSubscriptionStatus(this.userDataService.user?.subscription, plan);
      })

    this.storeService.customerInfoUpdate$
      .subscribe(() => this.updateSubscriptionInfo())
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }

  checkSubscriptionStatus(userPlan: SubscriptionPlan | undefined, storePlan: SubscriptionPlan | undefined) {
    console.log('Check Subscription Status', userPlan, storePlan)
    if(userPlan && !this.storeService.selectedPlanInitialized) {
      if(this.userDataService.user?.subscriptiontype=='band-on') {
        this.setCurrentPlan(userPlan, this.userDataService.user.customtunelimit)
      } else {
        this.setCurrentPlan(userPlan, userPlan.tunecount)
      }
    } else if(userPlan && storePlan) {
      if(userPlan.id==storePlan.id) {
        this.setCurrentPlan(storePlan, storePlan.tunecount)
      } else {
        //TODO: error?
      }
    } else if(!userPlan && storePlan) {
      this.setCurrentPlan(storePlan, storePlan.tunecount)
    } else if(userPlan && !storePlan) {
      if(this.userDataService.user?.subscriptiontype=='band-on') {
        this.setCurrentPlan(userPlan, this.userDataService.user.customtunelimit)
      } else {
        this.setCurrentPlan(undefined, 0)
      }
    } else {
      this.setCurrentPlan(undefined, 0)
    }
  }

  setCurrentPlan(plan: SubscriptionPlan | undefined, tunelimit: number) {
//    console.log('setCurrentPlan', plan, tunelimit)
    this.setTuneLimit(tunelimit)
    if((plan!=undefined && plan.id!==this.current_plan?.id) || (plan==undefined && this.current_plan!==undefined)) {
      this.current_plan = plan;

      if(this.groupsService.isInitialized.value) {
        this.checkUserSubscription(this.current_plan)
      } else {
        this.groupsService.isInitialized$
        .pipe(
          filter(value => value),
          take(1)
        )
        .subscribe(() => this.checkUserSubscription(this.current_plan))
      }
    }
  }

  setTuneLimit(limit: number) {
    this.tunelimit = limit;
  }

  async checkUserSubscription(plan: SubscriptionPlan | undefined) {
    if(this.userDataService.user) {
//      console.log('CheckUserSubscription', plan, this.userDataService.user.email)
      const existingGroup = this.userOwnsGroup();
      if(plan && this.groupsService.isInitialized.value) {
        await this.checkOrAddContentCreatorRole(this.userDataService.user)
        if(existingGroup==undefined) {
          console.log(`User has plan, create group`);
          this.createGroup(plan.membercount, plan.tunecount);
        } else if(existingGroup.userlimit < plan.membercount && this.userDataService.user.subscriptiontype!='band-on') {
          this.increaseGroupSize(plan.membercount, plan.tunecount);
        } else if(existingGroup.userlimit > plan.membercount && this.userDataService.user.subscriptiontype!='band-on') {
          this.decreaseGroupSize(plan.membercount, plan.tunecount);
        }
        console.log(`User has plan ${plan.designation}`, existingGroup);
      } else if(this.userDataService.user.subscriptiontype!='band-on'){
        console.log(`User has no plan`);
        this.prepareDataDeletion();
      }
      this.updateSubscriptionInfo();
    }
  }

  userOwnsGroup(): UserGroup | undefined {
    if(this.userDataService.user) {
      const group = this.groupsService.ownsGroup(this.userDataService.user);
      return group;
    }
    return undefined;
  }

  async checkOrAddContentCreatorRole(user: UserModel) {
    if(!this.checkContentCreatorRole(user)) {
      await this.userDataService.setRole(CONTENT_CREATOR_ROLE_ID, true)
    }
  }

  async removeContentCreatorRole(user: UserModel) {
    if(this.checkContentCreatorRole(user)) {
      await this.userDataService.setRole(CONTENT_CREATOR_ROLE_ID, false)
    }
  }

  checkContentCreatorRole(user: UserModel) {
    if(user.roles.find(r => r.id == CONTENT_CREATOR_ROLE_ID))
      return true;
    return false;
  }

  createGroup(memberlimit: number, tunelimit: number) {
    if(this.isCreatingGroup) {
      return;
    }
    this.isCreatingGroup = true;
    const existingGroup = this.userOwnsGroup();
    if(existingGroup) {
      console.log('User already owns a group')
    } else {
      const token = this.authService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);

      let group: UserGroup = { id: -1, designation: '', description: '', identifier: '', users: [] , tunelimit, userlimit: memberlimit}
      let owner: GroupUser = { user: this.userDataService.user, role: { id: 2, designation: 'Owner' } }
      group.users.push(owner)

      const formData = new FormData();
      formData.append('group', JSON.stringify(group));
      this.httpClient.put<UserGroup>(environment.apiURL+`/usergroups`, formData, {headers})
      .subscribe({
        next: resp => {
          group = resp;
          this.openCreatorWelcome(group)
        },
        error: err => {
          this.isCreatingGroup = false;
          console.log(err)
        }
      });
    }
  }

  increaseGroupSize(membercount: number, tunecount: number) {
    const existingGroup = this.userOwnsGroup();
    if(!existingGroup) {
      console.log('User doesn\'t own a group')
      //TODO: exception, create group?
      return;
    }

    existingGroup.userlimit = membercount;
    existingGroup.tunelimit = tunecount;
    this.saveGroup(existingGroup);

    this.alertController.create({
      header: this.translateService.instant('GROUPS.THANKS'),
      message: this.translateService.instant('GROUPS.ALERTINCREASE', { userlimit: membercount, tunelimit: tunecount }),
      buttons: [ this.translateService.instant('GROUPS.OK') ],
      backdropDismiss: true
    })
    .then(alert => alert.present());
  }

  async decreaseGroupSize(membercount: number, tunecount: number) {
    const existingGroup = this.userOwnsGroup();
    if(!existingGroup) {
      console.log('User doesn\'t own a group')
      //TODO: exception?
      return;
    }

    if(existingGroup.users?.length>membercount) {
      await this.openGroupReduction(existingGroup, membercount, tunecount)
    } else {
      this.alertController.create({
        header: this.translateService.instant('SUBSCRIPTIONMODALS.INFO'),
        message: this.translateService.instant('SUBSCRIPTIONMODALS.GROUPREDUCED'),
        buttons: [ this.translateService.instant('GROUPS.OK') ],
        backdropDismiss: true
      })
      .then(alert => alert.present());
    }

    existingGroup.userlimit = membercount;
    existingGroup.tunelimit = tunecount;
    this.saveGroup(existingGroup);

  }

  prepareDataDeletion() {
    const existingGroup = this.userOwnsGroup();
    if(existingGroup) {
      this.showGroupDelete(existingGroup);
    } else {
      this.removeContentCreatorRole(this.userDataService.user)
      this.userDataService.user.subscriptionid = 0
      this.userDataService.updateUserData();
    }
  }

  async openGroupReduction(group: UserGroup, memberlimit: number, tunelimit: number) {
    if(this.showDecrease) {
      return;
    }
    this.showDecrease = true;

    const modal = await this.modalController.create({
      component: GroupDecreaseComponent,
      componentProps: {
        group,
        newMemberLimit: memberlimit,
        newTuneLimit: tunelimit
      },
      backdropDismiss: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

/*    if (role === 'confirm') {
      this.message = `Hello, ${data}!`;
    }*/

    this.showDecrease = false;
  }

  async openCreatorWelcome(group: UserGroup) {
    const modal = await this.modalController.create({
      component: CreatorWelcomeComponent,
      componentProps: { group },
      backdropDismiss: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    this.isCreatingGroup = false;


/*    if (role === 'confirm') {
      this.message = `Hello, ${data}!`;
    }*/
  }

  async saveGroup(group: UserGroup) {
    await this.showSaving();
    const formData = new FormData();

    formData.append('group', JSON.stringify(group));

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);

    this.httpClient.post<UserGroup>(environment.apiURL+`/usergroups/${group.id}`, formData, {headers})
    .pipe(take(1))
    .subscribe({
      next: resp => {
        this.groupsService.refreshGroups(true);
        this.hideSaving();
      },
      error: err => {
        this.hideSaving();
        console.error(err)
      }
    });
  }

  async showGroupDelete(group: UserGroup) {
    if(this.showDelete) {
      return;
    }
    this.showDelete = true;

    this.deleteMessage = await this.modalController.create({
      component: GroupDeleteComponent,
      componentProps: { group },
      backdropDismiss: false
    });
    this.deleteMessage.present();

    const { data, role } = await this.deleteMessage.onWillDismiss();

    if (role === 'deleted') {
      await this.removeContentCreatorRole(this.userDataService.user)
      this.userDataService.user.subscriptionid = 0
      this.userDataService.updateUserData();
    }

    this.deleteMessage = undefined;
    this.showDelete = false;
  }

  async showSaving() {
    this.savingMessage = await this.loadingController.create({
      message: this.translateService.instant('GROUPS.SAVING'),
      cssClass: 'band-on-loading'
    });

    this.savingMessage.present();
  }

  hideSaving() {
    this.savingMessage.dismiss();
  }

  async updateSubscriptionInfo() {
    if(this.userDataService.user) {
      let subscriptions: SubscriptionItem[] = [];
      await this.fetchSubscriptions(subscriptions)

      let subscription = subscriptions
        .sort((a: any, b: any) => new Date(b.current_period_ends_at).getTime() - new Date(a.current_period_ends_at).getTime())[0];
      console.log('updateSubscriptionInfo', subscription)

      if(subscription && this.current_subscription && subscription.auto_renewal_status==RenewalState.WILL_NOT_RENEW && subscription.status==SubscriptionStatus.ACTIVE)
      {
        const current_date = new Date()
        const fiveDaysLater = new Date();
        fiveDaysLater.setDate(current_date.getDate() + environment.subscriptionWarnDays);

        let expiry_date = new Date(subscription.current_period_ends_at)
        if(!this.userDataService.user.showedexpiryalert && expiry_date > current_date && expiry_date <= fiveDaysLater) {
          this.userDataService.user.showedexpiryalert = true;
          //show expiry message
          this.alertController.create({
            header: this.translateService.instant('SUBSCRIPTIONMODALS.WILLEXPIRE', { expiry_date: this.formatDate(subscription.current_period_ends_at)}),
            message: this.translateService.instant('SUBSCRIPTIONMODALS.WILLEXPIRE2'),
            buttons: [this.translateService.instant('SUBSCRIPTIONMODALS.OK')],
          }).then(alert => alert.present())

          this.userDataService.updateUserData();
        }
      } else if (subscription && this.current_subscription && subscription.auto_renewal_status==RenewalState.WILL_RENEW && this.userDataService.user.showedexpiryalert) {
        this.userDataService.user.showedexpiryalert = false;
        this.userDataService.updateUserData();
      }
      this.current_subscription.next(subscription);
    } else {
      this.current_subscription.next(undefined);
    }
  }

  async fetchSubscriptions(subscriptions: any[], page: string | undefined = undefined) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.revenueCatAPIKey);

    let url = `https://api.revenuecat.com/v2/projects/${environment.revenueCatProjectID}/customers/${this.userDataService.user.uid}/subscriptions`
    if(page) {
      url = page
    }
    await lastValueFrom(
    this.httpClient.get<CustomerSubscriptionInformation>(url, {headers})
    )
    .then(async resp => {
        subscriptions.push(...resp.items)
        if(resp.next_page) {
          await this.fetchSubscriptions(subscriptions, resp.next_page)
        }
    })
    .catch(err => {
      console.error(err)
    });
  }

  formatDate(timestamp: string | number): string {
    const messageDate = new Date(timestamp);
    const datePipe = new DatePipe(this.languageService.selected || 'en')
    let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Display date if it's not today
    return datePipe.transform(messageDate, 'shortDate', currentTimezone);
  }
}
