import { AudioEngine } from '@musicdose/audioengine';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TuneSku } from 'src/app/shared/interfaces/tune-sku';
import { ModalController, createAnimation, IonicModule } from '@ionic/angular';
import { SafeUrl } from '@angular/platform-browser';
import { TuneDetailsComponent } from '../tune-details/tune-details.component';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { AuthenticationService, SimpleTuneModel } from 'bandon-shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { Package } from '@revenuecat/purchases-js';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-store-tune-item',
    templateUrl: './store-tune-item.component.html',
    styleUrls: ['./store-tune-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        CachedImageComponent,
        NgIf,
        NgCircleProgressModule,
        DecimalPipe,
        CurrencyPipe,
        TranslateModule,
    ],
})
export class StoreTuneItemComponent  implements OnInit, OnChanges, OnDestroy {

  @Input() tuneSku: TuneSku;
  @Input() tune: SimpleTuneModel;
  @Input() showDisabled = false;
  @Input() showRemove = false;
  @Input() isReordable = false;

  @Output() removeTune: EventEmitter<SimpleTuneModel> = new EventEmitter<SimpleTuneModel>();

  @ViewChild('tuneCover', { read: ElementRef }) coverElement!: ElementRef;
  @ViewChild('tunePreview', { read: ElementRef }) previewElement!: ElementRef;

  showCover = true;
  previewProgress = 0;
  previewDuration = 0;
  isPreviewPlayling = false;

  public previewURL: SafeUrl = '';

  private unsubscribe$ = new Subject<void>();
  private coverTurning = false;
  private timer: any;
  private isAuth = false;

  constructor(
    private storeService: StoreService,
    private authService: AuthenticationService,
    private modalController: ModalController,
    private bandonTranslations: BandONTranslationsService,
    private connService: ConnectivityService,
    private translate: TranslateService,
  ) { }

  get tuneImgSrc(): CachedImgSource {
    if(this.tune && this.tune.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+this.tune.picture.id, path: this.tunePath, token };
    }
    return { url: '', path: '', token: '' };
  }

  get tunePath(): string {
    if(this.tune && this.tune.picture) {
      return this.tune.picture.path;
    }
    return '';
  }

  get title(): string {
    if(this.tune && this.tune) {
      return this.bandonTranslations.getTuneTitle(this.tune);
    }
    return '';
  }

  get description(): string {
    if (this.tune) {
      return this.tune.tonalities+', '+this.tune.speeds;
    }
    return '';
  }

  get sku(): PurchasesPackage | Package {
    if(this.tuneSku) {
      return this.tuneSku.sku;
    }
    return undefined;
  }

  get price(): number {
    if(this.sku) {
      if(this.isWeb) {
        let sku = this.sku as Package;
        return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
      } else {
        let sku = this.sku as PurchasesPackage;
        return sku.product.price;
      }
    }
    return 0;
  }

  get currencyCode(): string {
    if(this.sku) {
      if(this.isWeb) {
        let sku = this.sku as Package;
        return sku.rcBillingProduct.currentPrice.currency;
      } else {
        let sku = this.sku as PurchasesPackage;
        return sku.product.currencyCode;
      }
    }
    return '';
  }

  get titleStyle(): string {
    if(this.showDisabled && (this.tuneSku && !this.tuneSku.isPurchased)) {
      return 'gray-title';
    }
    return '';
  }

  get tunePreviewControlsID(): string {
    return 'storeTunePreviewControls'+this.tuneSku.tune.id;
  }

  get hasPreview(): boolean {
    if(this.tuneSku && this.tuneSku.tune.previewpath) {
      return true;
    }
    return false;
  }

  get isOnline(): boolean {
    return this.connService.isConnected();
  }

  get ariaText(): string {
    if(this.tune) {
      return this.bandonTranslations.getTuneDescription(this.tune);
    }
    return this.translate.instant('PLAYER.NOINFORMATION');
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }


  ngOnInit() {
    this.timer = setInterval(() => {
      if (!this.showCover && this.hasPreview) {
        AudioEngine.getPreviewProgress().then(res => {
          if(res.tuneID===this.tuneSku.tune.id) {
            this.previewProgress = res.value;
          } else {
            this.previewProgress = 0;
          }
        });
      } else {
        this.previewProgress = 0;
      }
    }, 100);

    AudioEngine.addListener('previewStopped', res => {
      if(!this.showCover && res.tuneID===this.tuneSku.tune.id) {
        this.togglePreview(false);
        this.isPreviewPlayling = false;
      }
    });

    this.authService.isAuthenticated
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(auth => this.isAuth = auth);

  }

  async ngOnChanges(_changes: SimpleChanges) {
    //Load preview
    if(this.tuneSku && this.tuneSku.tune && this.tuneSku.tune.previewpath) {
    }
/*    if(this.tuneSku.sku && this.tunSkiusku.extravars.tuneid) {
      (await this.libraryService.loadTuneFromServer(Number(this.sku.extravars.tuneid), false))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(tune => {
          this.tune = tune;
        });
    }*/

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    AudioEngine.stopPreview();
    clearInterval(this.timer);
  }

  purchase() {
    this.storeService.purchaseTune(this.tuneSku);
  }

  triggerRemove() {
    this.removeTune.emit(this.tune);
  }


  async showTuneDetails() {
    if(!this.isPreviewPlayling) {
      this.togglePreview();
    }
    const modal = await this.modalController.create({
      component: TuneDetailsComponent,
      componentProps: {
        tune: this.tune,
        tuneSku: this.tuneSku
      },
      breakpoints: [0.25, 0.5, 1.0],
      initialBreakpoint: 1.0
    });
    modal.present();

    modal.onDidDismiss().then(() => {
      if(!this.showCover) {
        this.togglePreview();
      }
      this.stopPreview();
    });

/*    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.message = `Hello, ${data}!`;
    }*/
  }

  async togglePreview(stopAudio = true) {
    if(this.coverTurning) {
      return;
    }
    this.coverTurning = true;
    if(this.showCover && this.hasPreview) {
      await this.switch(this.coverElement, this.previewElement);

      this.startPreview();
    } else if(this.hasPreview) {
      await this.switch(this.previewElement, this.coverElement);
      if(stopAudio) {
        this.stopPreview();
      }
      this.previewProgress = 0;
    }
    this.coverTurning = false;
  }

  async switch(elementToHide: ElementRef, elementToShow: ElementRef) {
    const animation = createAnimation()
    .duration(250)
    .iterations(1)
    .addElement(elementToHide.nativeElement)
    .fromTo('transform', 'scaleX(100%)', 'scaleX(0)');

    const animation2 = createAnimation()
    .addElement(elementToShow.nativeElement)
    .duration(250)
    .iterations(1)
    .beforeStyles({
      transform: 'scaleX(0)'
    })
    .fromTo('transform', 'scaleX(0)', 'scaleX(100%)');

    await animation.play();
    this.showCover = !this.showCover;


    await animation2.play();
  }

  startPreview() {
    let token = environment.apiKey;
    if (this.isAuth) {
      token = this.authService.getToken();
    }

    AudioEngine.playPreview({tuneID: this.tuneSku.tune.id, accessToken: token})
    .then(value => {
      this.previewDuration = value.duration;
    });
    this.isPreviewPlayling = true;
  }

  stopPreview() {
    AudioEngine.stopPreview();
    this.isPreviewPlayling = false;
  }

}
