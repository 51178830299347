<app-paywall-container title="{{'SUBSCRIPTIONMODALS.TITLE' | translate}}"
  [showCloseBtn]="false"
  [selectedPlan]="selectedPlan"
  (subscriptionPurchased)="dismissModal('purchased', null)"
>

<div class="ion-text-center ion-margin">
  <div class="ion-margin-bottom">
      <h2>{{'SUBSCRIPTIONMODALS.TITLEREDUCTION' | translate}}</h2>
      <p style="color: var(--ion-color-light);">{{'SUBSCRIPTIONMODALS.REDUCTIONTEXT1' | translate}}</p>

      <p style="color: var(--ion-color-light);">{{'SUBSCRIPTIONMODALS.REDUCTIONTEXT2' | translate}}</p>
    </div>
    @if(showTuneRemove) {
    @if(tuneToRemove>1) {
      <ion-text>{{'SUBSCRIPTIONMODALS.REDUCETUNES' | translate: { tuneToRemove } }}</ion-text>
    } @else {
      <ion-text>{{'SUBSCRIPTIONMODALS.REDUCETUNE' | translate}}</ion-text>
    }

    <ion-list class="ion-margin-top">
      <ion-item lines="full" class="ion-no-padding">
        <ion-label>
          {{ 'TUNES.MYTUNES' | translate }}

          <div class="metadata-end-wrapper" slot="end">
            <ion-button fill="none" (click)="showTuneSearch()" class="search-button">
              <ion-icon name="search-outline" color="primary" ariaLabel="{{ 'GROUPS.SEARCH' | translate }}" slot="icon-only"></ion-icon>
            </ion-button>
          </div>

          <div [@searchSegment]="!tunesearchHidden
          ? {value: 'visible',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
          :  {value: 'hidden',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
          >
            <ion-searchbar #tuneSearchBar
              [(ngModel)]="tuneSearchTerm"
              (ionInput)="searchTune($event)"
              (ionCancel)="hideTuneSearch()"
              (ionBlur)="hideTuneSearch()"
              placeholder="{{ 'TUNES.MYTUNES' | translate }}"
              cancelButtonText="{{ 'TUNES.CANCEL' | translate }}"
              debounce="10"
              [ariaHidden]="tunesearchHidden"
              show-cancel-button="focus"
            >
            </ion-searchbar>
          </div>
        </ion-label>
      </ion-item>
      @for (tune of shownTunes; track $index) {
        <app-tune-item [tune]="tune" openAction="none">
          <div slot="end">
            <ion-checkbox (ionChange)="changeTuneRemoveStatus(tune, $event.detail.value)" class="dark-checkbox"></ion-checkbox>
          </div>
        </app-tune-item>
      } @empty {
        <ion-item>
          <ion-label>
            {{ 'TUNES.NORESULT' | translate }}
          </ion-label>
        </ion-item>
      }
    </ion-list>
    @if(editedTunes && tuneToChoose>0) {
      @if(tuneToChoose>1) {
        <ion-text color="primary">{{'SUBSCRIPTIONMODALS.ERRREDUCETUNES' | translate: { tuneToChoose } }}</ion-text>
      } @else {
        <ion-text color="primary">{{'SUBSCRIPTIONMODALS.ERRREDUCETUNE' | translate}}</ion-text>
      }
    }
    <div class="ion-margin">
      <br />
    </div>
  }

  @if(showMemberRemove) {
    @if(memberToRemove>1) {
      <ion-text>{{'SUBSCRIPTIONMODALS.REDUCEMEMBERS' | translate: { memberToRemove } }}</ion-text>
    } @else {
      <ion-text>{{'SUBSCRIPTIONMODALS.REDUCEMEMBER' | translate}}</ion-text>
    }

    <ion-list class="ion-margin-top">
      <ion-item lines="full" class="ion-no-padding">
        <ion-label>
          {{ 'GROUPS.MEMBERS' | translate }}

          <div class="metadata-end-wrapper" slot="end">
            <ion-button fill="none" (click)="showMemberSearch()" class="search-button">
              <ion-icon name="search-outline" color="primary" ariaLabel="{{ 'GROUPS.SEARCH' | translate }}" slot="icon-only"></ion-icon>
            </ion-button>
          </div>

          <div [@searchSegment]="!membersearchHidden
          ? {value: 'visible',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
          :  {value: 'hidden',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
          >
            <ion-searchbar #memberSearchBar
              [(ngModel)]="memberSearchTerm"
              (ionInput)="searchMember($event)"
              (ionCancel)="hideMemberSearch()"
              (ionBlur)="hideMemberSearch()"
              placeholder="{{ 'GROUPS.MEMBERS' | translate }}"
              cancelButtonText="{{ 'TUNES.CANCEL' | translate }}"
              debounce="10"
              [ariaHidden]="membersearchHidden"
              show-cancel-button="focus"
            >
            </ion-searchbar>
          </div>
        </ion-label>
      </ion-item>

      @for (member of shownMembers; track $index) {
        @if(!isGroupOwner(member)) {
          <app-user-item [user]="member.user"
            [enableSliding]="false"
            [status]="getUserStatus(member.user)"
            [marginEnd]="false"
            [showUsername]="false"
          >
            <span slot="end">
              <ion-checkbox (ionChange)="changeMemberRemoveStatus(member, $event.detail.value)" class="dark-checkbox"></ion-checkbox>
            </span>
          </app-user-item>
        }
      } @empty {
        <ion-item>
          <ion-label>
            {{ 'GROUPS.NOMEMBERS' | translate }}
          </ion-label>
        </ion-item>
      }
    </ion-list>
  }
  @if(editedMembers && memberToChoose>0) {
    @if(memberToChoose>1) {
      <ion-text color="primary">{{'SUBSCRIPTIONMODALS.ERRREDUCEMEMBERS' | translate: { memberToChoose } }}</ion-text>
    } @else {
      <ion-text color="primary">{{'SUBSCRIPTIONMODALS.ERRREDUCEMEMBER' | translate}}</ion-text>
    }
  }

</div>

<footer>

  <div class="ion-margin-horizontal ion-margin-bottom">
    <ion-button
      expand="block"
      fill="outline"
      color="primary"
      [disabled]="!enableRemoveButton"
      (click)="showDeleteAlert()"
    >
      {{'SUBSCRIPTIONMODALS.REMOVETUNESANDMEMBERS' | translate}}
    </ion-button>
  </div>
</footer>
