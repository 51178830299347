<div class="background-div" [class.bottom-margin]="isAndroid || isIos">
  <ion-toolbar class="ion-no-margin ion-no-padding control-bar footer-element">
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row class="ion-align-items-center" id="media-controls">
        <ion-col class="ion-align-self-center ion-no-padding" size="2.4">
          @if(hasCountOff) {
            <ion-button fill="clear" auto-hide="false" (click)="toggleCountOff()" class="player-button" [disabled]="disabled">
              <ion-icon src="/assets/icon/CountOff.svg" [color]="countOffActive ? 'primary' : 'light'" [ariaLabel]="ariaCountOffButton"  slot="icon-only" class="player-icon"></ion-icon>
            </ion-button>
          }
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
          @if(showRecButton) {
            <ion-button fill="clear" auto-hide="false" (click)="setupRecording()" [disabled]="isRecDisabled"  class="player-button" [disabled]="disabled">
              <ion-icon name="radio-button-on" [ariaLabel]="ariaRecordButton" [color]="isRecOn ? 'primary' : 'light'"  slot="icon-only" class="player-icon"></ion-icon>
            </ion-button>
          <!-- <ion-button fill="clear" auto-hide="false" (click)="stop()">
              <ion-icon name="square"></ion-icon>
            </ion-button>-->
          }
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
          <ion-button fill="clear" auto-hide="false" (click)="playPause()"  class="player-button" [disabled]="disabled">
            <ion-icon *ngIf="!isPlaying" name="play" class="player-icon" ariaLabel="{{ 'PLAYER.PLAY' | translate }}" role="button"  slot="icon-only"></ion-icon>
            <ion-icon *ngIf="isPlaying" name="pause" class="player-icon" ariaLabel="{{ 'PLAYER.PAUSE' | translate }}" role="button"  slot="icon-only"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
          <ion-button *ngIf="!isLooping" fill="clear" color="light" auto-hide="false" (click)="loop()"  class="player-button" [disabled]="disabled">
            <ion-icon name="repeat" ariaLabel="{{ 'PLAYER.LOOPON' | translate }}"  slot="icon-only" class="player-icon"></ion-icon>
          </ion-button>
          <ion-button *ngIf="isLooping" fill="clear" color="primary" auto-hide="false" (click)="resetLoop()"  class="player-button" [disabled]="disabled">
            <ion-icon name="repeat" ariaLabel="{{ 'PLAYER.LOOPOFF' | translate }}"  slot="icon-only" class="player-icon"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col size="2.4" class="ion-no-padding">
          @if(hasMultipleSpeeds || !isWeb) {
            <ion-button fill="clear" (click)="showSpeedsPopover()"  class="player-button" [disabled]="disabled">
              <ion-icon src="assets/icon/speedtest.svg" ariaLabel="{{ 'PLAYER.CHANGETEMPO' | translate }}"  slot="icon-only" class="player-icon"></ion-icon>
              <!--{{ currentSpeedString }}-->
            </ion-button>
            <ion-item class="select-container ion-hide">
              <ion-label class="ion-text-center">{{ 'PLAYER.SPEED' | translate }}</ion-label>

              <ion-select (ionChange)="selectSpeed($event)" #speedSelect
                  [value]="currentSpeed"
                  interface="alert"
                  [label]="speedString"
                  [interfaceOptions]="{ cssClass: 'band-on-alert'}"
              >
                <ion-select-option *ngFor="let s of speeds" [value]="s">
                  {{ s.speed }}bpm
                </ion-select-option>
              </ion-select>
            </ion-item>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>

<!--<div class="divider"></div>-->
<!--TODO: disable-->
<app-time-slider></app-time-slider>

<ion-toolbar class="footer-element">
  <ion-buttons slot="start">
    <ion-button auto-hide="false" (click)="jumpToPreviousPart()" *ngIf="!isLooping" ariaLabel="{{ 'PLAYER.JUMPBACK' | translate }}" [disabled]="disabled">
      <ion-icon name="play-skip-back" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="jumpToLoopBegin()" *ngIf="isLooping" ariaLabel="{{ 'PLAYER.JUMPTOLOOPSTART' | translate }}" [disabled]="disabled">
      <ion-icon name="play-skip-back" size="large"></ion-icon>
    </ion-button>
    <ion-icon *ngIf="isStartLoopPart" src="/assets/icon/Wiederholunga.svg" size="large" ariaLabel="{{ 'PLAYER.ISLOOPSTART' | translate }}"> </ion-icon>
  </ion-buttons>
  <ion-title class="ion-text-center ion-align-items-center" [ariaLabel]="ariaPartDesignation" #partDesignation role="heading" aria-level="5">
    {{ currentPart }}
    <ion-icon name="caret-forward" *ngIf="isInPickup" style="transform: translateY(3px);" aria-hidden="true"></ion-icon>
    <ion-text *ngIf="isInPickup" aria-labelledby="partDesignation" aria-hidden="true">{{ nextPart }}</ion-text>
  </ion-title>
  <ion-buttons slot="end">
    <ion-icon *ngIf="isEndLoopPart" src="/assets/icon/Wiederholungb.svg" size="large" ariaLabel="{{ 'PLAYER.ISLOOPEND' | translate }}"> </ion-icon>
    <ion-button auto-hide="false" (click)="jumpToNextPart()" [disabled]="!hasNextPart || disabled" *ngIf="!isLooping && (hasNextPart || !hasNextTune)"  ariaLabel="{{ 'PLAYER.JUMPFORWARD' | translate }}">
      <ion-icon name="play-skip-forward" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="loopNextPart()" [disabled]="!hasNextPart || nextPartLoop || disabled" *ngIf="isLooping" ariaLabel="{{ 'PLAYER.LOOPNEXTPART' | translate }}">
      <ion-icon name="return-up-forward-outline" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="jumpToNextTune()" *ngIf="!isLooping && !hasNextPart && hasNextTune" ariaLabel="{{ 'PLAYER.JUMPTONEXTTUNE' | translate }}" [disabled]="disabled">
      <ion-icon name="arrow-redo" size="large"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

@if(swiper && showTabBar) {
  <ion-grid class="tab-buttons ion-no-margin tab-bar footer-element" role="tabpanel">
    <ion-row class="ion-no-margin ion-no-padding">
      <!--<ion-col size="1"></ion-col>-->
      <ion-col [size]="12/tabsCount" class="ion-no-padding">
        <ion-button auto-hide="false" (click)="openMixView()" expand="full" [color]="mixColor" fill="clear" role="button" class="swiper-button"
          ariaLabel="{{'PLAYER.ARIAMIXER' | translate}}"
          [class.selected-swiper-button]="this.swiperIndex===this.mixIndex"
          [disabled]="disabled"
        >
          <ion-icon name="options" aria-hidden="true" slot="icon-only"></ion-icon>
          <!--Mix-->
        </ion-button>
      </ion-col>
      @if(hasLyrics) {
        <ion-col [size]="12/tabsCount" class="ion-no-padding">
          <ion-button auto-hide="false" (click)="openLyricsView()" expand="full" [color]="lyricsColor" fill="clear" role="button" class="swiper-button"
            ariaLabel="{{'PLAYER.ARIALYRICS' | translate}}"
            [class.selected-swiper-button]="this.swiperIndex===this.lyricsIndex"
            [disabled]="disabled"
          >
            <ion-icon name="text" aria-hidden="true" slot="icon-only"></ion-icon>
            <!--Mix-->
          </ion-button>
        </ion-col>
      }
      <ion-col [size]="12/tabsCount" class="ion-no-padding">
        <ion-button auto-hide="false" (click)="openMainView()" expand="full" [color]="mainColor" fill="clear" role="button" class="swiper-button selected-swiper-button"
          ariaLabel="{{'PLAYER.ARIAINFO' | translate}}"
          [class.selected-swiper-button]="this.swiperIndex===this.infoIndex"
          [disabled]="disabled"
        >
          <ion-icon name="information-circle-outline" slot="icon-only"></ion-icon>
          <!--Info-->
        </ion-button>
      </ion-col>
      @if(hasSheets) {
        <ion-col [size]="12/tabsCount" class="ion-no-padding">
          <ion-button auto-hide="false" (click)="openSheetsView()" expand="full" [color]="sheetsColor" fill="clear" role="button" class="swiper-button"
            ariaLabel="{{'PLAYER.SHEETS' | translate}}"
            [class.selected-swiper-button]="this.swiperIndex===this.sheetsIndex"
            [disabled]="disabled"
          >
            <ion-icon name="musical-notes" aria-hidden="true" slot="icon-only"></ion-icon>
            <!--Mix-->
          </ion-button>
        </ion-col>
      }
      <ion-col [size]="12/tabsCount" class="ion-no-padding">
        <ion-button auto-hide="false" (click)="openArrangementView()" expand="full" [color]="arrangementColor" fill="clear" role="button" class="swiper-button"
          ariaLabel="{{'PLAYER.ARIAARRENGEMENT' | translate}}"
          [class.selected-swiper-button]="this.swiperIndex===this.arrangementIndex"
          [disabled]="disabled"
        >
        <ion-icon aria-hidden="true" slot="icon-only" src="assets/icon/bricks.svg"></ion-icon>
          <!--Arrangement-->
        </ion-button>
      </ion-col>
      <!--<ion-col size="1"></ion-col>-->
    </ion-row>
  </ion-grid>
}

</div>
