import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { SubscriptionPlan } from 'bandon-shared';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { HorizontalDividerComponent } from 'src/app/components/general/horizontal-divider/horizontal-divider.component';
import { StoreService } from 'src/app/services/store/store.service';
import { SubscribeButtonsComponent } from './subscribe-buttons/subscribe-buttons.component';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';

@Component({
  selector: 'app-paywall-container',
  templateUrl: './paywall-container.component.html',
  styleUrls: ['./paywall-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    HorizontalDividerComponent,
    SubscribeButtonsComponent
  ]
})
export class PaywallContainerComponent  implements OnInit, OnChanges, OnDestroy {
  storeService = inject(StoreService)
  modalController = inject(ModalController)
  elRef = inject(ElementRef)
  subscriptionService = inject(SubscriptionPlansService)

  @Input() showCloseBtn = false;
  @Input() title = "Ensemble Abo";
  @Input() selectedPlan: SubscriptionPlan | undefined = undefined;
  @Output() subscriptionPurchased: EventEmitter<void> = new EventEmitter<void>();

  private unsubscribe$ = new Subject<void>();

  constructor() {
    this.elRef.nativeElement.classList.add('ion-page');
  }

  get isWeb() {
    //    return true;
    return Capacitor.getPlatform() === 'web';
  }

  ngOnInit() {
    this.subscriptionService.subscriptionPlans$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(plans => {
      plans = plans.sort((p1, p2) => p1.membercount - p2.membercount);
      this.selectPlan(plans[1])
    });
    this.selectPlan(this.selectedPlan)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.selectedPlan) {
      this.selectPlan(this.selectedPlan)
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectPlan(plan: SubscriptionPlan) {
    this.selectedPlan = plan;
  }

  dismissModal(role: string, data: any) {
    this.modalController.dismiss(data, role);
  }

  onPurchase() {
    this.subscriptionPurchased.emit();
  }

}
