<ion-row class="ion-align-items-center">
  <ion-col size="2">
    <div class="instrument-img ion-text-center" aria-hidden="true">
      <ion-thumbnail>
        <app-cached-image [src]="instImgSrc"></app-cached-image>
      </ion-thumbnail>
      <small class="instrument-title" no-padding no-margin>{{ designation }}</small><br>
    </div>
  </ion-col>
  <ion-col size="2">
    <ion-button fill="clear" (click)="togglePlay()" class="play-button" color="light">
      <ion-icon name="play" class="play-icon" *ngIf="!playState" ariaLabel="{{ 'PLAYER.PLAY' | translate }}"></ion-icon>
      <ion-icon name="pause" class="play-icon" *ngIf="playState" ariaLabel="{{ 'PLAYER.PAUSE' | translate }}"></ion-icon>
    </ion-button>
  </ion-col>
  <ion-col size="6">
    <ion-range min="0" [max]="duration" step="0.01" [(ngModel)]="currentTime"
      class="position-slider ion-no-margin"
      ariaLabel="{{ 'PLAYER.TIME' | translate }}"
      debounce="10"
      (ionChange)="setCurrentTime($event)" (ionKnobMoveStart)="startJump($event)"
      (ionKnobMoveEnd)="jumpTo($event)">
    </ion-range>
  </ion-col>
  <ion-col size="2">
    <ion-button fill="clear" color="light" (click)="share()" class="share-button" *ngIf="!isWeb" >
      <ion-icon name="share" class="share-icon" ariaLabel="{{ 'MIXER.SHARE' | translate }}"></ion-icon>
    </ion-button>
    <ion-button fill="clear" color="light" (click)="download()" class="share-button" *ngIf="isWeb" >
      <ion-icon name="download" class="share-icon" ariaLabel="{{ 'MIXER.SHARE' | translate }}"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>

<!--<ion-item class="ion-no-padding ion-no-margin">
  <div slot="start" class="instrument-img ion-text-center" aria-hidden="true">
    <ion-thumbnail>
      <app-cached-image [src]="instImgSrc"></app-cached-image>
    </ion-thumbnail>
    <small class="instrument-title" no-padding no-margin>{{ designation }}</small><br>
  </div>

  <ion-button fill="clear" (click)="togglePlay()" class="play-button" color="light">
    <ion-icon name="play" class="play-icon" *ngIf="!playState" ariaLabel="{{ 'PLAYER.PLAY' | translate }}"></ion-icon>
    <ion-icon name="pause" class="play-icon" *ngIf="playState" ariaLabel="{{ 'PLAYER.PAUSE' | translate }}"></ion-icon>
  </ion-button>
  <ion-range min="0" [max]="duration" step="0.01" [(ngModel)]="currentTime"
      class="position-slider ion-no-margin"
      ariaLabel="{{ 'PLAYER.TIME' | translate }}"
      debounce="10"
      (ionChange)="setCurrentTime($event)" (ionKnobMoveStart)="startJump($event)"
      (ionKnobMoveEnd)="jumpTo($event)">
  </ion-range>
  <ion-button fill="clear" color="light" (click)="share()" class="share-button" *ngIf="!isWeb" >
      <ion-icon name="share" class="share-icon" ariaLabel="{{ 'MIXER.SHARE' | translate }}"></ion-icon>
  </ion-button>
  <ion-button fill="clear" color="light" (click)="download()" class="share-button" *ngIf="isWeb" >
    <ion-icon name="download" class="share-icon" ariaLabel="{{ 'MIXER.SHARE' | translate }}"></ion-icon>
  </ion-button>

</ion-item>-->
