<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{'SUBSCRIPTIONMODALS.WELCOME' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-action-sheet
    [isOpen]="isPhotoSheetOpen"
    header="{{ 'USERMENU.NEWPHOTO' | translate }}"
    [buttons]="photoSourceButtons"
    (didDismiss)="photoSourceAction($event)"
    class="band-on-action-sheet"
  ></ion-action-sheet>


  <form [formGroup]="groupForm" (ngSubmit)="onSubmit()">
    <ion-grid class="ion-margin">
      <ion-row>
        <ion-col size="12">
        </ion-col>
      </ion-row>

      <ion-row class="ion-text-center">
        <ion-col size="12">
          <h2>{{'SUBSCRIPTIONMODALS.WELCOME' | translate}}</h2>
          <p>{{'SUBSCRIPTIONMODALS.NICETOHAVEYOU' | translate}}</p>
          <p>{{'SUBSCRIPTIONMODALS.ENTERGROUPINFO' | translate}}</p>
        </ion-col>
      </ion-row>

      <ion-row class="ion-text-center">
        <ion-col size="12" class="ion-justify-content-center">
          <div class="avatar-container">
            <ion-avatar>
              @if(newGroupImgSrc) {
                <img [src]="newGroupImgSrc" ariaLabel="{{ 'GROUPS.PICTURE' | translate }}">
              } @else {
                <ion-icon name="help-circle" class="empty-avatar" ariaLabel="{{ 'GROUPS.PICTURE' | translate }}"></ion-icon>
              }
            </ion-avatar>

            <div class="edit-container" (click)="setPhotoSheetOpen(true)" role="button">
              <ion-icon name="camera-outline"  ariaLabel="{{ 'GROUPS.CHANGEPICTURE' | translate}}"></ion-icon>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="ion-text-center">
        <ion-col size="12">
          <ion-input ariaLabel="{{ 'GROUPS.NAME' | translate }}" color="primary" class="active-input"
            [value]="designation"
            (ionInput)="changedDesignation($event)"
            placeholder="{{ 'GROUPS.NAME' | translate }}"
            formControlName="nameInput"
            [counter]="true" maxlength="30"
            style="--border-width: 0;"
          >
          </ion-input>
          @if ((nameInput.invalid && nameInput.touched) || nameInput.dirty) {
            <div class="ion-margin-bottom">
              @if (nameInput.errors?.['profanity']) {
                <ion-text color="primary">
                  {{ 'GROUPS.NAMEPROFANITY' | translate }}
                </ion-text>
              }
              @if (nameInput.errors?.['required']) {
                <ion-text color="primary">
                  {{ 'GROUPS.NAMEREQUIRED' | translate }}
                </ion-text>

              }
            </div>
          }
        </ion-col>
      </ion-row>
      <ion-row class="ion-text-center">
        <ion-col size="12">
          <ion-textarea ariaLabel="{{ 'GROUPS.DESCRIPTION' | translate }}" color="primary" class="active-input"
            [value]="description"
            (ionInput)="changedDescription($event)"
            placeholder="{{ 'GROUPS.DESCRIPTION' | translate }}"
            [counter]="true" maxlength="200"
            style="--border-width: 0;"
            formControlName="descriptionInput">
          </ion-textarea>
          @if ((descriptionInput.invalid && descriptionInput.touched) || descriptionInput.dirty) {
            <div class="ion-margin">
              @if (descriptionInput.errors?.['profanity']) {
                <ion-text color="primary">
                  {{ 'GROUPS.DESCRIPTIONPROFANITY' | translate }}
                </ion-text>
              }
            </div>
          }
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-button expand="block" type="submit" [disabled]="!groupForm.valid">
            {{'SUBSCRIPTIONMODALS.CONTINUE' | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
