import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, Lyric, Speed } from 'bandon-shared';
import { UploadFile } from 'src/app/components/upload/file-upload/file-upload.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranscribeService {
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)

  constructor() { }

  transcribeFromFile(speed: Speed, file: UploadFile) {
    if(speed.lyrics) {
      speed.lyrics.length = 0;
    } else {
      speed.lyrics = [];
    }

    const formData: FormData = new FormData();
    formData.append('audio', file.file, file.name);
    formData.append('tune_speed_id', speed.id.toString());

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    return this.httpClient.post<Lyric[]>(`${environment.apiURL}/transcribe`, formData, { headers });
  }
}
