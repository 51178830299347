<ion-item-sliding #slidingItem [disabled]="!enableSliding">
  <ion-item detail="false" [class.item-no-end-margin]="!marginEnd">
    <span class="visually-hidden">{{ ariaLabel }}</span>
    <ion-avatar aria-hidden="true" slot="start">
      @if(_src) {
        <img [src]="_src">
      }
      @else {
        <img src="assets/Band-On_Logo_loading.png">
      }
    </ion-avatar>
    <ion-label>
      <ion-text aria-hidden="true">
        @if(status==='established' || status==='myself') {
          {{ user.name }}
          @if(status==='myself') {
            ({{ 'CONTACTS.ME' | translate }})
          }
        }
        @else {
          {{ user.username }}
        }
        @if(status==='asked') {
          ({{ 'CONTACTS.OPENREQUEST' | translate }})
        }
      </ion-text>
      @if(status==='established'|| status==='myself') {
        @if(showUsername) {
          <br /><ion-note aria-hidden="true">{{ user.username }}</ion-note>
        }
        <br /><ion-note aria-hidden="true">{{ user.email }}</ion-note>
      }
      @if(role) {
        <br /><ion-note aria-hidden="true">{{ role.designation }}</ion-note>
      }
      @if(status==='none' || showRequestAlways) {
        <br />
        <ion-button (click)="sendRequest()">
          {{ requestText }}
        </ion-button>
      }
    </ion-label>
    @if(status === "request") {
      <ion-button slot="end" (click)="acceptRequest()">
        <ion-icon name="checkmark-outline" slot="icon-only" ariaLabel="{{ 'CONTACTS.ACCEPTREQUEST' | translate }}"></ion-icon>
      </ion-button>
      <ion-button slot="end" color="secondary" (click)="deleteContact($event, slidingItem)">
        <ion-icon name="close-outline" slot="icon-only" ariaLabel="{{ 'CONTACTS.REJECTREQUEST' | translate }}"></ion-icon>
      </ion-button>
    }
    @if(groupRole && groupRole.designation==='Administrator') {
      <ion-note slot="end" class="role-text" aria-hidden="true">{{ 'CONTACTS.ADMIN' | translate }}</ion-note>
    }
    @if(groupRole && groupRole.designation==='Owner') {
      <ion-note slot="end" class="role-text" aria-hidden="true">{{ 'CONTACTS.OWNER' | translate }}</ion-note>
    }
    <ng-content select="[slot=end]" slot="end"></ng-content>
  </ion-item>

  <ion-item-options>
    <ion-item-option color="primary" (click)="deleteContact($event, slidingItem)" type="submit">
      <ion-icon name="close" slot="top"></ion-icon>
      {{ 'CONTACTS.DELETE' | translate }}
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
