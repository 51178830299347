/* eslint-disable no-underscore-dangle */
import { environment } from 'src/environments/environment';
/* eslint-disable @typescript-eslint/member-ordering */
import { AudioEngine, PreloadOptions } from '@musicdose/audioengine';
import { inject, Injectable, NgZone } from '@angular/core';
import { Tune, Track, Tonality, Speed, InstrumentGroup, Instrument, AuthenticationService } from 'bandon-shared';
import { Part, Voice, Level, Playlist, ArrangementShowPart } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { AudioDownloadService } from './audio-download.service';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { BandONTranslationsService } from '../languages/band-ontranslations.service';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { UserDataService } from '../user/user-data.service';

interface TrackState {
  trackid: number;
  downloaded: boolean;
  initialized: boolean;
  volume: number;
}

interface CountOffState {
  downloaded: boolean;
  initialized: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private http = inject(HttpClient);
  public loadingController = inject(LoadingController);
  private authService = inject(AuthenticationService);
  private audioDownloadService = inject(AudioDownloadService);
  private ngZone = inject(NgZone);
  private bandonTranslations = inject(BandONTranslationsService);
  private connectivityService = inject(ConnectivityService);
  private userDataService = inject(UserDataService)

  public tune: Tune;
  public activePlaylist: Playlist | undefined;

  public duration = 1;
  public currentTime = 0; // in s

  public currentRecPlayTime = 0; // in s
  public startTime: number;        //Time where the cick starts

  public loopStart?: number;
  public loopEnd?: number;

  public downloadProgress = 0;
  public downloadSize = 0;
  public backgroundDownloadProgress = 0;
  public backgroundDownloadSize = 0;

  //CountOff
  public countOffActive = true;
  public countOffDuration = 0; // in s

  //Recording
  public recMode = false;
  public hasRecording = false;
  public recPlayState = false;
  public recPlayDuration = 1.0;

  public initCompletedBehaviour = new BehaviorSubject<boolean>(false);
  public initCompleted$ = this.initCompletedBehaviour.asObservable();
  private initCompleted = false;

  private trackStates: TrackState[] = [];
  private countOffState: CountOffState = { downloaded: false, initialized: false };

  //Preview
  public previewDuration = 0;

  //Setslists - trigger next tune
  public nextTuneBehaviour = new Subject<void>();
  public nextTune$ = this.nextTuneBehaviour.asObservable();

  //Setup Arrangement Info
  public loadSpeedCompletedBehaviour = new BehaviorSubject<boolean>(false);
  public loadSpeedCompleted$ = this.loadSpeedCompletedBehaviour.asObservable();

  //Trigger jump to start
  public jumpToStartBehaviour = new BehaviorSubject<boolean>(false);
  public jumpToStart$ = this.jumpToStartBehaviour.asObservable();

  //Save base64Data (in case of tune edit)
  public saveBase64Data = false;

  //State flags switch solo
  private isChangingSolo = false;

  get mainVolume(): number {
    return this.mainGain;
  }

  set mainVolume(vol: number) {
    this.mainGain = vol;
    this.activeTracks.forEach(t => this.setTrackVolume(t));
  }

  get currentTonality(): Tonality {
    return this.tonality;
  }

  get currentSpeed(): Speed {
    return this.speed;
  }

  get currentTempo(): number {
    return this.tempo;
  }

  set currentTempo(tempo: number) {
    this.tempo = tempo;
    //TODO: adjust
    this.adjustTempo();
  }

  get hasMultipleTonalities(): boolean {
    if(this._tonalities) {
      return this._tonalities.length > 1;
    }
    return false;
  }

  get tonalities(): Tonality[] {
    return this._tonalities;
  }

  get speeds(): Speed[] {
    return this._speeds;
  }

  get isPlaying(): boolean {
    return this.playing && !this.paused;
  }

  get isSoloActive(): boolean {
    let out = false;
    this.activeTracks.forEach( t => {
      if (t.solo) {
        out = true;
      }
    });
    return out;
  }

  get instruments(): Instrument[] {
    const groupedList: Instrument[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((track) =>{
        if (!groupedList.find(e => e.id===track.instrument.id)) {
          groupedList.push(track.instrument);
        }
      });
    }
    return groupedList;
  }

  get currentTimeMS() {
    return this.currentTime*1000.0;
  }

  get hasCountOff() {
    if(this.speed && this.speed.countofffile) {
      return true;
    }
    return false;
  }

  /** Gets all the active tracks based on tonality and speed */
  get activeTracks(): Track[] {
    let out: Track[] = [];
    if (this.tune) {
      out = this.tune.tracks.filter(e => this.speed && this.tonality &&
        e.tonality && e.speed &&
        e.speed.id===this.speed.id && e.tonality.id===this.tonality.id
      );
    }
    return out;
  }

  get isLooping(): boolean {
    if((this.loopStart || this.loopStart==0) && (this.loopEnd || this.loopEnd==0)) {
      return true;
    }
    return false;
  }

  get showBackgroundProgressBar(): boolean {
    return this.audioDownloadService.getDownloadCount()>0;
  }

  private playing = false;           // Boolean parameter to deal with "Play" & "Stop" buttons
  private paused = false;
  private isJumping = false;

  private _tonalities: Tonality[];
  private tonality: Tonality;
  private _speeds: Speed[];
  private speed: Speed;
  private tempo: number; //Selected tempo (slowed down/speeded up)

  private lastShowedPart?: Part;

  private mainGain = 1;

  private subscriptions = new Subscription();

  private isAuth = false;

  private tuneIsNewlyLoaded = false;

  constructor() {}

  async init() {
    await AudioEngine.initAudio();

    await AudioEngine.addListener('currentTime', async res => {
      if(res.time || res.time===0) {
        this.ngZone.run(() => {
          this.currentTime = res.time;
        });
//          this.currentTime = res.time;
        if(this.currentTime>=this.duration && !this.isLooping && this.duration && this.isPlaying) {
          console.log('next tune', this.duration, this.currentTime)
          await this.stop();
          this.jumpToStartBehaviour.next(true);
          if(this.activePlaylist) {
            this.nextTuneBehaviour.next();
          }
        }
      }
    });

    await AudioEngine.addListener('currentRecTime', res => {
      if(res.time || res.time===0) {
        this.ngZone.run(() => {
          this.currentRecPlayTime = res.time;
        });
        if(this.currentRecPlayTime>=this.recPlayDuration) {
          this.stopRecordingPlayer();
        }
    }
    });

    await AudioEngine.addListener('playPause', res => {
      if(res.state) {
        this.play();
      } else {
        this.pause();
        this.stopEngine();
      }
    });

    await AudioEngine.addListener('recordingReady', () => {
      this.checkHasRecording();
    });
    await AudioEngine.addListener('recordingPlayPause', recState => {
      this.recPlayState = recState.playing;
    });

    await AudioEngine.addListener('trackInitialized', res => {
//      this.duration = res.duration;
      const trackFile = this.trackStates.find(f => f.trackid===res.trackid);
      if(trackFile) {
        trackFile.initialized = true;

        //TODO: put this in a separate function
        let initComplete = this.countOffState.initialized;
        this.trackStates.forEach(t => {
          initComplete = initComplete && t.downloaded && t.initialized;
        });
        if(initComplete) {
          this.initCompleted = true;
          this.trackStates.length = 0;

          this.checkGroupMutes();
//          this.stop();
          if(this.tuneIsNewlyLoaded) {
            this.jumpToStartBehaviour.next(true);
            this.tuneIsNewlyLoaded = false;
          }
          AudioEngine.getDuration().then(d => {
            this.duration = d.value;
          });
          this.initCompletedBehaviour.next(true);
        }
      }
    });
    await AudioEngine.addListener('countOffInitialized', () => {
      this.countOffState.initialized = true;

      //TODO: put this in a separate function
      let initComplete = this.countOffState.initialized;
      this.trackStates.forEach(t => {
        initComplete = initComplete && t.downloaded && t.initialized;
      });
      if(initComplete) {
        this.initCompletedBehaviour.next(true);
        this.initCompleted = true;
        this.trackStates.length = 0;

        this.checkGroupMutes();
//          this.stop();
        if(this.tuneIsNewlyLoaded) {
          this.jumpToStartBehaviour.next(true);
          this.tuneIsNewlyLoaded = false;
        }
      }
    });
    this.audioDownloadService.progress$
      .subscribe(progress => {
        if(this.tune) {
          this.downloadProgress = progress;
          this.downloadSize = this.audioDownloadService.downloadSize;
        }
      });
    this.audioDownloadService.backgroundProgress$
      .subscribe(progress => {
        this.backgroundDownloadProgress = progress;
        this.backgroundDownloadSize = this.audioDownloadService.backgroundDownloadSize;
      });

    this.audioDownloadService.trackCompleted$
      .subscribe(trackCompleted => {
        if(this.tune) {
          const trackFile = this.trackStates.find(f => f.trackid===trackCompleted);
          if (trackFile) {
            trackFile.downloaded = true;

            this.prepareTrack(trackFile.trackid, trackFile.volume);
          }
        }
      });
    this.audioDownloadService.countOffCompleted$
      .subscribe(res => {
        if(this.tune && res && this.speed?.id) {
          this.prepareCountOff(this.speed.id);
        }
      });

    this.authService.isAuthenticated
    .subscribe(auth => this.isAuth = auth);

    //const isAwakeSupported = await KeepAwake.isSupported();
    //console.log('keep awake supported: '+isAwakeSupported.isSupported);

  }


  async finishLoading() {
//    this.dismissLoading();
  }

  public cancelDownload() {
    this.trackStates.forEach(ts => {
      if(!ts.downloaded) {
        AudioEngine.cancelDownload({id: String(ts.trackid)});
      }
    });
  }

  public async unloadTune() {
    AudioEngine.setTempo({tempo: 1.0});
    AudioEngine.unloadAudio();

    this.resetLoop();

    this.tune = null;
    this.tonality = null;
    this.speed = null;
    this.downloadProgress = 0;
    this.downloadSize = 0;
    this.countOffActive = true;

    this.audioDownloadService.clearDownloads();
    this.trackStates.length = 0;

    this.subscriptions.unsubscribe();
    this.initCompletedBehaviour.next(false);
    this.initCompleted = false;

    this.activePlaylist = null;

    this.duration = 1;
  }

  public async loadTune(tune: Tune, addToLastPlayed = true) {
    this.tune = tune;

//    await this.presentLoading();

    const data = {
      tuneid: tune.id
    };

    //Generate Tonalities Array
    //Show all Groups and all Instruments
    const groupedList: Tonality[] = [];
    if (this.tune && tune.tracks) {
      this.tune.tracks.forEach((track) =>{
        track.shown = true;
        track.instrumentgroup.open = true;
        track.instrumentgroup.groupLevel = 1;
        track.lastGainValue = 0.7;
        track.instrVolume = 0.7;
        track.instrument.playing = true;
        track.muted = false;
        track.solo = false;
        if (track.mainvoice!==1) {
          track.shown = false;
          this.muteTrack(track, true);
        }
        if (track.level.id>1) {
          track.shown = false;
          this.muteTrack(track, true);
        }
        if (!groupedList.find(e => e.id===track.tonality.id)) {
          groupedList.push(track.tonality);
        }
      });

      this.tuneIsNewlyLoaded = true;
    }
    this._tonalities =  groupedList;

    //TODO: Sort Arrangement parts

    //Add last played to user
    if(this.isAuth && this.connectivityService.isConnected() && addToLastPlayed) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.http.put(environment.apiURL+`/users/${this.authService.getUserID()}/lastplayed`, data, {headers})
      .subscribe(res => {
        this.userDataService.getUserData();
      });
    }

/*    for (const instrument of this.instruments.values()) {
      if (instrument.mainvoice===1) {
        instrument.shown = true;
      } else {
        this.muteInstrument(instrument, true);
      }
    }*/

/*    for (const p of this.filteredParts) {
      p.selected = true;
    }*/

    this.checkHasRecording();
  }

  public async setupRecording() {
    await AudioEngine.setupRecording({tuneID: this.tune.id});
    this.recMode = true;
  }

  public play(startTime: number=this.currentTime) {
    this.paused = false;
    AudioEngine.play();
    KeepAwake.keepAwake();
    this.playing = true;
  }

  public async pause(resetLoop = false) {
    if(resetLoop && this.isLooping) {
      this.resetLoop();
    }
 //   this.backgroundMode.disable();
    await AudioEngine.pause();
    KeepAwake.allowSleep();

    this.paused = true;
    this.recMode = false;
  }

  public async stop(resetLoop = true) {
    if(resetLoop && this.isLooping) {
      this.resetLoop();
    }
    await AudioEngine.pause();
    KeepAwake.allowSleep();
    this.playing = false;
    this.recMode = false;

    this.startTime = 0;
    if(this.speed && this.speed.start>0) {
      this.startTime = this.speed.start/1000;
      await this.setCurrentTime(this.startTime, true);
    } else {
      await this.setCurrentTime(0, true, 0, true);
    }
  }

  public stopEngine() {
    AudioEngine.stopEngine()
  }

  private checkHasRecording() {
    AudioEngine.hasRecording({tuneID: this.tune.id}).then( result => {
      this.hasRecording = result.exists;
      this.recPlayDuration = result.duration;
    });
  }

  public async generateRecordingDownload(): Promise<string> {
    let out = '';
    if(this.tune && this.hasRecording) {
      out = (await AudioEngine.createRecordingDownload({tuneID: this.tune.id})).url;
    }
    return out;
  }

  public selectTonality(tonality: Tonality) {
    if(this.tonality && this.tonality.id===tonality.id) {
      return;
    }
    this.stop();
    this.tonality = tonality;
    let speedSelected = false;

    const avaliableSpeeds: Speed[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((track) =>{
        if (!avaliableSpeeds.find(e => e.id===track.speed.id)) {
          avaliableSpeeds.push(track.speed);
        }
      });
    }
    this._speeds =  avaliableSpeeds;

    for(const speed of avaliableSpeeds.values()) {
      if(speed.main>0) {
        this.selectSpeed(speed, true);
        speedSelected = true;
      }
    }
    if(!speedSelected && avaliableSpeeds.length>0) {
      this.selectSpeed([...avaliableSpeeds.values()][0], true);
    }

    //Load all tracks
    for(let speed of this._speeds) {
      //Load Count Off File
      if(speed.countofffile) {
        let token = environment.apiKey;
        if (this.isAuth) {
          token = this.authService.getToken();
        }
        const options = {
          accessToken: token,
          tuneSpeedId : speed.id,
          filePath: speed.countofffile,
          fileMD5: speed.countoffchecksum
        };
        AudioEngine.downloadCountOff(options);
      }

      const tracks: Track[] = this.tune.tracks.filter(track => track.speed.id===speed.id && track.tonality.id===this.tonality.id);
      for (const track of tracks) {
        this.audioDownloadService.downloadFile(track.id, track.path, track.filechecksum, track.filesize, false, this.saveBase64Data);
      }
    }
  }

  public async selectSpeed(speed: Speed, forceReload=false) {
    if(this.speed && speed.id === this.speed.id && forceReload===false) {
      return;
    }

    this.downloadProgress = 0;
    this.downloadSize = 0;
//    await this.presentLoading();
    this.pause();
//    await AudioEngine.unloadAudio();
    //TODO: deactivate all the players
    for(let track of this.activeTracks) {
      await AudioEngine.setTrackActive({trackid: track.id, state: false})
    }

    this.speed = speed;
    this.tempo = speed.speed;
    if(speed.speeddesignation) {
      this.tempo = speed.speeddesignation.tempo;
    }
    this.startTime = this.speed.start/1000;

    //Load Count Off File
    if(speed.countofffile) {
      this.countOffState.downloaded = false;
      this.countOffState.initialized = false;

//      this.audioDownloadService.setIsBlocking(-speed.id, true);

      await AudioEngine.setPlayCountoff({active: true, duration: this.countOffDuration/*this.tuneInfoService.getCountOffDuration(speed)*/});
    } else {
      this.countOffState.downloaded = true;
      this.countOffState.initialized = true;
      await AudioEngine.setPlayCountoff({active: false, duration: 0});
    }

    const tracks: Track[] = this.tune.tracks.filter(track => track.speed.id===speed.id && track.tonality.id===this.tonality.id);
    let needsDownload = false;
    for (const track of tracks) {
      let volume = 1;
      if (!track.shown || track.instrument.softwareinstr) {
        volume = 0;
      }

      if(track.instrument.softwareinstr) {
        track.lastGainValue = track.instrVolume;
        track.instrVolume = 0;
        track.solo=false;
        track.muted = true;
      }
      if(this.audioDownloadService.setIsBlocking(track.id, true)) {
        this.trackStates.push({
          trackid: track.id,
          downloaded: false,
          initialized: false,
          volume
        });
        needsDownload = true;
      } else {
        this.trackStates.push({
          trackid: track.id,
          downloaded: true,
          initialized: false,
          volume
        });
        this.prepareTrack(track.id, volume);
      }
    }

    if(speed.countofffile && this.audioDownloadService.setIsBlocking(-speed.id, true)) {

    } else if(speed.countofffile) {
      this.prepareCountOff(speed.id);
    }

    if(!needsDownload) {
      AudioEngine.getDuration().then(d => {
        this.duration = d.value;
      });
    }

//    this.tuneIsNewlyLoaded = true;

    //TODO:
//    this.tuneInfoService.setupArrangementInfo();
    this.loadSpeedCompletedBehaviour.next(true);

    return needsDownload;
  }

  public adjustTempo() {
    let currentTempo = this.currentSpeed.speed;
    if(this.currentSpeed.speeddesignation) {
      currentTempo = this.currentSpeed.speeddesignation.tempo
    }
    const percentage = this.tempo/currentTempo;
    AudioEngine.setTempo({tempo: percentage});
  }

  public async setLoopTimes(start: number, end: number, jumpToStart = true) {
    if(start!==this.loopStart || end!==this.loopEnd) {
      this.loopStart = start;
      this.loopEnd = end;

      if(jumpToStart && start>this.currentTime) {
        await this.setCurrentTime(start, false);
      }
      if(end<this.currentTime) {
        await this.setCurrentTime(start, false);
      }

//      console.log('set loop times: ', this.loopStart, this.loopEnd);
      await AudioEngine.setLoopTimes({from: this.loopStart, to: this.loopEnd});
      await AudioEngine.setLoop({active: true});
    }
  }

  public resetLoop() {
    this.loopStart = null;
    this.loopEnd = null;

    AudioEngine.setLoop({active: false});
  }

  public async muteTrack(track: Track, mute: boolean, force=false) {
    if(track.muted!==mute || force) {
      track.muted = mute;
      if(track.muted) {
        track.lastGainValue = track.instrVolume;
        track.instrVolume = 0;
        track.solo=false;
        await AudioEngine.setVolume({trackid: track.id, volume: 0});
      } else {
        track.instrVolume = track.lastGainValue;
        await AudioEngine.setVolume({trackid: track.id, volume: track.instrVolume});
      }

      this.checkGroupMutes();
    }
  }

  public resetMix() {
    for (const track of this.activeTracks) {
      let volume = 1;
      if (!track.shown) {
        volume = 0;
      }
      else if(track.instrument.softwareinstr) {
        volume = 0;
      }
      track.solo = false;
      if(volume===0) {
        this.muteTrack(track, true, true);
      } else {
        track.lastGainValue = 0.7;
        this.muteTrack(track, false, true);
      }
    }
  }

  private checkGroupMutes() {
    this.getInstrumentGroups().forEach(group => {
      let isGroupMuted = true;
      this.getTracksOfGroup(group).forEach(t => {
        if(!t.muted) {
          isGroupMuted= false;
        }
      });
      group.muted = isGroupMuted;
    });
  }

  public setTrackVolume(track: Track) {
    const tracks: Track[] = this.activeTracks.filter(t => t.id===track.id);
    for (const t of tracks) {
      AudioEngine.setVolume({trackid: t.id, volume: this.mainVolume*track.instrVolume});
    }
  }

  public async soloTrack(track: Track, isSolo = !track.solo) {
    if(this.isChangingSolo) {
      return;
    }
    this.isChangingSolo = true;
    const soloActive = this.isSoloActive;
    track.solo = isSolo;
//    track.solo = !track.solo;

    if(track.solo) {
      await this.muteTrack(track, false);
      for (const t of this.activeTracks) {
//      this.activeTracks.forEach(t => {
        if(t.id!==track.id && t.shown && !t.solo) {
          if(!soloActive) {
            t.wasMute = t.muted;
          }
          await this.muteTrack(t, track.solo);
        }
      };
    } else if(this.isSoloActive) {
      await this.muteTrack(track, true);
    } else {
      for( const t of this.activeTracks) {
//      this.activeTracks.forEach(t => {
        if(t.id!==track.id && t.shown && !t.solo) {
          await this.muteTrack(t, t.wasMute);
        }
      };
    }

    this.checkGroupSolo();

    this.isChangingSolo = false;
  }

  private checkGroupSolo() {
    this.getInstrumentGroups().forEach(group => {
      let isGroupSoloed = true;
      this.getTracksOfGroup(group).forEach(t => {
        if(!t.solo) {
          isGroupSoloed= false;
        }
      });
      group.solo = isGroupSoloed;
    });
  }

  public getInstrumentGroups(): InstrumentGroup[] {
    const groupedList: InstrumentGroup[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((track) =>{
        if (!groupedList.find(e => e.id===track.instrumentgroup.id)) {
          groupedList.push(track.instrumentgroup);
        }
      });
    }
    return groupedList;
  }

  public getInstrumentOfGroup(group: InstrumentGroup): { instrument: Instrument, voice?: Voice }[] {
    const groupedList: { instrument: Instrument, voice?: Voice }[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((track) =>{
        if (!groupedList.find(e => e.instrument.id===track.instrument.id && (!e.voice || !track.voice || e.voice.id == track.voice.id)) &&
            track.instrumentgroup.id===group.id) {
          groupedList.push({ instrument: track.instrument, voice: track.voice });
        }
      });
    }
    return groupedList;
  }

  public getTracksOfGroup(group: InstrumentGroup): Track[] {
    let groupedList: Track[] = this.activeTracks;
    if (this.tune) {
      groupedList = groupedList.filter(e => e.instrumentgroup.id===group.id && e.shown);
    }
    return groupedList;
  }

  public getTracksToVoice(voice: Voice): Track[] {
    const groupedList: Track[] = [];
    if (this.tune) {
      this.activeTracks.forEach((track) =>{
        if (!groupedList.find(e => e.id===track.id) && track.voice && track.voice.id!==0 &&
            track.voice.id===voice.id) {
          groupedList.push(track);
        }
      });
    }
    return groupedList;
  }

  public getTracksToInstrument(instrument: Instrument, voice: Voice | undefined): Track[] {
    const groupedList: Track[] = [];
    if (this.tune) {
      this.activeTracks.forEach((track) =>{
        if (!groupedList.find(e => e.id===track.id) && track.instrument && track.instrument.id!==0 &&
            track.instrument.id===instrument.id && (!voice || !track.voice || voice.id==track.voice.id)) {
          groupedList.push(track);
        }
      });
    }
//    console.log(`Tracks to ${instrument.designation}, ${voice.designation}`, groupedList)
    return groupedList;
  }

  public getTracksToSpeed(speed: Speed) {
    let out: Track[] = [];
    if (this.tune) {
      out = this.tune.tracks.filter(e =>
        e.speed.id===speed.id
      );
    }
    return out;

  }

  private getTracks(): Track[] {
    if (this.tune) {
      return this.tune.tracks;
    }
    return null;
  }

  public getLevelsToInstrument(instrument: Instrument, voice: Voice | undefined) {
    const groupedList: Level[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((t) =>{
        if (!groupedList.find(e => e.id===t.id) && t.level && instrument.id===t.instrument.id &&
            (!voice || !t.voice || voice.id===t.voice.id)) {
          groupedList.push(t.level);
        }
      });
    }
    return groupedList;
  }

  public getLevelsToInstrumentGroup(group: InstrumentGroup) {
    const groupedList: Level[] = [];
    if (this.tune) {
      this.tune.tracks.forEach((t) =>{
        if (!groupedList.find(e => e.id===t.level.id) && t.level && group.id===t.instrumentgroup.id && t.level.id>0) {
          groupedList.push(t.level);
        }
      });
    }
    return groupedList;
  }

  public switchInstrumentLevel(instrument: Instrument, voice: Voice | undefined, level: Level) {
    const tracks = this.getTracksToInstrument(instrument, voice);
    const oldTrack = tracks.find(t => t.shown);
    const newTrack = tracks.find(t => t.level.id===level.id);
    if(oldTrack && newTrack) {
      this.switchTrack(oldTrack, newTrack);
    }
  }

  public switchTrack(track: Track, newTrack: Track) {
    if (newTrack!==track) {
      track.shown = false;
      const wasMuted = track.muted;
      const lastGainValue = track.lastGainValue;
      const instrVolume = track.instrVolume;
      const solo = track.solo;
      track.solo = false;

      this.muteTrack(track, true);
      this.muteTrack(newTrack, wasMuted);
      newTrack.lastGainValue = lastGainValue;
      newTrack.instrVolume = instrVolume;
      this.setTrackVolume(newTrack);
      newTrack.solo = solo;
      newTrack.shown = true;
    }
  }

  public async setCurrentTime(time: number, playCountOff: boolean = false, pickupTime: number = 0, resetLoop = false) {
//    console.log(`seek to, Audio Service, Set CurrentTime ${time}`)
    this.isJumping = true;
    const wasPaused = this.paused;
    const wasPlaying = this.playing;
    const countOff = playCountOff && this.countOffActive;
    if (this.playing) {
//      console.log(`seek to, Audio Service, stop`)
      await AudioEngine.stop();
    }
    if(this.hasCountOff) {
//      console.log(`seek to, Audio Service, set play count off, ${countOff}, ${pickupTime}`)
      await AudioEngine.setPlayCountoff({active: countOff, duration: pickupTime});
    }
//    console.log(`seek to, Audio Service, jump to`)
    await AudioEngine.jumpTo({seconds: time});

    this.currentTime = time;
    if(!wasPaused && wasPlaying) {
//      console.log(`seek to, Audio Service, play`)
      await this.play(this.currentTime);
    }
//    console.log(`seek to, Audio Service, complete`)
  }

  public playRecording() {
    if(this.hasRecording && this.tune) {
      AudioEngine.playRecording({tuneID: this.tune.id});
    }
  }

  public pauseRecordingPlayer() {
    if(this.hasRecording) {
      AudioEngine.pauseRecordingPlayer();
    }
  }

  public stopRecordingPlayer() {
    if(this.hasRecording) {
      AudioEngine.pauseRecordingPlayer();
      AudioEngine.setCurrentRecPlayerTime({time: 0});
    }
  }

  public async recPlayerJumpTo(value: number) {
    const wasPlaying = this.recPlayState;

    await AudioEngine.setCurrentRecPlayerTime({time: value});

    if(wasPlaying) {
      this.playRecording();
    }
  }

  updatePlayingInfo(part: Part) {
    //TODO: update Cover
    if (this.lastShowedPart !== part) {
      let token = environment.apiKey;
      if (this.isAuth) {
        token = this.authService.getToken();
      }

      const options = {
        songName : part.designation,
        albumName: this.bandonTranslations.getTuneTitle(this.tune),
        artistName: 'band-on',
        coverPath: environment.apiURL+'/photoImg/'+this.tune.picture?.id,
        token
      };

      AudioEngine.setPlayingInfo(options);
      this.lastShowedPart = part;
    }
  }

  async countOffUpdated() {
    if (!this.countOffActive) {
      let wasPlaying = this.isPlaying;
      if(wasPlaying) {
        await AudioEngine.pause();
      }
      await AudioEngine.setPlayCountoff({active: this.countOffActive, duration: 0});
      await AudioEngine.jumpTo({ seconds: this.currentTime });
      if(wasPlaying) {
        AudioEngine.play();
      }
    }
  }

  prepareTrack(trackid: number, volume: number) {
    let token = environment.apiKey;
    if (this.isAuth) {
      token = this.authService.getToken();
    }

    const options: PreloadOptions = {
      accessToken: token,
      assetPath : '',
      assetId: trackid,
      fileMD5: '',
      volume,
    };
    AudioEngine.preloadAudio(options);
  }

  prepareCountOff(speedid: number) {
    let token = environment.apiKey;
    if (this.isAuth) {
      token = this.authService.getToken();
    }

    const options = {
      accessToken: token,
      tuneSpeedId : speedid,
      filePath: '',
      fileMD5:''
    };
    AudioEngine.preloadCountOff(options);
  }
}
