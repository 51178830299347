import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SizeSelectorComponent } from '../size-selector/size-selector.component';
import { SubscriptionPlan } from 'bandon-shared';
import { TranslateModule } from '@ngx-translate/core';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { Subject, takeUntil } from 'rxjs';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { StoreService } from 'src/app/services/store/store.service';
import { SubscriptionPlansService } from 'src/app/services/store/subscription-plans.service';

@Component({
  selector: 'app-paywall-content',
  templateUrl: './paywall-content.component.html',
  styleUrls: ['./paywall-content.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SizeSelectorComponent,
    TranslateModule
  ]
})
export class PaywallContentComponent  implements OnInit, OnDestroy {
  private userDataService = inject(UserDataService)
  private storeService = inject(StoreService)
  subscriptionService = inject(SubscriptionPlansService)

  @Output() onSizeSelect: EventEmitter<SubscriptionPlan> = new EventEmitter<SubscriptionPlan>();

  selectedPlan: SubscriptionPlan | undefined = undefined;

  private unsubscribe$ = new Subject<void>();

  constructor() { }

  get isEligible() {
    return this.storeService.checkEligibilty();
  }

  get showIsBigger() {
    if(this.selectedPlan && this.selectedPlan.membercount==200) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.userDataService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
/*        Purchases.checkTrialOrIntroductoryPriceEligibility()
          .then(result => {

          })*/
      })

    this.subscriptionService.subscriptionPlans$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(plans => {
      plans = plans.sort((p1, p2) => p1.membercount - p2.membercount);
      this.selectedPlan = plans[1];
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

  selectSize(plan: SubscriptionPlan) {
    this.selectedPlan = plan;
    this.onSizeSelect.emit(plan);
  }

}
