<ion-row class="ion-align-items-center instrument-row">
  <ion-col size="2">
    <app-instrument-icon [track]="track" [id]="popoverTriggerID" aria-hidden="false" role="button" [ariaHasPopup]="hasPopover"></app-instrument-icon>
  </ion-col>
  <ion-col size="2" class="ion-text-center">
    @if(!instrMuted) {
      <ion-button
          fill="clear" expand="block" size="small"
          (click)="mute()"
          [disabled]="soloActive"
          color="light"
          ariaLabel="{{ 'PLAYER.MUTE' | translate: { designation } }}"
          role="button"
      >
        <ion-icon name="volume-mute" class="mute-icon" aria-hidden="true"></ion-icon>
      </ion-button>
    } @else {
      <ion-button
          fill="clear" expand="block" size="small"
          (click)="mute()"
          [disabled]="soloActive"
          color="primary"
          ariaLabel="{{ 'PLAYER.UNMUTE' | translate: { designation } }}"
          role="button"
      >
        <ion-icon name="volume-mute" class="mute-icon" aria-hidden="true"></ion-icon>
      </ion-button>
    }
  </ion-col>
  <ion-col size="6" class="ion-text-start group-designation">
    <ion-range min="0" max="1.0" step="0.01" [(ngModel)]="instrVolume"
      (ionInput)="onVolumeChange($event)"
      [disabled]="instrMuted"
      class="volume-slider ion-no-margin"
      ariaLabel="{{ 'PLAYER.VOLUME' | translate: { designation: designation } }}"
      role="slider"
    >
    </ion-range>
  </ion-col>
  <ion-col size="2" class="ion-text-center">
    @if(!instrSolo) {
      <ion-button fill="clear" color="light" expand="block" size="small" class="solo-icon" (click)="solo()" ariaLabel="{{ 'PLAYER.SOLO' | translate: { designation } }}" role="button">
        S
      </ion-button>
    } @else {
      <ion-button fill="clear" color="primary" expand="block" size="small" class="solo-icon" (click)="solo()" ariaLabel="{{ 'PLAYER.UNSOLO' | translate: { designation } }}" role="button">
        S
      </ion-button>
    }
  </ion-col>

  <ion-popover  *ngIf="hasPopover" [trigger]="popoverTriggerID" [dismissOnSelect]="true" size="auto" backdropDismiss="true">
    <ng-template>
      <ion-content scrollY="false">
        <ion-list *ngIf="hasInstruments" >
          <ion-list-header aria-level="2" role="heading">
            {{ "PLAYER.INSTRUMENT" | translate }}
          </ion-list-header>
          <ion-item *ngFor="let track of possibleInstruments"
              [button]="true"
              [detail]="false"
              (click)="switchTrack(track)">
            {{ track.instrument.designation }}
          </ion-item>
        </ion-list>
        <ion-list *ngIf="hasLevels" >
          <ion-list-header>
            {{ "PLAYER.LEVEL" | translate }}
          </ion-list-header>
          <ion-item *ngFor="let track of possibleLevels"
            [button]="true"
            [detail]="false"
            (click)="switchTrack(track)">
          {{ track.level.designation }}
        </ion-item>
        </ion-list>
        <ion-list *ngIf="hasSheets">
          <ion-list-header aria-level="2" role="heading">
            {{ "PLAYER.SHEETS" | translate }}
          </ion-list-header>
          <ion-item *ngIf="hasEmptySheets" (click)="openEmptySheet()">
            {{ "PLAYER.EMPTYSHEETS" | translate }}
          </ion-item>
          <ion-item *ngIf="hasRhythmSheets" (click)="openRhythmSheet()">
            {{ "PLAYER.RHYTHMSHEETS" | translate }}
          </ion-item>
          <!--<ion-item *ngIf="hasChartSheets" [disabled]="true">
            {{ "PLAYER.BUYPARTS" | translate }}
          </ion-item>-->
          <ion-item *ngIf="hasChartSheets" (click)="openSheetPreview()">
            {{ "PLAYER.COMPLETESHEETS" | translate }}
          </ion-item>
        </ion-list>
      <ion-item style="height: 0;">
        {{ "PLAYER.CLOSE" | translate }}
      </ion-item>
    </ion-content>
    </ng-template>
  </ion-popover>
</ion-row>


<!--<ion-item #volume class="ion-no-padding ion-no-margin">
  <app-instrument-icon slot="start" [track]="track" [id]="popoverTriggerID" aria-hidden="false" role="button" [ariaHasPopup]="hasPopover"></app-instrument-icon>

  @if(!instrMuted) {
    <ion-button
        fill="clear"
        (click)="mute()"
        [disabled]="soloActive"
        color="light"
        class="mute-button"
        ariaLabel="{{ 'PLAYER.MUTE' | translate: { designation } }}"
        role="button"
    >
      <ion-icon name="volume-mute" class="mute-icon" aria-hidden="true"></ion-icon>
    </ion-button>
  } @else {
    <ion-button
        fill="clear"
        (click)="mute()"
        [disabled]="soloActive"
        color="primary"
        class="mute-button"
        ariaLabel="{{ 'PLAYER.UNMUTE' | translate: { designation } }}"
        role="button"
    >
      <ion-icon name="volume-mute" class="mute-icon" aria-hidden="true"></ion-icon>
    </ion-button>
  }
  <ion-range min="0" max="1.0" step="0.01" [(ngModel)]="instrVolume"
    (ionInput)="onVolumeChange($event)"
      [disabled]="instrMuted"
      class="volume-slider ion-no-margin"
      ariaLabel="{{ 'PLAYER.VOLUME' | translate: { designation: designation } }}"
      role="slider"
  >
  </ion-range>
  @if(!instrSolo) {
    <ion-button fill="clear" color="light" (click)="solo()" class="solo-button" ariaLabel="{{ 'PLAYER.SOLO' | translate: { designation } }}" role="button">
      S
    </ion-button>
  } @else {
    <ion-button fill="clear" color="primary" (click)="solo()"  class="solo-button" ariaLabel="{{ 'PLAYER.UNSOLO' | translate: { designation } }}" role="button">
      S
    </ion-button>
  }

</ion-item>-->
