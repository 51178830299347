<div class="ion-text-center ion-no-padding">
  <ion-text>
    @if(tune && hasSubscription) {
      <p>{{ 'TUNES.SHARINGQUOTA' | translate: { slots: availableSharingSlots } }}</p>
    } @else if(playlist) {
    } @else {
      <p>
        <b>{{'TUNES.SHARINGNOTPOSSIBLE' | translate}}</b><br />
        {{'TUNES.SHARINGNOTPOSSIBLE2' | translate}}
      </p>
      <ion-button fill="clear" expand="full" color="primary" (click)="showPaywall()">{{'TUNES.TOSUBSCRIPTION' | translate}}</ion-button>
    }
  </ion-text>
  <ion-list class="ion-no-margin ion-no-padding">
    <ion-item lines="full" class="ion-no-padding">
      <ion-label>
        {{ 'GROUPS.MYGROUPS' | translate }}

        <div class="metadata-end-wrapper" slot="end">
          <ion-button fill="none" (click)="showGroupSearch()" class="search-button">
            <ion-icon name="search-outline" color="primary" ariaLabel="{{ 'GROUPS.SEARCH' | translate }}" slot="icon-only"></ion-icon>
          </ion-button>
        </div>

        <div [@searchSegment]="!groupsearchHidden
        ? {value: 'visible',
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
        :  {value: 'hidden',
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
        >
          <ion-searchbar #groupSearchBar
            [(ngModel)]="groupSearchTerm"
            (ionInput)="searchGroup($event)"
            (ionCancel)="hideGroupSearch()"
            (ionBlur)="hideGroupSearch()"
            placeholder="{{ 'GROUPS.MYGROUPS' | translate }}"
            cancelButtonText="{{'GROUPS.CANCEL' | translate}}"
            debounce="10"
            [ariaHidden]="groupsearchHidden"
            show-cancel-button="focus"
          >
          </ion-searchbar>
        </div>
      </ion-label>
    </ion-item>

    @if(groups.length==0) {
      <ion-item>
        <ion-label>
          {{ 'GROUPS.NOGROUP' | translate }}
        </ion-label>
      </ion-item>
    } @else {
      @for (group of shownGroups; track $index) {
        <app-group-list-item [group]="group" [showUserLimit]="true" [showGroupDescription]="false">
          @if(availableSharingSlots >= group.userlimit || groupsSharing.includes(group)) {
            <span slot="end">
              <ion-checkbox (ionChange)="changeGroupSharing($event, group)" [checked]="hasGroupSharing(group)" class="dark-checkbox"  ariaLabel="{{'TUNES.SHAREWITHUSER' | translate}}"></ion-checkbox>
            </span>
          } @else {
            <span slot="end">
              <ion-icon name="warning-outline" color="primary" style="font-size: larger;" ariaLabel="{{'TUNES.ARIANOTENOUGHSLOTS' | translate}}"></ion-icon>
            </span>
          }
        </app-group-list-item>
      } @empty {
        <ion-item>
          <ion-label>
            {{'GROUPS.NORESULT' | translate}}
          </ion-label>
        </ion-item>
      }
    }
  </ion-list>

  <ion-list class="ion-margin-top">
    <ion-item lines="full" class="ion-no-padding">
      <ion-label>
        {{ 'CONTACTS.MYCONTACTS' | translate }}

        <div class="metadata-end-wrapper" slot="end">
          <ion-button fill="none" (click)="showContactSearch()" class="search-button">
            <ion-icon name="search-outline" color="primary" ariaLabel="{{ 'GROUPS.SEARCH' | translate }}" slot="icon-only"></ion-icon>
          </ion-button>
        </div>

        <div [@searchSegment]="!contactsearchHidden
        ? {value: 'visible',
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
        :  {value: 'hidden',
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} "
        >
          <ion-searchbar #contactsSearchBar
            [(ngModel)]="contactSearchTerm"
            (ionInput)="searchContact($event)"
            (ionCancel)="hideContactSearch()"
            (ionBlur)="hideContactSearch()"
            placeholder="{{ 'CONTACTS.MYCONTACTS' | translate }}"
            cancelButtonText="{{'GROUPS.CANCEL' | translate}}"
            debounce="10"
            [ariaHidden]="contactsearchHidden"
            show-cancel-button="focus"
          >
          </ion-searchbar>
        </div>
      </ion-label>
    </ion-item>

    @if (contacts.length==0) {
      <ion-item>
        <ion-label>
          {{ 'CONTACTS.NOCONTACTS' | translate }}
        </ion-label>
      </ion-item>
    } @else {
      @for (contact of shownContacts; track $index) {
        <app-user-item [user]="getUser(contact)"
          [enableSliding]="false"
          [status]="getRelationStatus(contact)"
          [marginEnd]="false"
          [showUsername]="false"
        >
          @if(availableSharingSlots > 0 || contactsSharing.includes(getUser(contact))) {
            <span slot="end">
              @if(isOwner(getUser(contact))) {
                <p style="font-size: small;">{{ 'TUNES.OWNER' | translate }}</p>
              } @else {
                <ion-select ariaLabel="{{'TUNES.SHAREWITHUSER'}}" [value]="hasContactSharing(getUser(contact))" class="selectable" interface="popover" (ionChange)="changeContactSharing($event, getUser(contact))">
                  @if(!isPartOfGroupSharing(getUser(contact))) {
                    <ion-select-option value="none">{{ 'TUNES.PERMISSIONNONE' | translate }}</ion-select-option>
                  }
                  <ion-select-option value="listen">{{ 'TUNES.PERMISSIONLISTEN' | translate }}</ion-select-option>
                  <ion-select-option value="edit">{{ 'TUNES.PERMISSIONEDIT' | translate }}</ion-select-option>
                </ion-select>
                <!--<ion-checkbox (ionChange)="changeContactSharing($event, getUser(contact))" [checked]="hasContactSharing(getUser(contact))"  class="dark-checkbox"></ion-checkbox>-->
              }
            </span>
          } @else {
            <span slot="end">
              <ion-icon name="warning-outline" color="primary" style="font-size: larger;" ariaLabel="{{'TUNES.ARIANOTENOUGHSLOTS' | translate}}"></ion-icon>
            </span>
          }
        </app-user-item>
      } @empty {
        <ion-item>
          <ion-label>
            {{ 'CONTACTS.NORESULT' | translate }}
          </ion-label>
        </ion-item>
      }
    }
  </ion-list>
</div>
