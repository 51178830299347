/* eslint-disable @typescript-eslint/member-ordering */
import { Speed, Tune, Tonality } from 'bandon-shared';
import { Component, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, IonSelect, ModalController, PickerController, PopoverController, IonicModule, Platform } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio/audio.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { SelectTempoPage } from 'src/app/pages/modal/select-tempo/select-tempo.page';
import { environment } from 'src/environments/environment';
import { NgIf, NgClass, CommonModule } from '@angular/common';
import { TimeSliderComponent } from './time-slider/time-slider.component';
import { Capacitor } from '@capacitor/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-player-footer',
    templateUrl: './player-footer.component.html',
    styleUrls: ['./player-footer.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      IonicModule,
      TimeSliderComponent,
      TranslateModule
    ],
})
export class PlayerFooterComponent implements OnInit, OnDestroy {
  platform = inject(Platform)

  @Input() tune: Tune;
  @Input() showModalButtons = true;
  @Input() swiper: any;
  @Input() hasNextTune = false;
  @Input() showRecButton = true;
  @Input() hasSheets = false;
  @Input() hasLyrics = false;
  @Input() showTabBar = true;
//  @Input() disabled = false;
  @Input() currentTime: Observable<number>;

  @Output() changeSpeed: EventEmitter<Speed> = new EventEmitter<Speed>();
  @Output() toggleCover: EventEmitter<void> = new EventEmitter<void>();
  @Output() playNextTune: EventEmitter<void> = new EventEmitter<void>();


  @ViewChild('speedSelect') speedSelectRef: IonSelect;
  @ViewChild('tonalitySelect') tonalitySelectRef: IonSelect;

  showTonalities = false;
  speedToSelect: Speed;

  disabled = true;

  isIos: boolean;
  isAndroid: boolean;

  mixIndex = 0;
  lyricsIndex = 1;
  infoIndex = 2;
  sheetsIndex = 3;
  arrangementIndex = 4;

  private isJumping = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private audioService: AudioService,
    private translateService: TranslateService,
    private tuneInfoService: TuneInfoService,
    private popoverController: PopoverController,
    private modalController: ModalController
  ) { }

  get duration(): number {
    return this.audioService.duration;
  }

  get isPlaying(): boolean {
    return this.audioService.isPlaying;
  }

  get currentPart(): string {
    if (this.tuneInfoService.currentShowPart) {
      return this.tuneInfoService.currentShowPart.designation;
    }
    return '';
  }

  get isInPickup(): boolean {
    return this.tuneInfoService.isInPickup;
  }

  get nextPart(): string {
    if(this.tuneInfoService.nextPart) {
      return this.tuneInfoService.nextPart.designation;
    }
    return '';
  }

  get currentVolume(): number {
    return this.audioService.mainVolume;
  }

  set currentVolume(vol: number) {
    this.audioService.mainVolume = vol;
  }

  get currentTonality(): Tonality {
    if(this.audioService.currentTonality) {
      return this.audioService.currentTonality;
    }
    return null;
  }

  get currentTonalityString(): string {
    if(this.audioService.currentTonality) {
      return this.audioService.currentTonality.designation;
    }
    return '';
  }

  get currentSpeed(): Speed {
    if(this.audioService.currentSpeed) {
      return this.audioService.currentSpeed;
    }
    return null;
  }

  get currentSpeedString(): string {
    if(this.audioService.currentSpeed) {
      return this.audioService.currentTempo.toString();
    }
    return '';
  }

  get speedString(): string {
    return this.translateService.instant('PLAYER.SPEED');
  }

  get hasMultipleTonalities(): boolean {
    return this.audioService.hasMultipleTonalities;
  }

  get tonalities(): Tonality[] {
    return this.audioService.tonalities;
  }

  get hasMultipleSpeeds(): boolean {
    if(this.audioService.currentTonality && this.audioService.speeds.length>1) {
      return true;
    }
    return false;
  }

  get speeds(): Speed[] {
    if(this.audioService.currentTonality){
        return this.audioService.speeds.sort((a,b) => a.speed-b.speed);
      }
    return null;
  }

  get currentPartIsLooping(): boolean {
    return this.tuneInfoService.currentPartIsLooping;
  }

  get isLooping(): boolean {
    return this.tuneInfoService.isLooping;
  }

  get isStartLoopPart(): boolean {
    return this.tuneInfoService.isStartLoopPart && this.tuneInfoService.isLooping;
  }

  get isEndLoopPart(): boolean {
    return this.tuneInfoService.isEndLoopPart && this.tuneInfoService.isLooping;
  }

  get hasPreviousPart(): boolean {
    return this.tuneInfoService.hasPreviousPart;
  }

  get hasNextPart(): boolean {
    return this.tuneInfoService.hasNextPart;
  }

  get nextPartLoop(): boolean {
    if(this.tuneInfoService.isLooping && this.tuneInfoService.loopStartPart===this.tuneInfoService.nextPart) {
      return true;
    }
    return false;
  }

  get countOffActive(): boolean {
    return this.audioService.countOffActive;
  }

  set countOffActive(active: boolean) {
    this.audioService.countOffActive = active;
  }

  get hasCountOff(): boolean {
    return this.audioService.hasCountOff;
  }

  get isRecOn(): boolean {
    return this.audioService.recMode;
  }

  get recButtonColor(): string {
    if (this.isRecOn) {
      return 'primary';
    }
    return 'light';
  }

/*  get mixIndex() {
    return 0;
  }

  get lyricsIndex() {
    if(this.hasLyrics)
      return 1;
    return undefined;
  }

  get infoIndex() {
    if(this.lyricsIndex)
      return 2;
    return 1;
  }

  get sheetsIndex() {
    if(!this.hasSheets)
      return undefined;
    if(this.lyricsIndex)
      return 3;
    return 2;
  }

  get arrangementIndex() {
    if(this.lyricsIndex) {
      if(this.sheetsIndex)
        return 4;
      return 3;
    } else if(this.sheetsIndex) {
      return 3;
    }
    return 2;
  }*/

  get tabsCount() {
    if(this.hasLyrics && this.hasSheets)
      return 5;
    else if(!this.hasLyrics && !this.hasSheets)
      return 3;
    return 4;
  }

  get mixColor(): string {
    if (this.swiperIndex===this.mixIndex) {
      return 'primary';
    }
    return 'light';
  }

  get lyricsColor(): string {
    if (this.swiperIndex===this.lyricsIndex) {
      return 'primary';
    }
    return 'light';
  }

  get mainColor(): string {
    if (this.swiperIndex===this.infoIndex) {
      return 'primary';
    }
    return 'light';
  }

  get sheetsColor(): string {
    if (this.swiperIndex===this.sheetsIndex) {
      return 'primary';
    }
    return 'light';
  }

  get arrangementColor(): string {
    if (this.swiperIndex===this.arrangementIndex) {
      return 'primary';
    }
    return 'light';
  }

  get swiperIndex(): number {
    if(this.swiper) {
      return this.swiper.activeIndex;
    }
    return 1;
  }

  get isRecDisabled(): boolean {
    if(this.isPlaying && !this.audioService.recMode) {
      return true;
    }
    return false;
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  get ariaPartDesignation() {
    if(this.isInPickup) {
//      console.log(this.translateService.instant('PLAYER.PICKUP')+' '+this.nextPart)
      return this.currentPart+', '+this.translateService.instant('PLAYER.PICKUP')+' '+this.nextPart;
    }
    return this.currentPart;
  }


  get ariaCountOffButton() {
    if(this.countOffActive) {
      return this.translateService.instant('PLAYER.COUNTOFFOFF');
    }
    return this.translateService.instant('PLAYER.COUNTOFFON');
  }

  get ariaRecordButton() {
    if(this.isRecOn) {
      return this.translateService.instant('PLAYER.CANCELRECORD');
    }
    return this.translateService.instant('PLAYER.COUNTOFFON');
  }

  ngOnInit() {
    this.audioService.initCompleted$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(init => {
        if(init) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      })

    if(Capacitor.getPlatform() === 'web') {
      this.isIos = false;
      this.isAndroid = false;
    } else {
      this.isIos = this.platform.is('ios');
      this.isAndroid = this.platform.is('android');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Spacebar') {
      this.playPause();
    }
  }

  playPause() {
    if(!this.isPlaying) {
      this.audioService.play();
    } else {
      this.audioService.pause();
    }
  }

  setupRecording() {
    if(!this.audioService.recMode) {
      this.audioService.setupRecording();
    } else {
      this.audioService.pause();
    }
  }

  stop() {
    this.audioService.stop();
  }

  selectTonality($event) {
    if($event.target.value) {
      this.audioService.selectTonality($event.target.value);
    }
  }

  async presentSpeedSelect() {
    const speeds = this.tuneInfoService.sortedSpeeds;
    const ticks: number[] = [];
    for (const s of speeds) {
      if(s.speeddesignation) {
        ticks.push(s.speeddesignation.tempo)
      } else {
        ticks.push(s.speed);
      }
    }
    let min = 0;
    let max = 10;
    if(speeds.length>1) {
      let minSpeed = speeds[0].speed
      if(speeds[0].speeddesignation) {
        minSpeed = speeds[0].speeddesignation.tempo
      }
      let maxSpeed = speeds[speeds.length-1].speed
      if(speeds[speeds.length-1].speeddesignation) {
        maxSpeed = speeds[speeds.length-1].speeddesignation.tempo
      }
      min = Math.round(minSpeed*environment.minSpeedFactor/10)*10
      max = Math.round(maxSpeed*environment.maxSpeedFactor/10)*10
    } else if(speeds.length>0) {
      let minSpeed = speeds[0].speed
      if(speeds[0].speeddesignation) {
        minSpeed = speeds[0].speeddesignation.tempo
      }
      min = Math.round(minSpeed*environment.minSpeedFactor/10)*10
      max = Math.round(minSpeed*environment.maxSpeedFactor/10)*10
    }
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: SelectTempoPage,
      componentProps: {
        minTempo: min,
        maxTempo: max,
        ticks: ticks,
        startValue: this.audioService.currentTempo
      },
      cssClass: 'tempo-modal',
    });
    await modal.present();
  }

  async selectSpeed($event) {
    if($event.target.value) {
      const newSpeed = $event.target.value as Speed;
      if(newSpeed.id !== this.audioService.currentSpeed.id) {
        this.changeSpeed.emit(newSpeed);
      }
    }
  }

  loop() {
    if(this.tuneInfoService.currentShowPart) {
      this.tuneInfoService.setLoopParts(this.tuneInfoService.currentShowPart, this.tuneInfoService.currentShowPart);
    } else if(this.tuneInfoService.hasNextPart) {
      this.loopNextPart();
    }
  }

  resetLoop() {
    this.tuneInfoService.resetLoop();
  }

  async jumpToNextPart() {
    if(this.isJumping) {
      return;
    }
    this.isJumping = true;
    await this.tuneInfoService.jumpToNextPart();
    this.isJumping = false;
  }

  async jumpToNextTune() {
    if(this.isJumping) {
      return;
    }
    this.isJumping = true;
    if (this.hasNextTune) {
      this.playNextTune.emit();
    }
    this.isJumping = false;
  }

  async jumpToPreviousPart() {
    if(this.isJumping) {
      return;
    }
    this.isJumping = true;
    await this.tuneInfoService.jumpToPreviousPart();
    this.isJumping = false;
  }

  async jumpToLoopBegin() {
    if(this.isJumping) {
      return;
    }
    this.isJumping = true;
    await this.tuneInfoService.jumpToLoopStart();
//    await this.audioService.setCurrentTime(this.audioService.loopStart, true, -1);
    this.isJumping = false;
  }

  loopNextPart() {
    this.tuneInfoService.setLoopParts(this.tuneInfoService.nextPart, this.tuneInfoService.nextPart, false);
  }

  toggleCountOff() {
    this.countOffActive = !this.countOffActive;
    this.audioService.countOffUpdated();
  }

  async openMixView() {
    if(this.swiper) {
      this.swiper.slideTo(this.mixIndex);
    }
  }

  async openLyricsView() {
    if(this.swiper) {
      this.swiper.slideTo(this.lyricsIndex);
    }
  }

  async openSheetsView() {
    if(this.swiper) {
      this.swiper.slideTo(this.sheetsIndex);
    }
  }


  async openArrangementView() {
    if(this.swiper) {
      this.swiper.slideTo(this.arrangementIndex);
    }

  }

  async openMainView() {
    if(this.swiper && this.swiper.activeIndex===this.infoIndex) {
      this.toggleCover.emit();
    } else if(this.swiper) {
      this.swiper.slideTo(this.infoIndex);
    }
  }

  async showTonalitiesPopover() {
/*    const popover = await this.popoverController.create({
      component: TonalitiesMenuComponent,
      dismissOnSelect: true,
      componentProps: {
        tonalities: this.tonalities
      }
    });
    await popover.present();*/
    this.tonalitySelectRef.open();
  }

  async showSpeedsPopover() {
    if(this.isWeb) {
      this.speedSelectRef.open();
    } else {
      this.presentSpeedSelect();
    }
  }
}
