    <!-- Monthly Plan Button -->
    <ion-button
      expand="block"
      color="primary"
      fill="solid"
      class="ion-margin-bottom"
      [ariaLabel]="monthly_aria"
      [disabled]="!selectedMonthlyPlan"
      (click)="subscribe('monthly')"
    >
      @if (selectedMonthlyPlan) {
        {{'SUBSCRIPTIONMODALS.MONTHLYPRICE' | translate: { price_monthly: (price_monthly | number: '1.0-2' | currency: currencyCode_monthly+' ')  } }}
      }
      @else {
        {{'SUBSCRIPTIONMODALS.CHOOSESIZE' | translate}}
      }
    </ion-button>

    <!-- Annual Plan Button -->
    <ion-button
      expand="block"
      color="primary"
      fill="solid"
      [ariaLabel]="annual_aria"
      [disabled]="!selectedAnnualPlan"
      (click)="subscribe('annual')"
    >
    @if (selectedAnnualPlan) {
      {{'SUBSCRIPTIONMODALS.ANNUALPRICE' | translate: { price_annual: (price_annual | number: '1.0-2' | currency: currencyCode_annual+' ') } }}
    }
    @else {
      {{'SUBSCRIPTIONMODALS.CHOOSESIZE' | translate}}
    }
    </ion-button>
    <div class="ion-text-center remark" attr.aria-label="{{'PAYWALL.ARIASAVING' | translate: { price_annual: (price_annual/12 | number: '1.0-2' | currency: currencyCode_monthly+' '), reduction: 15  } }}">
      {{'SUBSCRIPTIONMODALS.ANNUALSAVE' | translate: { price_annual: (price_annual/12 | number: '1.0-2' | currency: currencyCode_monthly+' '), reduction: 15  } }}
    </div>
