import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonRadioGroup, Platform, SearchbarCustomEvent } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    UpperCasePipe
  ]
})
export class SearchableSelectComponent  implements OnInit, OnChanges {
  bandonTranslate = inject(BandONTranslationsService)

  @Input() title = '';
  @Input() data: any[];
  @Input() multiple = false;
//  @Input() itemTextField = 'name';
  @Input() idTextField = 'id';
  @Input() value: any = undefined;
  @Input() placeholder = ''
  @Input() disabled = false;
  @Input() displayFn: (item: any) => { text: string; imageUrl?: string } = (item) => ({
    text: item?.designation || item?.name || item?.label || item?.toString(),
    imageUrl: item?.image || item?.icon || '', // Adjust keys according to your data structure
  });
  @Input() ariaFn: (item: any) => string = (item) => {
    if(item) {
      return this.title+' '+this.displayFn(item).text
    }
    return this.title;
  };
  @Input() ariaSelectFn: (item: any) => string = (item) => {
    if(item) {
      return this.displayFn(item).text
    }
    return this.title;
  };
  //TODO: save old selection?

  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild('radioGroup') radioGroup: IonRadioGroup;

  platform = inject(Platform)
  translate = inject(TranslateService)

  isIos: boolean;
  isAndroid: boolean;

  isOpen = false;
  selected = [];
  filtered = [];

  constructor() {
    this.title = this.translate.instant('TUNES.SELECT');
    this.placeholder = this.translate.instant('TUNES.SELECT');
  }

  ngOnInit() {
    this.isIos = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.filtered = this.data;

  }

  open() {
    if(!this.disabled) {
      this.isOpen = true;
    }
  }

  cancel() {
    this.isOpen = false;
  }

  confirm() {
    this.isOpen = false;

    if(this.multiple) {
      this.selectedChanged.emit(this.selected);
    } else {
      if(this.radioGroup && this.radioGroup.value) {
        this.value = this.radioGroup.value;
        this.selectedChanged.emit([this.value]);
      }
    }
  }

  itemSelected() {
    this.selected = this.data.filter((item) => item.selected);
  }

  selectItem(event: any) {
//    this.value = event.target.value;
  }

  filter(event: SearchbarCustomEvent) {
    const filter = event.detail.value.toLowerCase();
    this.filtered = this.data.filter(item => this.displayFn(item).text.toLowerCase().indexOf(filter) >= 0);
  }

  compareWith(o1, o2) {
    if(!o1 && !o1) {
      return false;
    } else if (o1.id !== undefined && o2.id !== undefined) {
      return o1.id === o2.id;
    } else if (o1.uid !== undefined && o2.uid !== undefined) {
      return o1.uid === o2.uid;
    } else {
      return false; // If neither id nor uid exist or if they are not matching
    }
  }

/*  leaf = (obj) =>
    this.itemTextField.split('.').reduce((value, el) => value[el], obj);*/

  id = (obj) =>
    this.idTextField.split('.').reduce((value, el) => value[el], obj);


}
