import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { IonItemSliding, IonicModule } from '@ionic/angular';
import { AuthenticationService, GroupRole, Relationship, Role, UserModel } from 'bandon-shared';
import { CachedImageComponent, CachedImgSource } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContactsService } from 'src/app/services/social/contacts.service';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CachedImageComponent,
    TranslateModule
  ]
})
export class UserItemComponent  implements OnInit, OnChanges, OnDestroy {
  authService =  inject(AuthenticationService)
  httpClient = inject(HttpClient)
  sanitizer = inject(DomSanitizer)
  contactsService = inject(ContactsService)
  translate = inject(TranslateService)
  userDataService = inject(UserDataService)

  @Input() user: UserModel;
  @Input() role: Role;
  @Input() status: 'myself' | 'request' | 'established' | 'asked' | 'hide' | 'none' = 'request';
  @Input() groupRole: GroupRole;
  @Input() enableSliding = true;
  @Input() requestText = this.translate.instant('CONTACTS.SENDREQUEST')
  @Input() showRequestAlways = false;
  @Input() marginEnd = true;
  @Input() showUsername = true;

  @Output() onAcceptRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDeleteContact: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSendRequest: EventEmitter<void> = new EventEmitter<void>();

  _src: any;

  private unsubscribe$ = new Subject<void>();

  get ariaLabel(): string {
    let out = '';
    if(this.status==='established' || this.status==='myself') {
      out += this.user.name;
      if(this.status==='myself') {
        out += ` (${this.translate.instant('CONTACTS.ME')})`;
      }
    }
    else {
      out += this.user.username
    }
    if(this.status==='asked') {
      out += `(${this.translate.instant('CONTACTS.OPENREQUEST')})`;
    }
    if(this.status==='established'|| this.status==='myself') {
      out += `, ${ this.user.username }, ${this.user.email}`;
    }
    if(this.role) {
      out += `, ${ this.role.designation }`;
    }
    if(this.groupRole && this.groupRole.designation==='Administrator') {
      out += `, ${ this.translate.instant('CONTACTS.ADMIN')}`;
    }
    if(this.groupRole && this.groupRole.designation==='Owner') {
      out += `, ${ this.translate.instant('CONTACTS.OWNER')}`;
    }
    return out;
  }

  constructor() { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.user && this.user.img && this.userDataService.user) {
        const token = this.authService.getIDToken();
        const headers = new HttpHeaders().set('Authorization', token);

        this.httpClient.get(`${environment.apiURL}/users/${this.user.uid}/img`, { headers, responseType: 'blob'})
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: async resp => {
              if (resp) {
                let objectURL = URL.createObjectURL(resp);
                this._src = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              }
            }
          });
      } else {
        this._src = undefined;
      }
  }

  acceptRequest() {
    this.onAcceptRequest.emit();
  }

  deleteContact(event: any, slidingItem: IonItemSliding) {
    this.onDeleteContact.emit();
    slidingItem.close();
  }

  sendRequest() {
    this.onSendRequest.emit();
  }
}
