<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()"  color="primary">
        {{ 'PLAYLISTS.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="done()" color="primary">
        {{ 'PLAYLISTS.DONE' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center ion-margin-horizontal" role="heading" aria-level="2">
      {{ 'PLAYLISTS.ADDTOPL' | translate: {designation: plDesignation} }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="searchTerm"
      (ionInput)="searchTune($event)"
      placeholder="{{ 'COLLECTION.MEDIAS' | translate }}"
      showCancelButton="focus"
      cancelButtonText="{{ 'COLLECTION.CANCEL' | translate }}"
      debounce="10"
      animated
    >
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <app-tune-item *ngFor="let tune of shownTunes"
      openAction="none"
      [showDownloadState]="false"
      [tune]="tune"
      (click)="addToPlaylist(tune)"
    >
      <div slot="end">
        <ion-button fill="clear" class="add-button" class="ion-margin-end">
          @if(checkIfTuneIsInPlaylist(tune)) {
            <ion-icon slot="icon-only" name="checkmark" size="large" ariaLabel="{{ 'PLAYLISTS.ISINPLAYLIST' | translate }}"></ion-icon>
          } @else {
            <ion-icon slot="icon-only" name="add-circle-outline" size="large" ariaLabel="{{ 'PLAYLISTS.ADDTUNETOPL' | translate: { tune: tune.title} }}"></ion-icon>
          }
        </ion-button>
      </div>
    </app-tune-item>
  </ion-list>

  <ion-infinite-scroll (ionInfinite)="loadMoreTunes($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>

