<!--<ion-select
  label="{{'PAYWALL.SIZE' | translate}}"
  placeholder="{{'PAYWALL.CHOOSE' | translate}}"
  cancelText="{{'PAYWALL.CANCEL' | translate}}"
  (ionChange)="selectSize($event.detail.value)"
  [value]="selectedPlanID"
  [interfaceOptions]="{ cssClass: 'band-on-alert'}"
>
  @for (plan of subcription_plans; track $index) {
    @if(!isExcluded(plan)) {
      <ion-select-option [value]="plan.id" [ariaLabel]="getSizeAriaLable(plan)">
        @if(getNextSmallerPlan(plan)) {
          {{ getNextSmallerPlan(plan).membercount + 1 }}-{{ plan.membercount }} {{'PAYWALL.PERSONS' | translate}}
          ({{ getMonthlyPrice(plan)/plan.membercount| number: '1.0-2' | currency: getMonthlyCurrency(plan)+' ' }}/Person)
        } @else {
          1-{{ plan.membercount }} {{'PAYWALL.PERSONS' | translate}}
        }
        </ion-select-option>
    }
  }
</ion-select>-->

<div class="select-container" (click)="open()" aria-haspopup="listbox" aria-expanded="false" role="button">
  <ion-label color="light" aria-hidden="true">{{'PAYWALL.SIZE' | translate}}</ion-label>
  <div class="visually-hidden">{{ ariaFn(selectedPlan) }}</div>
  @if (selectedPlan) {
    <span class="ellipsis-overflow" aria-hidden="true">
      @if(getNextSmallerPlan(selectedPlan)) {
        {{ getNextSmallerPlan(selectedPlan).membercount + 1 }}-{{ selectedPlan.membercount }} {{'PAYWALL.PERSONS' | translate}}
      } @else {
        1-{{ selectedPlan.membercount }} {{'PAYWALL.PERSONS' | translate}}
      }
    </span>
  }
  @else {
    <span class="placeholder ellipsis-overflow" aria-hidden="true">
      {{'PAYWALL.CHOOSE' | translate}}
    </span>
  }

  @if (isIos) {
    <ion-icon name="chevron-expand-outline" color="primary" aria-hidden="true"></ion-icon>
  }
  @else {
    <ion-icon name="caret-down-outline" [ngClass]="{ 'rotate-icon': isOpen, 'reset-icon': !isOpen }" aria-hidden="true"></ion-icon>
  }
</div>

<ion-modal [isOpen]="isOpen" (didDismiss)="isOpen = false" class="select-modal">
  <ng-template>
    <ion-header [class.android-select-header]="!isIos">
      <ion-toolbar>
        <ion-title>{{'PAYWALL.SIZE' | translate}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="modal-background ion-text-center">
      <ion-radio-group [value]="selectedPlan" [compareWith]="compareWith" (ionChange)="selectSize($event.detail.value.id)" #radioGroup>
        @for (plan of subcription_plans; track $index) {
          <ion-item class="modal-background" lines="none">
            <ion-radio labelPlacement="end" justify="start" [value]="plan" [ariaLabel]="ariaSelectFn(plan)">
              @if(getNextSmallerPlan(plan)) {
                {{ getNextSmallerPlan(plan).membercount + 1 }}-{{ plan.membercount }} {{'PAYWALL.PERSONS' | translate}}
                <div style="font-size: small;">({{'PAYWALL.PERPERSON' | translate: { price: getMonthlyPrice(plan)/plan.membercount| number: '1.0-2' | currency: getMonthlyCurrency(plan)+' '} }})</div>
              } @else {
                1-{{ plan.membercount }} {{'PAYWALL.PERSONS' | translate}}
                <div style="font-size: small;">({{'PAYWALL.PERPERSON' | translate: { price: getMonthlyPrice(plan)/plan.membercount| number: '1.0-2' | currency: getMonthlyCurrency(plan)+' '} }})</div>
              }
            </ion-radio>
          </ion-item>
        }
        @empty {
          <div class="ion-text-center ion-margin">
            {{ 'TUNES.NORESULTSELECT' | translate }}
          </div>
        }
      </ion-radio-group>
    </ion-content>

    <ion-footer [class.android-select-footer]="!isIos">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="cancel()" color="primary">{{ 'TUNES.CANCEL' | translate | uppercase }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="confirm()" color="primary">{{ 'TUNES.OK' | translate | uppercase }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
