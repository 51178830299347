<swiper-container
  *ngIf="shopWindows.length>0"
  speed="300"
  pagination="false"
  [slidesPerView]="slidesPerView"
  autoplay-delay="3000"
  autoplay-disable-on-interaction="true"
  auto-height="true"
  [centeredSlides]="centeredSlides"
  space-between="30"
  class="swiper-container">
  @for (window of shopWindows; track $index) {
    <swiper-slide class="slide" (click)="openLink(window)">
      <div class="image-overlay-container">
        <app-cached-image
          [src]="getWindowImgSrc(window)"
          class="shop-window-img"
          [ariaInfo]="getWindowAriaLabel(window, $index)"
          role="link">
        </app-cached-image>
        <div class="gradient-overlay"></div>
        <div class="text-overlay">
          <h1 class="title">{{ window.title }}</h1>
          <h2 class="subtitle">{{ window.subtitle }}</h2>
        </div>
      </div>      <!--<app-cached-image [src]="getWindowImgSrc(window)" class="shop-window-img"
        [ariaInfo]="getWindowAriaLabel(window, $index)" role="link"></app-cached-image>-->
    </swiper-slide>
  }
</swiper-container>
