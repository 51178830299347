import { Injectable } from '@angular/core';
import { ArrangementShowPart, Meter, Part, Speed, Tune } from 'bandon-shared';
import { AudioService } from './audio.service';
import { AudioEngine } from '@musicdose/audioengine';
import { BehaviorSubject } from 'rxjs';

export interface TimeStampDefinition {
  speed?: Speed;
  showPartIndex?: number;
  partIndex?: number;
  timestamp?: number;
  beatNumber?: number;
  partProgress?: number;
  beatProgress?: number;
}

@Injectable({
  providedIn: 'root'
})
export class TuneInfoService {

//  public filteredParts: Part[] = [];
  public filteredPartsBehaviour = new BehaviorSubject<Part[]>([]);
  public filteredParts$ = this.filteredPartsBehaviour.asObservable();

//  public shownParts: ArrangementShowPart[] = [];
  public shownPartsBehaviour = new BehaviorSubject<ArrangementShowPart[]>([]);
  public shownParts$ = this.shownPartsBehaviour.asObservable();

  public loopStartPart?: ArrangementShowPart;
  public loopEndPart?: ArrangementShowPart;

  public loopStartDefBehaviour = new BehaviorSubject<TimeStampDefinition | undefined>(undefined);
  public loopStartDef$ = this.loopStartDefBehaviour.asObservable();
  public loopEndDefBehaviour = new BehaviorSubject<TimeStampDefinition | undefined>(undefined);
  public loopEndDef$ = this.loopEndDefBehaviour.asObservable();

  public oldTimestampDef?: TimeStampDefinition;
  public oldLoopstartDef?: TimeStampDefinition;
  public oldLoopendDef?: TimeStampDefinition;

  constructor(
    private audioService: AudioService
  ) {}

  get tune(): Tune {
    return this.audioService.tune;
  }

  get currentShowPart(): ArrangementShowPart {
    if(this.shownPartsBehaviour.value && this.currentPart) {
      for (const showPart of this.shownPartsBehaviour.value) {
        if(showPart.parts.find(p => p.id === this.currentPart.id )) {
          return showPart;
        }
      }
    }
    return null;
  }

  get currentPart(): Part {
    if(this.tune) {
      for (const part of this.filteredPartsBehaviour.value) {
        let partTimeStart = Math.floor(part.timestart);
        let partTimeEnd = Math.floor(part.timeend);
        if (partTimeStart<=this.audioService.currentTimeMS && partTimeEnd>this.audioService.currentTimeMS) {
//          console.log(part.designation, partTimeStart, partTimeEnd, this.audioService.currentTimeMS);
          this.audioService.updatePlayingInfo(part);
          return part;
        }
      }
    }
    return null;
  }

  get currentPartIsLooping(): boolean {
    if(this.loopStartPart===this.currentShowPart && this.loopEndPart===this.currentShowPart) {
      return true;
    }
    return false;
  }

  get isLooping(): boolean {
    if(this.loopEndPart && this.loopStartPart) {
      return true;
    }
    return false;
  }

  get isStartLoopPart(): boolean {
    if(this.loopStartPart===this.currentShowPart) {
      return true;
    }
    return false;
  }

  get startLoopPart(): ArrangementShowPart {
    return this.loopStartPart;
  }

  get isEndLoopPart(): boolean {
    if(this.loopEndPart===this.currentShowPart) {
      return true;
    }
    return false;
  }

  get endLoopPart(): ArrangementShowPart {
    return this.loopEndPart;
  }

  get hasPreviousPart(): boolean {
    if(this.tune) {
      const index = this.filteredPartsBehaviour.value.indexOf(this.currentPart);
      if(index > 0) {
        return true;
      } else if(index===-1) {
        for(const p of this.shownPartsBehaviour.value) {
          if(p.timestart/1000<this.audioService.currentTime) {
            return true;
          }
        }
      }
    }
    return false;
  }

  get hasNextPart(): boolean {
    if(this.tune && this.shownPartsBehaviour.value[0]) {
      const index = this.shownPartsBehaviour.value.indexOf(this.currentShowPart);
      if(index < this.shownPartsBehaviour.value.length-1 && index>-1) {
        return true;
      } else if(index===-1) {
        for(const p of this.shownPartsBehaviour.value) {
          if(p.timestart/1000>this.audioService.currentTime) {
            return true;
          }
        }
      }
    }
    return false;
  }

  get nextPart(): ArrangementShowPart {
    if(this.tune && this.shownPartsBehaviour.value) {
      const index = this.shownPartsBehaviour.value.indexOf(this.currentShowPart);
      if(index < this.shownPartsBehaviour.value.length-1 && index>-1) {
        return this.shownPartsBehaviour.value[index+1];
      } else if(index===-1) {
        for(const p of this.shownPartsBehaviour.value) {
          if(p.timestart/1000>this.audioService.currentTime) {
            return p;
          }
        }
      }
    }
    return null;
  }

  get isInPickup(): boolean {
    if(this.hasNextPart) {
      if(this.nextPart.timepickup || this.nextPart.timepickup==0) {
        let timepickup = Math.floor(this.nextPart.timepickup);
        if(this.audioService.currentTimeMS>=timepickup) {
          return true;
        }
      }
    }
    return false;
  }

  get sortedSpeeds(): Speed[] {
    let out: Speed[] = [];
    for (const t of this.tune.tracks) {
      if(t.speed) {
        const exSpeed = out.find(s => s.speed===t.speed.speed)
        if(!exSpeed) {
          out.push(t.speed);
        }
      }
    }
    return out.sort((s1, s2) => s1.speed-s2.speed);
  }

  async init() {
    await AudioEngine.addListener('jumpToNextPart', () => {
      if(this.hasNextPart) {
        this.jumpToNextPart();
      }
    });
    await AudioEngine.addListener('jumpToPreviousPart', () => {
      if(this.hasPreviousPart) {
        this.jumpToPreviousPart();
      }
    });
    await AudioEngine.addListener('setLoop', res => {
      if(res.state) {
        this.setLoopParts(this.currentShowPart, this.currentShowPart);
      } else {
        this.resetLoop();
      }
    });

    await this.audioService.loadSpeedCompleted$
      .subscribe(res => {
        if(res) {
          this.setupArrangementInfo(this.audioService.currentSpeed);
        }
      });

    await this.audioService.jumpToStart$
      .subscribe(res => {
        if(res) {
          this.jumpToStart();
        }
      });

  }

  public async unloadTune() {
    this.audioService.unloadTune();

    this.loopStartPart = null;
    this.loopEndPart = null;
    this.filteredPartsBehaviour.next([]);
    this.shownPartsBehaviour.next([]);

    this.oldTimestampDef = undefined;
    this.oldLoopstartDef = undefined;
    this.oldLoopendDef = undefined;
  }

  setupArrangementInfo(speed: Speed) {
    //Sort parts ascending by time in tune
    let filteredParts = this.tune.parts.filter(p => !p.tunespeedid || p.tunespeedid===speed.id);

    let sortByBeat = false;
    if (filteredParts.length>0 && filteredParts[0].barend>0) {
      sortByBeat = true;
    }

    this.tune.parts = this.tune.parts.sort((p1, p2) => {
      if(sortByBeat) {
        return p1.barstart-p2.barstart;
      } else {
        return p1.timestart-p2.timestart;
      }
    })
    filteredParts = this.tune.parts.filter(p => !p.tunespeedid || p.tunespeedid===speed.id);

    //Generate bar numbers
    let barStartNumber = 1;
    let metrum: Meter | undefined = undefined;
    if(this.tune.meters && this.tune.meters[0]) {
      //TODO: pick correct metrum
      metrum = { id: 3, nn: this.tune.meters[0].meter, dd: 4 };
    } else {
      metrum = { id: 3, nn: 4, dd: 4 };
    }
    let tempo = speed.speed;
    let trackTempo = this.tune.tracks.find(t => t.speed)
    if(trackTempo) {
      if(trackTempo.speed.speed) {
        tempo = trackTempo.speed.speed;
      } else if(trackTempo.speed.speeddesignation && trackTempo.speed.speeddesignation.tempo) {
        tempo = trackTempo.speed.speeddesignation.tempo;
      }
    }
    let lastArrangementPart: ArrangementShowPart | undefined;
    this.shownPartsBehaviour.next([]);
    for(const part of filteredParts) {
      //Translate bar based information to time based information
      if (part.barstart && part.barend) {
        part.timestart = this.getBarTime(part.barstart)*1000;
        part.timeend = this.getBarTime(part.barend+1)*1000;
        if(part.pickup) {
          part.timepickup = part.timestart-part.pickup/this.audioService.currentSpeed.speed*60000;
        } else {
          part.timepickup = undefined;
        }
      }

      if(!lastArrangementPart || lastArrangementPart.designation!==part.designation) {
        if(lastArrangementPart) {
          lastArrangementPart.timeend = part.timestart;
        }
        lastArrangementPart = { designation: part.designation, parts: [], timestart: part.timestart, beatsCount: 0,
          showbarslider: true, selected: true, id: part.id, timepickup: part.timepickup };
//        console.log('Last Arrangement part changed: ', lastArrangementPart);
        this.shownPartsBehaviour.value.push(lastArrangementPart);
      }
      lastArrangementPart.parts.push(part);
      if(part.barstartnumber) {
        barStartNumber = part.barstartnumber;
      }
      part.rBarStart = barStartNumber;

      if(part.meter) {
        metrum = part.meter;
      }
      part.rMeter = metrum;

      if(part.tempo) {
        tempo = part.tempo;
      }
      part.rTempo = tempo;

      const duration = (part.timeend-part.timestart)/(1000.0*60);

      const beats = Math.round(duration*part.rTempo);
      const bars = Math.round(beats/part.rMeter.nn);
      lastArrangementPart.beatsCount += beats;
      lastArrangementPart.showbarslider &&= part.showbarslider;

      part.rBarEnd = part.rBarStart+bars-1;
      barStartNumber += bars;

      if(filteredParts.indexOf(part)===filteredParts.length-1) {
        lastArrangementPart.timeend = part.timeend;
      }
//      console.log(part.designation, part.timestart, part.timeend);
    }
    this.shownPartsBehaviour.next(this.shownPartsBehaviour.value)

/*    this.filteredPartsBehaviour.next(filteredParts.sort((p1, p2) => {
      if(sortByBeat) {
        return p1.barstart-p2.barstart;
      }
      return p1.timestart-p2.timestart;
    }));*/
    this.filteredPartsBehaviour.next(filteredParts);

//    console.log(this.filteredPartsBehaviour.value)
  }

  public async setLoopParts(start: ArrangementShowPart, end: ArrangementShowPart, jumpToNext = true) {
    this.loopStartPart = start;
    this.loopEndPart = end;
    this.determineLoopParts(start, end);

    const timeStart = this.getLoopStartTime(start);
    this.loopStartDefBehaviour.next(this.getTimestampDef(timeStart));
    const timeEnd = this.getLoopEndTime(end);
    this.loopEndDefBehaviour.next(this.getTimestampDef(timeEnd, true));

    if(jumpToNext && timeStart>this.audioService.currentTime) {
      await this.jumpToPart(start, true, false, false);
    }
    if(timeEnd<this.audioService.currentTime) {
      await this.jumpToPart(start, false, false, false);
    }
    await this.audioService.setLoopTimes(timeStart, timeEnd, jumpToNext);
  }

  public async setLoopTimes(timeStart: number, timeEnd: number) {
    this.loopStartDefBehaviour.next(this.getTimestampDef(timeStart));
    this.loopEndDefBehaviour.next(this.getTimestampDef(timeEnd, true));

    await this.audioService.setLoopTimes(timeStart, timeEnd);
  }

  public resetLoop() {
    this.loopStartPart = null;
    this.loopEndPart = null;
    this.loopStartDefBehaviour.next(undefined);
    this.loopEndDefBehaviour.next(undefined);
    if(this.tune) {
      for (const part of this.shownPartsBehaviour.value) {
        part.selected = true;
      }
    }

    this.audioService.resetLoop();
  }

  public async jumpToStart() {
    if(this.shownPartsBehaviour.value.length>0) {
      await this.jumpToPart(this.shownPartsBehaviour.value[0], true);
    } else {
      await this.audioService.setCurrentTime(this.audioService.startTime, true);
    }
  }

  public async jumpToNextPart() {
    const index = this.shownPartsBehaviour.value.indexOf(this.currentShowPart);
    if(this.hasNextPart) {
      let playPickup = true;
      if (this.isInPickup) {
        playPickup = false;
      }
      await this.jumpToPart(this.shownPartsBehaviour.value[index+1], playPickup);
    }
  }

  public async jumpToPreviousPart() {
    const index = this.shownPartsBehaviour.value.indexOf(this.currentShowPart);
    if(index > 0) {
      await this.jumpToPart(this.shownPartsBehaviour.value[index-1], true);
    } else if(index === 0 || (index===-1 && !this.hasPreviousPart)) {
      await this.jumpToPart(this.shownPartsBehaviour.value[0], true);
    } else if(this.hasPreviousPart) {
      await this.jumpToPart(this.shownPartsBehaviour.value[this.shownPartsBehaviour.value.length-1], true);
    }
  }

  public async jumpToPartBegin() {
    await this.jumpToPart(this.currentShowPart, false, false);
  }

  public async jumpToPart(part: ArrangementShowPart, withPickup: boolean, resetLoop = true,
      playCountOff = this.audioService.countOffActive) {
//    console.log("Seek to part, init");

    let time = part.timestart/1000.0;

    if(/*resetLoop && */this.isLooping && this.loopStartPart && this.loopEndPart) {
      const startPartIndex = this.shownPartsBehaviour.value.indexOf(this.loopStartPart);
      const endPartIndex = this.shownPartsBehaviour.value.indexOf(this.loopEndPart);
      const newPartIndex = this.shownPartsBehaviour.value.indexOf(part);
      if(startPartIndex>-1 && endPartIndex>-1 && newPartIndex>-1 &&
          (startPartIndex>newPartIndex || endPartIndex<newPartIndex)) {
        this.resetLoop();
      } else if(startPartIndex===newPartIndex && this.audioService.loopStart>time) {
        resetLoop = true;
      }

    }
//    console.log("Seek to part, count off");


    const countOffD = this.getCountOffDuration(this.audioService.currentSpeed.speed, this.audioService.currentSpeed.countoffbeats);
    this.audioService.countOffDuration = countOffD;
//    console.log("Seek to part, set current time");
    if(withPickup && (part.timepickup || part.timepickup===0)) {
      time = part.timepickup/1000.0;
      const pickupD = countOffD-(part.timestart-part.timepickup)/1000;
      await this.audioService.setCurrentTime(time, true, pickupD, resetLoop);
    } else {
      await this.audioService.setCurrentTime(time, true, countOffD, resetLoop);
    }
//    console.log("Seek to part, done");

  }

  public async jumpToLoopStart() {
    let beat = 0;
    if(this.loopStartPart && this.loopStartPart.beatLoopStart) {
      beat = this.loopStartPart.beatLoopStart;
    }
    const countOffD = this.getCountOffDuration(this.audioService.currentSpeed.speed, this.audioService.currentSpeed.countoffbeats);
    const beatInfo = this.getBeatInfo(this.loopStartPart, beat);
    let pickupBeats = 0;
    if (beatInfo.beat !== 1) {
      pickupBeats = beatInfo.meter.nn-(beatInfo.beat-1);
    }
    const pickupD = this.getCountOffDuration(beatInfo.tempo,
      this.audioService.currentSpeed.countoffbeats, pickupBeats);
//    console.log('Pickup: ', pickupBeats, countOffD, pickupD);
    this.audioService.setCurrentTime(beatInfo.time, true, pickupD);
  }

  /**
   * This function returns the part for a given timestamp. It can be destinguished if at a border of two parts, the part before or after should be returned.
    @param {number} timestamp - the timestamp in s
    @param {boolean} getPartBefore - if the timestamp is at the end of a part and can therefore be in both. Return the ending part (true) or the one that starts new.
   * @returns the part to the timestamp
   */
  public getPartToTime(timestamp: number, getPartBefore = false): Part | undefined {
    for(const p of this.filteredPartsBehaviour.value) {
      if(getPartBefore && p.timestart/1000<=timestamp && p.timeend/1000>=timestamp) {
        return p;
      } else if(p.timestart/1000<=timestamp && p.timeend/1000>timestamp) {
        return p;
      }
    }
    return undefined;
  }

  /**
   * This function returns the show part for a given timestamp. It can be destinguished if at a border of two parts, the part before or after should be returned.
    @param {number} timestamp - the timestamp in s
    @param {boolean} getPartBefore - if the timestamp is at the end of a part and can therefore be in both. Return the ending part (true) or the one that starts new.
   * @returns the show part to the timestamp
   */
  public getShowPartToTime(timestamp: number, getPartBefore = false): ArrangementShowPart | undefined {
    for(const p of this.shownPartsBehaviour.value) {
      if(getPartBefore && p.timestart/1000<=timestamp && p.timeend/1000>=timestamp) {
        return p;
      } else if(p.timestart/1000<=timestamp && p.timeend/1000>timestamp) {
        return p;
      }
    }
    return undefined;
  }

  // TODO: just used to transfer the bar info into time info
  public getBarTime(bar: number): number {
    return (bar-1)*this.tune.meters[0].meter/this.audioService.currentSpeed.speed*60.0+this.audioService.startTime;
  }

  public getBarOfTime(time: number): number {
    if(this.tune) {
      for(const part of this.filteredPartsBehaviour.value) {
        let isLastPart = this.filteredPartsBehaviour.value.indexOf(part) === this.filteredPartsBehaviour.value.length-1;
        if(part.timestart<=time*1000 && (part.timeend>time*1000 || isLastPart)) {
            const partTime = time-part.timestart/1000;
            const beat = Math.floor(part.rTempo/60.*partTime);
            const bar = Math.floor(beat/part.rMeter.nn)+part.rBarStart;
            return bar;
        }
      }
    }
    return 0;

  }

  public getBeatOfTime(time: number): number {
    if(this.tune) {
      for(const part of this.filteredPartsBehaviour.value) {
        if(part.timestart<=time*1000 && part.timeend>=time*1000) {
            const partTime = time-part.timestart/1000;
            const beat = Math.floor(part.rTempo/60.*partTime);
            return beat;
        }
      }
    }
    return 0;
  }

  public getBeatOfTimeInShowPart(part: ArrangementShowPart, time: number) {
    let accBeats = 0;
    for(const p of part.parts) {
      const duration = (p.timeend-p.timestart)/(1000.0*60);
      const beats = Math.round(duration*p.rTempo);
      if(p.timestart/1000.0<=time && p.timeend>=time) {
        const partTime = time-p.timestart/1000;
        const beat = Math.floor(p.rTempo/60.*partTime);
        return beat+accBeats;
      }
      accBeats += beats;
    }
  }


  public getTimeOfBeat(part: Part, beat: number): number {
    return (60.0*beat)/part.rTempo + part.timestart/1000.0;
  }

  public getTimeOfBeatInShowPart(part: ArrangementShowPart, beat: number) {
    let accBeats = 0;
    for(const p of part.parts) {
      const duration = (p.timeend-p.timestart)/(1000.0*60);
      const beats = Math.round(duration*p.rTempo);
      if(accBeats<=beat && accBeats+beats>=beat) {
        return this.getTimeOfBeat(p, beat-accBeats);
      }
      accBeats += beats;
    }
  }

  public getShowPartToPart(part: Part) {
    for(const showPart of this.shownPartsBehaviour.value) {
      if(showPart.parts.includes(part)) {
        return showPart;
      }
    }
    return undefined;
  }

  /**
   * Gets the duration of a count off in seconds based on the speed instance.
   *
   * @Returns the count off duration in [s]
   *
   * @param speed - the speed instance for which we analyze the count off
   * @param pickupBeats - the number of beats which are a pickup
   */
  public getCountOffDuration(tempo: number, countOffBeats: number, pickupBeats: number = 0): number {
    if (countOffBeats>0 && tempo>0) {
      return (countOffBeats-pickupBeats)*60/tempo;
    }
    return 0;
  }

  public getBeatInfo(part: ArrangementShowPart, beat: number): { bar: number; beat: number; time: number; tempo: number; meter?: Meter } {
    let accBeats = 0;
    for(const p of part.parts) {
      const duration = (p.timeend-p.timestart)/(1000.0*60);
      const beats = Math.round(duration*p.rTempo);
      if(accBeats<=beat && accBeats+beats>=beat) {
        const bar = Math.floor((beat-accBeats)/p.rMeter.nn);
        const beatOut = (beat-accBeats)-p.rMeter.nn*bar + 1;
        const time = (beat-accBeats)/p.rTempo*60+p.timestart/1000.0;
        return {
          bar: bar+p.rBarStart,
          beat: beatOut,
          time,
          tempo: p.rTempo,
          meter: p.rMeter
        };
      }
      accBeats += beats;
    }
    return { bar: 0, beat: 0, time: 0, tempo: 0, meter: undefined };
  }

  public getLoopStartTime(loopPart: ArrangementShowPart) {
    let startTime = loopPart.timestart/1000;

    if(loopPart.timeLoopStart) {
      startTime = loopPart.timeLoopStart/1000;
    } else if(loopPart.beatLoopStart) {
      startTime = this.getBeatInfo(loopPart, loopPart.beatLoopStart).time;
    }
    return startTime;
  }

  public getLoopEndTime(loopPart: ArrangementShowPart) {
    let endTime = loopPart.timeend/1000;

    if(loopPart.timeLoopEnd) {
      endTime = loopPart.timeLoopEnd/1000;
    } else if(loopPart.beatLoopEnd) {
      endTime = this.getBeatInfo(loopPart, loopPart.beatLoopEnd).time;
    }
    return endTime;
  }

  public saveOldSpeedData() {
    this.oldTimestampDef = this.getTimestampDef(this.audioService.currentTime);
    this.oldLoopstartDef = this.loopStartDefBehaviour.value;
    this.oldLoopendDef = this.loopEndDefBehaviour.value;

  }

  /**
    This function calculates all the time data (showPart, part, beat, beatProgress) and returns the timestamp definition for a given timestamp.
    @param {number} timestamp - the timestamp in s
    @param {boolean} getPartBefore - if the timestamp is at the end of a part and can therefore be in both. Return the ending part (true) or the one that starts new.
  */
  public getTimestampDef(timestamp: number, getPartBefore = false): TimeStampDefinition {
    const definition: TimeStampDefinition = {}
    //TODO: not current part
    const showpart = this.getShowPartToTime(timestamp, getPartBefore);
    const part = this.getPartToTime(timestamp, getPartBefore);
    definition.speed = this.audioService.currentSpeed;
    definition.timestamp = timestamp;

    if(showpart && part) {
      const partDurationS = (showpart.timeend - showpart.timestart)/1000.0;
      definition.showPartIndex = this.shownPartsBehaviour.value.indexOf(showpart);
      definition.partProgress = (timestamp - showpart.timestart/1000) / partDurationS;
      if(showpart.showbarslider) {
        definition.beatNumber = this.getBeatOfTimeInShowPart(showpart, timestamp);
        const localBeatNumber = this.getBeatOfTime(timestamp);
        definition.beatProgress = (timestamp - this.getTimeOfBeat(part, localBeatNumber))/(60/part.rTempo)
      }
    }

    return definition
  }

  public calcNewTimestamp(timestampDef: TimeStampDefinition) {
    let newShowPart: ArrangementShowPart | undefined;
    if(timestampDef.showPartIndex!==undefined && this.shownPartsBehaviour.value.length>timestampDef.showPartIndex) {
      newShowPart = this.shownPartsBehaviour.value[timestampDef.showPartIndex];
    }
    let newTimestamp = 0;
    if(newShowPart) {
      if(newShowPart && timestampDef.beatNumber!==undefined && timestampDef.beatProgress!==undefined) {
        //Case 1: we have all the information about the beats in the part
        let beatInfo = this.getBeatInfo(newShowPart, timestampDef.beatNumber);
        newTimestamp = beatInfo.time + timestampDef.beatProgress * (60.0/beatInfo.tempo);
//        console.log(`case 1: new Part: ${newShowPart.designation}, beat ${timestampDef.beatNumber}, progress ${timestampDef.beatProgress} => t=${newTimestamp}`);
      } else if(timestampDef.partProgress!==undefined || timestampDef.beatProgress===0) {
        //Case 2: we jump into an amount of time of the new show part
        const partDurationS = (newShowPart.timeend - newShowPart.timestart)/1000.0;
        newTimestamp = newShowPart.timestart/1000.0 + timestampDef.partProgress * partDurationS;
//        console.log(`case 2: new Part: ${newShowPart.designation}, progress ${timestampDef.partProgress} => t=${newTimestamp}`);
      }
    }
    else if(timestampDef.speed && timestampDef.timestamp!==undefined) {
      //Case 3: no arrangement info: we just scale the timestamp with the speed ratio
      newTimestamp = timestampDef.speed.speed / this.audioService.currentSpeed.speed * timestampDef.timestamp;
//      console.log(`case 3: old timestamp ${timestampDef.timestamp} => t=${newTimestamp}`);
    }
    return newTimestamp;
  }

  /**
   * Copies the old loop definition into the new speed.
   */
  public copyLoop() {
    let newLoopStartPart: ArrangementShowPart | undefined;
    let newLoopStart: number | undefined = undefined;
    let newLoopEnd: number | undefined = undefined;

    if(this.oldLoopstartDef) {
      if(this.oldLoopstartDef.showPartIndex!==undefined && this.shownPartsBehaviour.value.length>this.oldLoopstartDef.showPartIndex) {
        newLoopStartPart = this.shownPartsBehaviour.value[this.oldLoopstartDef.showPartIndex];
      }
      newLoopStart = this.calcNewTimestamp(this.oldLoopstartDef);
    }
    let newLoopEndPart: ArrangementShowPart | undefined;
    if(this.oldLoopendDef) {
      if(this.oldLoopendDef.showPartIndex!==undefined && this.shownPartsBehaviour.value.length>this.oldLoopendDef.showPartIndex) {
        newLoopEndPart = this.shownPartsBehaviour.value[this.oldLoopendDef.showPartIndex];
      }
      newLoopEnd = this.calcNewTimestamp(this.oldLoopendDef);
    }

    if(newLoopStartPart && newLoopEndPart) {
      this.loopStartPart = newLoopStartPart;
      this.loopEndPart = newLoopEndPart;
      this.determineLoopParts(newLoopStartPart, newLoopEndPart);
//        this.setLoopParts(newLoopStartPart, newLoopEndPart);
    }
    if(newLoopStart!==undefined && newLoopEnd!==undefined) {
      this.setLoopTimes(newLoopStart, newLoopEnd);
    }
  }

  /**
   * Copies the old loop instrument mix into the new speed.
   */
  public copyMix() {
    if(this.oldTimestampDef?.speed) {
      const oldSpeedTracks = this.audioService.getTracksToSpeed(this.oldTimestampDef.speed);
      for(let track of this.audioService.activeTracks) {
        const oldTrack = oldSpeedTracks.filter(t => {
          return t.instrument?.id === track.instrument?.id &&
          t.voice?.id === track.voice?.id &&
          t.level?.id === track.level?.id
        });
        if(oldTrack.length>0) {
          track.wasMute = oldTrack[0].wasMute;
          this.audioService.muteTrack(track, oldTrack[0].muted);
          track.solo = oldTrack[0].solo;
          track.lastGainValue = oldTrack[0].lastGainValue;
          track.instrVolume = oldTrack[0].instrVolume;
          this.audioService.setTrackVolume(track);
        }
      }
    }
  }

  private determineLoopParts(start: ArrangementShowPart, end: ArrangementShowPart) {
    let active = false;
    for (const part of this.shownPartsBehaviour.value) {
      if (part === start) {
        active = true;
      }
      part.selected = active;
      if (part === end) {
        active = false;
      }
    }
  }

}
