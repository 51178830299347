import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Region } from '../waveform/waveform.component';

export interface Marker {
  time: number;
  label: string;
  width: number;
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class TimelineComponent  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('zoomContainer', { static: true }) zoomContainer: ElementRef;

  @Input() duration: number = 150; // Duration of the audio in seconds (e.g., 150 seconds = 2:30)
  //Shown Region
  @Input() shownRegion: Region | undefined = undefined;

  containerWidth: number = 0; // To track container width
  timeLabels: Marker[] = [];  // Array to hold dynamic time labels

  // Tick interval (e.g., every 10 units of time)
  tickInterval = 1;

  // Array to hold time ticks
  timeTicks: number[] = [];


  sizeObserver: ResizeObserver | undefined;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if(this.zoomContainer) {
      this.sizeObserver = new ResizeObserver(() => {
        // Calculate the container width and time labels on initialization
        this.calculateContainerWidth();
        this.generateTimeLabels();
        this.generateTimeTicks();
      })

      this.sizeObserver.observe(this.zoomContainer.nativeElement);
    }
  }


  ngOnDestroy(): void {
    if(this.sizeObserver) {
      this.sizeObserver.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateContainerWidth();
    this.generateTimeLabels();
    this.generateTimeTicks();
  }

  private calculateContainerWidth() {
    const containerElement = this.zoomContainer.nativeElement;
    if (containerElement) {
      this.containerWidth = containerElement.offsetWidth;
    }
  }

  private generateTimeLabels() {
    // Determine how many labels to display based on the duration and container width
    const minSpacing = 35; // Minimum pixel space between labels
    const maxLabels = Math.floor(this.containerWidth / minSpacing);
    let interval = this.selectInterval(maxLabels)

    if(interval>10) {
      this.tickInterval = 10;
    } else if(interval>1) {
      this.tickInterval = 1;
    } else {
      this.tickInterval = 0.5;
    }


    this.timeLabels = [];
    for (let time = 0; time <= this.duration; time += interval) {
      if(time+interval < this.duration) {
        this.timeLabels.push({ time, width: interval, label: this.formatTime(time) });
      } else {
        this.timeLabels.push({ time, width: this.duration-time, label: this.formatTime(time) });
      }
    }
  }

  private generateTimeTicks() {
    this.timeTicks.length = 0;
    for (let tick = 0; tick <= this.duration; tick += this.tickInterval) {
      this.timeTicks.push(tick);
    }
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  private selectInterval(maxLabels: number): number {
    const possibleIntervals = [1, 5, 10, 30, 60, 120, 300, 600]; // 1s, 5s, 10s, 30s, 1m, 2m, 5m, 10m

    // Determine the best interval that fits within the maximum number of labels
    for (let interval of possibleIntervals) {
      if (this.duration / interval <= maxLabels) {
        return interval;
      }
    }
    return 600; // Default to 10-minute intervals if duration is very long
  }
}
